import { useEffect } from "react";
import { useTradingStore } from "../use-trading-store-hook/useTradingStore";
import { tradingApi } from "../../api/requests/trading.api";

export const useTradingPlans = (): void => {
  const { tradingPlans, setTradingPlans, setIsTradingPlansLoading } =
    useTradingStore();

  useEffect(() => {
    const fetchTradingPlans = async () => {
      if (tradingPlans.length) {
        return;
      }

      setIsTradingPlansLoading(true);

      const plans = await tradingApi.getTradingPlans();

      setTradingPlans(plans);
      setIsTradingPlansLoading(false);
    };

    fetchTradingPlans();
  }, []);
};
