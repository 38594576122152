import styled from "styled-components";

export const TradingTopPlansModalStyledWrapper = styled.div`
  display: flex;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;
