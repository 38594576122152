import { useEffect } from "react";
import { UserStore } from "../../store/UserStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { localStorageUtils } from "../../utils/browser-utils/LocalStorage";
import { checkIsTokenExpired } from "../../utils/date-utils/checkIsTokenExpired";
import jwtDecode from "jwt-decode";
import { RefreshTokenResponseInterface } from "../../types/interfaces/RefreshToken.interface";
import { fetchUserById, refreshToken } from "./useAuth.utils";
import { PostsStore } from "../../store/PostsStore";
import { AppStore } from "../../store/AppStore";
import { useTradingStore } from "../use-trading-store-hook/useTradingStore";

export const useAuth = () => {
  const { setCurrentUser, setIsUserLoading } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;
  const { setPersistedPostContacts } = useStore(
    StoresEnum.POSTS_STORE
  ) as PostsStore;
  const { setTradingAccount, setTradingAccountState } = useTradingStore();

  const { setIsTourOpen } = useStore(StoresEnum.APP_STORE) as AppStore;

  useEffect(() => {
    setIsUserLoading(true);

    // TODO: move to separate function FETCH USER BY TOKEN
    const accessToken = localStorageUtils.getToken();
    const tokenExpiresIn = localStorageUtils.getTokenExpiresIn();

    let isTokenExpired = checkIsTokenExpired(tokenExpiresIn);

    const { user = null } = accessToken
      ? jwtDecode<RefreshTokenResponseInterface>(accessToken)
      : {};
    const { id: decodedUserId } = user || {};

    const isUseToken = !isTokenExpired && decodedUserId;
    if (isUseToken) {
      fetchUserById(
        decodedUserId,
        setCurrentUser,
        setPersistedPostContacts,
        setTradingAccount,
        setTradingAccountState
      );
      setIsUserLoading(false);
      setIsTourOpen(false);

      return;
    }

    // TODO: move to separate function FETCH USER BY REFRESH_TOKEN
    const refreshAccessToken = localStorageUtils.getRefreshToken();
    const refreshTokenExpiresIn = localStorageUtils.getRefreshTokenExpiresIn();
    const isRefreshTokenExpired = checkIsTokenExpired(refreshTokenExpiresIn);
    const isUseRefreshToken = !isRefreshTokenExpired && refreshAccessToken;

    if (isUseRefreshToken) {
      refreshToken(
        refreshAccessToken,
        setCurrentUser,
        setPersistedPostContacts,
        setTradingAccount,
        setTradingAccountState
      );
      setIsUserLoading(false);
      setIsTourOpen(false);

      return;
    }

    localStorageUtils.clearAuthData();

    setIsUserLoading(false);
  }, []);
};
