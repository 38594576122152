import React, { ReactElement, useState } from "react";
import { postApi } from "../../../../../../api/requests/posts.api";
import { Spin } from "antd";
import PhoneInput from "react-phone-input-2";

import {
  AddYourPhoneStyledWrapper,
  PhoneStyledWording,
} from "./AddYourPhone.styled";
import { observer } from "mobx-react-lite";
import { useAddPostStore } from "../../../../../../hooks/use-add-post-store/useAddPostStore";
import { ValidatedPhoneInput } from "../../../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../../i18n/translationMap";

export const AddYourPhone = observer(
  ({ onValidatePhone }: any): ReactElement => {
    const {
      phone,
      postModalData,
      isPhoneConfirmationLoading,

      setPhone,
    } = useAddPostStore();

    const { t } = useTranslation();

    const { country } = postModalData;

    const [isValid, setIsPhoneValid] = useState(false);

    const handleChangePhone = (value: string) => {
      if (onValidatePhone && onValidatePhone instanceof Function) {
        onValidatePhone(value);
      }

      setPhone(value);
    };

    if (isPhoneConfirmationLoading) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      );
    }

    return (
      <AddYourPhoneStyledWrapper>
        <PhoneStyledWording>
          {t(translationMap.contacts.enterYourPhone)}
        </PhoneStyledWording>

        <ValidatedPhoneInput
          isValid={isValid}
          setIsPhoneValid={setIsPhoneValid}
          country={String(country).toLowerCase()}
          phone={phone}
          onChangePhone={handleChangePhone}
        />

        <p style={{ fontSize: 14, marginTop: 10 }}>
          {t(translationMap.contacts.toSkipThisStep)}
        </p>
      </AddYourPhoneStyledWrapper>
    );
  }
);
