import { colors } from "../../../../constants/styles/colors.constants";
import styled from "styled-components";

export const PostStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 12px 20px;
  box-shadow: -1px 10px 11px -6px rgba(0, 0, 0, 0.05);
`;

export const SeparatorStyledWrapper = styled.div`
  width: 4px;
  height: 4px;
  background: #c8c8c8;
  border-radius: 50%;
  margin: 0 5px;
`;

export const CerealCropImageStyledWrapper = styled.img`
  width: 50px;
  border-radius: 5px;
`;

export const PostInfoStyledWrapper = styled.div`
  margin-left: 10px;
  text-align: left;
`;

export const CerealCropNameStyled = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
`;
export const LocationStyled = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PriceInfoStyled = styled.p<{ color?: string }>`
  font-size: 16px;
  font-weight: 600;

  ${({ color }) => (color ? `color: ${color}` : "")};
`;

export const NoteStyled = styled.span`
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;

  & svg {
    font-size: 20px;
    margin-right: 8px;
  }
`;

export const DateStyled = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.darkGrey};
`;

export const PostRightSideStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PostDateStyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PostInfoContainerStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PostDescriptionStyledWrapper = styled.div`
    display: flex
    flex-direction: column;
`;

export const PostDescriptionControllsStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DescriptionStyledWrapper = styled.p`
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: justify;
  font-size: 14px;
  background: ${colors.darkWhite};
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
`;
