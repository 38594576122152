import { BsCashCoin, BsFillCreditCard2BackFill } from "react-icons/bs";
import { PaymentTypesEnum } from "../../../../types/enums/PaymentTypes.enum";
import { colors } from "../../../../constants/styles/colors.constants";
import { BiHide } from "react-icons/bi";

export const paymentTypeIcon = {
  [PaymentTypesEnum.CASH]: (
    <BsCashCoin size={16} color={colors.black} style={{ marginRight: 4 }} />
  ),
  [PaymentTypesEnum.CARD]: (
    <BsFillCreditCard2BackFill
      size={16}
      color={colors.black}
      style={{ marginRight: 4 }}
    />
  ),
  [PaymentTypesEnum.ANY]: (
    <div>
      <BsCashCoin size={12} color={colors.black} style={{ marginRight: -1 }} />
      <BsFillCreditCard2BackFill
        size={12}
        color={colors.black}
        style={{ marginRight: 4 }}
      />
    </div>
  ),
  [PaymentTypesEnum.HIDDEN]: (
    <BiHide size={16} color={colors.black} style={{ marginRight: 4 }} />
  ),
};
