import styled from "styled-components";

export const TradingPostsEmptyStateStyledWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;

  p {
    margin-bottom: 20px;
  }
`;
