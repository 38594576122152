import React, { ReactElement } from "react";

import {
  CurrencyStyleButton,
  FiltersStyledWrapper,
  MobileFiltersStyledWrapper,
  SelectCurrenciesStyledWrapper,
  SelectFiltersStyledWrapper,
} from "./Filters.styled";
import { SideBarTitleRow } from "../SidebarTitleRow/SideBarTitleRow";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../i18n/translationMap";
import { colors } from "../../../constants/styles/colors.constants";
import { AiFillFilter } from "react-icons/ai";
import { FormItemLabel } from "../FormItemLablel/FormItemLablel";
import { Select, SelectProps } from "antd";
import { FilterKeysEnum } from "../../../types/enums/FilterKeys.enum";
import { observer } from "mobx-react-lite";
import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../store/AppStore";
import { CerealCropTag } from "../CerelCropTag/CerealCropTag";
import { getCerealCropsOptions } from "../../../utils/options-utils/getCeralCropsOptions";
import { CurrencyEnum } from "../../../types/enums/Currency.enum";
import { currencyPrefixesMap } from "../../../constants/currencies.constants";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  getCountriesFilterOptions,
  getCountryStatesOptions,
  getSellCountryStatesOptions,
} from "../../../utils/options-utils/getCountriesOptions";
import { PaymentTypesEnum } from "../../../types/enums/PaymentTypes.enum";
import { MobileBottomSlideModal } from "../MobileBottomSlideModal/MobileBottomSlideModal";
import { MdDelete } from "react-icons/md";

export const CurrenciesSelect = observer(
  ({ filters, setFilters }: any): ReactElement => {
    const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

    const { t } = useTranslation();

    const applyCurrencyFilter = (currency: CurrencyEnum) => {
      if (filters[FilterKeysEnum.CURRENCY] === currency) {
        return setFilters({
          ...filters,
          [FilterKeysEnum.CURRENCY]: "",
        });
      }

      setFilters({
        ...filters,
        [FilterKeysEnum.CURRENCY]: currency,
      });
    };

    return (
      <SelectFiltersStyledWrapper>
        <FormItemLabel title={t(translationMap.filters.selectCurrency)} />

        <SelectCurrenciesStyledWrapper isMobile={isMobile()}>
          <CurrencyStyleButton
            isSelected={filters[FilterKeysEnum.CURRENCY] === CurrencyEnum.USD}
            onClick={() => applyCurrencyFilter(CurrencyEnum.USD)}
            isMobile={isMobile()}
          >
            {currencyPrefixesMap[CurrencyEnum.USD]}
          </CurrencyStyleButton>

          <CurrencyStyleButton
            isSelected={filters[FilterKeysEnum.CURRENCY] === CurrencyEnum.EUR}
            onClick={() => applyCurrencyFilter(CurrencyEnum.EUR)}
            isMobile={isMobile()}
          >
            {currencyPrefixesMap[CurrencyEnum.EUR]}
          </CurrencyStyleButton>

          <CurrencyStyleButton
            isSelected={filters[FilterKeysEnum.CURRENCY] === CurrencyEnum.PLN}
            onClick={() => applyCurrencyFilter(CurrencyEnum.PLN)}
            isMobile={isMobile()}
          >
            {currencyPrefixesMap[CurrencyEnum.PLN]}
          </CurrencyStyleButton>

          <CurrencyStyleButton
            isSelected={filters[FilterKeysEnum.CURRENCY] === CurrencyEnum.UAH}
            onClick={() => applyCurrencyFilter(CurrencyEnum.UAH)}
            isMobile={isMobile()}
          >
            {currencyPrefixesMap[CurrencyEnum.UAH]}
          </CurrencyStyleButton>
        </SelectCurrenciesStyledWrapper>
      </SelectFiltersStyledWrapper>
    );
  }
);

interface FiltersProps {
  disabled?: boolean;
  onClear?: () => void;

  localFilters?: any;
  setLocalFilters?: any;
  selectedCerealCrops?: any;
  selectFilter?: any;
  handleApplyFilters?: any;

  // mobile
  setIsFiltersOpen?: any;
  isFiltersOpen?: any;

  isSell?: boolean;
}

export const Filters = observer((props: FiltersProps): ReactElement => {
  const {
    disabled = false,
    onClear,
    localFilters,
    setLocalFilters,
    selectedCerealCrops,
    selectFilter,
    handleApplyFilters,

    // mobile
    setIsFiltersOpen,
    isFiltersOpen,

    isSell = false,
  } = props;

  const {
    isMobile,
    language,
    cerealCrops,
    countries = [],
    countriesStates,
  } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { t } = useTranslation();

  const selectProps: SelectProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: selectedCerealCrops || undefined,
    allowClear: true,
    tagRender: CerealCropTag,
    options: getCerealCropsOptions(cerealCrops, language),
    onChange: (newValue: string[]) => {
      selectFilter(FilterKeysEnum.CEREAL_CROPS)(newValue);
    },
  };

  const country = countries.find(
    (country: any) => country.id === localFilters[FilterKeysEnum.COUNTRY]
  )?.code;
  const selectStatesProps: SelectProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: localFilters[FilterKeysEnum.STATES] || undefined,
    allowClear: true,
    // tagRender: CerealCropTag,
    options: isSell
      ? getSellCountryStatesOptions(countriesStates?.[country] || [], language)
      : getCountryStatesOptions(countriesStates?.[country] || [], language),
    onChange: (newValue: string[]) => {
      selectFilter(FilterKeysEnum.STATES)(newValue);
    },
    // @ts-ignore
    disabled: !Boolean(localFilters[FilterKeysEnum.COUNTRY]),
  };

  const selectPaymentTypesProps: SelectProps = {
    mode: "multiple",
    style: { width: "100%", position: "relative" },
    value: localFilters[FilterKeysEnum.PAYMENT_TYPES] || undefined,
    allowClear: true,
    options: [
      {
        value: PaymentTypesEnum.CASH,
        label: t(translationMap.paymentTypes.cash),
      },
      {
        value: PaymentTypesEnum.CARD,
        label: t(translationMap.paymentTypes.card),
      },
      {
        value: PaymentTypesEnum.ANY,
        label: t(translationMap.paymentTypes.any),
      },
      {
        value: PaymentTypesEnum.HIDDEN,
        label: t(translationMap.paymentTypes.hidden),
      },
    ],
    onChange: (newValue: string[]) => {
      selectFilter(FilterKeysEnum.PAYMENT_TYPES)(newValue);
    },
  };

  const content = (
    <>
      {!isMobile() && (
        <SideBarTitleRow
          title={t(translationMap.filters.filters) as string}
          clearWording={`${t(translationMap.buttons.clear)}` as string}
          Icon={<AiFillFilter size={20} color={colors.primary} />}
          onClear={onClear}
          disabled={disabled}
        />
      )}

      <SelectFiltersStyledWrapper>
        <FormItemLabel title={t(translationMap.post.selectCrop)} />
        <Select {...selectProps} showSearch={false} />
      </SelectFiltersStyledWrapper>

      <SelectFiltersStyledWrapper>
        <FormItemLabel title={t(translationMap.post.selectCountry)} />
        <Select
          style={{ width: "100%" }}
          allowClear
          value={localFilters[FilterKeysEnum.COUNTRY] as string}
          onChange={selectFilter(FilterKeysEnum.COUNTRY)}
          options={getCountriesFilterOptions([...countries], language)}
          showSearch={false}
        />
      </SelectFiltersStyledWrapper>

      <SelectFiltersStyledWrapper>
        <FormItemLabel title={t(translationMap.post.selectState)} />
        <Select {...selectStatesProps} showSearch={false} />
      </SelectFiltersStyledWrapper>

      <SelectFiltersStyledWrapper>
        <FormItemLabel title={t(translationMap.post.selectPaymentType)} />
        <Select {...selectPaymentTypesProps} showSearch={false} />
      </SelectFiltersStyledWrapper>

      <CurrenciesSelect setFilters={setLocalFilters} filters={localFilters} />

      <CustomButton
        type="primary"
        margin="20px 0 0 0"
        style={{ width: "100%" }}
        onClick={handleApplyFilters}
      >
        {t(translationMap.filters.applyFilters)}
      </CustomButton>
    </>
  );

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isFiltersOpen}
        title={t(translationMap.filters.filters)}
        LeftSlot={
          <div
            style={{
              display: "flex",
            }}
            onClick={onClear}
          >
            <MdDelete size={26} color={colors.link} />
            <p
              style={{
                fontSize: 24,
                marginLeft: 4,
                color: colors.link,
                fontWeight: 600,
              }}
            >
              {t(translationMap.buttons.clear)}
            </p>
          </div>
        }
        onClose={() => setIsFiltersOpen(false)}
        Content={
          <MobileFiltersStyledWrapper>{content}</MobileFiltersStyledWrapper>
        }
      />
    );
  }

  return <FiltersStyledWrapper>{content}</FiltersStyledWrapper>;
});
