import { httpClient } from "../httpClient/HttpClient";

export class VisitsApi {
  private VISITS_ENDPOINT = "visits";

  visitPosts = async (): Promise<any> => {
    const data = await httpClient.post(`${this.VISITS_ENDPOINT}/posts`, {});

    return data;
  };

  visitTradingPosts = async (): Promise<any> => {
    const data = await httpClient.post(`${this.VISITS_ENDPOINT}/trading`, {});

    return data;
  };
}

export const visitsApi = new VisitsApi();
