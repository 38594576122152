import styled, { css } from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const TradingPostStyledWrapper = styled.div`
  width: 100%;
  margin: 15px 0;
  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const PublishedTradingPostCropThumbStyled = styled.div`
  padding: 15px;
`;

export const PublishedTradingPostListThumbnailStyled = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-right: 10px;
`;

const FirstLineCSS = css<{
  $isSmall?: boolean;
}>`
  top: 10px;

  left: ${({ $isSmall }) => ($isSmall ? "-30px" : "-30px")};
  width: ${({ $isSmall }) => ($isSmall ? "95px" : "100px")};
  height: ${({ $isSmall }) => ($isSmall ? "18px" : "22px")};
`;

const SecondLineCSS = css`
  top: 25px;
  left: -30px;
  width: 140px;
`;
export const PostLineLabelStyled = styled.div<{
  $bg?: string;
  $isTop?: boolean;
  $isSecond?: boolean;
  $isSmall?: boolean;
}>`
  position: absolute;
  font-size: ${({ $isSmall }) => ($isSmall ? "12px" : "16px")};

  rotate: -45deg;
  display: flex;

  align-items: center;
  justify-content: center;

  font-weight: 600;

  height: 22px;
  color: ${colors.white};

  background: ${({ $bg }) => $bg || colors.primary};

  ${({ $isSecond }) => ($isSecond ? SecondLineCSS : FirstLineCSS)}
`;

// MOBILE

export const MobilePublishedTradingPostListThumbnailStyled = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  margin-right: 10px;
`;

export const MobilePostInfoStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
`;

export const MobilePostCerealCropInfoStyled = styled.div`
  display: flex;
`;

export const MobileTradingPostStyledWrapper = styled.div`
  width: 95%;
  margin: 15px auto;
  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 15px;
`;

export const MobilePostLocationStyled = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const MobilePostPricesStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
