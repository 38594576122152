import React, { ReactElement, useEffect } from "react";
import { Page } from "../components/shared-components/Page/Page";
import { PostsList } from "../components/composit-components/sell-module/Posts/PostsList";
import { AdvertisementBanner } from "../components/composit-components/app-module/AdvertisementBanner/AdvertisementBanner";
import { Pagination } from "antd";
import { StoresEnum, useStore } from "../hooks/use-store-hook/useStore";
import { PostsStore } from "../store/PostsStore";
import { observer } from "mobx-react-lite";
import { PostSorts } from "../components/composit-components/sell-module/PostSorts/PostSorts";
import { AppStore } from "../store/AppStore";
import { PostFilters } from "../components/composit-components/sell-module/PostFilters/PostFilters";
import { QuickFilters } from "../components/composit-components/sell-module/QuickFilters/QucikFilters";
import { ListTitle } from "../components/shared-components/ListTitle/ListTitle";
import { ListLayout } from "../components/shared-components/ListLayout/ListLayout";
import { UserStore } from "../store/UserStore";
import { ProfileTabEnum } from "../types/enums/ProfileTab.enum";
import { visitsApi } from "../api/requests/visits.api";
import { UkrainianFlagIcon } from "../static/icons/UkrainianFlagIcon";
import { colors } from "../constants/styles/colors.constants";

export const Posts = observer((): ReactElement => {
  const {
    currentPage,
    postsAmount,

    setCurrentPage,
  } = useStore(StoresEnum.POSTS_STORE) as PostsStore;

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { setProfileTab, setPlanModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  useEffect(() => {
    setProfileTab(ProfileTabEnum.ACCOUNT);
  }, []);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const saleBanner = (
    <div
      style={{
        margin: "0 auto",
        fontSize: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: colors.primary,
        fontWeight: 600,
        marginBottom: 10,
        borderRadius: 8,
        padding: 10,
        border: `2px solid ${colors.primary}`,
        width: "90%",
        background: colors.white,
        boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.2)",
        position: "relative",
      }}
      onClick={setPlanModalOpenClose}
    >
      <p style={{ padding: "0 20px" }}>
        На честь дня незалежності України, до 27 серпня, разом до перемоги{" "}
        <UkrainianFlagIcon />
      </p>

      <p
        style={{
          position: "absolute",
          bottom: -6,
          left: -16,
          padding: "5px 10px",
          color: colors.white,
          borderRadius: 8,
          fontSize: 18,
          fontWeight: 600,
          background: "#c92838",
          rotate: "10deg",
        }}
      >
        Знижки
      </p>

      <p
        style={{
          position: "absolute",
          bottom: -6,
          right: -16,
          padding: "5px 10px",
          color: colors.white,
          borderRadius: 8,
          fontSize: 10,
          fontWeight: 800,
          background: colors.orange,
          rotate: "-10deg",
        }}
      >
        -10%
      </p>

      <p
        style={{
          position: "absolute",
          top: 28,
          right: -16,
          padding: "5px 10px",
          color: colors.white,
          borderRadius: 8,
          fontSize: 12,
          fontWeight: 800,
          background: colors.primary,
          rotate: "2deg",
        }}
      >
        -17%
      </p>

      <p
        style={{
          position: "absolute",
          top: -6,
          right: -16,
          padding: "5px 10px",
          color: colors.white,
          borderRadius: 8,
          fontSize: 14,
          fontWeight: 800,
          background: "#c92838",
          rotate: "10deg",
        }}
      >
        -20%
      </p>
    </div>
  );

  if (isMobile()) {
    return (
      <Page>
        <AdvertisementBanner />

        <ListTitle title="Оголошення на продаж" />

        <PostSorts />

        <QuickFilters />

        <PostFilters />

        <PostsList />

        <div
          style={{
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            defaultCurrent={1}
            total={postsAmount}
            pageSize={30}
            current={currentPage}
            responsive
            showSizeChanger={false}
            onChange={onChangePage}
            hideOnSinglePage
          />
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <AdvertisementBanner />

      <ListTitle title="Оголошення на продаж" />

      <PostSorts />

      <ListLayout SideBar={<PostFilters />} List={<PostsList />} />

      <div
        style={{
          margin: "20px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          defaultCurrent={1}
          total={postsAmount}
          pageSize={30}
          current={currentPage}
          responsive
          showSizeChanger={false}
          onChange={onChangePage}
          hideOnSinglePage
        />
      </div>
    </Page>
  );
});
