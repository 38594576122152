import React, { ReactElement } from "react";

import { SuggestedActionButton } from "../../../../shared-components/SuggestedActionButton/SuggestedActionButton";

import { UserErrorMessagesEnum } from "../../../../../constants/error-messages/user-error-messages";

import { AuthUserErrorStyledWrapper } from "./AuthUserError.styled";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../store/AppStore";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../i18n/translationMap";
import { LanguagesEnum } from "../../../../../types/enums/Languages.enum";

interface AuthUserErrorProps {
  userErrorType: UserErrorMessagesEnum | null;
  onClick: () => void;
}

const localTranslations = {
  [LanguagesEnum.EN]: "User not found, try another phone or",
  [LanguagesEnum.PL]: "Użytkownik nie znaleziony, spróbuj innego telefonu lub",
  [LanguagesEnum.UK]: "Користувач не знайдений, спробуйте інший телефон або",
};

const localTranslationsSignUp = {
  [LanguagesEnum.EN]: "User already exist, try another phone or",
  [LanguagesEnum.PL]: "Użytkownik już istnieje, spróbuj innego telefonu lub",
  [LanguagesEnum.UK]: "Користувач вже існує, спробуйте інший телефон або",
};

const localTranslations2 = {
  [LanguagesEnum.EN]: "Sign in",
  [LanguagesEnum.PL]: "Zaloguj się",
  [LanguagesEnum.UK]: "Увійдть",
};

export const AuthUserError = observer(
  (props: AuthUserErrorProps): ReactElement => {
    const { userErrorType, onClick } = props;

    const { t } = useTranslation();

    const { isMobile, language } = useStore(StoresEnum.APP_STORE) as AppStore;

    if (userErrorType === UserErrorMessagesEnum.USER_ALREADY_EXISTS) {
      return (
        <AuthUserErrorStyledWrapper>
          <span>{localTranslationsSignUp[language]}</span>

          {isMobile() ? (
            <span>{` ${t(translationMap.auth.signIn)}`}</span>
          ) : (
            <SuggestedActionButton
              title={t(translationMap.auth.signIn)}
              onClick={onClick}
            />
          )}
        </AuthUserErrorStyledWrapper>
      );
    }

    if (userErrorType === UserErrorMessagesEnum.USER_NOT_FOUND) {
      return (
        <AuthUserErrorStyledWrapper>
          <span>{localTranslations[language]}</span>
          {isMobile() ? (
            <span>{` ${localTranslations2[language]}`}</span>
          ) : (
            <SuggestedActionButton title="Sign up" onClick={onClick} />
          )}
        </AuthUserErrorStyledWrapper>
      );
    }

    return <></>;
  }
);
