import React from "react";
import { observer } from "mobx-react-lite";

import {
  SupportPulseStyledWrapper,
  SupportStyledWrapper,
  SupportDesktopWrapper,
  SupportOptionsStyledWrapper,
  MobileDesktopWrapper,
} from "./Support.styled";
import { BiHelpCircle, BiSupport } from "react-icons/bi";
import { colors } from "../../../../constants/styles/colors.constants";
import {
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from "../../sell-module/AddPost/AddPostForm/add-post-form-components/ContactsInformation/ContsctIcons";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Support = observer(() => {
  const { isMobile, isSupportOpen, toggleSupport } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  const navigateToViber = () => {
    window.open(`viber://chat?number=+380937782382`, "_blank");
  };

  const navigateToWhatsapp = () => {
    window.open(`https://wa.me/+380937782382`, "_blank");
  };

  const navigateToTelegram = () => {
    window.open(`https://t.me/+380937782382`, "_blank");
  };

  if (isMobile()) {
    return (
      <MobileDesktopWrapper isOpen={isSupportOpen}>
        <FaAngleDown
          size={40}
          style={{ width: "20%" }}
          onClick={toggleSupport}
        />

        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div onClick={navigateToTelegram}>
            <TelegramIcon fontSize={35} />
          </div>

          <div onClick={navigateToViber}>
            <ViberIcon fontSize={35} />
          </div>

          <div onClick={navigateToWhatsapp}>
            <WhatsappIcon fontSize={35} />
          </div>

          <Link to="/faq" onClick={() => toggleSupport()}>
            <BiHelpCircle size={35} />
          </Link>
        </div>
      </MobileDesktopWrapper>
    );
  }

  return (
    <SupportDesktopWrapper>
      <SupportStyledWrapper onClick={toggleSupport}>
        <BiSupport size={26} color={colors.white} />
      </SupportStyledWrapper>
      <SupportPulseStyledWrapper></SupportPulseStyledWrapper>

      <SupportOptionsStyledWrapper isOpen={isSupportOpen}>
        <div onClick={navigateToTelegram}>
          <TelegramIcon fontSize={35} />
        </div>

        <div onClick={navigateToViber}>
          <ViberIcon fontSize={35} />
        </div>

        <div onClick={navigateToWhatsapp}>
          <WhatsappIcon fontSize={35} />
        </div>

        <Link to="/faq" onClick={() => toggleSupport()}>
          <BiHelpCircle size={38} />
        </Link>
      </SupportOptionsStyledWrapper>
    </SupportDesktopWrapper>
  );
});
