import styled from "styled-components";

export const AddOneMorePostPlugStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  p {
    font-size: 16;
    font-weight: 400;
  }
`;
