import React, { ReactElement } from "react";
import { colors } from "../../../../constants/styles/colors.constants";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";

interface MobileTourProps {
  isVisible: boolean;
  content: ReactElement;
  contentWidth: number;
  contentLeft: number;
  angleRight?: number;
  contentTop: number;
  children: ReactElement;
}

export const MobileTourPopupStyles = styled.div`
  position: absolute;
  padding: 15px;
  background: ${colors.white};
  z-index: 400;
  box-shadow: 2px -10px 10px 0px rgba(0, 0, 0, 0.2);
  border: 3px solid ${colors.darkGrey};
  border-radius: 10px;

  p {
    margin-bottom: 10px;
    text-align: justify;
  }
`;

const Rectangle = styled.div`
  width: 0px;
  height: 0px;
  border: 15px solid;
  border-top-color: ${colors.darkGrey};
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: -14px;
`;

export const MobileTour = observer((props: MobileTourProps): ReactElement => {
  const {
    isVisible,
    angleRight = 15,
    children,
    contentTop,
    contentLeft,
    contentWidth,
    content,
  } = props;

  const { currentTourStep, isTourOpen } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  if (!isTourOpen) {
    return children;
  }

  if (!isVisible) {
    return children;
  }

  return (
    <div>
      <div style={{ position: "relative" }}>
        <MobileTourPopupStyles
          style={{ left: contentLeft, width: contentWidth, top: contentTop }}
        >
          {content}
        </MobileTourPopupStyles>
        <Rectangle style={{ right: angleRight }}></Rectangle>
      </div>

      {children}
    </div>
  );
});
