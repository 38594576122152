import styled from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";

export const MenuMobileStyledWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 85%;
  background: ${colors.white};
  z-index: 600;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  left: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
`;

export const MenuMobileOpenStyledButton = styled.div`
  padding-left: 10px;
`;

export const MenuMobileLinksListStyledWrapper = styled.div`
  margin-top: 30px;

  display: flex;
  flex-direction: column;

  a {
    margin: 0;
    margin-bottom: 25px;
    font-size: 24px;
  }
`;

export const MobileMenuHeaderStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileLanguageSelectStyledWrapper = styled.div`
  padding: 5px 10px 5px 5px;
  margin-left: auto;
`;

export const MobileMenuStaticHeaderStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 15px;
`;
