import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const MobileBottomSlideModalStyledWrapper = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 400;
  bottom: ${({ isOpen }) => (isOpen ? 0 : "-100vh")};
  height: 85vh;
  background: ${colors.white};
  left: 0;
  right: 0;
  transition: 0.3s ease-in-out;
`;

export const MobileOverlayStyledWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};

  z-index: 100;
`;

export const MobileBottomSlideModalHeaderStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 15px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

  p {
    font-size: 26px;
    font-weight: 600;
  }
`;
