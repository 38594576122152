import React, { ReactElement } from "react";

import { observer } from "mobx-react-lite";

import { SortKeysEnum } from "../../../../types/enums/SortsKeysEnum";

import { usePostStore } from "../../../../hooks/use-posts-store/usePostStore";
import { Sorts } from "../../../shared-components/Sorts/Sorts";

export const PostSorts = observer((): ReactElement => {
  const {
    unstable_filters,
    unstable_sorts,
    handleChangeSorts,
    handleChangeFilters,
    clearPostSorts,
    checkIsSortsApplied,
  } = usePostStore();

  const handleChangeSort = (key: SortKeysEnum) => {
    return (value: string) => {
      handleChangeSorts({
        [key]: value,
      });
    };
  };

  const handleClearSorts = () => {
    if (!checkIsSortsApplied()) {
      return;
    }

    clearPostSorts();
  };

  return (
    <Sorts
      filters={unstable_filters}
      sorts={unstable_sorts}
      handleChangeSort={handleChangeSort}
      handleClearSorts={handleClearSorts}
      handleChangeFilters={handleChangeFilters}
      checkIsSortsApplied={checkIsSortsApplied}
    />
  );
});
