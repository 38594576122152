import { ReactElement } from "react";
import { MenuLink } from "../DesktopMenu/DesktopMenu.styled";
import { MobileMenuLink } from "./MenuLinks.styled";
import { HiArrowCircleDown, HiArrowCircleUp } from "react-icons/hi";
import { useLocation } from "react-router-dom";

export const MenuLinks = ({
  styles = {},
  isMobile = false,
}: any): ReactElement => {
  let location = useLocation();
  const isPosts =
    location.pathname.includes("/posts") || location.pathname === "/";
  const isTradingPosts = location.pathname.includes("/trading-posts");

  if (isMobile) {
    return (
      <>
        <MobileMenuLink to="/posts" $isMobile={isMobile} $isSelected={isPosts}>
          <HiArrowCircleUp size={22} />
          Продаж
        </MobileMenuLink>
        <MobileMenuLink
          to="/trading-posts"
          $isMobile={isMobile}
          $isSelected={isTradingPosts}
        >
          <HiArrowCircleDown size={22} />
          Закупівля
        </MobileMenuLink>
      </>
    );
  }

  return (
    <>
      <MenuLink to="/posts" style={styles} $isMobile={isMobile}>
        Продаж зернових
      </MenuLink>
      <MenuLink to="/trading-posts" style={styles} $isMobile={isMobile}>
        Закупівля зернових
      </MenuLink>
    </>
  );
};
