import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const PostSortsStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.white};
  margin-bottom: 10px;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.05);
`;

export const MobilePostSortsWithPriceSelectionStyledWrapper = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  position: relative;
  transition: all 0.3s ease;

  margin-bottom: ${({ isOpen }) => (isOpen ? "35px" : "0")};
`;

export const MobilePostSortsPriceSelectionStyledWrapper = styled.div<{
  isOpen: boolean;
}>`
  position: absolute;
  top: ${({ isOpen }) => (isOpen ? "38px" : "0px")};
  z-index: ${({ isOpen }) => (isOpen ? "0" : "-1")};
  left: 5%;
  right: 5%;
  background: ${colors.white};
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.05);
  height: 34px;
  transition: all 0.2s ease;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 10px;
`;

export const MobileSortCurrencyStyledButton = styled.p<{
  isSelected: boolean;
}>`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  background: ${({ isSelected }) =>
    isSelected ? colors.primary : colors.darkWhite};
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.black)};
`;

export const MobilePostSortsStyledWrapper = styled.div`
  display: flex;
  width: 94%;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.white};
  margin-bottom: 5px;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.05);
`;

export const SelectSortsStyledWrapper = styled.div`
  display: flex;
  align-items: center;

  & .ant-select {
    margin-top: 3px;
  }
`;

export const SidebarRowClearActionStyledButton = styled.div<{
  disabled: boolean;
}>`
  font-size: 12px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  color: ${colors.link}};
  font-weight: 600;
  margin-left: -15px
`;

export const MobileSidebarRowClearActionStyledButton = styled.div`
  font-size: 12px;
  cursor: pointer;
  color: ${colors.link}};
  font-weight: 600;
`;
