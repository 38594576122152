import { Input } from "antd";
import { ContactsTitle } from "../ContactsTitle/ContactsTitle";
import { FacebookIcon } from "../ContsctIcons";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAddPostStore } from "../../../../../../../../hooks/use-add-post-store/useAddPostStore";
import { translationMap } from "../../../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

const FaceBookUrlRegex = new RegExp(
  /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/
);

export const FacebookUrl = observer(() => {
  const {
    postModalData,
    isFacebookUrlValid,
    onChangeSelect,
    setIsFacebookUrlValid,
  } = useAddPostStore();
  const { contacts } = postModalData || {};
  const { facebookUrl } = contacts || {};

  const { t } = useTranslation();

  const [isFacebookInputUnfocused, setIsFacebookInputUnfocused] =
    useState(false);

  const handleChangeFacebookUrl = () => {
    const changeContacts = onChangeSelect("contacts");

    return (e: any) => {
      changeContacts({ ...contacts, facebookUrl: e.target.value });
    };
  };

  const handleFacebookInputBlur = () => {
    setIsFacebookInputUnfocused(true);
  };

  useEffect(() => {
    if (!facebookUrl) {
      setIsFacebookUrlValid(true);
    } else {
      const isFacebookUrlValid = FaceBookUrlRegex.test(facebookUrl);

      setIsFacebookUrlValid(isFacebookUrlValid);
    }
  }, [facebookUrl]);

  return (
    <>
      <ContactsTitle
        title={t(translationMap.contacts.facebookUrl)}
        Icon={<FacebookIcon />}
        marginTop={-3}
      />
      <Input
        value={facebookUrl}
        placeholder="Enter facebook url"
        onChange={handleChangeFacebookUrl()}
        onBlur={handleFacebookInputBlur}
      />

      {isFacebookInputUnfocused && !isFacebookUrlValid && (
        <p style={{ color: "red", fontSize: 14 }}>
          Please enter valid facebook url
        </p>
      )}
    </>
  );
});
