import styled from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";
import { Link } from "react-router-dom";

export const MenuLink = styled(Link)<{ $isMobile: boolean }>`
  font-size: 18px;
  text-decoration: none;
  color: ${colors.black};
  margin-right: 20px;
  transition: 0.3s;

  &:hover {
    color: ${({ $isMobile }) => ($isMobile ? colors.black : colors.orange)};
    padding-bottom: ${({ $isMobile }) => ($isMobile ? 0 : "5px")};
    border-bottom: ${({ $isMobile }) =>
      $isMobile ? "none" : `1px solid ${colors.orange}`};
  }
`;

export const MenuStyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #fff;
  z-index: 300;
`;

export const MenuContentStyledWrapper = styled.div`
  width: 1100px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MenuLinksAndAction = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuLinksStyledWrapper = styled.div`
  margin-top: 8px;
`;
