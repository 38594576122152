import { ButtonProps } from "antd";
import React, { ReactElement } from "react";
import { CustomButtonStyledWrapper } from "./CustomButton.styled";

interface CustomButtonProps extends ButtonProps {
  margin?: string;
}

export const CustomButton = (props: CustomButtonProps): ReactElement => {
  return (
    <CustomButtonStyledWrapper {...props}>
      {props.children}
    </CustomButtonStyledWrapper>
  );
};
