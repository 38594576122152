import { PostInterface } from "../../types/interfaces/Post.interface";
import { addParamsToUrl } from "../../utils/url-utils/addParamsToUrl";
import { addUtmMarkToUrl } from "../../utils/url-utils/addUtmMarkToUrl";
import { httpClient } from "../httpClient/HttpClient";

interface GetPostContactsParamsInterface {
  perPage?: number;
  page?: number;
  filters: any;
  sorts: any;
}

class PostApi {
  private POSTS_ENDPOINT = "post";

  getAllPosts = async (
    params: GetPostContactsParamsInterface
  ): Promise<any> => {
    const URL = `${this.POSTS_ENDPOINT}/all`;
    const defaultParams = { perPage: 30, page: 1 };

    const { filters, sorts, page, perPage } = params;

    const data = await httpClient.get(
      addParamsToUrl(URL, {
        perPage: perPage || defaultParams.perPage,
        page: page || defaultParams.page,
        ...filters,
        ...sorts,
      })
    );

    const {
      data: { posts: allPosts, amount },
    } = data;

    return { posts: allPosts, amount };
  };

  getPostContacts = async (id: string): Promise<any> => {
    const data = await httpClient.get(`${this.POSTS_ENDPOINT}/contacts/${id}`);

    return data?.contacts || {};
  };

  startCreatePost = async (phone: string): Promise<any> => {
    const createdPost = await httpClient.post(`${this.POSTS_ENDPOINT}/start`, {
      phone,
    });

    return createdPost;
  };

  createPost = async (
    post: any,
    confirmation: { phone: string; code: string }
  ): Promise<any> => {
    try {
      const createdPost = await httpClient.post(
        addUtmMarkToUrl(`${this.POSTS_ENDPOINT}`),
        {
          post,
          confirmation,
        }
      );

      if (createdPost?.errors?.length) {
        return { isPostCreated: false, errors: createdPost.errors };
      }

      return { ...createdPost, isPostCreated: true, errors: null };
    } catch (error: any) {
      return { isPostCreated: false, errors: error.errors };
    }
  };

  getOpenedPosts = async (): Promise<any> => {
    const { data } = await httpClient.get(`${this.POSTS_ENDPOINT}/opened`);

    return data;
  };
}

export const postApi = new PostApi();
