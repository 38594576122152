import { ReactElement } from "react";
import { PolandFlagIcon } from "../static/icons/PolandFlagIcon";
import { UkrainianFlagIcon } from "../static/icons/UkrainianFlagIcon";
import { CountriesEnum } from "../types/enums/Countries.enum";
import { CurrencyEnum } from "../types/enums/Currency.enum";
import { LanguagesEnum } from "../types/enums/Languages.enum";

export const countriesCurrenciesMap: Record<string, string> = {
  [CountriesEnum.UA]: CurrencyEnum.UAH,
  [CountriesEnum.PL]: CurrencyEnum.PLN,
};

export const countriesLanguagesMap: Record<string, string> = {
  [CountriesEnum.UA]: LanguagesEnum.UK,
  [CountriesEnum.PL]: LanguagesEnum.PL,
};

export const countriesCodesToNamesMap: Record<string, string> = {
  [CountriesEnum.UA]: "Ukraine",
  [CountriesEnum.PL]: "Poland",
};

export const countriesCodesToFlagIconMap: Record<string, ReactElement> = {
  [CountriesEnum.UA]: <UkrainianFlagIcon />,
  [CountriesEnum.PL]: <PolandFlagIcon />,
};
