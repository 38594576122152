import React, { ReactElement } from "react";

import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";

import { TradingPostActionsStyledWrapper } from "./TradingPostActions.styled";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { getStandardLocaleDate } from "../../../../utils/date-utils/getStandardLocaleDate";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";
import { colors } from "../../../../constants/styles/colors.constants";

interface TradingPostActionsProps {
  tradingPost: any;
  isExpired?: boolean;
  isTopExpired?: boolean;
  isMobile?: boolean;
  activeTill?: any;
  topActiveTill?: any;
  language: LanguagesEnum;
}

export const TradingPostActions = observer(
  (props: TradingPostActionsProps): ReactElement => {
    const {
      tradingPost,
      isExpired,
      isTopExpired,
      activeTill,
      topActiveTill,
      language,
    } = props;
    const {
      tradingAccount,

      setTradingPostToTop,
      toggleModalForBalance,
      toggleTradingTopPlanModal,
      toggleTradingContinuePlanModal,
      setTradingPostToContinue,
    } = useTradingStore();

    const handleContinuePostClick = async () => {
      toggleTradingContinuePlanModal();
      setTradingPostToContinue(tradingPost);
    };

    const handleAddToTopClick = () => {
      if (isExpired) {
        if (tradingAccount?.postBalance) {
          return handleContinuePostClick();
        }

        toggleModalForBalance();

        return;
      }

      setTradingPostToTop(tradingPost);
      toggleTradingTopPlanModal();
    };

    const topWording = isExpired
      ? "Пост неактивний"
      : isTopExpired
      ? "В топ"
      : "Топ активний";

    const continueWording = isExpired ? "Продовжити" : "Пост активний";

    const TopButton = (
      <CustomButton
        style={{
          width: "100%",
          minWidth: 150,
        }}
        type="primary"
        onClick={handleAddToTopClick}
        disabled={!isTopExpired || isExpired}
      >
        {topWording}
      </CustomButton>
    );

    const ContinueButton = (
      <CustomButton
        style={{ width: "100%" }}
        onClick={handleContinuePostClick}
        disabled={!isExpired}
      >
        {continueWording}
      </CustomButton>
    );

    if (props.isMobile) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: 20,
          }}
        >
          <div
            style={{
              width: "48%",
            }}
          >
            {isTopExpired ? (
              TopButton
            ) : (
              <div
                style={{
                  textAlign: "center",
                  border: `2px solid ${colors.link}`,
                  padding: 5,
                  borderRadius: 4,
                }}
              >
                В топі до
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  {getStandardLocaleDate(topActiveTill, language)}
                </p>
              </div>
            )}
          </div>
          <div
            style={{
              width: "48%",
            }}
          >
            <div>
              {isExpired ? (
                ContinueButton
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    border: `2px solid ${colors.primary}`,
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  Активне до
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 16,
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    {getStandardLocaleDate(activeTill, language)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <TradingPostActionsStyledWrapper>
        {TopButton}
        {ContinueButton}
      </TradingPostActionsStyledWrapper>
    );
  }
);
