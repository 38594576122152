import React, { ReactElement } from "react";
import { Page } from "../components/shared-components/Page/Page";

export const FAQPage = (): ReactElement => {
  return (
    <Page>
      <div
        style={{
          marginTop: 30,
          padding: 20,
          backgroundColor: "white",
          borderRadius: 10,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          marginBottom: 20,
        }}
      >
        <h1>FAQ in progress</h1>
      </div>
    </Page>
  );
};
