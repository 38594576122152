import { LanguagesEnum } from "../../types/enums/Languages.enum";

export const getCountriesOptions = (
  countries: string[],
  language: LanguagesEnum
): any[] => {
  return countries.map(({ code, translation }: any) => ({
    value: code,
    label: translation[language],
  }));
};

export const getCountriesFilterOptions = (
  countries: string[],
  language: LanguagesEnum
): any[] => {
  return countries.map(({ id, code, translation }: any) => ({
    value: id,
    label: translation[language],
  }));
};

export const getCountryStatesOptions = (
  countryStates: string[],
  language: LanguagesEnum,
  isShortForm: boolean = false
): any[] => {
  if (!countryStates.length) {
    return [];
  }

  return countryStates
    .map(({ isoCode, translation }: any) => ({
      value: isoCode,
      label: isShortForm
        ? translation[language].replace("область", "").trim()
        : translation[language],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getSellCountryStatesOptions = (
  countryStates: string[],
  language: LanguagesEnum
): any[] => {
  if (!countryStates.length) {
    return [];
  }

  return countryStates
    .map(({ name, translation }: any) => ({
      value: name,
      label: translation[language],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
