import { TradingStore } from "../../store/TradingStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";

export const useTradingStore = () => {
  const {
    tradingAccountPosts,
    setTradingAccountPosts,

    tradingAccount,
    setTradingAccount,

    isModalForBalanceOpen,
    setIsModalForBalanceOpen,

    tradingAccountState,
    setTradingAccountState,

    isCreatePostModalOpen,

    tradingPlans,
    setTradingPlans,

    isTradingPlansLoading,
    setIsTradingPlansLoading,

    tradingTopPlans,
    setTradingTopPlans,

    setIsTradingTopPlansLoading,

    setCreatedPostModalOpen,

    isTradingTopPlanOpen,
    toggleTradingTopPlanModal,

    tradingPostToTop,
    setTradingPostToTop,

    isEditPostModalOpen,
    toggleEditPostModal,

    postToEdit,
    setPostToEdit,

    continuePlans,
    setContinuePlans,

    isTradingContinuePlanOpen,
    toggleTradingContinuePlanModal,

    tradingPostToContinue,
    setTradingPostToContinue,

    currentAccountPage,
    currentAccountPerPage,
    currentAccountPostsAmount,
    setCurrentAccountPage,

    getTradingAccountPosts,

    tradingActivePostsAmount,

    isTradingInfoModalOpen, 
    toggleTradingInfoModalOpen,
  } = useStore(StoresEnum.TRADING_STORE) as TradingStore;

  const toggleModalForBalance = () => {
    setIsModalForBalanceOpen(!isModalForBalanceOpen);
  };

  const toggleCreatePostModal = () => {
    setCreatedPostModalOpen(!isCreatePostModalOpen);
  };

  return {
    tradingAccountPosts,
    setTradingAccountPosts,

    tradingAccount,
    setTradingAccount,

    isModalForBalanceOpen,
    setIsModalForBalanceOpen,

    tradingAccountState,
    setTradingAccountState,

    isCreatePostModalOpen,

    tradingPlans,
    setTradingPlans,

    isTradingPlansLoading,
    setIsTradingPlansLoading,

    tradingTopPlans,
    setTradingTopPlans,

    setIsTradingTopPlansLoading,

    setCreatedPostModalOpen,

    isTradingTopPlanOpen,
    toggleTradingTopPlanModal,

    tradingPostToTop,
    setTradingPostToTop,

    isEditPostModalOpen,
    toggleEditPostModal,

    postToEdit,
    setPostToEdit,

    toggleModalForBalance,
    toggleCreatePostModal,

    continuePlans,
    setContinuePlans,

    isTradingContinuePlanOpen,
    toggleTradingContinuePlanModal,

    tradingPostToContinue,
    setTradingPostToContinue,

    currentAccountPage,
    currentAccountPerPage,
    currentAccountPostsAmount,
    setCurrentAccountPage,

    getTradingAccountPosts,

    tradingActivePostsAmount,

    isTradingInfoModalOpen, 
    toggleTradingInfoModalOpen,
  };
};
