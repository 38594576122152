import React, { ReactElement } from "react";

import { ListTitleStyledWrapper } from "./ListTitle.styled";

interface ListTitleProps {
  title: string;
}

export const ListTitle = (props: ListTitleProps): ReactElement => {
  const { title } = props;

  return <ListTitleStyledWrapper>{title}</ListTitleStyledWrapper>;
};
