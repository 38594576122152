import React, { ReactElement, useState } from "react";

import { ModalForBalanceStyledWrapper } from "./ModalForBalance.styled";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { Modal } from "antd";
import { TradingCreationPostPlan } from "../TradingCreationPostPlan/TradingCreationPostPlan";
import { TradingPlanInterface } from "../../../../types/interfaces/TradingPlan.interface";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { SelectPlanCurrency } from "../../../shared-components/SelectPlanCurrency/SelectPlanCurrency";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import { useTradingPlans } from "../../../../hooks/use-trading-plans-hook/useTradingPlans";

export const ModalForBalance = observer((): ReactElement => {
  const {
    isModalForBalanceOpen,
    tradingPlans = [],
    toggleModalForBalance,
  } = useTradingStore();

  useTradingPlans();

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const [selectedCurrency, setSelectedCurrency] = useState(CurrencyEnum.UAH);

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isModalForBalanceOpen}
        title="Поповнити баланс"
        onClose={toggleModalForBalance}
        Content={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectPlanCurrency
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
            </div>
            <ModalForBalanceStyledWrapper>
              {tradingPlans.map((plan: TradingPlanInterface) => {
                return (
                  <TradingCreationPostPlan
                    plan={plan}
                    currency={selectedCurrency}
                  />
                );
              })}
            </ModalForBalanceStyledWrapper>
          </>
        }
      />
    );
  }

  return (
    <Modal
      title="Поповнити баланс"
      open={isModalForBalanceOpen}
      onOk={toggleModalForBalance}
      onCancel={toggleModalForBalance}
      centered
      width={740}
      footer={null}
    >
      <ModalForBalanceStyledWrapper>
        {tradingPlans.map((plan: TradingPlanInterface) => {
          return (
            <TradingCreationPostPlan plan={plan} currency={selectedCurrency} />
          );
        })}
      </ModalForBalanceStyledWrapper>
    </Modal>
  );
});
