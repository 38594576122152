import { createContext, useContext } from "react";

import { PostsStore } from "../../store/PostsStore";
import { UserStore } from "../../store/UserStore";
import { AppStore } from "../../store/AppStore";
import { AddPostStore } from "../../store/AddPostStore";
import { TradingStore } from "../../store/TradingStore";
import { AddTradingPostStore } from "../../store/AddTradingPostStore";

export enum StoresEnum {
  POSTS_STORE = "postsStore",
  USER_STORE = "userStore",
  APP_STORE = "appStore",
  ADD_POST_STORE = "addPostStore",
  TRADING_STORE = "tradingStore",
  ADD_TRADING_POST_STORE = "addTradingPostStore",
}

export interface StoreInterface {
  postsStore: PostsStore;
  userStore: UserStore;
  appStore: AppStore;
  addPostStore: AddPostStore;
  tradingStore: TradingStore;
  addTradingPostStore: AddTradingPostStore;
}

export const store: StoreInterface = {
  [StoresEnum.APP_STORE]: new AppStore(),
  [StoresEnum.USER_STORE]: new UserStore(),
  [StoresEnum.POSTS_STORE]: new PostsStore(),
  [StoresEnum.ADD_POST_STORE]: new AddPostStore(),
  [StoresEnum.TRADING_STORE]: new TradingStore(),
  [StoresEnum.ADD_TRADING_POST_STORE]: new AddTradingPostStore(),
};

export const StoreContext = createContext(store);

export const useStore = (store: StoresEnum) => {
  const stores: StoreInterface = useContext(StoreContext);

  return stores[store];
};
