import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const SupportDesktopWrapper = styled.div`
  cursor: pointer;
`;

export const MobileDesktopWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  bottom: ${({ isOpen }) => (isOpen ? "60px" : "-100%")};
  background: ${colors.white};
  z-index: 10;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -5px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 15px 10px;
  transition: all 0.3s ease;
`;

export const SupportStyledWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: ${colors.primary};

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
`;

export const SupportPulseStyledWrapper = styled.div`
  content: "";
  position: fixed;
  display: block;
  bottom: 15px;
  right: 15px;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -10px;
  z-index: 100;
  border-radius: 45px;
  background-color: ${colors.primary};
  animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
`;

export const SupportOptionsStyledWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 90px;
  right: ${({ isOpen }) => (isOpen ? "20px" : "-150px")};
  z-index: 1000;
  width: 70px;
  height: 240px;
  background: ${colors.white};
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.05);
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
`;
