import { ReactElement } from "react";
import { SiFacebook, SiTelegram, SiViber, SiWhatsapp } from "react-icons/si";
import { colors } from "../../../../../../../constants/styles/colors.constants";
import { PhoneFilled } from "@ant-design/icons";
import { AiOutlineMessage } from "react-icons/ai";

export const ViberIcon = ({
  marginBottom = -5,
  fontSize = 20,
}: any): ReactElement => {
  return <SiViber style={{ color: "#7360F2", fontSize, marginBottom }} />;
};

export const WhatsappIcon = ({
  marginBottom = -5,
  fontSize = 20,
}: any): ReactElement => {
  return <SiWhatsapp style={{ color: "#25D366", fontSize, marginBottom }} />;
};

export const TelegramIcon = ({
  marginBottom = -5,
  fontSize = 20,
}: any): ReactElement => {
  return <SiTelegram style={{ color: "#2AABEE", fontSize, marginBottom }} />;
};

export const FacebookIcon = ({
  size = 25,
  marginBottom = 0,
}: any): ReactElement => {
  return (
    <SiFacebook style={{ color: "#3b5998", fontSize: size, marginBottom }} />
  );
};

export const PhoneIcon = (): ReactElement => {
  return <PhoneFilled style={{ color: colors.primary, fontSize: 20 }} />;
};

export const MessageIcon = (): ReactElement => {
  return <AiOutlineMessage style={{ color: "#3b5998", fontSize: 25 }} />;
};
