interface AuthTranslationMapInterface {
  signIn: "auth.signIn";
  signUp: "auth.signUp";
  iAccept: "auth.iAccept";
  and: "auth.and";
  enterYourName: "auth.enterYourName";
  name: "auth.name";
}

interface ErrorTranslationMapInterface {
  phoneFormatInvalid: "errors.phoneFormatInvalid";
}

interface ButtonsTranslationMapInterface {
  next: "buttons.next";
  back: "buttons.back";
  save: "buttons.save";
  cancel: "buttons.cancel";
  confirm: "buttons.confirm";
  clear: "buttons.clear";
}

interface PaymentTypesTranslationMapInterface {
  card: "paymentTypes.card";
  cash: "paymentTypes.cash";
  any: "paymentTypes.any";
  hidden: "paymentTypes.hidden";
}

interface LanguagesTranslationMapInterface {
  english: "languages.english";
  ukrainian: "languages.ukrainian";
  polish: "languages.polish";
}

interface ContactsTranslationMapInterface {
  phone: "contacts.phone";
  confirmCode: "contacts.confirmCode";
  resendCode: "contacts.resendCode";
  anyTroubles: "contacts.anyTroubles";
  enterYourPhone: "contacts.enterYourPhone";
  toSkipThisStep: "contacts.toSkipThisStep";
  checkYourSMS: "contacts.checkYourSMS";
  resendCodeIn: "contacts.resendCodeIn";
  seconds: "contacts.seconds";
  thisPhoneIn: "contacts.thisPhoneIn";
  messengers: "contacts.messengers";
  facebookUrl: "contacts.facebookUrl";
  smsLimitation: "contacts.smsLimitation";
}

interface FiltersTranslationMapInterface {
  filters: "filters.filters";
  selectCrop: "filters.selectCrop";
  selectCountry: "filters.selectCountry";
  selectState: "filters.selectState";
  selectPaymentType: "filters.selectPaymentType";
  selectCurrency: "filters.selectCurrency";
  applyFilters: "filters.applyFilters";
  currencyFilter: "filters.currencyFilter";
}

interface SortsTranslationMapInterface {
  sorts: "sorts.sorts";
  byPrice: "sorts.byPrice";
  byDate: "sorts.byDate";
  byQuantity: "sorts.byQuantity";
  clearSorts: "sorts.clearSorts";
  selectCurrencyFirst: "sorts.selectCurrencyFirst";
}

interface MenuTranslationMapInterface {
  contacts: "menu.contacts";
  posts: "menu.posts";
  payment: "menu.payment";
  termOfUse: "menu.termOfUse";
  privacyPolicy: "menu.privacyPolicy";
  FAQ: "menu.FAQ";
  support: "menu.support";
  filters: "menu.filters";
  post: "menu.post";
  plans: "menu.plans";
  signIn: "menu.signIn";
  signUp: "menu.signUp";
  account: "menu.account";
  logout: "menu.logout";
  personalInfo: "menu.personalInfo";
  yourPlan: "menu.yourPlan";
  activeTill: "menu.activeTill";
  sell: "menu.sell";
  buy: "menu.buy";
}

interface TranslationMapInterface {
  auth: AuthTranslationMapInterface;
  buttons: ButtonsTranslationMapInterface;
  paymentTypes: PaymentTypesTranslationMapInterface;
  languages: LanguagesTranslationMapInterface;
  contacts: ContactsTranslationMapInterface;
  filters: FiltersTranslationMapInterface;
  sorts: SortsTranslationMapInterface;
  menu: MenuTranslationMapInterface;
}

interface PostTranslationMapInterface {
  addPost: "post.addPost";
  addTradingPost: "post.addTradingPost";
  getContacts: "post.getContacts";
  card: "post.card";
  cash: "post.cash";
  any: "post.any";
  hidden: "post.hidden";
  description: "post.description";
  cropInformation: "post.cropInformation";
  selectCrop: "post.selectCrop";
  harvestYear: "post.harvestYear";
  quantity: "post.quantity";
  selectCountry: "post.selectCountry";
  selectState: "post.selectState";
  selectLocation: "post.selectLocation";
  locationAvailableFor: "post.locationAvailableFor";
  train: "post.train";
  truck: "post.truck";
  ship: "post.ship";
  selectPaymentType: "post.selectPaymentType";
  fillPrices: "post.fillPrices";
  price: "post.price";
  priceHiddenWording: "post.priceHiddenWording";
  postDescription: "post.postDescription";
  howToContactYou: "post.howToContactYou";
  postSuccessfullyCreated: "post.postSuccessfullyCreated";
  youCanAddOneMorePost: "post.youCanAddOneMorePost";
  addOneMorePost: "post.addOneMorePost";
  currencies: "post.currencies";
  locationNear: "post.locationNear";
  welcomeTo: "post.welcomeTo";
  youCreditedWith: "post.youCreditedWith";
  freeDays: "post.freeDays";
  priceHidden: "post.priceHidden";
}

interface PaymentTranslationMapInterface {
  yourPlan: "payment.yourPlan";
  plans: "payment.plans";
  plan: "payment.plan";
  selectCurrency: "payment.selectCurrency";
  choosePlan: "payment.choosePlan";
  signInToGetPlan: "payment.signInToGetPlan";
  subscriptionExpirationMessage: "payment.subscriptionExpirationMessage";
  notActive: "payment.notActive";
}

interface TranslationMapInterface {
  auth: AuthTranslationMapInterface;
  post: PostTranslationMapInterface;
  payment: PaymentTranslationMapInterface;
  buttons: ButtonsTranslationMapInterface;
  paymentTypes: PaymentTypesTranslationMapInterface;
  languages: LanguagesTranslationMapInterface;
  contacts: ContactsTranslationMapInterface;
  filters: FiltersTranslationMapInterface;
  sorts: SortsTranslationMapInterface;
  menu: MenuTranslationMapInterface;
  errors: ErrorTranslationMapInterface;
}

export const translationMap: TranslationMapInterface = {
  auth: {
    signIn: "auth.signIn",
    signUp: "auth.signUp",
    iAccept: "auth.iAccept",
    and: "auth.and",
    enterYourName: "auth.enterYourName",
    name: "auth.name",
  },
  buttons: {
    next: "buttons.next",
    back: "buttons.back",
    save: "buttons.save",
    cancel: "buttons.cancel",
    confirm: "buttons.confirm",
    clear: "buttons.clear",
  },
  paymentTypes: {
    card: "paymentTypes.card",
    cash: "paymentTypes.cash",
    any: "paymentTypes.any",
    hidden: "paymentTypes.hidden",
  },
  languages: {
    english: "languages.english",
    ukrainian: "languages.ukrainian",
    polish: "languages.polish",
  },
  contacts: {
    phone: "contacts.phone",
    confirmCode: "contacts.confirmCode",
    resendCode: "contacts.resendCode",
    anyTroubles: "contacts.anyTroubles",
    enterYourPhone: "contacts.enterYourPhone",
    toSkipThisStep: "contacts.toSkipThisStep",
    checkYourSMS: "contacts.checkYourSMS",
    resendCodeIn: "contacts.resendCodeIn",
    seconds: "contacts.seconds",
    thisPhoneIn: "contacts.thisPhoneIn",
    messengers: "contacts.messengers",
    facebookUrl: "contacts.facebookUrl",
    smsLimitation: "contacts.smsLimitation",
  },
  post: {
    addPost: "post.addPost",
    addTradingPost: "post.addTradingPost",
    getContacts: "post.getContacts",
    card: "post.card",
    cash: "post.cash",
    any: "post.any",
    hidden: "post.hidden",
    description: "post.description",
    cropInformation: "post.cropInformation",
    selectCrop: "post.selectCrop",
    harvestYear: "post.harvestYear",
    quantity: "post.quantity",
    selectCountry: "post.selectCountry",
    selectState: "post.selectState",
    selectLocation: "post.selectLocation",
    locationAvailableFor: "post.locationAvailableFor",
    train: "post.train",
    truck: "post.truck",
    ship: "post.ship",
    selectPaymentType: "post.selectPaymentType",
    fillPrices: "post.fillPrices",
    price: "post.price",
    priceHiddenWording: "post.priceHiddenWording",
    postDescription: "post.postDescription",
    howToContactYou: "post.howToContactYou",
    postSuccessfullyCreated: "post.postSuccessfullyCreated",
    youCanAddOneMorePost: "post.youCanAddOneMorePost",
    addOneMorePost: "post.addOneMorePost",
    currencies: "post.currencies",
    locationNear: "post.locationNear",
    welcomeTo: "post.welcomeTo",
    youCreditedWith: "post.youCreditedWith",
    freeDays: "post.freeDays",
    priceHidden: "post.priceHidden",
  },
  filters: {
    filters: "filters.filters",
    selectCrop: "filters.selectCrop",
    selectCountry: "filters.selectCountry",
    selectState: "filters.selectState",
    selectPaymentType: "filters.selectPaymentType",
    selectCurrency: "filters.selectCurrency",
    applyFilters: "filters.applyFilters",
    currencyFilter: "filters.currencyFilter",
  },
  sorts: {
    sorts: "sorts.sorts",
    byPrice: "sorts.byPrice",
    byDate: "sorts.byDate",
    byQuantity: "sorts.byQuantity",
    clearSorts: "sorts.clearSorts",
    selectCurrencyFirst: "sorts.selectCurrencyFirst",
  },
  payment: {
    yourPlan: "payment.yourPlan",
    plans: "payment.plans",
    plan: "payment.plan",
    selectCurrency: "payment.selectCurrency",
    choosePlan: "payment.choosePlan",
    signInToGetPlan: "payment.signInToGetPlan",
    subscriptionExpirationMessage: "payment.subscriptionExpirationMessage",
    notActive: "payment.notActive",
  },
  menu: {
    contacts: "menu.contacts",
    posts: "menu.posts",
    payment: "menu.payment",
    termOfUse: "menu.termOfUse",
    privacyPolicy: "menu.privacyPolicy",
    FAQ: "menu.FAQ",
    support: "menu.support",
    filters: "menu.filters",
    post: "menu.post",
    plans: "menu.plans",
    signIn: "menu.signIn",
    signUp: "menu.signUp",
    account: "menu.account",
    logout: "menu.logout",
    personalInfo: "menu.personalInfo",
    yourPlan: "menu.yourPlan",
    activeTill: "menu.activeTill",
    sell: "menu.sell",
    buy: "menu.buy",
  },
  errors: {
    phoneFormatInvalid: "errors.phoneFormatInvalid",
  },
};
