import React, { ReactElement } from "react";

import { TradingPostInterface } from "../../../../types/interfaces/TradingPost.interface";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";
import { currencyPrefixesMap } from "../../../../constants/currencies.constants";

import {
  TradingPostPriceStyledWrapper,
  TradingPostPricesStyledWrapper,
} from "./TradingPostPrices.styled";
import { PaymentTypesEnum } from "../../../../types/enums/PaymentTypes.enum";

interface TradingPostPricesProps {
  post: TradingPostInterface;
  language: LanguagesEnum;
}

export const TradingPostPrices = (
  props: TradingPostPricesProps
): ReactElement => {
  const { post, language = LanguagesEnum.EN } = props;
  const { prices, paymentType } = post || {};

  const pricesKeys: any = Object.keys(prices || {});

  if (paymentType === PaymentTypesEnum.HIDDEN) {
    return (
      <TradingPostPricesStyledWrapper>
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          Договірна
        </p>
      </TradingPostPricesStyledWrapper>
    );
  }

  return (
    <TradingPostPricesStyledWrapper>
      {pricesKeys.map((key: any) => {
        // @ts-ignore
        const prefix = currencyPrefixesMap?.[key || ""] || "";

        return (
          <TradingPostPriceStyledWrapper key={key}>
            <span>{prefix}</span>
            <span>{prices[key]}</span>
          </TradingPostPriceStyledWrapper>
        );
      })}
    </TradingPostPricesStyledWrapper>
  );
};
