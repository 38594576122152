import { toJS } from "mobx";
import {
  polandCurrenciesPriorityList,
  ukraineCurrenciesPriorityList,
} from "../../../../constants/currencies.constants";
import { defaultCurrenciesPriorityList } from "../../../../constants/currencies.constants";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";

const getLanguageByPriority = (
  prices: any,
  priority: CurrencyEnum[] = defaultCurrenciesPriorityList
): any => {
  let price;

  for (const currency of priority) {
    if (prices?.[currency] || prices?.[currency] === 0) {
      price = { currency, price: prices?.[currency] };

      break;
    }
  }

  return price;
};

export const getPriceByLanguagePriority = (
  prices: any,
  language: LanguagesEnum | string
): any => {
  if (!language) {
    return (
      getLanguageByPriority(prices, defaultCurrenciesPriorityList) ||
      getLanguageByPriority(
        { [CurrencyEnum.USD]: 0 },
        defaultCurrenciesPriorityList
      )
    );
  }

  if (language === LanguagesEnum.PL) {
    return (
      getLanguageByPriority(prices, polandCurrenciesPriorityList) ||
      getLanguageByPriority(
        { [CurrencyEnum.USD]: 0 },
        defaultCurrenciesPriorityList
      )
    );
  }

  if (language === LanguagesEnum.UK) {
    return (
      getLanguageByPriority(prices, ukraineCurrenciesPriorityList) ||
      getLanguageByPriority(
        { [CurrencyEnum.USD]: 0 },
        defaultCurrenciesPriorityList
      )
    );
  }

  return (
    getLanguageByPriority(prices, defaultCurrenciesPriorityList) ||
    getLanguageByPriority(
      { [CurrencyEnum.USD]: 0 },
      defaultCurrenciesPriorityList
    )
  );
};

interface GetPrioritizedPrice {
  prioritizedPrice: number;
  prioritizedCurrency: CurrencyEnum;
}
export const getPrioritizedPrice = (
  prices: any,
  language: LanguagesEnum | string,
  currency?: CurrencyEnum
): GetPrioritizedPrice => {
  if (currency && prices?.[currency]) {
    const price = prices[currency];

    return {
      prioritizedPrice: price,
      prioritizedCurrency: currency,
    };
  }

  const { price: prioritizedPrice, currency: prioritizedCurrency }: any =
    getPriceByLanguagePriority(prices, language) || {};

  return {
    prioritizedPrice,
    prioritizedCurrency,
  };
};
