import styled from "styled-components";
import { colors } from "../../../../../../../constants/styles/colors.constants";

export const ContactsInformationStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PhoneAvailableInCheckboxStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  div {
    margin: 0 5px;
  }

  span {
    font-weight: 600;
  }
`;

export const AddMessengerStyledWrapper = styled.div<{
  isRootPhoneSelected: boolean;
}>`
  display: flex;
  width: 400px;
  position: relative;
  align-items: center;
  margin-left: 5px;
  margin-top: -14px;

  div {
    display: inline-block;
  }

  span {
    font-weight: 600;
  }

  p {
    margin-left: 5px;
  }
`;
