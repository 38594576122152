import { ReactElement } from "react";
import { DateStyled } from "./PostDate.styled";
import { getStandardLocaleDate } from "../../../utils/date-utils/getStandardLocaleDate";
import { LanguagesEnum } from "../../../types/enums/Languages.enum";

interface PostDateProps {
  date: string;
  language: LanguagesEnum;
}

export const PostDate = (props: PostDateProps): ReactElement => {
  const { date, language = LanguagesEnum.EN } = props;

  if (!date) {
    return <DateStyled> - </DateStyled>;
  }

  return (
    <DateStyled>{getStandardLocaleDate(date, language, "LLL")}</DateStyled>
  );
};
