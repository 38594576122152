export const gtagReportConversion = (
  url: string | undefined,
  sendTo: string
): void => {
  const callback = function () {
    if (typeof url !== "undefined") {
      // @ts-ignore
      window.location = url;
    }
  };

  // @ts-ignore
  window.dataLayer.push({
    event: "conversion",
    send_to: sendTo,
    event_callback: callback,
  });
};
