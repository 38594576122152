import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";
import { SortSwitcherValuesEnum } from "./Sortswitcher";

export const SortSwitcherStyledWrapper = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-right: ${({ isMobile }) => (isMobile ? "10px" : "30px")};

  p {
    margin-right: 5px;
  }
`;

export const SortSwitchesStyledWrapper = styled.div<{
  isDisabled?: boolean;
}>`
  border-radius: 2px;
  position: relative;
  width: 55px;
  height: 23px;
  display: flex;
  background: ${colors.darkWhite};

  opacity: ${({ isDisabled }) => (isDisabled ? 0.7 : 1)};

  div {
    padding: 0 5px;
  }
`;

export const SortSwitchRightStyledButton = styled.div<{
  isSelected: boolean;
  isDisabled?: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  position: absolute;
  right: 1px;
  top: 53%;
  transform: translateY(-50%);
  z-index: 2;

  svg {
    font-size: 14px;
  }
`;

export const SortSwitchLeftStyledButton = styled.div<{
  isSelected: boolean;
  isDisabled?: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  z-index: 2;

  svg {
    font-size: 14px;
  }
`;

export const SortHighliterStyledWrapper = styled.div<{
  sort: SortSwitcherValuesEnum;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 1;
  transition: all 0.1s ease-in-out;
  opacity: 0.2;

  background: ${({ sort }) => {
    if (
      sort === SortSwitcherValuesEnum.ASC ||
      sort === SortSwitcherValuesEnum.DESC
    ) {
      return colors.darkGrey;
    } else {
      return colors.darkWhite;
    }
  }};

  left: ${({ sort }) => {
    if (sort === SortSwitcherValuesEnum.ASC) {
      return "0";
    } else if (sort === SortSwitcherValuesEnum.DESC) {
      return "50%";
    }
  }};

  border-radius: 3px;
`;
