import { observer } from "mobx-react-lite";
import { colors } from "../../../../constants/styles/colors.constants";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { DevicesEnum } from "../../../../hooks/use-adaptation-hook/useAdapatation";
import { Link } from "react-router-dom";

export const Footer = observer(() => {
  const { device } = useStore(StoresEnum.APP_STORE) as AppStore;

  return (
    <footer
      style={{
        display: "flex",
        flexDirection: device === DevicesEnum.DESKTOP ? "column" : "column",
        height: device === DevicesEnum.DESKTOP ? "210px" : "auto",
        padding: device === DevicesEnum.DESKTOP ? "0" : "20px 0 80px",
        justifyContent: "center",
        alignItems: "center",
        background: colors.darkGrey,
        color: colors.white,
        borderTop: `4px solid ${colors.orange}`,
        width: "100%",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: device === DevicesEnum.DESKTOP ? "row" : "column",
          width: device === DevicesEnum.DESKTOP ? 400 : "100%",
          justifyContent:
            device === DevicesEnum.DESKTOP ? "space-between" : "center",
          fontSize: device === DevicesEnum.DESKTOP ? 16 : 20,
          alignItems: device === DevicesEnum.DESKTOP ? "center" : "center",
        }}
      >
        <Link
          to="/posts"
          style={{
            color: colors.white,
            textDecoration: "none",
            padding: device === DevicesEnum.DESKTOP ? "0" : "10px 0",
          }}
        >
          Posts
        </Link>
        <Link
          to="/terms-of-use"
          style={{
            color: colors.white,
            textDecoration: "none",
            padding: device === DevicesEnum.DESKTOP ? "0" : "10px 0",
          }}
        >
          Terms of use
        </Link>

        <Link
          to="/privacy-policy"
          style={{
            color: colors.white,
            textDecoration: "none",
            padding: device === DevicesEnum.DESKTOP ? "0" : "10px 0",
          }}
        >
          Privacy policy
        </Link>

        <Link
          to="/faq"
          style={{
            color: colors.white,
            textDecoration: "none",
            padding: device === DevicesEnum.DESKTOP ? "0" : "10px 0",
          }}
        >
          FAQ
        </Link>
      </div>

      <div
        style={{
          fontSize: 14,
          fontWeight: 600,
          marginTop: device === DevicesEnum.DESKTOP ? "30px" : "10px",
          marginRight: device === DevicesEnum.DESKTOP ? "-50px" : 0,
          textAlign: "center",
        }}
      >
        Agrobridges.com {new Date().getFullYear()}
      </div>
    </footer>
  );
});
