import { makeAutoObservable } from "mobx";
import { LanguagesEnum } from "../types/enums/Languages.enum";
import { CurrencyEnum } from "../types/enums/Currency.enum";
import { ContactsFormInterface } from "../types/interfaces/Contacts.interface";

interface PostModalDataInterface {
  cerealCrop: string;
  country: string;
  state: string;
  locationAvailableFor: {
    truck: boolean;
    train: boolean;
    ship: boolean;
  };
  contacts: ContactsFormInterface;
  prices: Record<string, number>;
  descriptions: Record<string, string>;
  paymentType: string;
}

const postModalDefault = {
  cerealCrop: "",
  country: "",
  state: "",
  locationAvailableFor: {
    truck: false,
    train: false,
    ship: false,
  },
  contacts: {
    rootPhone: "",
    messengers: {
      viber: {
        isRootPhoneSelected: false,
        phone: "",
      },
      whatsapp: {
        isRootPhoneSelected: false,
        phone: "",
      },
      telegram: {
        isRootPhoneSelected: false,
        phone: "",
      },
    },
    facebookUrl: "",
  },
  prices: {
    [CurrencyEnum.USD]: 0,
    [CurrencyEnum.EUR]: 0,
    [CurrencyEnum.UAH]: 0,
    [CurrencyEnum.PLN]: 0,
  },
  paymentType: "",
  descriptions: {
    [LanguagesEnum.EN]: "",
    [LanguagesEnum.PL]: "",
    [LanguagesEnum.UK]: "",
  },
};

export class AddPostStore {
  public postModalData: PostModalDataInterface = postModalDefault;
  public currentStep = 0;
  public isAddPostFormValid = true;
  public isPhoneAdded = false;
  public code = "";
  public phone = "";
  public isPhoneConfirmationLoading = false;
  public isFacebookUrlValid = false;
  public errorFields: string[] = [];

  public stepsValidation = {
    isCerealCropStepValid: false,
    isContactsStepValid: false,
    isDescriptionStepValid: false,
    isLocationStepValid: false,
    isPriceStepValid: false,
    isCodeStepValid: false,
    isPhoneStepValid: false,
  };

  constructor() {
    makeAutoObservable(this);

    this.setPostModalData = this.setPostModalData.bind(this);
    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.setIsAddPostFormValid = this.setIsAddPostFormValid.bind(this);
    this.setIsPhoneAdded = this.setIsPhoneAdded.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.setCode = this.setCode.bind(this);
    this.setIsPhoneConfirmationLoading =
      this.setIsPhoneConfirmationLoading.bind(this);
    this.setIsFacebookUrlValid = this.setIsFacebookUrlValid.bind(this);

    this.setErrorFields = this.setErrorFields.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeDescriptions = this.onChangeDescriptions.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);

    this.onClearPostModal = this.onClearPostModal.bind(this);
  }

  setPostModalData(postModalData: PostModalDataInterface) {
    this.postModalData = postModalData;
  }

  setCurrentStep(currentStep: number) {
    this.currentStep = currentStep;
  }

  setIsAddPostFormValid(isValid: boolean) {
    this.isAddPostFormValid = isValid;
  }

  setIsPhoneAdded(isPhoneAdded: boolean) {
    this.isPhoneAdded = isPhoneAdded;
  }

  setPhone(phone: string) {
    this.phone = phone;
  }

  setCode(code: string) {
    this.code = code;
  }

  setIsPhoneConfirmationLoading(isPhoneConfirmationLoading: boolean) {
    this.isPhoneConfirmationLoading = isPhoneConfirmationLoading;
  }

  setIsFacebookUrlValid(isFacebookUrlValid: boolean) {
    this.isFacebookUrlValid = isFacebookUrlValid;
  }

  setStepsValidation = (stepsValidation: any) => {
    this.stepsValidation = stepsValidation;
  };

  setErrorFields = (errorFields: string[]) => {
    this.errorFields = errorFields;
  };

  onChangeInput = (key: any, type?: string) => {
    return (e: any) => {
      const splittedKey = key.split("/");
      const isDescription = splittedKey?.[0] === "description";
      const isPrice = splittedKey?.[0] === "price";

      if (isDescription) {
        const descriptionLang = splittedKey[1] as LanguagesEnum;

        return this.setPostModalData({
          ...this.postModalData,
          descriptions: {
            ...this.postModalData.descriptions,
            [descriptionLang]: e.target.value,
          },
        });
      }

      if (isPrice) {
        const currency = splittedKey[1] as CurrencyEnum;

        return this.setPostModalData({
          ...this.postModalData,
          prices: {
            ...this.postModalData?.prices,
            [currency]: Number(e.target.value),
          },
        });
      }

      this.setPostModalData({
        ...this.postModalData,
        [key]: type === "NUMBER" ? parseInt(e.target.value) : e.target.value,
      });
    };
  };

  onChangeDescriptions = (descriptions: any) => {
    this.setPostModalData({ ...this.postModalData, descriptions });
  };

  onChangeSelect = (key: any) => {
    return (value: any) => {
      this.setPostModalData({ ...this.postModalData, [key]: value });
    };
  };

  onClearPostModal() {
    this.setCurrentStep(0);
    this.setCode("");
    this.setPhone("");
    this.setIsPhoneAdded(false);
    this.setPostModalData(postModalDefault);
  }
}
