import styled from "styled-components";

export const PhoneInputStyledWrapper = styled.div<{
  marginBottom?: number;
}>`
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  position: relative;

  border-radius: 5px;

  & .form-control {
    width: 100%;
  }
`;

export const PhoneErrorStyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  color: red;
  top: -8px;
  left: 0px;
  position: absolute;
  z-index: 200;
  background: white;
  font-size: 10px;
`;
