import React, { ReactElement, useEffect } from "react";

import { Page } from "../../components/shared-components/Page/Page";

import { AccountStyledWrapper } from "./AccountPage.styled";
import { TradingAccount } from "../../components/composit-components/trading-module/TradingAccount/TradingAccount.page";
import {
  TabOptionInterface,
  Tabs,
} from "../../components/shared-components/Tabs/Tabs";
import { LanguagesEnum } from "../../types/enums/Languages.enum";
import { ProfileTabEnum } from "../../types/enums/ProfileTab.enum";
import { observer } from "mobx-react-lite";
import { StoresEnum, useStore } from "../../hooks/use-store-hook/useStore";
import { UserStore } from "../../store/UserStore";
import { AppStore } from "../../store/AppStore";
import { Account } from "../../components/composit-components/auth-module/Account/Account";
import { MenuLinks } from "../../components/composit-components/app-module/Menu/MenuLinks/MenuLinks";

const accountTabOptions: TabOptionInterface[] = [
  {
    tabValue: ProfileTabEnum.ACCOUNT,
    translations: {
      [LanguagesEnum.UK]: "Кабінет",
      [LanguagesEnum.PL]: "Konto",
      [LanguagesEnum.EN]: "Account",
    },
  },
  {
    tabValue: ProfileTabEnum.TRADING,
    translations: {
      [LanguagesEnum.UK]: "Моя закупівля",
      [LanguagesEnum.PL]: "Zakup",
      [LanguagesEnum.EN]: "Trading",
    },
  },
];

export const AccountPage = observer((): ReactElement => {
  const { user, accountTab, setProfileTab } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;
  const { language, isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const handleChangeAccountTab = (tab: ProfileTabEnum) => {
    setProfileTab(tab);
  };

  const isShowTradingAccount = accountTab === ProfileTabEnum.TRADING;
  const isShowAccount = accountTab === ProfileTabEnum.ACCOUNT;

  return (
    <Page>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          {isMobile() && <MenuLinks isMobile />}
        </div>

        {Boolean(user?.id) && (
          <AccountStyledWrapper $isMobile={isMobile()}>
            <div
              style={{
                display: "flex",
                justifyContent: isMobile() ? "center" : "flex-start",
                marginBottom: isMobile() ? "20px" : "0px",
              }}
            >
              <Tabs
                language={language}
                tabOptions={accountTabOptions}
                selectedTab={accountTab}
                onChange={handleChangeAccountTab}
                tabWidth={isMobile() ? "170px" : 160}
              />
            </div>

            {isShowTradingAccount && <TradingAccount />}
            {isShowAccount && <Account />}
          </AccountStyledWrapper>
        )}
      </>
    </Page>
  );
});
