import { CurrencyEnum } from "../../types/enums/Currency.enum";
import { httpClient } from "../httpClient/HttpClient";

export class PaymentApi {
  private PAYMENT_ENDPOINT = "payment";

  getCheckoutUrl = async (
    planId: string,
    currency: CurrencyEnum,
    userId: string
  ): Promise<any> => {
    const data = await httpClient.get(
      `${this.PAYMENT_ENDPOINT}/checkout/redirect-url?planId=${planId}&currency=${currency}&userId=${userId}`
    );
    const { checkoutUrl } = data;

    return checkoutUrl;
  };
}

export const paymentApi = new PaymentApi();
