import React, { ReactElement, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

import { Menu } from "./components/composit-components/app-module/Menu/Menu";
import { useInit } from "./hooks/use-init-hook/useInit";
import { Posts } from "./pages/Posts.page";
import { ChoosePlanModal } from "./components/composit-components/sell-module/ChoosePlan/ChoosePlanModal";
import { StoresEnum, useStore } from "./hooks/use-store-hook/useStore";
import { UserStore } from "./store/UserStore";
import { SignInModal } from "./components/composit-components/auth-module/AuthModals/SignInModal";
import { Spin } from "antd";
import { SignUpModal } from "./components/composit-components/auth-module/AuthModals/SignUpModal";
import ReactGA from "react-ga";

import "./i18n/config";
import { Footer } from "./components/composit-components/app-module/Footer/Foolter";
import { useAdaptation } from "./hooks/use-adaptation-hook/useAdapatation";
import { Support } from "./components/composit-components/app-module/Support/Support";
import { PrivacyPolicyPage } from "./pages/PrifacyPolicy.page";
import { FAQPage } from "./pages/FAQ.page";
import { TermOfUse } from "./pages/TermOfUse";
import { usePlanCheck } from "./hooks/use-plan-check/usePlanCheck";
import { TradingPostsPage } from "./pages/TradingPostsPage/TradingPostsPage";
import { AccountPage } from "./pages/AccountPage/AccountPage";
import { ModalForBalance } from "./components/composit-components/trading-module/ModalForBalance/ModalForBalance";
import { visitsApi } from "./api/requests/visits.api";
import { BotAuthPage } from "./pages/BotAuth/BotAuth";
import { TelegramModal } from "./components/composit-components/app-module/TelegramModal/TelegramModal";

const TRACKING_ID = "G-HJS3BR46M0";
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: "G-HJS3BR46M0",
};

TagManager.initialize(tagManagerArgs);

const App = (): ReactElement => {
  useInit();

  const {
    isUserLoading,
    isPostsVisited,
    setPostsVisited,
    isTradingVisited,
    setTradingVisited,
  } = useStore(StoresEnum.USER_STORE) as UserStore;

  useAdaptation();

  usePlanCheck();

  let location = useLocation();

  const isPosts =
    location.pathname.includes("posts") || location.pathname === "/";
  const isTradingPosts = location.pathname.includes("trading-posts");
  const isTradingAccount = location.pathname.includes("/account");

  useEffect(() => {
    if (isPosts && !isPostsVisited && !isTradingPosts) {
      setPostsVisited();

      try {
        visitsApi.visitPosts();
      } catch (e) {}
    }

    if (isTradingPosts && !isTradingVisited) {
      setTradingVisited();

      visitsApi.visitTradingPosts();
    }
  }, [location]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (isUserLoading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="App">
      <Menu isPosts={isPosts} isTrading={isTradingAccount} />
      <div style={{ minHeight: "calc(100vh - 204px)" }}>
        <Routes>
          <Route path="/" element={<Posts />} />
          <Route path="/posts" element={<Posts />} />

          <Route path="/trading-posts" element={<TradingPostsPage />} />

          <Route path="/account" element={<AccountPage />} />

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/terms-of-use" element={<TermOfUse />} />

          <Route path="/bot/auth" element={<BotAuthPage />} />

          <Route path="*" element={<Posts />} />
        </Routes>
      </div>

      <Footer />
      <ChoosePlanModal />
      <SignInModal />
      <SignUpModal />
      <ModalForBalance />
      {/* <PricesUpModal /> */}
      <TelegramModal />

      <Support />
    </div>
  );
};

export default App;
