import React, { ReactElement, useEffect, useState } from "react";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { VerificationCodeInput } from "../../../shared-components/VerificationCodeInput/VerificationCodeInput";

import {
  CheckSMSWording,
  PhoneWording,
  ResendCodeStyledWrapper,
} from "./SmsVerification.styled";
import { translationMap } from "../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import {
  SignUpDividerStyledWrapper,
  SignUpWithTelegramButtonStyledWrapper,
} from "../AuthForms/SignUpForm/SignUpForm.styled";
import { FaTelegram } from "react-icons/fa";

export const SmsVerification = (props: any): ReactElement => {
  const {
    code,
    phone,
    isInvalid,
    resendCode,
    isTelegram,
    isConfirmButtonDisabled,

    onSave,
    onChangeCode,
  } = props;

  const [isResendCodeRequested, setIsResendCodeRequested] = useState(true);
  const { t } = useTranslation();

  const [resendCodeTimer, setResendCodeTimer] = useState(60);

  const startResendCodeTimer = () => {
    setIsResendCodeRequested(true);
    setResendCodeTimer(60);

    const interval = setInterval(() => {
      setResendCodeTimer((prev) => prev - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setIsResendCodeRequested(false);
    }, 60000);
  };

  const startResendCode = () => {
    resendCode();
    onChangeCode("····");

    startResendCodeTimer();
  };

  useEffect(() => {
    setIsResendCodeRequested(true);
    setResendCodeTimer(60);

    const interval = setInterval(() => {
      setResendCodeTimer((prev) => prev - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setIsResendCodeRequested(false);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <SignUpWithTelegramButtonStyledWrapper
        href={
          isTelegram
            ? `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?start=login`
            : `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?start=auth`
        }
        target="_blank"
      >
        {isTelegram
          ? "Отримати код через Telegram"
          : "Реєстрація через Telegram"}
        <FaTelegram style={{ marginLeft: 8 }} color="#fff" size={24} />
      </SignUpWithTelegramButtonStyledWrapper>
      <SignUpDividerStyledWrapper $margin="20px 0 15px 0">
        <div />
        <span>Або</span>
        <div />
      </SignUpDividerStyledWrapper>

      <CheckSMSWording>
        {t(translationMap.contacts.checkYourSMS)}
      </CheckSMSWording>
      <PhoneWording>+{phone}</PhoneWording>

      <VerificationCodeInput
        onChange={onChangeCode}
        autoFocus
        value={code}
        isInvalid={isInvalid}
      />

      <ResendCodeStyledWrapper>
        {!isResendCodeRequested ? (
          <>
            <p style={{ fontSize: 16, color: "gray" }}>
              {t(translationMap.contacts.anyTroubles)}
            </p>

            <CustomButton
              type="ghost"
              color="primary"
              onClick={startResendCode}
            >
              {t(translationMap.contacts.resendCode)}
            </CustomButton>
          </>
        ) : (
          <p
            style={{
              fontSize: 16,
              color: "gray",
              marginLeft: 10,
              textAlign: "center",
            }}
          >
            {t(translationMap.contacts.resendCodeIn)} {resendCodeTimer}{" "}
            {t(translationMap.contacts.seconds)}
          </p>
        )}
      </ResendCodeStyledWrapper>

      <CustomButton
        margin="10px 0 0 0"
        type="primary"
        onClick={onSave}
        disabled={isConfirmButtonDisabled}
        style={{ width: "100%" }}
        size="large"
      >
        {t(translationMap.contacts.confirmCode)}
      </CustomButton>
    </div>
  );
};
