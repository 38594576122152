import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const CurrenciesListStyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AdditionalCurrencyStyledWrapper = styled.div`
  width: 49%;
  margin-bottom: 10px;
  display: flex;
`;

export const DefaultCurrencyInputStyledWrapper = styled.div`
  width: 47%;
  margin-bottom: 10;

  & .ant-input-affix-wrapper {
    border-color: ${colors.primary};
  }
`;
