import { CustomButton } from "../../../../../../shared-components/CustomButton/CustomButton";
import React, { ReactElement } from "react";
import { ActionsBarStyledWrapper } from "./ActionsBar.styled";
import { observer } from "mobx-react-lite";
import { useAddPostStore } from "../../../../../../../hooks/use-add-post-store/useAddPostStore";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { translationMap } from "../../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

export const ActionsBar = observer(
  ({
    onConfirmPhone,
    onSavePost,
    isAuthorized,
    onCancel,
  }: any): ReactElement => {
    const { isPhoneAdded, currentStep, stepsValidation, setCurrentStep } =
      useAddPostStore();

    const isCodeConfirmation = currentStep === 4 && isPhoneAdded;
    const isUserAuthorizedAndReadyToSavePost =
      isAuthorized && currentStep === 3;

    const { t } = useTranslation();

    const handleNextStepClick = () => {
      if (isUserAuthorizedAndReadyToSavePost) {
        setCurrentStep(5);

        onSavePost?.();

        return;
      }

      if (isCodeConfirmation) {
        onSavePost?.();

        return;
      }

      if (currentStep === 4) {
        onConfirmPhone?.();

        return;
      }

      setCurrentStep(currentStep + 1);
    };

    const handleBackStepClick = () => {
      setCurrentStep(currentStep - 1);
    };

    const getValidationStatusByCurrentStep = () => {
      if (currentStep === 0) {
        return (
          stepsValidation.isCerealCropStepValid &&
          stepsValidation.isLocationStepValid
        );
      }

      if (currentStep === 1) {
        return stepsValidation.isPriceStepValid;
      }

      if (currentStep === 2) {
        return stepsValidation.isDescriptionStepValid;
      }

      if (currentStep === 3) {
        return stepsValidation.isContactsStepValid;
      }

      if (currentStep === 4) {
        return stepsValidation.isPhoneStepValid;
      }

      return true;
    };

    if (currentStep === 4 && isCodeConfirmation && !isAuthorized) {
      return <></>;
    }

    if (currentStep === 5) {
      return <></>;
    }

    return (
      <>
        <ActionsBarStyledWrapper>
          {currentStep === 0 ? (
            <CustomButton style={{ width: "30%" }} onClick={onCancel}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontSize: 16, fontWeight: 600 }}>
                  {" "}
                  {t(translationMap.buttons.cancel)}
                </span>
              </div>
            </CustomButton>
          ) : (
            <CustomButton
              style={{ width: "30%" }}
              onClick={handleBackStepClick}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IoMdArrowBack size={20} style={{ marginLeft: -14 }} />
                <span style={{ fontSize: 16, fontWeight: 600, marginLeft: 5 }}>
                  {t(translationMap.buttons.back)}
                </span>
              </div>
            </CustomButton>
          )}

          <CustomButton
            style={{ width: "30%" }}
            type="primary"
            onClick={handleNextStepClick}
            disabled={!getValidationStatusByCurrentStep()}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, marginRight: 5 }}>
                {isUserAuthorizedAndReadyToSavePost || isCodeConfirmation
                  ? t(translationMap.buttons.save)
                  : t(translationMap.buttons.next)}
              </span>
              <IoMdArrowForward size={20} style={{ marginRight: -14 }} />
            </div>
          </CustomButton>
        </ActionsBarStyledWrapper>
      </>
    );
  }
);
