import React, { ReactElement } from "react";

import { TradingPostsEmptyStateStyledWrapper } from "./TradingPostsEmptyState.styled";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { TradingAccountWidgetStatesEnum } from "../../../../types/enums/TradingAccountWidgetStates.enum";

export const TradingPostsEmptyState = observer((): ReactElement => {
  const {
    tradingAccountState,
    tradingAccount,
    toggleCreatePostModal,
    toggleModalForBalance,
  } = useTradingStore();

  if (
    tradingAccountState === TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT
  ) {
    return (
      <TradingPostsEmptyStateStyledWrapper>
        <p>Ви не маєте торгового облікового запису</p>
      </TradingPostsEmptyStateStyledWrapper>
    );
  }

  const isTradingAccountBalanceEmpty = tradingAccount?.postBalance === 0;

  const handleClickCreatePost = () => {
    if (isTradingAccountBalanceEmpty) {
      return toggleModalForBalance();
    }

    toggleCreatePostModal();
  };

  return (
    <TradingPostsEmptyStateStyledWrapper>
      <p
        style={{
          marginBottom: 16,
        }}
      >
        {isTradingAccountBalanceEmpty
          ? "Баланс облікового запису дорівнює 0"
          : "Оголошень на закупівлю не знайдено"}
      </p>

      <CustomButton
        type="primary"
        style={{ width: "50%" }}
        onClick={handleClickCreatePost}
      >
        {isTradingAccountBalanceEmpty
          ? "Поповнити баланс"
          : "Додати оголошення"}
      </CustomButton>
    </TradingPostsEmptyStateStyledWrapper>
  );
});
