import { useLocation } from "../use-locations-hook/useLocations";
import { useAuth } from "../use-auth-hook/useAuth";
import { usePlan } from "../use-plan-hook/usePlan";
import { useCerealCrops } from "../use-cereal-crops-hook/useCerealCrops";

export const useInit = () => {
  useLocation();
  useAuth();
  usePlan();
  useCerealCrops();
};
