import { ReactElement, useState } from "react";
import { ContactsTitle } from "../ContactsTitle/ContactsTitle";
import { TelegramIcon, ViberIcon, WhatsappIcon } from "../ContsctIcons";
import { PhoneAvailableInCheckboxStyledWrapper } from "../ContactsInformation.styled";
import { Checkbox } from "antd";
import { useAddPostStore } from "../../../../../../../../hooks/use-add-post-store/useAddPostStore";
import { MessengerInterface } from "../../../../../../../../types/interfaces/Contacts.interface";
import { observer } from "mobx-react-lite";
import { ValidatedPhoneInput } from "../../../../../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import {
  StoresEnum,
  useStore,
} from "../../../../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../../../../store/AppStore";
import { DevicesEnum } from "../../../../../../../../hooks/use-adaptation-hook/useAdapatation";
import { useAddPostForm } from "../../../useAddPostForm";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../../../../i18n/translationMap";

interface MessengerCheckboxProps {
  Icon: any;
  messenger: MessengerInterface;
  isMobile?: boolean;
  onCheck: () => void;
}
const MessengerCheckbox = (props: MessengerCheckboxProps): ReactElement => {
  const { messenger, onCheck, Icon, isMobile = false } = props;
  const { isRootPhoneSelected } = messenger;

  return (
    <PhoneAvailableInCheckboxStyledWrapper onClick={onCheck}>
      <Checkbox checked={isRootPhoneSelected} />
      <div>{Icon}</div>
    </PhoneAvailableInCheckboxStyledWrapper>
  );
};

export const RootPhone = observer((): ReactElement => {
  const { postModalData } = useAddPostStore();
  const { t } = useTranslation();

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { country } = postModalData || {};

  const { state, handlers } = useAddPostForm();
  const { rootPhone, messengers, isPhoneValid } = state;
  const {
    handleChangePhone,
    handleMessengerCheckChange,
    handleSetIsPhoneValid,
  } = handlers;

  return (
    <>
      <ContactsTitle marginTop={-10} title={t(translationMap.contacts.phone)} />

      <ValidatedPhoneInput
        country={String(country).toLowerCase()}
        phone={rootPhone}
        isValid={isPhoneValid}
        onChangePhone={handleChangePhone()}
        setIsPhoneValid={handleSetIsPhoneValid}
        inputStyle={{ width: "100%" }}
        marginBottom={5}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ marginRight: 20 }}>This phone in: </p>

        <MessengerCheckbox
          Icon={<ViberIcon />}
          messenger={messengers?.viber}
          onCheck={handleMessengerCheckChange("viber")}
          isMobile={isMobile()}
        />

        <MessengerCheckbox
          Icon={<WhatsappIcon />}
          messenger={messengers?.whatsapp}
          onCheck={handleMessengerCheckChange("whatsapp")}
          isMobile={isMobile()}
        />

        <MessengerCheckbox
          Icon={<TelegramIcon />}
          messenger={messengers?.telegram}
          onCheck={handleMessengerCheckChange("telegram")}
          isMobile={isMobile()}
        />
      </div>

      {isMobile() && <div style={{ height: 20 }}></div>}
    </>
  );
});
