import { CurrencyEnum } from "../../types/enums/Currency.enum";
import { convertDataUrlToBlob } from "../../utils/file-utils/convertDataUrlToBlob";
import { addParamsToUrl } from "../../utils/url-utils/addParamsToUrl";
import { httpClient } from "../httpClient/HttpClient";

interface GetTradingPostParamsInterface {
  perPage?: number;
  page?: number;
  filters: any;
  sorts: any;
  showed: string[];
  toShow: string[];
}

export class TradingApi {
  private TRADING_ENDPOINT = "trading";

  getTrading = async (): Promise<any> => {
    const data = await httpClient.get(`${this.TRADING_ENDPOINT}/`);

    return data;
  };

  getPublicTradingPosts = async (
    params: GetTradingPostParamsInterface
  ): Promise<any> => {
    const URL = `${this.TRADING_ENDPOINT}/public-posts`;
    const defaultParams = { perPage: 20, page: 1 };

    const { filters, sorts, page, perPage, showed, toShow } = params;

    const { tradingPosts, amount = 0 } = await httpClient.get(
      addParamsToUrl(URL, {
        perPage: perPage || defaultParams.perPage,
        page: page || defaultParams.page,
        showed,
        toShow,
        ...filters,
        ...sorts,
      })
    );

    return { tradingPosts, amount };
  };

  getTradingPosts = async ({ page = 1, perPage = 20 }: any): Promise<any> => {
    try {
      const {
        tradingPosts,
        amount = 0,
        activeAmount,
      } = await httpClient.get(
        addParamsToUrl(`${this.TRADING_ENDPOINT}/posts`, {
          page,
          perPage,
        })
      );

      return { posts: tradingPosts, amount, activeAmount };
    } catch (error) {
      return [];
    }
  };

  createTradingAccount = async (
    tradingAccount: any,
    logoFile: any
  ): Promise<any> => {
    const formData = new FormData();

    if (logoFile) {
      const blob = convertDataUrlToBlob(logoFile);
      const file = new File([blob], "cropped-image.png", { type: "image/png" });

      formData.append("file", file, file.name);
    }

    Object.keys(tradingAccount).forEach((key: any) => {
      // @ts-ignore
      if (typeof tradingAccount[key] === "object") {
        // @ts-ignore
        formData.append(key, JSON.stringify(tradingAccount[key]));
      } else {
        // @ts-ignore
        formData.append(key, tradingAccount[key]);
      }
    });

    const data = await httpClient.post(
      `${this.TRADING_ENDPOINT}/trading-account`,
      formData,
      true
    );

    return data;
  };

  updateTradingAccount = async (
    tradingAccount: any,
    logoFile: any
  ): Promise<any> => {
    const formData = new FormData();

    if (logoFile) {
      const blob = convertDataUrlToBlob(logoFile);
      const file = new File([blob], "cropped-image.png", { type: "image/png" });

      formData.append("file", file, file.name);
    }

    Object.keys(tradingAccount).forEach((key: any) => {
      // @ts-ignore
      if (typeof tradingAccount[key] === "object") {
        // @ts-ignore
        formData.append(key, JSON.stringify(tradingAccount[key]));
      } else {
        // @ts-ignore
        formData.append(key, tradingAccount[key]);
      }
    });

    const data = await httpClient.put(
      `${this.TRADING_ENDPOINT}/trading-account`,
      formData,
      true
    );

    return data;
  };

  getTradingAccount = async (): Promise<any> => {
    const { tradingAccount } =
      (await httpClient.get(`${this.TRADING_ENDPOINT}/trading-account`)) || {};

    return tradingAccount;
  };

  createTradingPost = async (tradingPost: any): Promise<any> => {
    const data = await httpClient.post(
      `${this.TRADING_ENDPOINT}/post`,
      tradingPost
    );

    return data;
  };

  getTradingPlans = async (): Promise<any> => {
    const { tradingPlans } =
      (await httpClient.get(`${this.TRADING_ENDPOINT}/trading-plans`)) || {};

    return tradingPlans;
  };

  getTradingTopPlans = async (): Promise<any> => {
    const { tradingTopPlans } =
      (await httpClient.get(`${this.TRADING_ENDPOINT}/trading-top-plans`)) ||
      {};

    return tradingTopPlans;
  };

  increaseAccountBalance = async (planId: string): Promise<any> => {
    const data = await httpClient.put(
      `${this.TRADING_ENDPOINT}/increase-balance`,
      { tradingPlanId: planId }
    );

    return data;
  };

  getBalanceCheckoutUrl = async (
    planId: string,
    currency: CurrencyEnum
  ): Promise<any> => {
    const data = await httpClient.get(
      `${this.TRADING_ENDPOINT}/balance-checkout-url?tradingPlanId=${planId}&currency=${currency}`
    );

    return data;
  };

  getToTopCheckoutUrl = async (
    planId: string,
    currency: CurrencyEnum,
    tradingPostId: string
  ): Promise<any> => {
    const data = await httpClient.get(
      `${this.TRADING_ENDPOINT}/top-checkout-url?tradingTopPlanId=${planId}&currency=${currency}&tradingPostId=${tradingPostId}`
    );

    return data;
  };

  continueTradingPost = async (tradingPostId: string): Promise<any> => {
    const data = await httpClient.put(`${this.TRADING_ENDPOINT}/continue`, {
      tradingPostId,
    });

    return data;
  };

  updateTradingPost = async (tradingPost: any): Promise<any> => {
    const data = await httpClient.put(
      `${this.TRADING_ENDPOINT}/post`,
      tradingPost
    );

    return data;
  };

  getTradingContinueAllPlans = async (): Promise<any> => {
    const { tradingContinuePlans } =
      (await httpClient.get(`${this.TRADING_ENDPOINT}/continue-plans`)) || {};

    return tradingContinuePlans;
  };

  getTradingContinueCheckoutUrl = async (
    planId: string,
    currency: CurrencyEnum,
    tradingPostId: string
  ): Promise<any> => {
    const data = await httpClient.get(
      `${this.TRADING_ENDPOINT}/continue-checkout-url?tradingContinuePlanId=${planId}&currency=${currency}&tradingPostId=${tradingPostId}`
    );

    return data;
  };

  continuePostFromBalance = async (
    planId: string,
    tradingPostId: string
  ): Promise<any> => {
    const { activeTill, balance } = await httpClient.put(
      `${this.TRADING_ENDPOINT}/continue-plan-from-balance`,
      {
        tradingContinuePlanId: planId,
        tradingPostId,
      }
    );

    return { activeTill, balance };
  };

  increasePostClick = async (id: string): Promise<any> => {
    const data = await httpClient.put(`${this.TRADING_ENDPOINT}/post-click`, {
      id,
    });

    return data;
  };
}

export const tradingApi = new TradingApi();
