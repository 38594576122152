import React, { ReactElement } from "react";
import { ContactsTitle } from "../ContactsTitle/ContactsTitle";
import { TelegramIcon, ViberIcon, WhatsappIcon } from "../ContsctIcons";
import { AddMessengerStyledWrapper } from "../ContactsInformation.styled";
import { observer } from "mobx-react-lite";
import { useAddPostStore } from "../../../../../../../../hooks/use-add-post-store/useAddPostStore";
import { ValidatedPhoneInput } from "../../../../../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import {
  StoresEnum,
  useStore,
} from "../../../../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../../../../store/AppStore";
import { useAddPostForm } from "../../../useAddPostForm";
import { translationMap } from "../../../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

interface AddMessengerProps {
  Icon: any;
  phone: string;
  messengerName: string;
  country: string;
  isMobile?: boolean;
  isValid?: boolean;
  messenger: {
    isRootPhoneSelected: boolean;
    phone: string;
  };
  onChange: (value: string) => void;
}
const AddMessenger = (props: AddMessengerProps): ReactElement => {
  const {
    isMobile,
    messengerName,
    messenger,
    country,
    phone,
    Icon,
    isValid,
    onChange,
  } = props;
  const { isRootPhoneSelected, phone: messengerPhone } = messenger;

  const { t } = useTranslation();

  const [isPhoneValid, setIsPhoneValid] = React.useState<boolean>(false);

  const handleChangeMessengerPhone = (value: string) => {
    onChange(value);
  };

  const getValidatedPhone = (): boolean => {
    if (!isRootPhoneSelected && !messengerPhone) {
      return true;
    }

    return Boolean(isValid);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: isMobile ? "flex-start" : "space-between",
        width: isMobile ? "100%" : "auto",
      }}
    >
      {isMobile && <div style={{ marginRight: 5, marginTop: -12 }}>{Icon}</div>}

      <ValidatedPhoneInput
        country={String(country).toLowerCase()}
        phone={isRootPhoneSelected ? phone : messengerPhone}
        isValid={getValidatedPhone()}
        setIsPhoneValid={setIsPhoneValid}
        onChangePhone={handleChangeMessengerPhone}
        inputStyle={{ width: 300 }}
        marginBottom={16}
        disabled={isRootPhoneSelected}
      />

      {!isMobile && (
        <AddMessengerStyledWrapper isRootPhoneSelected={isRootPhoneSelected}>
          <div>{Icon}</div>

          <p>
            {t(translationMap.contacts.phone)} <span>{messengerName}</span>
          </p>
        </AddMessengerStyledWrapper>
      )}
    </div>
  );
};

export const Messengers = observer((): ReactElement => {
  const { postModalData, errorFields } = useAddPostStore();
  const { state, handlers } = useAddPostForm();
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { t } = useTranslation();

  const { country } = postModalData || {};
  const { messengers, rootPhone } = state;

  const { handleChangeMessengerPhone } = handlers;

  return (
    <>
      <ContactsTitle
        marginTop={8}
        title={t(translationMap.contacts.messengers)}
      />

      <div>
        <AddMessenger
          messengerName="Viber"
          messenger={messengers?.viber}
          country={country}
          isValid={!errorFields.includes("viber")}
          phone={rootPhone}
          Icon={<ViberIcon />}
          onChange={handleChangeMessengerPhone("viber")}
          isMobile={isMobile()}
        />

        <AddMessenger
          messengerName="WhatsApp"
          phone={rootPhone}
          country={country}
          isValid={!errorFields.includes("whatsapp")}
          messenger={messengers?.whatsapp}
          Icon={<WhatsappIcon />}
          onChange={handleChangeMessengerPhone("whatsapp")}
          isMobile={isMobile()}
        />

        <AddMessenger
          messengerName="Telegram"
          messenger={messengers?.telegram}
          country={country}
          isValid={!errorFields.includes("telegram")}
          phone={rootPhone}
          Icon={<TelegramIcon />}
          onChange={handleChangeMessengerPhone("telegram")}
          isMobile={isMobile()}
        />
      </div>
    </>
  );
});
