import styled from "styled-components";

export const VerificationCodeStyled = styled.div`
  & .ReactInputVerificationCode__item {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin: 0 15px;
  }

  & .ReactInputVerificationCode__container {
    display: flex;
    justify-content: center;
  }
`;
