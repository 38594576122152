import styled, { css } from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";

const LargeLogoCSS = css`
  font-size: 26px;

  div {
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  span {
    font-weight: 600;

    & .logo-s {
      margin-left: -6px;
      font-weight: 400;
    }
  }
`;

const SmallLogoCSS = css`
  font-size: 22px;

  div {
    width: 28px;
    height: 28px;
    border: 2px solid #000;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  span {
    font-weight: 600;

    & .logo-s {
      margin-left: -6px;
      font-weight: 400;
    }
  }
`;

const getLogoStyles = (props: any) => {
  if (props.size === "large") {
    return LargeLogoCSS;
  }

  if (props.size === "small") {
    return SmallLogoCSS;
  }
};

export const LogoStyled = styled.a<{ size: "small" | "medium" | "large" }>`
  font-family: "Gilroy", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${getLogoStyles}
`;

export const LogoShortStyled = styled.div<{ bridgeLogoBorder?: string }>`
  font-family: "Gilroy", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;

  font-size: 46px;

  div {
    width: 50px;
    height: 50px;
    background: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    ${({ bridgeLogoBorder }) =>
      bridgeLogoBorder && `border: ${bridgeLogoBorder};`}
  }
`;
