interface ColorsInterface {
  primary: string;
  greenGradientStart: string;
  greenGradientEnd: string;
  black: string;
  white: string;
  darkWhite: string;
  lightGreen: string;
  darkGrey: string;
  lightGrey: string;
  link: string;
  orange: string;
}

export const colors: ColorsInterface = {
  primary: "#31A05F",
  greenGradientStart: "#22A45D",
  greenGradientEnd: "#87DC69",
  black: "#000000",
  white: "#FFFFFF",
  darkWhite: "#EFEFF0",
  lightGreen: "#D3EDDF",
  darkGrey: "#4B4B4B",
  lightGrey: "#C4C4C4",
  link: "#1677ff",
  orange: "#EF9920"
};
