import React, { ReactElement } from "react";
import TextArea from "antd/es/input/TextArea";

import { LanguagesEnum } from "../../../../../../../../types/enums/Languages.enum";
import { languagesFullTranslatedNamesMap } from "../../../../../../../../constants/languages.constants";
import { DescriptionTextAreaStyledWrapper } from "./DescriptionTextArea.styled";

interface DescriptionProps {
  value: string;
  language: LanguagesEnum;
  disabled?: boolean;
  onChange: (e: any) => void;
}

export const DescriptionTextArea = (props: DescriptionProps): ReactElement => {
  const { value, language, disabled, onChange } = props;

  return (
    <DescriptionTextAreaStyledWrapper>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: 8,
        }}
      >
        {languagesFullTranslatedNamesMap[language]} description
      </p>

      <TextArea
        value={value}
        placeholder={`Enter ${languagesFullTranslatedNamesMap[language] || ""}`}
        onChange={onChange}
        style={{ marginBottom: 15 }}
        disabled={disabled}
        maxLength={500}
        showCount
        autoSize={{ minRows: 3, maxRows: 3 }}
      />
    </DescriptionTextAreaStyledWrapper>
  );
};
