import React, { ReactElement } from "react";

import { Filters } from "../../../shared-components/Filters/Filters";
import { observer } from "mobx-react-lite";
import { useSharedFilters } from "../../../../hooks/use-shared-filters-hook/useSharedFilters";
import { toJS } from "mobx";
import { usePublicTradingPosts } from "../../../../hooks/use-public-trading-posts-hook/usePublicTradingPosts";

export const TradingFilters = observer((): ReactElement => {
  const {
    filters,
    setFilters,
    checkIsFiltersApplied,
    isFiltersOpen,
    setIsFiltersOpen,
    onClearTradingFilters,
  } = usePublicTradingPosts(false);

  const {
    localFilters,

    onApplyFilters,
    selectFilter,
    onClearPostFilters,
    setLocalFilters,
  } = useSharedFilters({
    filters,

    handleChangeFilters: setFilters,
    clearPostFilters: onClearTradingFilters,
    checkIsFiltersApplied,
    setIsFiltersOpen,
  });

  const { cerealCrops: selectedCerealCrops } = localFilters;


  return (
    <Filters
      onClear={onClearPostFilters}
      disabled={!checkIsFiltersApplied()}
      localFilters={localFilters}
      setLocalFilters={setLocalFilters}
      selectedCerealCrops={selectedCerealCrops}
      selectFilter={selectFilter}
      handleApplyFilters={onApplyFilters}
      // mobile
      setIsFiltersOpen={setIsFiltersOpen}
      isFiltersOpen={isFiltersOpen}
    />
  );
});
