import React, { ReactElement } from "react";

import { observer } from "mobx-react-lite";
import { translationMap } from "../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { getSellCountryStatesOptions } from "../../../../utils/options-utils/getCountriesOptions";
import { AppStore } from "../../../../store/AppStore";
import { ValidationSelect } from "../../../shared-components/ValisdationSelect/VaslidationSelect";

interface StatesListProps {
  value?: string | string[];
  isValid: boolean;
  onChange: (value: string) => void;
  isRequired: boolean;
  style?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  disabled?: boolean;
  showSearch?: boolean;
  country: string;
  mode?: "tags" | "multiple" | undefined;
  isShortForm?: boolean;
}

export const SellStatesList = observer(
  (props: StatesListProps): ReactElement => {
    const {
      value,
      isValid,
      onChange: handleChangeState,
      isRequired,
      style = {},
      selectStyle = {},
      disabled,
      showSearch,
      country,
      mode = undefined,
    } = props;

    const { countriesStates, language } = useStore(
      StoresEnum.APP_STORE
    ) as AppStore;
    const stateOptions = getSellCountryStatesOptions(
      countriesStates?.[country] || [],
      language
    );

    const { t } = useTranslation();

    return (
      <ValidationSelect
        showSearch={showSearch}
        placeholder={t(translationMap.post.selectState)}
        isValid={isValid}
        value={value || undefined}
        isRequired={isRequired}
        onChange={handleChangeState}
        style={style}
        selectStyle={selectStyle}
        options={stateOptions}
        disabled={disabled}
        mode={mode}
      />
    );
  }
);
