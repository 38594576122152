import { useEffect } from "react";
import { AppStore } from "../../store/AppStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";

enum BreakpointsEnum {
  SMALL = 576,
  MEDIUM = 768,
  LARGE = 4200,
}

export enum DevicesEnum {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  DESKTOP = "DESKTOP",
}

const isMobile = (width: number): boolean => width < BreakpointsEnum.SMALL;
const isTablet = (width: number): boolean => {
  return width >= BreakpointsEnum.SMALL && width < BreakpointsEnum.MEDIUM;
};
const isDesktop = (width: number): boolean => {
  return width >= BreakpointsEnum.MEDIUM && width < BreakpointsEnum.LARGE;
};

const getDevice = (): DevicesEnum | null => {
  const windowWidth = window.innerWidth;
  if (isMobile(windowWidth)) {
    return DevicesEnum.MOBILE;
  }

  if (isTablet(windowWidth)) {
    return DevicesEnum.TABLET;
  }

  if (isDesktop(windowWidth)) {
    return DevicesEnum.DESKTOP;
  }

  return null;
};

export const useAdaptation = (): void => {
  const { setDevice } = useStore(StoresEnum.APP_STORE) as AppStore;

  function updateSize() {
    setDevice(getDevice());
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    updateSize();
  }, []);
};
