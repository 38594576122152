import React, { useEffect, useState } from "react";

import { currencyPrefixesMap } from "../../../../../../constants/currencies.constants";
import { CurrencyEnum } from "../../../../../../types/enums/Currency.enum";
import { PostInterface } from "../../../../../../types/interfaces/Post.interface";
import { colors } from "../../../../../../constants/styles/colors.constants";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../../store/AppStore";
import { getPriceByLanguagePriority } from "../../Post.utils";
import { PaymentTypesEnum } from "../../../../../../types/enums/PaymentTypes.enum";

interface CurrenciesListProps {
  prices: PostInterface["prices"];
  filters: Record<string, any>;
  paymentType?: PaymentTypesEnum;
}

export const CurrenciesList = observer((props: CurrenciesListProps) => {
  const { language } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { prices, filters, paymentType = "" } = props;

  const [currentPrices, setCurrenciesPrices] = useState(prices);
  const [isHidePrices, setIsHidePrices] = useState(false);

  useEffect(() => {
    if (!prices) {
      return;
    }

    if (filters?.currency) {
      const filteredPrices: any = Object.entries(prices).reduce(
        (acc, [key, value]) => {
          if (key === filters?.currency || !value) {
            return acc;
          }

          return { ...acc, [key]: value };
        },
        {}
      );

      setCurrenciesPrices(filteredPrices);
      const isHidePrices = Object.keys(filteredPrices).length === 0;
      setIsHidePrices(isHidePrices);

      return;
    }

    const { currency: priorCurrency } = getPriceByLanguagePriority(
      prices,
      language
    );
    const filteredPricesByPriority: any = Object.entries(prices).reduce(
      (acc, [key, value]) => {
        if (key === priorCurrency || !value) {
          return acc;
        }

        return { ...acc, [key]: value };
      },
      {}
    );

    setCurrenciesPrices(filteredPricesByPriority);
    const isHidePrices = Object.keys(filteredPricesByPriority).length === 0;
    setIsHidePrices(isHidePrices);
  }, [prices, filters?.currency, language]);

  if (isHidePrices || paymentType === PaymentTypesEnum.HIDDEN) {
    return <></>;
  }

  return (
    <p style={{ fontSize: 16, marginTop: 5 }}>
      {Boolean(currentPrices?.[CurrencyEnum.USD]) && (
        <span
          style={{
            margin: "0 5px",
            color: colors.primary,
            fontWeight: 600,
          }}
        >
          {currencyPrefixesMap[CurrencyEnum.USD]}
          {currentPrices[CurrencyEnum.USD]}
        </span>
      )}
      {Boolean(currentPrices?.[CurrencyEnum.EUR]) && (
        <span
          style={{
            margin: "0 5px",
            color: colors.primary,
            fontWeight: 600,
          }}
        >
          {currencyPrefixesMap[CurrencyEnum.EUR]}
          {currentPrices[CurrencyEnum.EUR]}
        </span>
      )}
      {Boolean(currentPrices?.[CurrencyEnum.PLN]) && (
        <span
          style={{
            margin: "0 5px",
            color: colors.primary,
            fontWeight: 600,
          }}
        >
          {currencyPrefixesMap[CurrencyEnum.PLN]}
          {currentPrices[CurrencyEnum.PLN]}
        </span>
      )}
      {Boolean(currentPrices?.[CurrencyEnum.UAH]) && (
        <span
          style={{
            margin: "0 5px",
            color: colors.primary,
            fontWeight: 600,
          }}
        >
          {currencyPrefixesMap[CurrencyEnum.UAH]}
          {currentPrices[CurrencyEnum.UAH]}
        </span>
      )}
    </p>
  );
});
