import React, { ReactElement } from "react";

import { TradingTopPlansModalStyledWrapper } from "./TradingTopPlansModale.styled";
import { TradingTopPlanInterface } from "../../../../types/interfaces/TradingTopPlan.interface";
import { Modal } from "antd";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { observer } from "mobx-react-lite";
import { Plan } from "../../../shared-components/Plan/Plan";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { useTradingTopPlans } from "../../../../hooks/use-use-trading-top-plans-hook/useUseTradingTopPlans";
import { tradingApi } from "../../../../api/requests/trading.api";
import { navigateByCheckoutUrl } from "../../../../utils/navigation-utils/navigateByCheckoutUrl";
import { addDays, isBefore } from "date-fns";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { SelectPlanCurrency } from "../../../shared-components/SelectPlanCurrency/SelectPlanCurrency";

export const TradingTopPlansModal = observer((): ReactElement => {
  const {
    isTradingTopPlanOpen,
    tradingTopPlans = [],
    tradingPostToTop,
    toggleTradingTopPlanModal,
    setTradingPostToTop,
  } = useTradingStore();

  useTradingTopPlans();

  const { isMobile, language } = useStore(StoresEnum.APP_STORE) as AppStore;

  const [selectedCurrency, setSelectedCurrency] = React.useState(
    CurrencyEnum.UAH
  );

  const handleChooseTopPlan = async (id: string): Promise<void> => {
    const { payment } = await tradingApi.getToTopCheckoutUrl(
      id,
      CurrencyEnum.UAH,
      tradingPostToTop.id
    );

    navigateByCheckoutUrl(payment.checkoutUrl);

    setTradingPostToTop(null);
  };

  const handleCancelTopPlan = () => {
    setTradingPostToTop(null);
    toggleTradingTopPlanModal();
  };

  const checkIsPlanDisabled = (plan: TradingTopPlanInterface): boolean => {
    const dateOfEndTopPlan = addDays(new Date(), plan.days);
    const dateOfEndPost = new Date(tradingPostToTop?.activeTill);

    return !isBefore(dateOfEndTopPlan, dateOfEndPost);
  };

  const Content = (
    <TradingTopPlansModalStyledWrapper>
      {tradingTopPlans.map((plan: TradingTopPlanInterface) => {
        return (
          <Plan
            isMobile={isMobile()}
            plan={plan}
            selectedCurrency={selectedCurrency}
            handleChoosePlan={() => handleChooseTopPlan(plan.id)}
            language={language}
            isDisabled={checkIsPlanDisabled(plan)}
          />
        );
      })}
    </TradingTopPlansModalStyledWrapper>
  );

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isTradingTopPlanOpen}
        title="Топ план баланс"
        onClose={handleCancelTopPlan}
        Content={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectPlanCurrency
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
            </div>
            {Content}
          </>
        }
      />
    );
  }

  return (
    <Modal
      title="Топ план баланс"
      open={isTradingTopPlanOpen}
      onOk={toggleTradingTopPlanModal}
      onCancel={handleCancelTopPlan}
      centered
      width={740}
      footer={null}
    >
      {Content}
    </Modal>
  );
});
