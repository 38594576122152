import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const SidebarTitleRowStyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const SidebarRowActionStyledWording = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const SidebarRowClearActionStyledButton = styled.div<{
  disabled: boolean;
}>`
  font-size: 12px;
  color: ${colors.link}};
  font-weight: 600;

  ${({ disabled }) => disabled && `opacity: 0.5;`};
  cursor:  ${({ disabled }) => (disabled ? "default" : "pointer")};
`;
