export const addUtmMarkToUrl = (url: string): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmMark = urlParams.get("utm_source");

  if (!utmMark) {
    return url;
  }

  return `${url}?utm_source=${utmMark}`;
};
