import React, { ReactElement, useEffect } from "react";

import { Page } from "../../components/shared-components/Page/Page";

import { TradingPostsPageStyledWrapper } from "./TradingPostsPage.styled";
import { AdvertisementBanner } from "../../components/composit-components/app-module/AdvertisementBanner/AdvertisementBanner";
import { ListTitle } from "../../components/shared-components/ListTitle/ListTitle";
import { PublicTradingPost } from "../../components/composit-components/trading-module/PublicTradingPost/PublicTradingPost";
import { ListLayout } from "../../components/shared-components/ListLayout/ListLayout";
import { TradingFilters } from "../../components/composit-components/trading-module/TradingFilters/TradingFilters";
import { observer } from "mobx-react-lite";
import { usePublicTradingPosts } from "../../hooks/use-public-trading-posts-hook/usePublicTradingPosts";
import { Pagination, Spin } from "antd";
import { Sorts } from "../../components/shared-components/Sorts/Sorts";
import { SortKeysEnum } from "../../types/enums/SortsKeysEnum";
import { StoresEnum, useStore } from "../../hooks/use-store-hook/useStore";
import { AppStore } from "../../store/AppStore";
import { UserStore } from "../../store/UserStore";
import { ProfileTabEnum } from "../../types/enums/ProfileTab.enum";
import { CreateTradingPost } from "../../components/composit-components/trading-module/CreateTradingPost/CreateTradingPost";

const TradingPostsList = observer(() => {
  const { publicTradingPosts, isTradingPostsLoading, filters } =
    usePublicTradingPosts();

  if (isTradingPostsLoading) {
    return (
      <div
        style={{
          minHeight: "calc(60vh)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (publicTradingPosts.length === 0) {
    return (
      <TradingPostsPageStyledWrapper>
        <p
          style={{
            fontSize: 22,
            fontWeight: 600,
            textAlign: "center",
            marginTop: 20,
          }}
        >
          Постів не знайдено
        </p>
      </TradingPostsPageStyledWrapper>
    );
  }

  return (
    <TradingPostsPageStyledWrapper>
      {publicTradingPosts.map((post: any) => {
        return <PublicTradingPost post={post} filters={filters} />;
      })}
    </TradingPostsPageStyledWrapper>
  );
});

export const TradingPostsPage = observer((): ReactElement => {
  const {
    sorts,
    page,
    perPage,
    setPage,
    postsAmount,
    filters,
    setFilters,
    handleChangeSorts,
    clearPostSorts,
    checkIsSortsApplied,
    onClearTradingFilters,
  } = usePublicTradingPosts(false);

  const handleChangeSort = (key: SortKeysEnum) => {
    return (value: string) => {
      handleChangeSorts({
        [key]: value,
      });
    };
  };

  const handleClearSorts = () => {
    if (!checkIsSortsApplied()) {
      return;
    }

    clearPostSorts();
  };

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { setProfileTab } = useStore(StoresEnum.USER_STORE) as UserStore;

  useEffect(() => {
    setProfileTab(ProfileTabEnum.TRADING);

    return () => {
      onClearTradingFilters();
    };
  }, []);

  const onChangePage = (page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  if (isMobile()) {
    return (
      <Page>
        <AdvertisementBanner />

        <ListTitle title="Оголошення на купівлю" />

        <Sorts
          filters={filters}
          sorts={sorts}
          handleChangeSort={handleChangeSort}
          handleClearSorts={handleClearSorts}
          handleChangeFilters={setFilters}
          checkIsSortsApplied={checkIsSortsApplied}
        />

        <TradingFilters />

        <TradingPostsList />

        <div
          style={{
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            total={postsAmount}
            pageSize={perPage}
            current={page}
            responsive
            showSizeChanger={false}
            onChange={onChangePage}
            hideOnSinglePage
          />
        </div>

        <CreateTradingPost />
      </Page>
    );
  }

  return (
    <Page>
      <AdvertisementBanner />

      <ListTitle title="Оголошення на купівлю" />

      <Sorts
        filters={filters}
        sorts={sorts}
        handleChangeSort={handleChangeSort}
        handleClearSorts={handleClearSorts}
        handleChangeFilters={setFilters}
        checkIsSortsApplied={checkIsSortsApplied}
      />

      <ListLayout SideBar={<TradingFilters />} List={<TradingPostsList />} />

      <div
        style={{
          margin: "20px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          total={postsAmount}
          pageSize={perPage}
          current={page}
          responsive
          showSizeChanger={false}
          onChange={onChangePage}
          hideOnSinglePage
        />
      </div>

      <CreateTradingPost />
    </Page>
  );
});
