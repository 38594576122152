import React, { ReactElement, useEffect, useState } from "react";

import { DescriptionTextArea } from "./DescriptionTextArea/DescriptionTextArea";

import { updateDescriptionInformation } from "./DescriptionInformation.utils";
import { countriesLanguagesMap } from "../../../../../../../constants/countries.constants";

import { LanguagesEnum } from "../../../../../../../types/enums/Languages.enum";

import {
  DescriptionInformationStyledWrapper,
  DescriptionsListStyledWrapper,
} from "./DescriptionInformation.styled";
import { useAddPostStore } from "../../../../../../../hooks/use-add-post-store/useAddPostStore";
import { observer } from "mobx-react-lite";

const languages: LanguagesEnum[] = [
  LanguagesEnum.EN,
  LanguagesEnum.UK,
  LanguagesEnum.PL,
];

export const DescriptionInformation = observer((): ReactElement => {
  const { postModalData, onChangeInput, onChangeDescriptions } =
    useAddPostStore();

  const { country, descriptions } = postModalData;

  const currentLanguage = countriesLanguagesMap[country] as LanguagesEnum;

  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum[]>([]);
  const [availableLanguages, setAvailableLanguages] =
    useState<LanguagesEnum[]>(languages);

  useEffect(() => {
    const updatedAvailableLanguages = languages.filter(
      (language) => language !== currentLanguage
    );

    setSelectedLanguage([]);
    setAvailableLanguages(updatedAvailableLanguages);
  }, [currentLanguage]);

  useEffect(() => {
    const updatedDescriptions = updateDescriptionInformation(
      currentLanguage,
      selectedLanguage,
      descriptions
    );

    onChangeDescriptions(updatedDescriptions);
  }, [selectedLanguage]);

  return (
    <DescriptionInformationStyledWrapper>
      <DescriptionsListStyledWrapper>
        <div>
          <DescriptionTextArea
            language={currentLanguage}
            value={descriptions?.[currentLanguage]}
            onChange={onChangeInput(`description/${currentLanguage}`)}
            disabled={!Boolean(country)}
          />
        </div>

        {availableLanguages.map((language: LanguagesEnum) => (
          <DescriptionTextArea
            value={descriptions?.[language]}
            language={language}
            onChange={onChangeInput(`description/${language}`)}
          />
        ))}
      </DescriptionsListStyledWrapper>
    </DescriptionInformationStyledWrapper>
  );
});
