import { ReactElement } from "react";
import { PostInterface } from "../../../../types/interfaces/Post.interface";
import { Post } from "../Post/Post";

export const renderPostElements = (posts: PostInterface[]): ReactElement[] => {
  return posts.map((post: PostInterface) => {
    const { id } = post;

    return <Post key={id} post={post} />;
  });
};
