import React, { ReactElement } from "react";

import { CardStyledWrapper } from "./Card.styled";

interface CardProps {
  children?: ReactElement;
  style?: any;
}

export const Card = (props: CardProps): ReactElement => {
  const { children, style = {} } = props;

  return <CardStyledWrapper style={style}>{children}</CardStyledWrapper>;
};
