import { ReactElement } from "react";
import { CgAddR } from "react-icons/cg";
import { MdAutorenew } from "react-icons/md";
import { AiFillFilter } from "react-icons/ai";
import { SlLogin } from "react-icons/sl";
import { BiSupport } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";

import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { PostsStore } from "../../../../../store/PostsStore";
import { AppStore } from "../../../../../store/AppStore";
import { UserStore } from "../../../../../store/UserStore";
import { useTranslation } from "react-i18next";
import { localStorageUtils } from "../../../../../utils/browser-utils/LocalStorage";
import { colors } from "../../../../../constants/styles/colors.constants";
import { translationMap } from "../../../../../i18n/translationMap";

import {
  MobileMenuActionButtonWrapper,
  MobileMenuActionsBarFixedStyledWrapper,
  MobileMenuActionsBarStyledWrapper,
  MobileMenuOverlayStyledWrapper,
} from "./MobileMenuActionBar.styled";
import { observer } from "mobx-react-lite";
import { MobileTour, MobileTourPopupStyles } from "../../MobileTour/MobileTour";
import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import { LanguagesEnum } from "../../../../../types/enums/Languages.enum";
import { gtagReportConversion } from "../../../../../analitics/GA/gtagReportConversion";
import { ProfileTabEnum } from "../../../../../types/enums/ProfileTab.enum";
import { useTradingStore } from "../../../../../hooks/use-trading-store-hook/useTradingStore";
import { usePublicTradingPosts } from "../../../../../hooks/use-public-trading-posts-hook/usePublicTradingPosts";

const MobileMenuButton = ({ onClick, Icon, title }: any) => {
  return (
    <MobileMenuActionButtonWrapper onClick={onClick}>
      {Icon}
      <p>{title}</p>
    </MobileMenuActionButtonWrapper>
  );
};

const tourStepSignInTitle = {
  [LanguagesEnum.UK]: "Як зареєструватися?",
  [LanguagesEnum.PL]: "Jak się zarejestrować?",
  [LanguagesEnum.EN]: "How to register?",
};
const tourStepSignIn: any = {
  [LanguagesEnum.UK]: [
    "Для початку, вам потрібно зареєструватися на сайті,",
    "після реєстрації вам дадуть 2 безкоштовні дні для огляду контактів.",
    "Зареєструватися можете у правому нижньому віконці",
  ],
  [LanguagesEnum.PL]: [
    "Na początek musisz zarejestrować się na stronie,",
    "po rejestracji otrzymasz 2 bezpłatnych dni na przeglądanie kontaktów.",
    "Możesz zarejestrować się w prawym dolnym oknie",
  ],
  [LanguagesEnum.EN]: [
    "To begin with, you need to register on the site,",
    "after registration you will be given 2 free days to view contacts.",
    "You can register in the lower right window",
  ],
};

const tourStepPostTitle = {
  [LanguagesEnum.UK]: "Як додати оголошення?",
  [LanguagesEnum.PL]: "Jak dodać ogłoszenie?",
  [LanguagesEnum.EN]: "How to add a post?",
};
const tourStepPost: any = {
  [LanguagesEnum.UK]: [
    "Щоб додати оголошення,",
    'вам потрібно нажати на кнопку "Продати" або "Купити"  у низу в центрі єкрану і заповнити інформацію',
  ],
  [LanguagesEnum.PL]: [
    "Aby dodać ogłoszenie,",
    'należy kliknąć przycisk "Ogłoszenie" na dole na środku ekranu i uzupełnić informacje.',
  ],
  [LanguagesEnum.EN]: [
    "To add an post",
    "you need to click on the 'Post' button at the bottom in the center of the screen, and fill in the information",
  ],
};

const tourStepHowToFilterTitle = {
  [LanguagesEnum.UK]: "Як фільтрувати?",
  [LanguagesEnum.PL]: "Jak filtrować?",
  [LanguagesEnum.EN]: "How to filter?",
};
const tourStepHowToFilter: any = {
  [LanguagesEnum.UK]: [
    "Для Фільтрації є окреме меню,",
    "де ви может вибрати зручні вам налаштування фільтрації",
  ],
  [LanguagesEnum.PL]: [
    "W przypadku filtrowania dostępne jest osobne menu,",
    "w którym można wybrać wygodne dla siebie ustawienia filtra.",
  ],
  [LanguagesEnum.EN]: [
    "For Filtering, there is a separate menu",
    "where you can choose the filter settings that are convenient for you.",
  ],
};

const tourStepSupportTitle = {
  [LanguagesEnum.UK]: "Залишились питання?",
  [LanguagesEnum.PL]: "Masz jeszcze jakieś pytania?",
  [LanguagesEnum.EN]: "Do you have any more questions?",
};
const tourStepSupport: any = {
  [LanguagesEnum.UK]: [
    "Якщо у вас виникнуть питання,",
    "ви можете їх задати у зручному для вас мессенджері.",
  ],
  [LanguagesEnum.PL]: [
    "Jeśli masz jakieś pytania,",
    "możesz je zadać w wygodnym dla Ciebie komunikatorze.",
  ],
  [LanguagesEnum.EN]: [
    "If you have any questions,",
    "you can ask them in the messenger that is convenient for you.",
  ],
};

const tourStepWelcome: any = {
  [LanguagesEnum.UK]: [
    "Ви зайшли на сайт на якому кожного дня аграрії та покупці виставляють свої оголошення.",
  ],
  [LanguagesEnum.PL]: [
    "Weszłeś na stronę, na której każdego dnia rolnicy z różnych krajów publikują swoje ogłoszenia.",
    "Publikacja ogłoszeń jest bezpłatna.",
  ],
  [LanguagesEnum.EN]: [
    "You've entered a site where every day, agrarians from various countries post their announcements.",
    "Posting announcements is free of charge.",
  ],
};

const tourStepSupportTop = {
  [LanguagesEnum.UK]: -227,
  [LanguagesEnum.PL]: -227,
  [LanguagesEnum.EN]: -242,
};
const tourSignInAngle = {
  [LanguagesEnum.UK]: 0,
  [LanguagesEnum.PL]: 10,
  [LanguagesEnum.EN]: 0,
};
const tourPostAngle = {
  [LanguagesEnum.UK]: 22,
  [LanguagesEnum.PL]: 17,
  [LanguagesEnum.EN]: -3,
};
const tourFiltersAngle = {
  [LanguagesEnum.UK]: 8,
  [LanguagesEnum.PL]: -2,
  [LanguagesEnum.EN]: 2,
};

const gotItButton = {
  [LanguagesEnum.UK]: "Зрозуміло",
  [LanguagesEnum.PL]: "Rozumiem",
  [LanguagesEnum.EN]: "Understood",
};

const welcomeTitle = {
  [LanguagesEnum.UK]: "Ласкаво просимо до",
  [LanguagesEnum.PL]: "Serdecznie zapraszamy do",
  [LanguagesEnum.EN]: "Welcome to",
};

interface MobileMenuActionBarProps {
  isMenuOpen: boolean;
  onCheckout: () => void;
  onOpenCloseMenu: () => void;
  onOpenCloseSignIn: () => void;
  onToggleAccountModal: () => void;
}
export const MobileMenuActionBar = observer(
  (props: MobileMenuActionBarProps): ReactElement => {
    const { isMenuOpen, onCheckout, onToggleAccountModal, onOpenCloseSignIn } =
      props;

    const { accountTab } = useStore(StoresEnum.USER_STORE) as UserStore;

    const { setIsFiltersOpen } = useStore(StoresEnum.POSTS_STORE) as PostsStore;
    const {
      setIsAddPostModalOpen,
      toggleSupport,
      setCurrentTourStep,
      setIsTourOpen,
      currentTourStep,
      isTourOpen,
      language,
    } = useStore(StoresEnum.APP_STORE) as AppStore;

    const { toggleCreatePostModal, tradingAccount, toggleModalForBalance } =
      useTradingStore();
    const { user } = useStore(StoresEnum.USER_STORE) as UserStore;
    const { postBalance } = tradingAccount || {};
    const isPostBalanceEmpty = postBalance === 0;
    const isTradingAccountExists = Boolean(tradingAccount?.id);

    const { setIsFiltersOpen: setIsTradingFiltersOpen } =
      usePublicTradingPosts(false);

    const { t } = useTranslation();

    const { phone } = user || {};
    const isUserLoggedIn = Boolean(phone) && localStorageUtils.getToken();

    const handleSignInTourClick = () => {
      setCurrentTourStep(2);
    };

    const handlePostClick = () => {
      setCurrentTourStep(3);
    };

    const handleFilterClick = () => {
      setCurrentTourStep(4);
    };

    const handleSupportClick = () => {
      setCurrentTourStep(-1);

      setIsTourOpen(false);
      localStorageUtils.setTour();
    };

    return (
      <MobileMenuActionsBarFixedStyledWrapper>
        <MobileMenuOverlayStyledWrapper isOpen={isMenuOpen} />

        <MobileMenuActionsBarStyledWrapper>
          <MobileTour
            isVisible={currentTourStep === 3}
            contentLeft={-10}
            contentWidth={230}
            contentTop={language === LanguagesEnum.PL ? -242 : -225}
            angleRight={tourFiltersAngle[language]}
            content={
              <>
                <p style={{ fontWeight: 600 }}>
                  {tourStepHowToFilterTitle[language]}
                </p>

                {tourStepHowToFilter[language].map((step: string) => (
                  <p>{step}</p>
                ))}

                <CustomButton
                  type="primary"
                  style={{ width: "100%", marginTop: 20 }}
                  size="middle"
                  onClick={handleFilterClick}
                >
                  {gotItButton[language]}
                </CustomButton>
              </>
            }
          >
            <MobileMenuButton
              title={t(translationMap.menu.filters)}
              Icon={<AiFillFilter size={26} />}
              onClick={() => {
                if (accountTab === ProfileTabEnum.ACCOUNT) {
                  setIsFiltersOpen(true);
                }

                if (accountTab === ProfileTabEnum.TRADING) {
                  setIsTradingFiltersOpen(true);
                }
              }}
            />
          </MobileTour>

          <MobileTour
            isVisible={currentTourStep === 4}
            contentLeft={0}
            contentWidth={220}
            contentTop={tourStepSupportTop[language]}
            angleRight={10}
            content={
              <>
                <p style={{ fontWeight: 600 }}>
                  {tourStepSupportTitle[language]}
                </p>

                {tourStepSupport[language].map((step: string) => (
                  <p>{step}</p>
                ))}

                <CustomButton
                  type="primary"
                  style={{ width: "100%", marginTop: 20 }}
                  size="middle"
                  onClick={handleSupportClick}
                >
                  {gotItButton[language]}
                </CustomButton>
              </>
            }
          >
            <MobileMenuButton
              title={t(translationMap.menu.support)}
              Icon={<BiSupport size={26} />}
              onClick={toggleSupport}
            />
          </MobileTour>

          <MobileTour
            isVisible={currentTourStep === 2}
            contentLeft={-70}
            contentWidth={230}
            contentTop={language === LanguagesEnum.EN ? -242 : -245}
            angleRight={tourPostAngle[language]}
            content={
              <>
                <p style={{ fontWeight: 600 }}>{tourStepPostTitle[language]}</p>

                {tourStepPost[language].map((step: string) => (
                  <p>{step}</p>
                ))}

                <CustomButton
                  type="primary"
                  style={{ width: "100%", marginTop: 20 }}
                  size="middle"
                  onClick={handlePostClick}
                >
                  {gotItButton[language]}
                </CustomButton>
              </>
            }
          >
            <MobileMenuButton
              title={t(
                accountTab === ProfileTabEnum.ACCOUNT
                  ? translationMap.menu.sell
                  : translationMap.menu.buy
              )}
              Icon={<CgAddR size={26} color={colors.primary} />}
              onClick={() => {
                if (accountTab === ProfileTabEnum.ACCOUNT) {
                  setIsAddPostModalOpen(true);
                }

                if (accountTab === ProfileTabEnum.TRADING) {
                  if (isUserLoggedIn) {
                    if (isPostBalanceEmpty && isTradingAccountExists) {
                      toggleModalForBalance();
                    } else {
                      toggleCreatePostModal();
                    }
                  } else {
                    onOpenCloseSignIn();
                  }
                }
              }}
            />
          </MobileTour>

          <MobileMenuButton
            title={t(translationMap.menu.plans)}
            Icon={<MdAutorenew size={26} />}
            onClick={
              accountTab === ProfileTabEnum.ACCOUNT
                ? onCheckout
                : toggleModalForBalance
            }
          />

          {!isUserLoggedIn ? (
            <MobileTour
              isVisible={currentTourStep === 1}
              contentLeft={-190}
              contentTop={-267}
              contentWidth={250}
              angleRight={tourSignInAngle[language]}
              content={
                <>
                  <p style={{ fontWeight: 600 }}>
                    {tourStepSignInTitle[language]}
                  </p>

                  {tourStepSignIn[language].map((step: string) => (
                    <p>{step}</p>
                  ))}

                  <CustomButton
                    type="primary"
                    style={{ width: "100%", marginTop: 20 }}
                    size="middle"
                    onClick={handleSignInTourClick}
                  >
                    {gotItButton[language]}
                  </CustomButton>
                </>
              }
            >
              <MobileMenuButton
                title={t(translationMap.menu.signIn)}
                Icon={<SlLogin size={30} />}
                onClick={() => {
                  gtagReportConversion(
                    undefined,
                    "AW-11135401568/Fel1CPe45qMYEOD84r0p"
                  );
                  onOpenCloseSignIn();
                }}
              />
            </MobileTour>
          ) : (
            <MobileMenuButton
              title={t(translationMap.menu.account)}
              Icon={<BsFillPersonFill size={30} />}
              onClick={onToggleAccountModal}
            />
          )}
        </MobileMenuActionsBarStyledWrapper>

        {currentTourStep === 0 && isTourOpen && (
          <MobileTourPopupStyles style={{ top: -500, left: "5%", right: "5%" }}>
            <img
              src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/hamster"
              alt="hamster"
              width={150}
              style={{
                display: "block",
                margin: "0 auto",
              }}
            />
            <p style={{ fontWeight: 600, fontSize: 20, textAlign: "center" }}>
              {welcomeTitle[language]}
            </p>
            <p style={{ fontWeight: 600, fontSize: 20, textAlign: "center" }}>
              agrobridges.com
            </p>

            {tourStepWelcome[language].map((step: string) => (
              <p style={{ textAlign: "center" }}>{step}</p>
            ))}

            <CustomButton
              type="primary"
              style={{ width: "100%", marginTop: 20 }}
              size="middle"
              onClick={() => setCurrentTourStep(1)}
            >
              {gotItButton[language]}
            </CustomButton>
          </MobileTourPopupStyles>
        )}
      </MobileMenuActionsBarFixedStyledWrapper>
    );
  }
);
