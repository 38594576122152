import { FilterKeysEnum } from "../types/enums/FilterKeys.enum";
import { SortKeysEnum } from "../types/enums/SortsKeysEnum";
import { ContactsInterface } from "../types/interfaces/Contacts.interface";
import { PostInterface } from "../types/interfaces/Post.interface";
import { postApi } from "./../api/requests/posts.api";
import { makeAutoObservable } from "mobx";

export const defaultSorts = {
  [SortKeysEnum.DATE]: "",
  [SortKeysEnum.PRICE]: "",
  [SortKeysEnum.AMOUNT]: "",
};

export const defaultFilters = {
  [FilterKeysEnum.CEREAL_CROPS]: [],
  [FilterKeysEnum.COUNTRY]: "",
  [FilterKeysEnum.STATES]: [],
  [FilterKeysEnum.PAYMENT_TYPES]: "",
  [FilterKeysEnum.CURRENCY]: "",
};

type FilterValueType = string | string[] | number[];

export class PostsStore {
  public posts: PostInterface[] = [];
  public isPostsLoading = false;
  public postsAmount = 0;

  public postContacts: Record<string, ContactsInterface> = {};

  public filters: Record<FilterKeysEnum, FilterValueType> = {
    ...defaultFilters,
  };

  public sorts: Record<SortKeysEnum, string> = defaultSorts;

  public currentAddPost = null;
  public isCurrentAddPostCreating = false;

  public currentPage: number = 1;

  public isFiltersOpen = false;

  public unstable_filters: any = {};
  public unstable_sorts: any = {};

  constructor() {
    makeAutoObservable(this);

    this.getAllPosts = this.getAllPosts.bind(this);

    this.setPostContacts = this.setPostContacts.bind(this);
    this.setPersistedPostContacts = this.setPersistedPostContacts.bind(this);
    this.setIsPostsLoading = this.setIsPostsLoading.bind(this);
    this.setPostsAmount = this.setPostsAmount.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.setIsFiltersOpen = this.setIsFiltersOpen.bind(this);

    this.clearPostFilters = this.clearPostFilters.bind(this);
    this.clearPostSorts = this.clearPostSorts.bind(this);
    this.clearPostContacts = this.clearPostContacts.bind(this);

    this.resetCurrentPage = this.resetCurrentPage.bind(this);

    this.unstable_setFilters = this.unstable_setFilters.bind(this);
    this.unstable_setSorts = this.unstable_setSorts.bind(this);
  }

  unstable_setFilters(filters: any) {
    this.unstable_filters = filters;
  }

  unstable_setSorts(sorts: any) {
    this.unstable_sorts = sorts;
  }

  async setIsPostsLoading(isLoading: boolean) {
    this.isPostsLoading = isLoading;
  }

  async getAllPosts() {
    this.setIsPostsLoading(true);

    const { posts, amount } = await postApi.getAllPosts({
      page: this.currentPage || 1,
      filters: this.unstable_filters,
      sorts: this.unstable_sorts,
    });

    this.posts = posts;
    this.postsAmount = amount;

    this.setIsPostsLoading(false);
  }

  async setPostContacts(id: string, contacts: ContactsInterface) {
    const postContacts = {
      ...this.postContacts,
      [id]: contacts,
    };

    this.postContacts = postContacts;
  }

  setPersistedPostContacts(contacts: Record<string, ContactsInterface>) {
    this.postContacts = contacts;
  }

  setPostsAmount(postsAmount: number) {
    this.postsAmount = postsAmount;
  }

  setFilters(filters: any) {
    this.filters = filters;
  }

  setIsFiltersOpen(isFiltersOpen: boolean) {
    this.isFiltersOpen = isFiltersOpen;
  }

  clearPostFilters() {
    this.setFilters({ ...defaultFilters });
    this.setCurrentPage(1);
    this.isFiltersOpen = false;
  }

  clearPostSorts() {
    this.sorts = { ...defaultSorts };
    this.setCurrentPage(1);
  }

  clearPostContacts() {
    this.postContacts = {};
  }

  setCurrentPage(page = 1): void {
    this.currentPage = page;
  }

  resetCurrentPage(): void {
    this.currentPage = 1;
  }
}
