import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const LanguageSelectStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
`;

export const LanguageDropdownStyledWrapper = styled.div`
  position: absolute;
  width: 190px;
  bottom: -150px;
  left: -140px;
  background: ${colors.white};
  border-radius: 5px;
  padding: 0 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-bottom: 1px solid ${colors.orange};
`;

export const LanguageValueStyledWrapper = styled.div<{ isSelected: boolean }>`
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    cursor: pointer;
    color: ${colors.orange};
  }

  color: ${({ isSelected }) => (isSelected ? colors.orange : colors.black)};
`;
