import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const TradingPostActionsStyledWrapper = styled.div`
  padding: 15px;
  color: ${colors.white};
  border-left: 1px solid ${colors.primary};
  border-radius: 0 5px 5px 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
