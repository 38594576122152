import React, { ReactElement } from "react";

import { StatesTagsStyledWrapper } from "./StatesTags.styled";
import { colors } from "../../../constants/styles/colors.constants";
import { LanguagesEnum } from "../../../types/enums/Languages.enum";
import { CountriesEnum } from "../../../types/enums/Countries.enum";
import {
  getStateNameByCode,
  getTranslatedStates,
} from "../../../utils/common-utils/getTranslatedStates";
import { CountryInterface } from "../../../types/interfaces/CountryInterface";
import { toJS } from "mobx";

interface StatesTagsProps {
  states: string[];
  countriesStates: { [key: string]: string[] };
  language: LanguagesEnum;
  country: CountryInterface;
  isFullList?: boolean;
  isShowMarker?: boolean;

  isFullCountry?: boolean;
}

export const VISIBLE_STATES_AMOUNT = 2;

export const StatesTags = (props: StatesTagsProps): ReactElement => {
  const {
    states,
    countriesStates,
    language,
    isFullList,
    isShowMarker,
    country,
    isFullCountry = false,
  } = props;

  const translatedStates = getTranslatedStates({
    states,
    country: country?.code,
    countriesStates,
  });

  const tagStyles = {
    fontSize: 12,
    marginLeft: 5,
    background: colors.white,
    color: colors.black,
    padding: "2px 4px",
    borderRadius: 4,
    marginTop: 2,
  };

  const visibleStates = translatedStates.slice(0, VISIBLE_STATES_AMOUNT);
  const hiddenStates = translatedStates.slice(VISIBLE_STATES_AMOUNT);

  const statesToShow = isFullList ? translatedStates : visibleStates;

  if (isFullCountry) {
    return (
      <StatesTagsStyledWrapper>
        <p
          style={{
            ...tagStyles,
            border: `1px solid ${colors.orange}`,
          }}
        >
          Вся Україна
        </p>
      </StatesTagsStyledWrapper>
    );
  }

  return (
    <StatesTagsStyledWrapper>
      {statesToShow.map((state: any) => {
        return (
          <p
            key={state}
            style={{
              ...tagStyles,
              border: `1px solid ${
                state?.type === "city" ? colors.link : colors.primary
              }`,
            }}
          >
            {getStateNameByCode({
              isoCode: state.isoCode,
              language,
              country: country?.code,
              countriesStates,
            }).replace("область", "")}
          </p>
        );
      })}

      {Boolean(hiddenStates.length) && !isFullList && isShowMarker && (
        <p
          style={{
            fontSize: 10,
            marginLeft: 5,
            background: colors.primary,
            color: colors.white,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 20,
            height: 20,
            borderRadius: "50%",
          }}
        >
          +{hiddenStates.length}
        </p>
      )}
    </StatesTagsStyledWrapper>
  );
};
