import React, { ReactElement } from "react";

import {
  ActiveAmountOfPosts,
  TradingAccountStatisticsWidgetStyledWrapper,
} from "./TradingAccountStatisticsWidget.styled";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { AppStore } from "../../../../store/AppStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";

export const TradingAccountStatisticsWidget = observer((): ReactElement => {
  const {
    tradingAccount,
    tradingActivePostsAmount,
    toggleModalForBalance,
    toggleCreatePostModal,
  } = useTradingStore();

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  if (!tradingAccount?.id) {
    return (
      <TradingAccountStatisticsWidgetStyledWrapper $isMobile={isMobile()}>
        <ActiveAmountOfPosts $isMobile={isMobile()}>
          <p className="statistic-title">Активні</p>
          <p className="statistic-number">-</p>
        </ActiveAmountOfPosts>

        <ActiveAmountOfPosts $isMobile={isMobile()}>
          <p className="statistic-title">Баланс</p>
          <p className="statistic-number">-</p>
        </ActiveAmountOfPosts>
      </TradingAccountStatisticsWidgetStyledWrapper>
    );
  }

  const { postBalance } = tradingAccount;

  const handleAddPostClick = () => {
    if (postBalance < 1 || !postBalance) {
      return toggleModalForBalance();
    }

    toggleCreatePostModal();
  };

  return (
    <TradingAccountStatisticsWidgetStyledWrapper $isMobile={isMobile()}>
      <ActiveAmountOfPosts $isMobile={isMobile()}>
        <p className="statistic-title">Активні</p>
        <p className="statistic-number">{tradingActivePostsAmount}</p>

        <CustomButton
          type="primary"
          onClick={handleAddPostClick}
          className="statistic-button"
        >
          Додати оголошення
        </CustomButton>
      </ActiveAmountOfPosts>

      <ActiveAmountOfPosts $isMobile={isMobile()}>
        <p className="statistic-title">Баланс</p>
        <p className="statistic-number">{tradingAccount.postBalance}</p>

        <CustomButton
          type="primary"
          onClick={toggleModalForBalance}
          className="statistic-button"
        >
          Поповнити
        </CustomButton>
      </ActiveAmountOfPosts>
    </TradingAccountStatisticsWidgetStyledWrapper>
  );
});
