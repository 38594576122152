import { useState } from "react";

import { useAddPostStore } from "../../../../../hooks/use-add-post-store/useAddPostStore";
import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../store/AppStore";
import { getCerealCropsOptions } from "../../../../../utils/options-utils/getCeralCropsOptions";
import {
  getCountriesOptions,
  getCountryStatesOptions,
} from "../../../../../utils/options-utils/getCountriesOptions";
import { LocationAvailableForEnum } from "./add-post-form-components/DeliveryInformation/DeliveryInformation";
import { PaymentTypesEnum } from "../../../../../types/enums/PaymentTypes.enum";
import { countriesCurrenciesMap } from "../../../../../constants/countries.constants";
import { CurrencyEnum } from "../../../../../types/enums/Currency.enum";

export const useCerealCropForm = () => {
  const { postModalData, onChangeSelect, onChangeInput } = useAddPostStore();
  const { cerealCrops, language } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { cerealCrop, harvestYear } = postModalData;
  const cerealCropOptions = getCerealCropsOptions(cerealCrops, language);

  const changeCerealCrop = onChangeSelect("cerealCrop");
  const changeHarvestYear = onChangeInput("harvestYear", "NUMBER");

  const handleChangeCerealCrop = (value: any): void => {
    changeCerealCrop(value);
  };

  const handleChangeHarvestYear = (e: any): void => {
    if (e.target.value.length > 4) return;

    changeHarvestYear(e);
  };

  return {
    state: {
      cerealCrop,
      harvestYear,
      cerealCropOptions,
    },
    handlers: {
      handleChangeCerealCrop,
      handleChangeHarvestYear,
    },
  };
};

export const useAmountForm = () => {
  const { postModalData, onChangeInput } = useAddPostStore();

  const [isRange, setIsRange] = useState(false);

  const { amount, amountFrom, amountTo } = postModalData;

  const changeAmount = onChangeInput("amount", "NUMBER");
  const changeRangeFrom = onChangeInput("amountFrom", "NUMBER");
  const changeRangeTo = onChangeInput("amountTo", "NUMBER");

  const handleChangeAmount = (value: any): void => {
    changeAmount(value);
  };

  const handleChangeAmountFrom = (value: any): void => {
    changeRangeFrom(value);
  };

  const handleChangeAmountTo = (value: any): void => {
    changeRangeTo(value);
  };

  const handleChangeIsRange = (): void => {
    changeAmount({ target: { value: 0 } });
    changeRangeTo({ target: { value: 0 } });
    changeRangeFrom({ target: { value: 0 } });

    setIsRange(!isRange);
  };

  return {
    state: {
      amount,
      isRange,
      amountTo,
      amountFrom,
    },
    handlers: {
      handleChangeAmount,
      handleChangeIsRange,
      handleChangeAmountTo,
      handleChangeAmountFrom,
    },
  };
};

export const useLocationForm = () => {
  const { postModalData, onChangeSelect } = useAddPostStore();
  const { country, state } = postModalData;

  const changeCountry = onChangeSelect("country");
  const changeState = onChangeSelect("state");

  const handleChangeCountry = (value: any): void => {
    changeCountry(value);
  };

  const handleChangeState = (value: any): void => {
    changeState(value);
  };

  return {
    state: {
      state,
      country,
    },
    handlers: {
      handleChangeCountry,
      handleChangeState,
    },
  };
};

export const useLocationAvailableForForm = () => {
  const { postModalData, onChangeSelect } = useAddPostStore();
  const { locationAvailableFor } = postModalData || {};

  const changeLocationAvailableFor = onChangeSelect("locationAvailableFor");

  const handleAddLocationAvailableFor = (key: LocationAvailableForEnum) => {
    return () => {
      changeLocationAvailableFor({
        ...(locationAvailableFor || {}),
        [key]: !locationAvailableFor?.[key],
      });
    };
  };

  return {
    state: {
      locationAvailableFor,
    },
    handlers: {
      handleAddLocationAvailableFor,
    },
  };
};

const usePriceForm = () => {
  const { postModalData, onChangeSelect, onChangeInput } = useAddPostStore();
  const { country, prices, paymentType } = postModalData || {};

  const changePaymentType = onChangeSelect("paymentType");

  const currentCurrency = countriesCurrenciesMap[country] as CurrencyEnum;

  const handleChangePaymentType = (value: PaymentTypesEnum) => {
    changePaymentType(value);
  };

  const handleChangePrice = (currency: CurrencyEnum) =>
    onChangeInput(`price/${currency}`, "NUMBER");

  return {
    state: {
      prices,
      paymentType,
      currentCurrency,
    },
    handlers: {
      handleChangePaymentType,
      handleChangePrice,
    },
  };
};

const useContactsForm = () => {
  const { postModalData, onChangeSelect } = useAddPostStore();
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);

  const { contacts } = postModalData || {};
  const { rootPhone, messengers } = contacts || {};

  const changeContacts = onChangeSelect("contacts");

  const handleChangePhone = () => {
    return (value: string) => {
      changeContacts({
        ...contacts,
        rootPhone: value,
      });
    };
  };

  const handleMessengerCheckChange = (key: string) => {
    return () => {
      changeContacts({
        ...contacts,
        messengers: {
          ...messengers,
          [key]: {
            phone: rootPhone,
            isRootPhoneSelected: !messengers?.[key]?.isRootPhoneSelected,
          },
        },
      });
    };
  };

  const handleSetIsPhoneValid = (value: boolean) => {
    setIsPhoneValid(value);
  };

  const handleChangeMessengerPhone = (key: string) => {
    const changeContacts = onChangeSelect("contacts");

    return (value: string) => {
      changeContacts({
        ...contacts,
        messengers: {
          ...messengers,
          [key]: {
            ...(messengers?.[key] || {}),
            phone: value,
          },
        },
      });
    };
  };

  return {
    state: {
      rootPhone,
      isPhoneValid,
      messengers,
    },
    handlers: {
      handleChangePhone,
      handleSetIsPhoneValid,
      handleMessengerCheckChange,
      handleChangeMessengerPhone,
    },
  };
};

const useConfirmationForm = () => {
  const { phone, code, setCode, setPhone } = useAddPostStore();

  const handleChangeConfirmationCode = (value: string) => {
    setCode(value);
  };

  const handleChangeConfirmationPhone = (value: string) => {
    setPhone(value);
  };

  return {
    state: {
      phone,
      code,
    },
    handlers: {
      handleChangeConfirmationCode,
      handleChangeConfirmationPhone,
    },
  };
};

export const useAddPostForm = () => {
  const { errorFields } = useAddPostStore();

  const { state: amountState, handlers: amountHandlers } = useAmountForm();
  const { state: cerealCropState, handlers: cerealCropHandlers } =
    useCerealCropForm();
  const { state: locationState, handlers: locationHandlers } =
    useLocationForm();
  const {
    state: locationAvailableForState,
    handlers: locationAvailableForHandlers,
  } = useLocationAvailableForForm();
  const { state: priceState, handlers: priceHandlers } = usePriceForm();
  const { state: contactsState, handlers: contactsHandlers } =
    useContactsForm();
  const { state: confirmationState, handlers: confirmationHandlers } =
    useConfirmationForm();

  return {
    state: {
      ...amountState,
      ...cerealCropState,
      ...locationState,
      ...locationAvailableForState,
      ...priceState,
      ...contactsState,
      ...confirmationState,
    },
    handlers: {
      ...amountHandlers,
      ...cerealCropHandlers,
      ...locationHandlers,
      ...locationAvailableForHandlers,
      ...priceHandlers,
      ...contactsHandlers,
      ...confirmationHandlers,
    },
    errorFields,
  };
};
