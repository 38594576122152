import { useEffect } from "react";
import {
  PostsStore,
  defaultFilters,
  defaultSorts,
} from "../../store/PostsStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { toJS } from "mobx";
import lodash from "lodash";
import { useQuery } from "react-query";
import { SortKeysEnum } from "../../types/enums/SortsKeysEnum";
import { checkIsFiltersApplied } from "../../utils/common-utils/checkIsFiltersApplied";

const onlyValidFilters = (filters: any) =>
  Object.entries(filters).reduce((acc: any, [key, value]: any) => {
    if (typeof value === "string" && value.length > 0) {
      return { ...acc, [key]: value };
    }

    if (typeof value === "number" && value >= 0) {
      return { ...acc, [key]: value };
    }

    if (Array.isArray(value) && value.length > 0) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});

export const usePostStore = () => {
  const {
    posts,
    currentPage,

    unstable_sorts,
    unstable_filters,
    unstable_setSorts,
    unstable_setFilters,

    setCurrentPage,
    getAllPosts,
  } = useStore(StoresEnum.POSTS_STORE) as PostsStore;

  useQuery({
    queryKey: ["posts", unstable_sorts, unstable_filters, currentPage],
    queryFn: getAllPosts,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!unstable_filters?.currency) {
      unstable_setSorts({
        ...unstable_sorts,
        [SortKeysEnum.PRICE]: "",
      });
    }
  }, [unstable_filters]);

  const handleChangeFilters = (filters: any) => {
    setCurrentPage(1);
    unstable_setFilters(onlyValidFilters(filters));
  };

  const handleChangeSorts = (sorts: any) => {
    setCurrentPage(1);
    unstable_setSorts(sorts);
  };

  const clearPostSorts = () => {
    setCurrentPage(1);
    unstable_setSorts(defaultSorts);
  };

  const clearPostFilters = () => {
    setCurrentPage(1);
    unstable_setFilters(defaultFilters);
  };

  const isFiltersApplied = () => {
    return checkIsFiltersApplied(unstable_filters);
  };

  const checkIsSortsApplied = () => {
    return Object.values(unstable_sorts).some((value) => {
      if (typeof value === "string") {
        return value.length > 0;
      }

      return !!value;
    });
  };

  return {
    unstable_filters,
    unstable_sorts,

    clearPostSorts,
    clearPostFilters,
    handleChangeSorts,
    handleChangeFilters,
    checkIsFiltersApplied: isFiltersApplied,
    checkIsSortsApplied,
  };
};
