import { UkFlagIcon } from "../static/icons/UkFlagIcon";
import { ReactElement } from "react";
import { CountriesEnum } from "../types/enums/Countries.enum";
import { LanguagesEnum } from "../types/enums/Languages.enum";
import { UkrainianFlagIcon } from "../static/icons/UkrainianFlagIcon";
import { PolandFlagIcon } from "../static/icons/PolandFlagIcon";

export const languagesFullTranslatedNamesMap = {
  [LanguagesEnum.EN]: "English",
  [LanguagesEnum.PL]: "Polski",
  [LanguagesEnum.UK]: "Українська",
};

export const languagesFullEnglishNamesMap = {
  [LanguagesEnum.EN]: "english",
  [LanguagesEnum.PL]: "polish",
  [LanguagesEnum.UK]: "ukrainian",
};

export const languagesCodesToCountryCodesMap = {
  [LanguagesEnum.EN]: CountriesEnum.PL,
  [LanguagesEnum.UK]: CountriesEnum.UA,
};

export const languagesCodesToFlagIconMap: Record<string, ReactElement> = {
  [LanguagesEnum.UK]: <UkrainianFlagIcon />,
  [LanguagesEnum.PL]: <PolandFlagIcon />,
  [LanguagesEnum.EN]: <UkFlagIcon />,
};
