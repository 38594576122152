export const checkIsErrorExist = (
  errors: { message: string }[],
  error: string
) => {
  if (!errors) {
    return false;
  }

  return errors.some((err) => err.message === error);
};
