import styled, { css } from "styled-components";

type FlexAxisType =
  | "center"
  | "flex-end"
  | "flex-start"
  | "space-around"
  | "space-between"
  | "space-evenly"
  | "stretch"
  | "baseline"
  | "initial"
  | "inherit";

interface FlexCoordinatesInterface {
  justifyContent?: FlexAxisType;
  alignItems?: FlexAxisType;
}

const FlexCoordinates = css<FlexCoordinatesInterface>`
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
`;

export const FlexStyledWrapper = styled.div<FlexCoordinatesInterface>`
  display: flex;

  ${FlexCoordinates}
`;

export const FlexColumnStyledWrapper = styled.div<FlexCoordinatesInterface>`
  display: flex;
  flex-direction: column;

  ${FlexCoordinates}
`;
