import { TradingCompanyInterface } from "./../types/interfaces/TradingCompany.interface";
import { makeAutoObservable } from "mobx";

import { PublicTradingPostInterface } from "../types/interfaces/PublicTradingPost.interface";
import { TradingAccountWidgetStatesEnum } from "../types/enums/TradingAccountWidgetStates.enum";
import { TradingPlanInterface } from "../types/interfaces/TradingPlan.interface";
import { TradingTopPlanInterface } from "../types/interfaces/TradingTopPlan.interface";
import { tradingApi } from "../api/requests/trading.api";
import { SortKeysEnum } from "../types/enums/SortsKeysEnum";
import { localStorageUtils } from "../utils/browser-utils/LocalStorage";

export const defaultSorts = {
  [SortKeysEnum.DATE]: "",
  [SortKeysEnum.PRICE]: "",
  [SortKeysEnum.AMOUNT]: "",
};

export class TradingStore {
  public tradingAccountPosts: unknown[] = [];
  public tradingAccount: TradingCompanyInterface | null = null;
  public tradingActivePostsAmount = 0;

  public publicTradingPosts: PublicTradingPostInterface[] = [];
  public isTradingPostsLoading = false;

  public isModalForBalanceOpen = false;

  public tradingPostToTop: any = null;

  public tradingAccountState: TradingAccountWidgetStatesEnum =
    TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT;

  public isCreatePostModalOpen = false;

  public tradingPlans: TradingPlanInterface[] = [];
  public isTradingPlansLoading = false;
  public tradingTopPlans: TradingTopPlanInterface[] = [];
  public isTradingTopPlansLoading = false;

  public isTradingTopPlanOpen = false;

  public isEditPostModalOpen = false;
  public postToEdit: any = null;

  public filters = {
    country: "",
    states: [],
    cerealCrops: [],
    paymentTypes: [],
    currency: "",
  };

  public sorts = defaultSorts;

  public isFiltersOpen = false;

  public page = 1;
  public perPage = 20;

  public postsAmount = 0;

  public continuePlans: any = [];

  public isTradingContinuePlanOpen = false;

  public tradingPostToContinue: any = null;

  public showedTradingPostsByPages: Record<number | string, string[]> = {};

  public currentAccountPage = 1;
  public currentAccountPerPage = 20;
  public currentAccountPostsAmount = 0;

  public isTradingInfoModalOpen =
    localStorageUtils.getIsTradingTourDone() === "true" ? false : true;

  constructor() {
    makeAutoObservable(this);

    this.setTradingAccountPosts = this.setTradingAccountPosts.bind(this);
    this.setTradingAccount = this.setTradingAccount.bind(this);
    this.setPublicTradingPosts = this.setPublicTradingPosts.bind(this);
    this.setIsModalForBalanceOpen = this.setIsModalForBalanceOpen.bind(this);
    this.setTradingAccountState = this.setTradingAccountState.bind(this);
    this.setCreatedPostModalOpen = this.setCreatedPostModalOpen.bind(this);
    this.setTradingPlans = this.setTradingPlans.bind(this);
    this.setIsTradingPlansLoading = this.setIsTradingPlansLoading.bind(this);

    this.setTradingTopPlans = this.setTradingTopPlans.bind(this);
    this.setIsTradingTopPlansLoading =
      this.setIsTradingTopPlansLoading.bind(this);

    this.toggleTradingTopPlanModal = this.toggleTradingTopPlanModal.bind(this);

    this.setTradingPostToTop = this.setTradingPostToTop.bind(this);

    this.toggleEditPostModal = this.toggleEditPostModal.bind(this);
    this.setPostToEdit = this.setPostToEdit.bind(this);

    this.setFilters = this.setFilters.bind(this);

    this.setIsFiltersOpen = this.setIsFiltersOpen.bind(this);

    this.getAllTradingPosts = this.getAllTradingPosts.bind(this);
    this.setIsTradingPostsLoading = this.setIsTradingPostsLoading.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setPostsAmount = this.setPostsAmount.bind(this);

    this.setSorts = this.setSorts.bind(this);

    this.onClearTradingFilters = this.onClearTradingFilters.bind(this);

    this.setContinuePlans = this.setContinuePlans.bind(this);

    this.toggleTradingContinuePlanModal =
      this.toggleTradingContinuePlanModal.bind(this);
    this.setTradingPostToContinue = this.setTradingPostToContinue.bind(this);

    this.setShowedTradingPostsByPages =
      this.setShowedTradingPostsByPages.bind(this);

    this.setCurrentAccountPage = this.setCurrentAccountPage.bind(this);

    this.getTradingAccountPosts = this.getTradingAccountPosts.bind(this);

    this.setCurrentAccountPostsAmount =
      this.setCurrentAccountPostsAmount.bind(this);

    this.toggleTradingInfoModalOpen =
      this.toggleTradingInfoModalOpen.bind(this);
  }

  setTradingAccountPosts(tradingAccountPosts: unknown[]) {
    this.tradingAccountPosts = tradingAccountPosts;
  }

  async getTradingAccountPosts() {
    const { posts, amount, activeAmount } = await tradingApi.getTradingPosts({
      page: this.currentAccountPage,
    });

    this.setTradingAccountPosts(posts);
    this.setCurrentAccountPostsAmount(amount);
    this.tradingActivePostsAmount = activeAmount;
  }

  setTradingAccount(tradingAccount: TradingCompanyInterface) {
    this.tradingAccount = tradingAccount;
  }

  setPublicTradingPosts(posts: PublicTradingPostInterface[]) {
    this.publicTradingPosts = posts;
  }

  setIsModalForBalanceOpen(isOpen: boolean) {
    this.isModalForBalanceOpen = isOpen;
  }

  setTradingAccountState(state: TradingAccountWidgetStatesEnum) {
    this.tradingAccountState = state;
  }

  setCreatedPostModalOpen(isOpen: boolean) {
    this.isCreatePostModalOpen = isOpen;
  }

  setTradingPlans(tradingPlans: TradingPlanInterface[]) {
    this.tradingPlans = tradingPlans;
  }

  setIsTradingPlansLoading(isLoading: boolean) {
    this.isTradingPlansLoading = isLoading;
  }

  setTradingTopPlans(tradingTopPlans: TradingTopPlanInterface[]) {
    this.tradingTopPlans = tradingTopPlans;
  }

  setIsTradingTopPlansLoading(isLoading: boolean) {
    this.isTradingTopPlansLoading = isLoading;
  }

  toggleTradingTopPlanModal() {
    this.isTradingTopPlanOpen = !this.isTradingTopPlanOpen;
  }

  setTradingPostToTop(post: any) {
    this.tradingPostToTop = post;
  }

  toggleEditPostModal() {
    this.isEditPostModalOpen = !this.isEditPostModalOpen;
  }

  setPostToEdit(post: any) {
    this.postToEdit = post;
  }

  setFilters(filters: any) {
    this.filters = filters;
  }

  setIsFiltersOpen(isOpen: boolean) {
    this.isFiltersOpen = isOpen;
  }

  setIsTradingPostsLoading(isLoading: boolean) {
    this.isTradingPostsLoading = isLoading;
  }

  async getAllTradingPosts() {
    this.setIsTradingPostsLoading(true);

    const currentPage = this.page || 1;
    const page = this.filters.cerealCrops.length
      ? `${currentPage}${this.filters.cerealCrops.join(",")}`
      : currentPage;

    const { tradingPosts, amount } = await tradingApi.getPublicTradingPosts({
      page: currentPage,
      perPage: this.perPage || 20,
      filters: this.filters,
      sorts: this.sorts,
      showed:
        Array.from(
          new Set(Object.values(this.showedTradingPostsByPages)?.flat())
        ) || [],
      toShow: this.showedTradingPostsByPages?.[page] || [],
    });

    const topPostsIds = tradingPosts
      .filter((post: any) => post.isTop)
      .map((post: any) => post.id);

    if (!this.showedTradingPostsByPages[page]) {
      this.setShowedTradingPostsByPages(topPostsIds, page);
    }

    this.setPublicTradingPosts(tradingPosts);
    this.setPostsAmount(amount);
    this.setIsTradingPostsLoading(false);
  }

  setPage(page: number) {
    this.page = page;
  }

  setPostsAmount(amount: number) {
    this.postsAmount = amount;
  }

  setSorts(sorts: any) {
    this.sorts = sorts;
  }

  onClearTradingFilters() {
    this.setFilters({
      country: "",
      states: [],
      cerealCrops: [],
      paymentTypes: [],
      currency: "",
    });
  }

  setContinuePlans(plans: any) {
    this.continuePlans = plans;
  }

  toggleTradingContinuePlanModal() {
    this.isTradingContinuePlanOpen = !this.isTradingContinuePlanOpen;
  }

  setTradingPostToContinue(post: any) {
    this.tradingPostToContinue = post;
  }

  setShowedTradingPostsByPages(ids: string[], page: number | string) {
    this.showedTradingPostsByPages = {
      ...this.showedTradingPostsByPages,
      [page]: ids,
    };
  }

  setCurrentAccountPage(page: number) {
    this.currentAccountPage = page;
  }

  setCurrentAccountPostsAmount(amount: number) {
    this.currentAccountPostsAmount = amount;
  }

  toggleTradingInfoModalOpen() {
    this.isTradingInfoModalOpen = !this.isTradingInfoModalOpen;
  }
}
