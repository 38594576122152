import { useEffect } from "react";
import { AppStore } from "../../store/AppStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { fetchLocations } from "./use-locations.utils";

export const useLocation = () => {
  const { setCountries, setCountriesStates, setIsLocationsLoading } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  useEffect(() => {
    fetchLocations(setCountries, setCountriesStates, setIsLocationsLoading);
  }, []);
};
