import { useEffect } from "react";
import { AppStore } from "../../store/AppStore";
import { UserStore } from "../../store/UserStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";

export const usePlanCheck = (): void => {
  const {} = useStore(StoresEnum.APP_STORE) as AppStore;
  const { user, isSubscriptionActive, setPlanModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  useEffect(() => {

  }, [isSubscriptionActive, user]);
};
