import React, { ReactElement } from "react";

import { ContactsInterface } from "../../../../../types/interfaces/Contacts.interface";
import { navigateToViber } from "../../../../../utils/navigation-utils/navigateToViber";
import { navigateToWhatsapp } from "../../../../../utils/navigation-utils/navigateToWhatsapp";
import { navigateToTelegram } from "../../../../../utils/navigation-utils/navigateToTelegram";
import {
  FacebookIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from "../../AddPost/AddPostForm/add-post-form-components/ContactsInformation/ContsctIcons";

import { ContactIconsStyled } from "./ContactIconsStyled";

interface ContactIconsProps {
  postId: string;
  postContacts: Record<string, ContactsInterface>;
}

interface ContactIconWrapperProps {
  isProvided: boolean;
  Icon: ReactElement;
  onClick: () => void;
}

const ContactIconWrapper = (props: ContactIconWrapperProps): ReactElement => {
  const { isProvided, Icon, onClick } = props;

  if (!isProvided) {
    return <></>;
  }

  return (
    <div onClick={onClick} style={{ marginLeft: 4 }}>
      {Icon}
    </div>
  );
};

export const ContactIcons = (props: ContactIconsProps): ReactElement => {
  const { postId, postContacts } = props;

  const handleNavigateToViber = (): void => {
    navigateToViber(postContacts[postId]?.messengers?.viber?.phone);
  };

  const handleNavigateToWhatsapp = () => {
    navigateToWhatsapp(postContacts[postId]?.messengers?.whatsapp?.phone);
  };

  const handleNavigateToTelegram = () => {
    navigateToTelegram(postContacts[postId]?.messengers?.telegram?.phone);
  };

  const handleNavigateToFacebook = () => {
    window.open(`${postContacts[postId]?.facebookUrl}`, "_blank");
  };

  const isViberProvided = Boolean(
    postContacts[postId]?.messengers?.viber?.phone
  );

  const isWhatsappProvided = Boolean(
    postContacts[postId]?.messengers?.whatsapp?.phone
  );

  const isTelegramProvided = Boolean(
    postContacts[postId]?.messengers?.telegram?.phone
  );

  const isFacebookProvided = Boolean(postContacts[postId]?.facebookUrl);

  return (
    <ContactIconsStyled>
      <ContactIconWrapper
        isProvided={isViberProvided}
        Icon={<ViberIcon marginBottom={-2} />}
        onClick={handleNavigateToViber}
      />

      <ContactIconWrapper
        isProvided={isWhatsappProvided}
        Icon={<WhatsappIcon marginBottom={0} />}
        onClick={handleNavigateToWhatsapp}
      />

      <ContactIconWrapper
        isProvided={isTelegramProvided}
        Icon={<TelegramIcon marginBottom={-1} />}
        onClick={handleNavigateToTelegram}
      />

      <ContactIconWrapper
        isProvided={isFacebookProvided}
        Icon={<FacebookIcon size={20} marginBottom={0} />}
        onClick={handleNavigateToFacebook}
      />
    </ContactIconsStyled>
  );
};
