import React, { ReactElement } from "react";

import { TradingPostDatesStyledWrapper } from "./TradingPostDates.styled";
import { TradingPostInterface } from "../../../../types/interfaces/TradingPost.interface";
import { PostDate } from "../../../shared-components/PostDate/PostDate";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";

interface TradingPostDatesProps {
  post: TradingPostInterface;
  language: LanguagesEnum;
}

export const TradingPostDates = (
  props: TradingPostDatesProps
): ReactElement => {
  const { post, language } = props;

  const { activeTill, topActiveTill } = post || {};

  return (
    <TradingPostDatesStyledWrapper>
      <p>
        В топі до: <PostDate date={topActiveTill} language={language} />
      </p>
      <p>
        Активний до: <PostDate date={activeTill} language={language} />
      </p>
    </TradingPostDatesStyledWrapper>
  );
};
