import React, { ReactElement } from "react";

import { CerealCropInformation } from "../../add-post-form-components/CerealCropInformation/CerealCropInforamtion";
import { LocationInformation } from "../../add-post-form-components/LocationInformation/LocationInformation";
import { DeliveryInformation } from "../../add-post-form-components/DeliveryInformation/DeliveryInformation";

export const GeneralInformationStep = (): ReactElement => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CerealCropInformation />

      <LocationInformation />

      <DeliveryInformation />
    </div>
  );
};
