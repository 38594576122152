import React, { ReactElement, useState } from "react";

import { ImageCropStyledWrapper } from "./ImageCrop.styled";
import { Modal, Slider, Upload } from "antd";
import { CustomButton } from "../CustomButton/CustomButton";
import AvatarEditor from "react-avatar-editor";
import { ConfirmActionsBar } from "../ConfirmActionsBar/ConfirmActionsBar";
import { UploadOutlined } from "@ant-design/icons";
import { colors } from "../../../constants/styles/colors.constants";

interface ImageCropProps {
  fileUrl?: string;
  isOpen: boolean;
  toggleModal: () => void;
  onFileChange: (file: any) => void;
}

export const ImageCrop = (props: ImageCropProps): ReactElement => {
  const { fileUrl, isOpen, toggleModal, onFileChange } = props;

  var editor: any = "";
  const [picture, setPicture] = useState<any>({
    cropperOpen: false,
    img: null,
    zoom: 1.2,
    croppedImg: fileUrl,
  });

  const handleSlider: any = (value: any): any => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });

    toggleModal();
  };

  const setEditorRef = (ed: any) => {
    editor = ed;
  };

  const handleSave = () => {
    // @ts-ignore
    if (setEditorRef) {
      const croppedImg = editor.getImage().toDataURL();

      setPicture({
        ...picture,
        img: croppedImg,
        cropperOpen: false,
        croppedImg: croppedImg,
      });

      const dataURLtoFile = (dataurl: any, filename: any) => {
        let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      };

      const canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 100;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.onload = function () {
        // @ts-ignore
        ctx.drawImage(img, 0, 0, 100, 100);
        const resizedDataUrl = canvas.toDataURL();
        setPicture({
          ...picture,
          img: resizedDataUrl,
          cropperOpen: false,
          croppedImg: resizedDataUrl,
        });

        const file = dataURLtoFile(resizedDataUrl, "avatar.png");

        onFileChange(file);
      };
      img.src = editor.getImage().toDataURL();
    }

    toggleModal();
  };

  return (
    <ImageCropStyledWrapper>
      <Modal
        open={isOpen}
        onCancel={toggleModal}
        centered
        footer={
          <div>
            <ConfirmActionsBar
              confirmText="Зберегти"
              cancelText="Скасувати"
              handleCancel={handleCancel}
              handleConfirm={handleSave}
            />
          </div>
        }
      >
        <div>
          <div>
            <AvatarEditor
              ref={setEditorRef}
              // @ts-ignore
              image={fileUrl}
              width={200}
              height={200}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={picture.zoom}
              rotate={0}
            />
            <Slider
              defaultValue={picture.zoom}
              min={1}
              max={2}
              step={0.01}
              onChange={handleSlider}
            />
            <div>
              {/* <CropStyledImg src={fileUrl} alt="avatar" /> */}

              <label
                style={{
                  padding: 4,
                  color: colors.black,
                  backgroundColor: colors.white,
                  border: `1px solid ${colors.primary}`,
                  borderRadius: 4,
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                />
                <span
                  style={{
                    textAlign: "center",
                  }}
                >
                  Завантажити
                </span>
              </label>
            </div>
          </div>
        </div>
      </Modal>
    </ImageCropStyledWrapper>
  );
};
