import React, { ReactElement } from "react";

import { PaymentType } from "../PaymentType/PaymentType";
import { CurrenciesLists } from "./AddCurrenciesList/AddCurrenciesList";

import { useAddPostForm } from "../../useAddPostForm";

import { PricesInformationStyledWrapper } from "./PricesInformation.styled";
import { observer } from "mobx-react-lite";
import { BsAsterisk } from "react-icons/bs";
import { translationMap } from "../../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

export const PricesInformation = observer((): ReactElement => {
  const { state, handlers, errorFields } = useAddPostForm();
  const { paymentType } = state;
  const { handleChangePaymentType } = handlers;

  const { t } = useTranslation();

  return (
    <PricesInformationStyledWrapper>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: 10,
          position: "relative",
        }}
      >
        {t(translationMap.post.selectPaymentType)}
        <BsAsterisk
          size={8}
          color="red"
          style={{ position: "absolute", top: 4 }}
        />
      </p>

      <PaymentType
        paymentType={paymentType}
        onChange={handleChangePaymentType}
        isValid={!errorFields.includes("paymentType")}
      />

      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: 10,
          marginTop: 20,
          position: "relative",
        }}
      >
        {t(translationMap.post.fillPrices)}
        <BsAsterisk
          size={8}
          color="red"
          style={{ position: "absolute", top: 4 }}
        />
      </p>

      <CurrenciesLists />
    </PricesInformationStyledWrapper>
  );
});
