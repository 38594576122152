import React, { ReactElement, useState } from "react";
import { TbWeight } from "react-icons/tb";
import { FaAngleDown } from "react-icons/fa";

import { PostContacts } from "../../PostContacts/PostContacts";

import { countriesCodesToFlagIconMap } from "../../../../../constants/countries.constants";
import { getPrioritizedPrice } from "../Post.utils";
import { LanguagesEnum } from "../../../../../types/enums/Languages.enum";
import { PostInterface } from "../../../../../types/interfaces/Post.interface";

import { CurrenciesList } from "../PostSharedComponents/CurrenciesList/CurrenciesList";
import { HarvestYear } from "../PostSharedComponents/HarvestYear/HarvestYear";
import { LocationAvailableFor } from "../PostSharedComponents/LocationAvailableFor/LocationAvailableFor";
import { PaymentTypesEnum } from "../../../../../types/enums/PaymentTypes.enum";
import { currencyPrefixesMap } from "../../../../../constants/currencies.constants";
import { translationMap } from "../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { getStandardLocaleDate } from "../../../../../utils/date-utils/getStandardLocaleDate";

import {
  MobileCerealCropImageStyledWrapper,
  MobilePostDateStyledWrapper,
  MobilePostDescriptionStyledWrapper,
  MobileDetailsStyledWrapper,
  MobilePostDetailsAndContactsStyledWrapper,
  MobilePostHeaderStyledWrapper,
  MobilePostLocationAndPriceStyledWrapper,
  MobilePostLocationStyledWrapper,
  MobilePostMainInfoStyledWrapper,
  MobilePostPriceStyledWrapper,
  MobilePostStyledWrapper,
  MobilePostTitleAndDateStyledWrapper,
  MobilePostTitleStyledWrapper,
} from "./MobilePost.styled";
import { capitalizeString } from "../../../../../utils/common-utils/capitalizeString";
import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../store/AppStore";
import { checkIsDetailsExist } from "../../../../../utils/common-utils/checkIsDetailsEmpty";
import { paymentTypeIcon } from "../payment-type-icon";

interface MobilePostProps {
  language: LanguagesEnum;
  post: PostInterface;
  filters: Record<string, any>;
}

const getAmountString = ({
  amount,
  amountFrom,
  amountTo,
}: Partial<PostInterface>) => {
  if (amount) {
    return `${amount}T`;
  }

  if (amountFrom && amountTo) {
    return `${amountFrom} - ${amountTo}T`;
  }

  return "-";
};

export const MobilePost = (props: MobilePostProps): ReactElement => {
  const { language, post, filters } = props;

  const [isDetailsOpen, setIsDetailsOpen] = useState(true);

  const { t } = useTranslation();

  const {
    id,
    descriptions,
    cerealCrop,
    createdAt,
    state,
    country,
    contacts,
    prices,
    harvestYear,
    locationAvailableFor,
    paymentType,
  } = post;

  const isDescriptionProvided = Boolean(descriptions?.[language]);

  const { prioritizedPrice, prioritizedCurrency } = getPrioritizedPrice(
    prices,
    language,
    filters?.currency
  );

  const isDetailsEmpty = !checkIsDetailsExist(post, language);
  const isShowAvailableFor = Object.values(locationAvailableFor).some(
    (value) => value
  );

  const { countriesStates } = useStore(StoresEnum.APP_STORE) as AppStore;

  const translatedState = countriesStates?.[country?.code || ""]?.find(
    ({ name }: any) => name === state
  )?.translation[language];

  const toggleDetails = (): void => {
    if (isDetailsEmpty) {
      return;
    }

    setIsDetailsOpen(!isDetailsOpen);
  };

  return (
    <MobilePostStyledWrapper>
      <MobilePostHeaderStyledWrapper>
        <MobilePostMainInfoStyledWrapper>
          <MobilePostTitleAndDateStyledWrapper>
            <MobileCerealCropImageStyledWrapper
              src={cerealCrop?.imgUrl || ""}
              alt={cerealCrop?.name || ""}
            />

            <MobilePostTitleStyledWrapper>
              {capitalizeString(
                String(
                  cerealCrop?.translations?.[language] || cerealCrop?.name || ""
                )
              )}
            </MobilePostTitleStyledWrapper>

            <MobilePostDateStyledWrapper
              style={{
                marginLeft: "auto",
              }}
            >
              {getStandardLocaleDate(createdAt, language, "LLL")}
            </MobilePostDateStyledWrapper>
          </MobilePostTitleAndDateStyledWrapper>

          <MobilePostLocationAndPriceStyledWrapper>
            <MobilePostLocationStyledWrapper>
              {countriesCodesToFlagIconMap[country?.code || ""]}

              <p style={{ marginLeft: 5 }}>
                {translatedState?.replace?.("область", "")?.trim?.() ||
                  translatedState ||
                  ""}
              </p>
            </MobilePostLocationStyledWrapper>

            <MobilePostPriceStyledWrapper>
              {paymentType !== PaymentTypesEnum.HIDDEN &&
                paymentTypeIcon[paymentType]}

              {paymentType !== PaymentTypesEnum.HIDDEN ? (
                <div className="price">
                  <span>{prioritizedPrice}</span>
                  <span style={{ fontSize: 14 }}>
                    {currencyPrefixesMap[prioritizedCurrency]}/T
                  </span>
                </div>
              ) : (
                <p style={{ fontSize: 14 }}>
                  {t(translationMap.post.priceHidden)}
                </p>
              )}
            </MobilePostPriceStyledWrapper>
          </MobilePostLocationAndPriceStyledWrapper>
        </MobilePostMainInfoStyledWrapper>
      </MobilePostHeaderStyledWrapper>

      <MobilePostDetailsAndContactsStyledWrapper
        isDetailsEmpty={isDetailsEmpty}
      >
        <div className="details" onClick={toggleDetails}>
          <FaAngleDown style={{ marginBottom: -2 }} />
          <span>{t(translationMap.post.description)}</span>
        </div>

        <p
          className="quantity"
          style={{
            marginLeft: 6,
          }}
        >
          <TbWeight style={{}} />
          {getAmountString(post)}
        </p>

        <PostContacts postId={id} contacts={contacts} isMobile />
      </MobilePostDetailsAndContactsStyledWrapper>

      <MobileDetailsStyledWrapper isOpen={isDetailsOpen}>
        <CurrenciesList
          prices={prices}
          filters={filters}
          paymentType={paymentType}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {Boolean(harvestYear) && (
            <div style={{ marginTop: 10 }}>
              <HarvestYear harvestYear={harvestYear} />{" "}
            </div>
          )}

          {isShowAvailableFor && (
            <LocationAvailableFor locationAvailableFor={locationAvailableFor} />
          )}
        </div>

        {isDescriptionProvided && (
          <MobilePostDescriptionStyledWrapper>
            {descriptions?.[language]}
          </MobilePostDescriptionStyledWrapper>
        )}
      </MobileDetailsStyledWrapper>
    </MobilePostStyledWrapper>
  );
};
