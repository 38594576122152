import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import { TradingPostActions } from "../TradingPostActions/TradingPostActions";
import { TradingPostContent } from "../TradingPostContent/TradingPostContent";

import { TradingPostInterface } from "../../../../types/interfaces/TradingPost.interface";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";

import {
  MobilePostCerealCropInfoStyled,
  MobilePostInfoStyled,
  MobilePostLocationStyled,
  MobilePostPricesStyled,
  MobilePublishedTradingPostListThumbnailStyled,
  MobileTradingPostStyledWrapper,
  PostLineLabelStyled,
  PublishedTradingPostCropThumbStyled,
  PublishedTradingPostListThumbnailStyled,
  TradingPostStyledWrapper,
} from "./TradingPost.styled";
import { colors } from "../../../../constants/styles/colors.constants";
import { PostDate } from "../../../shared-components/PostDate/PostDate";
import { StatesTags } from "../../../shared-components/StatesTags/StatesTags";
import { countriesCodesToFlagIconMap } from "../../../../constants/countries.constants";
import { currencyPrefixesMap } from "../../../../constants/currencies.constants";
import { paymentTypeIcon } from "../../sell-module/Post/payment-type-icon";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";

interface TradingPostProps {
  post: TradingPostInterface;
}

// TODO: move to utils rewrite to date-fns
const checkIsDateInPast = (date: string): boolean => {
  if (!date) {
    return false;
  }

  return new Date(date) < new Date();
};

export const TradingPost = observer((props: TradingPostProps): ReactElement => {
  const { post } = props;
  // @ts-ignore
  const { cerealCrop, activeTill, topActiveTill } = post;
  const { imgUrl } = cerealCrop || {};

  const { language, countriesStates, isMobile } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  const isPostExpired = activeTill ? checkIsDateInPast(activeTill) : true;
  const isTopPostExpired = topActiveTill
    ? checkIsDateInPast(topActiveTill)
    : true;

  const { toggleEditPostModal, setPostToEdit } = useTradingStore();
  const handleEditPost = (): void => {
    setPostToEdit(post);
    toggleEditPostModal();
  };

  if (isMobile()) {
    return (
      <MobileTradingPostStyledWrapper>
        <MobilePostInfoStyled>
          <MobilePublishedTradingPostListThumbnailStyled
            src={imgUrl}
            alt="thumbnail"
          />

          <MobilePostCerealCropInfoStyled>
            <p
              style={{
                fontSize: 16,
                fontWeight: 600,
                marginRight: 10,
              }}
            >
              {post?.cerealCrop?.translations?.[language]}
            </p>

            {Boolean(post.amount) ? <p>{post.amount}т</p> : <></>}
          </MobilePostCerealCropInfoStyled>

          <div style={{ marginLeft: "auto" }}>
            <PostDate date={post.createdAt} language={language} />
          </div>
        </MobilePostInfoStyled>

        <MobilePostLocationStyled>
          {countriesCodesToFlagIconMap[post?.country?.code || ""]}

          <StatesTags
            states={post.states}
            countriesStates={countriesStates}
            country={post.country}
            language={language}
            isFullList
            isFullCountry={post.isFullCountry}
          />
        </MobilePostLocationStyled>

        <MobilePostPricesStyled>
          <p
            style={{
              marginLeft: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            {paymentTypeIcon?.[post?.paymentType || ""] || ""}
            {post?.paymentType === "HIDDEN" ? "Договірна" : ""}
          </p>
          {Object.entries(post?.prices || {}).map(
            ([key, value]: [any, any]) => (
              <p
                key={key}
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: colors.primary,
                  marginRight: 10,
                }}
              >
                {value}
                {/* @ts-ignore */}
                {currencyPrefixesMap?.[key]}
              </p>
            )
          )}

          <p
            style={{
              marginLeft: "auto",
              fontSize: 14,
            }}
          >
            +{post?.contacts?.rootPhone}
          </p>
        </MobilePostPricesStyled>

        <div>
          {post.descriptions?.[LanguagesEnum.UK] && (
            <p
              style={{
                fontSize: 16,
                fontWeight: 600,
                marginTop: 10,
                background: colors.darkWhite,
                padding: "5px 10px",
              }}
            >
              {post.descriptions?.[LanguagesEnum.UK]}
            </p>
          )}
        </div>

        <TradingPostActions
          tradingPost={post}
          isExpired={isPostExpired}
          isTopExpired={isTopPostExpired}
          topActiveTill={topActiveTill}
          activeTill={activeTill}
          language={language}
          isMobile
        />

        <div
          style={{
            marginTop: 10,
          }}
        >
          <CustomButton
            style={{
              width: "100%",
              marginTop: 10,
              border: `2px solid ${colors.primary}`,
            }}
            onClick={handleEditPost}
            disabled={isPostExpired}
          >
            Редагувати
          </CustomButton>
        </div>
      </MobileTradingPostStyledWrapper>
    );
  }

  return (
    <TradingPostStyledWrapper>
      <PublishedTradingPostCropThumbStyled>
        <PublishedTradingPostListThumbnailStyled src={imgUrl} alt="thumbnail" />
      </PublishedTradingPostCropThumbStyled>

      <TradingPostContent
        post={post}
        language={language}
        countriesStates={countriesStates}
        onEditPost={handleEditPost}
      />

      <TradingPostActions
        tradingPost={post}
        isExpired={isPostExpired}
        isTopExpired={isTopPostExpired}
        topActiveTill={topActiveTill}
        activeTill={activeTill}
        language={language}
      />

      <PostLineLabelStyled
        $isTop
        $bg={isTopPostExpired ? colors.darkGrey : colors.link}
      >
        ТОП
      </PostLineLabelStyled>

      <PostLineLabelStyled
        $isTop
        $isSecond
        $bg={isPostExpired ? colors.darkGrey : colors.primary}
      >
        Активно
      </PostLineLabelStyled>
    </TradingPostStyledWrapper>
  );
});
