import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import {
  Mobile_TradingBalanceStyledWrapper,
  TradingBalanceStyledWrapper,
} from "./TradingAccountInfo.styled";
import { TradingCompanyInfoWidget } from "../TradingCompanyInfoWidget/TradingCompanyInfoWidget";
import { TradingAccountStatisticsWidget } from "../TradingAccountStatisticsWidget/TradingAccountStatisticsWidget";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { TradingAccountEdit } from "../TradingAccountEdit/TradingAccountEdit";
import { TradingAccountWidgetStatesEnum } from "../../../../types/enums/TradingAccountWidgetStates.enum";
import { NoTradingAccount } from "../NoTradingAccount/NoTradingAccount";
import { TradingAccountCreate } from "../TradingAccountCreate/TradingAccountCreate";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";

const TradingAccountWidgetByState: Record<TradingAccountWidgetStatesEnum, any> =
  {
    [TradingAccountWidgetStatesEnum.EDIT_TRADING_ACCOUNT]: (
      <TradingAccountEdit />
    ),
    [TradingAccountWidgetStatesEnum.CREATE_TRADING_ACCOUNT]: (
      <TradingAccountCreate />
    ),
    [TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT]: (
      <TradingCompanyInfoWidget />
    ),
    [TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT]: <NoTradingAccount />,
  };

export const TradingAccountInfo = observer(() => {
  const { tradingAccountState } = useTradingStore();
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  if (isMobile()) {
    return (
      <Mobile_TradingBalanceStyledWrapper>
        {TradingAccountWidgetByState?.[tradingAccountState] || <></>}

        <TradingAccountStatisticsWidget />
      </Mobile_TradingBalanceStyledWrapper>
    );
  }

  return (
    <>
      <TradingBalanceStyledWrapper>
        {TradingAccountWidgetByState?.[tradingAccountState] || <></>}

        <TradingAccountStatisticsWidget />
      </TradingBalanceStyledWrapper>
    </>
  );
});
