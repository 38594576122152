const isSafari = () => {
  return (
    typeof window !== "undefined" &&
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor)
  );
};

export const navigateByCheckoutUrl = async (checkoutUrl: string) => {
  if (isSafari()) {
    window.location.assign(checkoutUrl);

    return;
  }

  window.open(checkoutUrl, "_self");
};
