import React, { ReactElement } from "react";

import { ListLayoutStyledWrapper } from "./ListLayout.styled";

interface ListLayoutProps {
  SideBar: ReactElement;
  List: ReactElement;
}

export const ListLayout = (props: ListLayoutProps): ReactElement => {
  const { SideBar, List } = props;

  return (
    <ListLayoutStyledWrapper>
      <div style={{ width: "25%" }}>{SideBar}</div>

      <div style={{ width: "75%" }}>{List}</div>
    </ListLayoutStyledWrapper>
  );
};
