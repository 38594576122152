import { useEffect } from "react";
import { AppStore } from "../../store/AppStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { cerealCropsApi } from "../../api/requests/cereal-crops.api";

export const useCerealCrops = () => {
  const { setCerealCrops, setIsCerealCropsLoading } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  useEffect(() => {
    const fetchCerealCrops = async () => {
      setIsCerealCropsLoading(true);

      const { cerealCrops } = await cerealCropsApi.getAllCerealCrops();

      setCerealCrops(
        cerealCrops.sort((a: any, b: any) => a.priority - b.priority)
      );

      setIsCerealCropsLoading(false);
    };

    fetchCerealCrops();
  }, []);
};
