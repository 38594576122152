import styled from "styled-components";

export const ImageCropStyledWrapper = styled.div``;

export const CropStyledImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
`;
