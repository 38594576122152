import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../i18n/translationMap";
import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import React, { ReactElement } from "react";

export const MenuActions = ({
  handleOpenCloseAddPostModal,
  handleCheckout,
}: any): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <CustomButton
        type="primary"
        margin="0 10px"
        onClick={handleOpenCloseAddPostModal}
        size="small"
      >
        {t(translationMap.post.addPost)}
      </CustomButton>

      <CustomButton type="primary" margin="0 10px" size="small">
        {t(translationMap.post.addTradingPost)}
      </CustomButton>

      <CustomButton
        type="default"
        onClick={handleCheckout}
        margin="0 10px"
        size="small"
      >
        {t(translationMap.payment.yourPlan)}
      </CustomButton>
    </>
  );
};
