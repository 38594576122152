import React, { ReactElement } from "react";

import { PostInterface } from "../../../../types/interfaces/Post.interface";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";

import { PostStyledWrapper } from "./Post.styled";
import { PostInfo } from "./PostInfo";
import { PostDescription } from "./PostDescription";
import { MobilePost } from "./MobilePost/MobilePost";
import { PostsStore } from "../../../../store/PostsStore";

interface PostProps {
  post: PostInterface;
}

export const Post = observer((props: PostProps): ReactElement => {
  const { post } = props;

  const { isMobile, language } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { unstable_filters } = useStore(StoresEnum.POSTS_STORE) as PostsStore;

  if (isMobile()) {
    return (
      <MobilePost post={post} language={language} filters={unstable_filters} />
    );
  }

  return (
    <PostStyledWrapper>
      <PostInfo post={post} language={language} />

      <PostDescription
        post={post}
        language={language}
        filters={unstable_filters}
      />
    </PostStyledWrapper>
  );
});
