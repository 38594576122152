import { Input, InputProps } from "antd";
import { useState } from "react";
import { BsAsterisk } from "react-icons/bs";

interface ValidationInputProps extends InputProps {
  value: string | number | undefined;
  placeholder?: any;
  style?: any;
  inputStyle?: any;
  type?: string;
  isValid: boolean;
  isRequired?: boolean;
  onChange: (value: any) => void;
}

export const ValidationInput = (props: ValidationInputProps) => {
  const {
    value,
    placeholder = "",
    inputStyle = {},
    style = {},
    type = "text",
    isValid,
    isRequired = false,
    onChange,

    ...rest
  } = props;

  const [isTouched, setIsTouched] = useState(false);

  const invalidStyle = !isValid && isTouched ? { border: "1px solid red" } : {};

  const handleChangeValidationInput = (e: any) => {
    onChange(e);
  };

  const handleTouch = (e: any) => {
    setIsTouched(true);
  };

  return (
    <div style={{ ...style, position: "relative" }}>
      <Input
        value={value || undefined}
        placeholder={placeholder}
        style={{ ...inputStyle, ...invalidStyle }}
        type={type}
        onChange={handleChangeValidationInput}
        onFocus={handleTouch}
        {...rest}
      />

      {isRequired && (
        <BsAsterisk
          size={8}
          color="red"
          style={{ position: "absolute", left: -2, top: -2, zIndex: 200 }}
        />
      )}
    </div>
  );
};
