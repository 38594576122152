import { AddPostStore } from "../../store/AddPostStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { useEffect } from "react";
import {
  checkIsCerealCropStepValid,
  checkIsContactsStepValid,
  isDescriptionStepValid,
  checkIsLocationStepValid,
  isPriceStepValid,
  checkIsConfirmationCodeFormValid,
  checkIsConfirmationPhoneFormValid,
} from "./useAddPostStore.utils";

interface UseAddPostStoreInterface {
  currentStep: number;
  postModalData: any;
  isAddPostFormValid: boolean;
  isPhoneAdded: boolean;
  phone: string;
  code: string;
  isPhoneConfirmationLoading: boolean;
  isFacebookUrlValid: boolean;
  stepsValidation: Record<string, boolean>;
  errorFields: string[];

  setPhone: (phone: string) => void;
  setCode: (code: string) => void;
  setCurrentStep: (currentStep: number) => void;
  setIsPhoneAdded: (isPhoneAdded: boolean) => void;
  setPostModalData: (postModalData: any) => void;
  onChangeInput: (key: any, type?: string) => (e: any) => void;
  onChangeDescriptions: (descriptions: any) => void;
  onChangeSelect: (key: any, type?: string) => (e: any) => void;
  setIsPhoneConfirmationLoading: (isPhoneConfirmationLoading: boolean) => void;
  setIsFacebookUrlValid: (isFacebookUrlValid: boolean) => void;
  onClearPostModal: () => void;
}

export const useAddPostStore = (): UseAddPostStoreInterface => {
  const {
    currentStep,
    postModalData,
    isAddPostFormValid,
    isPhoneAdded,
    phone,
    code,
    isPhoneConfirmationLoading,
    isFacebookUrlValid,
    stepsValidation,
    errorFields,

    onChangeInput,
    onChangeSelect,
    setCurrentStep,
    setIsPhoneAdded,
    setPostModalData,
    onChangeDescriptions,
    setPhone,
    setCode,
    setIsPhoneConfirmationLoading,
    setIsFacebookUrlValid,
    setStepsValidation,
    onClearPostModal,
    setErrorFields,
  } = useStore(StoresEnum.ADD_POST_STORE) as AddPostStore;

  useEffect(() => {
    const {
      isValid: isCerealCropStepValid,
      errorFields: cerealCropErrorFields,
    } = checkIsCerealCropStepValid(postModalData);

    const { isValid: isLocationStepValid, errorFields: locationErrorFields } =
      checkIsLocationStepValid(postModalData);

    const { isValid: isContactsStepValid, errorFields: contactsErrorFields } =
      checkIsContactsStepValid(postModalData, isFacebookUrlValid);

    const { isValid: isPhoneStepValid, errorFields: phoneErrorFields } =
      checkIsConfirmationPhoneFormValid(phone);

    const { isValid: isCodeStepValid, errorFields: codeErrorFields } =
      checkIsConfirmationCodeFormValid(code);

    setStepsValidation({
      isCerealCropStepValid,
      isLocationStepValid,
      isPriceStepValid: isPriceStepValid(postModalData),
      isDescriptionStepValid: isDescriptionStepValid(),
      isContactsStepValid,
      isCodeStepValid,
      isPhoneStepValid,
    });

    setErrorFields([
      ...cerealCropErrorFields,
      ...locationErrorFields,
      ...contactsErrorFields,
      ...codeErrorFields,
      ...phoneErrorFields,
    ]);
  }, [postModalData, isFacebookUrlValid, phone, code]);

  return {
    currentStep,
    postModalData,
    isAddPostFormValid,
    isPhoneAdded,
    phone,
    code,
    isPhoneConfirmationLoading,
    isFacebookUrlValid,
    stepsValidation,
    errorFields,

    setCurrentStep,
    setIsPhoneAdded,
    setPostModalData,
    onChangeInput,
    onChangeDescriptions,
    onChangeSelect,
    setPhone,
    setCode,
    setIsPhoneConfirmationLoading,
    setIsFacebookUrlValid,
    onClearPostModal,
  };
};
