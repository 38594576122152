import styled from "styled-components";

export const TradingInfoModalStyledWrapper = styled.div`
  p {
    text-align: center;
    margin: 4px 0;
    font-weight: 500;
  }

  .default-p {
    padding-top: 5px;
    margin: 20px 0;
    border-top: 1px solid #e5e5e5;
  }
`;
