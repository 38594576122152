import { RiPencilLine } from "react-icons/ri";
import { MdAttachMoney, MdDone, MdOutlineTextsms } from "react-icons/md";
import { GiWheat } from "react-icons/gi";
import { TiContacts } from "react-icons/ti";

export const STEPS_ITEMS: any = [
  {
    status: "process",
    icon: <GiWheat />,
  },
  {
    status: "process",
    icon: <MdAttachMoney />,
  },
  {
    status: "process",
    icon: <RiPencilLine />,
  },
  {
    status: "process",
    icon: <TiContacts />,
  },
  {
    status: "process",
    icon: <MdOutlineTextsms />,
  },
  {
    status: "process",
    icon: <MdDone />,
  },
];

export const AUTHORIZED_STEPS_ITEMS: any = [
  {
    status: "process",
    icon: <GiWheat />,
  },
  {
    status: "process",
    icon: <MdAttachMoney />,
  },
  {
    status: "process",
    icon: <RiPencilLine />,
  },
  {
    status: "process",
    icon: <TiContacts />,
  },
  {
    status: "process",
    icon: <MdDone />,
  },
];

export const getFormSteps = (isAuthorized: boolean) => {
  return isAuthorized ? AUTHORIZED_STEPS_ITEMS : STEPS_ITEMS;
};
