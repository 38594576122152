import React, { ReactElement } from "react";
import { Tag } from "antd";

import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../store/AppStore";
import { CerealCropInterface } from "../../../types/interfaces/CerealCrop.interface";

export const CerealCropTag = (props: any): ReactElement => {
  const { value } = props;

  const { language, cerealCrops } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { translations } =
    cerealCrops.find((item: CerealCropInterface) => item.id === value) || {};

  return <Tag>{translations?.[language]}</Tag>;
};
