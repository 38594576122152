import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { AuthModal } from "./AuthModal";
import { UserStore } from "../../../../store/UserStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { SignInForm } from "../AuthForms/SignInForm/SignInForm";
import { AppStore } from "../../../../store/AppStore";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../i18n/translationMap";

export const SignInModal = observer((): ReactElement => {
  const { isSignInModalOpen, setSignInModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const { t } = useTranslation();

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  if (isMobile()) {
    return <></>;
  }

  return (
    <AuthModal
      title={t(translationMap.auth.signIn)}
      isOpen={isSignInModalOpen}
      content={<SignInForm />}
      handleClose={setSignInModalOpenClose}
    />
  );
});
