import React, { ReactElement } from "react";

interface FormItemLabelProps {
  title: string;
}

export const FormItemLabel = (props: FormItemLabelProps): ReactElement => {
  const { title } = props;

  return <p style={{ color: "gray" }}>{title}:</p>;
};
