import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const TradingAccountStatisticsWidgetStyledWrapper = styled.div<{
  $isMobile?: boolean;
}>`
  display: flex;
  justify-content: space-between;

  width: ${({ $isMobile }) => ($isMobile ? "95%" : "60%")};
  margin: ${({ $isMobile }) => ($isMobile ? "0 auto" : "0")};
  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
`;

export const ActiveAmountOfPosts = styled.div<{ $isMobile: boolean }>`
  background: ${colors.white};
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-left: ${({ $isMobile }) => ($isMobile ? "0px" : "20px")};
  margin-top: ${({ $isMobile }) => ($isMobile ? "10px" : "0px")};
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "48%")};
  flex-direction: ${({ $isMobile }) => ($isMobile ? "row" : "column")};
  align-items: ${({ $isMobile }) => ($isMobile ? "center" : "center")};

  display: flex;
  justify-content: flex-start;

  .statistic-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .statistic-number {
    font-size: 34px;
    font-weight: 800;
    color: ${colors.primary};
    margin: ${({ $isMobile }) => ($isMobile ? "0 20px 0 10px" : "0 0 10px 0")};
  }

  .statistic-button {
    margin-left: ${({ $isMobile }) => ($isMobile ? "auto" : "0")};
    width: ${({ $isMobile }) => ($isMobile ? "200px" : "100%")};
  }
`;
