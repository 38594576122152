import { useEffect } from "react";
import { useTradingStore } from "../use-trading-store-hook/useTradingStore";
import { tradingApi } from "../../api/requests/trading.api";

export const useTradingTopPlans = (): void => {
  const { tradingTopPlans, setTradingTopPlans, setIsTradingTopPlansLoading } =
    useTradingStore();

  useEffect(() => {
    const fetchTradingPlans = async () => {
      if (tradingTopPlans.length) {
        return;
      }

      setIsTradingTopPlansLoading(true);

      const plans = await tradingApi.getTradingTopPlans();

      setTradingTopPlans(plans);
      setIsTradingTopPlansLoading(false);
    };

    fetchTradingPlans();
  }, []);
};
