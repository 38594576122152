import { ReactElement } from "react";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";

import {
  SortSwitcherStyledWrapper,
  SortSwitchesStyledWrapper,
  SortSwitchLeftStyledButton,
  SortSwitchRightStyledButton,
  SortHighliterStyledWrapper,
} from "./SortSwitcher.styled";
import { Tooltip } from "antd";

export enum SortSwitcherValuesEnum {
  ASC = "asc",
  DESC = "desc",
  UN_SORTED = "unsorted",
}

interface SortSwitcherProps {
  title: string;
  value?: SortSwitcherValuesEnum;
  isMobile?: boolean;
  Icon?: ReactElement;
  isDisabled?: boolean;
  disablingReason?: string;
  onChange: (value: string) => void;
}

export const SortSwitcher = (props: SortSwitcherProps): ReactElement => {
  const {
    title = "",
    isDisabled,
    isMobile = false,
    Icon,
    disablingReason = "",
    value = SortSwitcherValuesEnum.UN_SORTED,
    onChange,
  } = props;

  const handleSwitchAsc = () => {
    if (isDisabled) {
      return;
    }

    onChange(SortSwitcherValuesEnum.ASC);
  };

  const handleSwitchDesc = () => {
    if (isDisabled) {
      return;
    }

    onChange(SortSwitcherValuesEnum.DESC);
  };

  const content = (
    <SortSwitchesStyledWrapper isDisabled={isDisabled}>
      <SortSwitchLeftStyledButton
        onClick={handleSwitchAsc}
        isSelected={value === SortSwitcherValuesEnum.ASC}
        isDisabled={isDisabled}
      >
        <FaSortAmountUpAlt />
      </SortSwitchLeftStyledButton>

      <SortSwitchRightStyledButton
        onClick={handleSwitchDesc}
        isSelected={value === SortSwitcherValuesEnum.DESC}
        isDisabled={isDisabled}
      >
        <FaSortAmountDownAlt />
      </SortSwitchRightStyledButton>

      <SortHighliterStyledWrapper sort={value} />
    </SortSwitchesStyledWrapper>
  );

  return (
    <SortSwitcherStyledWrapper isMobile={isMobile}>
      {Boolean(Icon) && Icon}
      {!isMobile && <p>{`${title}: `}</p>}

      {isDisabled && disablingReason ? (
        <Tooltip title={disablingReason}>{content}</Tooltip>
      ) : (
        content
      )}
    </SortSwitcherStyledWrapper>
  );
};
