import { Select, SelectProps } from "antd";
import { useState } from "react";
import { BsAsterisk } from "react-icons/bs";
import styled from "styled-components";

interface ValidationSelectProps extends SelectProps {
  value: any;
  placeholder?: string | undefined | null;
  style?: any;
  selectStyle?: any;
  isRequired?: boolean;
  isValid: boolean;
  options: any[];
  onChange: (value: any) => void;
}

const StyledSelect = styled(Select)<{ isValid: boolean; isTouched: boolean }>`
  & .ant-select-selector {
    border: ${({ isValid, isTouched }) =>
      isTouched && !isValid ? "1px solid red !important" : ""};
  }
`;

export const ValidationSelect = (props: ValidationSelectProps) => {
  const {
    value,
    placeholder = "",
    selectStyle = {},
    options,
    style = {},
    isValid,
    isRequired = false,
    onChange,
    ...rest
  } = props;

  const [isTouched, setIsTouched] = useState(false);

  const handleChangeValidationSelect = (value: any) => {
    onChange(value);
  };

  const handleTouch = (e: any) => {
    setIsTouched(true);
  };

  return (
    <div style={{ ...style, position: "relative" }}>
      {/* @ts-ignore */}
      <StyledSelect
        value={value || undefined}
        placeholder={placeholder}
        onChange={handleChangeValidationSelect}
        style={{ ...selectStyle }}
        options={options}
        isValid={isValid}
        isTouched={isTouched}
        onFocus={handleTouch}
        {...rest}
      />

      {isRequired && (
        <BsAsterisk
          size={8}
          color="red"
          style={{ position: "absolute", left: -2, top: -2 }}
        />
      )}
    </div>
  );
};
