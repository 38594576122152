import React from "react";
import { observer } from "mobx-react-lite";
import { TradingMyOffers } from "../TradingMyOffers/TradingMyOffers";
import { TradingAccountInfo } from "../TradingAccountInfo/TradingAccountInfo";
import { TradingAccountStyledWrapper } from "./TradingAccount.styled";
import { CreateTradingPost } from "../CreateTradingPost/CreateTradingPost";
import { TradingTopPlansModal } from "../TradingTopPlansModale/TradingTopPlansModale";
import { TradingPostEditModal } from "../TradingPostEditModal/TradingPostEditModal";
import { TradingContinuePlansModal } from "../TradingContinuePlansModal/TradingContinuePlansModal";
import { TradingInfoModal } from "../TradingInfoModal/TradingInfoModal";

export const TradingAccount = observer(() => {
  return (
    <>
      <TradingAccountStyledWrapper>
        <TradingAccountInfo />

        <TradingMyOffers />
      </TradingAccountStyledWrapper>

      <CreateTradingPost />
      <TradingTopPlansModal />
      <TradingPostEditModal />
      <TradingContinuePlansModal />
      <TradingInfoModal />
    </>
  );
});
