import styled from "styled-components";

export const TradingAccountCreateStyledWrapper = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
  width: 100%;
`;

export const CurrentAvatarStyledImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
`;

export const CurrentAvatarStyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
