import React, { ReactElement } from "react";

import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../store/UserStore";
import { MenuMobile } from "./MenuMobile/Menu.mobile";
import { AppStore } from "../../../../store/AppStore";
import { DesktopMenu } from "./DesktopMenu/DesktopMenu";

interface MenuProps {
  isPosts: boolean;
  isTrading: boolean;
}

export const Menu = observer(
  ({ isPosts, isTrading }: MenuProps): ReactElement => {
    const { setPlanModalOpenClose } = useStore(
      StoresEnum.USER_STORE
    ) as UserStore;

    const { isMobile, isAddPostModalOpen, setIsAddPostModalOpen } = useStore(
      StoresEnum.APP_STORE
    ) as AppStore;

    const handleCheckout = async (): Promise<void> => {
      setPlanModalOpenClose();
    };

    const handleOpenCloseAddPostModal = (): void => {
      setIsAddPostModalOpen(!isAddPostModalOpen);
    };

    if (isMobile()) {
      return (
        <MenuMobile
          isPosts={isPosts}
          isTrading={isTrading}
          handleOpenCloseAddPostModal={handleOpenCloseAddPostModal}
          handleCheckout={handleCheckout}
        />
      );
    }

    return (
      <DesktopMenu
        isPosts={isPosts}
        isTrading={isTrading}
        handleOpenCloseAddPostModal={handleOpenCloseAddPostModal}
        handleCheckout={handleCheckout}
      />
    );
  }
);
