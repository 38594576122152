import React, { ReactElement } from "react";
import { Modal } from "antd";

import { AddPostForm } from "../AddPostForm/AddPostForm";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../store/AppStore";
import { MobileBottomSlideModal } from "../../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { AddPostStore } from "../../../../../store/AddPostStore";
import { translationMap } from "../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

export const AddPostModal = observer((): ReactElement => {
  const { isMobile, isAddPostModalOpen, setIsAddPostModalOpen } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;
  const { onClearPostModal } = useStore(
    StoresEnum.ADD_POST_STORE
  ) as AddPostStore;

  const handleCancel = (): void => {
    setIsAddPostModalOpen(false);
    onClearPostModal();
  };

  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isAddPostModalOpen}
        title={t(translationMap.post.addPost)}
        onClose={handleCancel}
        Content={
          <div>
            <AddPostForm onCancel={handleCancel} />
          </div>
        }
      />
    );
  }

  return (
    <Modal
      title={t(translationMap.post.addPost)}
      centered
      open={isAddPostModalOpen}
      onOk={() => setIsAddPostModalOpen(false)}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <AddPostForm onCancel={handleCancel} />
    </Modal>
  );
});
