import { makeAutoObservable } from "mobx";
import { PaymentEventInterface } from "../types/interfaces/PaymentEvent.interface";
import { CerealCropInterface } from "../types/interfaces/CerealCrop.interface";
import { LanguagesEnum } from "../types/enums/Languages.enum";
import { DevicesEnum } from "../hooks/use-adaptation-hook/useAdapatation";
import { localStorageUtils } from "../utils/browser-utils/LocalStorage";
import { getLanguageFromBrowser } from "../utils/browser-utils/getLanguageFromBrowser";
import { PagesEnum } from "../types/enums/Pages.enum";

export class AppStore {
  public language: LanguagesEnum = getLanguageFromBrowser() || LanguagesEnum.UK;

  public countries: any = [];
  public countriesStates: any = {};
  public isLocationsLoading: boolean = false;

  public plans: any = [];
  public isPlansLoading: boolean = false;

  public isPaymentProcessing: boolean = false;
  public payment: PaymentEventInterface | {} = {};

  public isCerealCropsLoading: boolean = false;
  public cerealCrops: CerealCropInterface[] = [];

  public device: DevicesEnum | null = DevicesEnum.MOBILE;

  public isAddPostModalOpen = false;

  public isSupportOpen = false;

  public currentTourStep = 0;
  public isTourOpen = localStorageUtils.getTour() === 2 ? false : true;

  public currentPageName: PagesEnum | null = null;

  public isPriceModalUp =
    localStorageUtils.getPriceUpModal() === "true" ? false : true;

  public isTelegramModalUp =
    localStorageUtils.getTelegramUpModal() === "true" ? false : true;

  constructor() {
    makeAutoObservable(this);

    this.setLanguage = this.setLanguage.bind(this);

    this.setCountries = this.setCountries.bind(this);
    this.setCountriesStates = this.setCountriesStates.bind(this);
    this.setIsLocationsLoading = this.setIsLocationsLoading.bind(this);

    this.setPlans = this.setPlans.bind(this);
    this.setIsPlansLoading = this.setIsPlansLoading.bind(this);

    this.startPayment = this.startPayment.bind(this);
    this.stopPayment = this.stopPayment.bind(this);

    this.setCerealCrops = this.setCerealCrops.bind(this);
    this.setIsCerealCropsLoading = this.setIsCerealCropsLoading.bind(this);

    this.setDevice = this.setDevice.bind(this);

    this.setIsAddPostModalOpen = this.setIsAddPostModalOpen.bind(this);

    this.isMobile = this.isMobile.bind(this);

    this.toggleSupport = this.toggleSupport.bind(this);

    this.setCurrentTourStep = this.setCurrentTourStep.bind(this);
    this.setIsTourOpen = this.setIsTourOpen.bind(this);

    this.setCurrentPageName = this.setCurrentPageName.bind(this);

    this.setIsPriceModalUp = this.setIsPriceModalUp.bind(this);

    this.setIsTelegramModalUp = this.setIsTelegramModalUp.bind(this);
  }

  setCurrentTourStep(currentTourStep: number): void {
    this.currentTourStep = currentTourStep;
  }

  setIsTourOpen(isTourOpen: boolean): void {
    this.isTourOpen = isTourOpen;
  }

  setLanguage(language: LanguagesEnum): void {
    this.language = language;
  }

  setCountries(countries: any): void {
    this.countries = countries;
  }
  setCountriesStates(countriesStates: any): void {
    this.countriesStates = countriesStates;
  }
  setIsLocationsLoading(isLocationsLoading: boolean): void {
    this.isLocationsLoading = isLocationsLoading;
  }

  setPlans(plans: any): void {
    this.plans = plans;
  }
  setIsPlansLoading(isPlansLoading: boolean): void {
    this.isPlansLoading = isPlansLoading;
  }

  startPayment(paymentEvent: PaymentEventInterface): void {
    this.payment = paymentEvent;
    this.isPaymentProcessing = true;
  }
  stopPayment(paymentEvent: PaymentEventInterface): void {
    this.payment = paymentEvent;
    this.isPaymentProcessing = false;
  }

  setCerealCrops(cerealCrops: CerealCropInterface[]): void {
    this.cerealCrops = cerealCrops;
  }
  setIsCerealCropsLoading(isCerealCropsLoading: boolean): void {
    this.isCerealCropsLoading = isCerealCropsLoading;
  }

  setDevice(device: DevicesEnum | null): void {
    this.device = device;
  }

  setIsAddPostModalOpen(isAddPostModalOpen: boolean): void {
    this.isAddPostModalOpen = isAddPostModalOpen;
  }

  isMobile(): boolean {
    return (
      this.device === DevicesEnum.MOBILE || this.device === DevicesEnum.TABLET
    );
  }

  toggleSupport(): void {
    this.isSupportOpen = !this.isSupportOpen;
  }

  setCurrentPageName(currentPageName: PagesEnum | null): void {
    this.currentPageName = currentPageName;
  }

  setIsPriceModalUp(isPriceModalUp: boolean): void {
    this.isPriceModalUp = isPriceModalUp;

    localStorageUtils.setPriceUpModal();
  }

  setIsTelegramModalUp(isTelegramModalUp: boolean): void {
    this.isTelegramModalUp = isTelegramModalUp;

    localStorageUtils.setTelegramUpModal();
  }
}
