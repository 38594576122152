import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const FiltersStyledWrapper = styled.div`
  width: 250px;
  display: flex;
  height: auto;
  flex-direction: column;
  background: #fff;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 20px 10px;
  box-shadow: -1px 10px 11px -6px rgba(0, 0, 0, 0.05);
  margin-right: 20px;
`;

export const SelectFiltersStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  & .ant-select,
  .ant-input-group {
    margin-top: 3px;
  }
`;

export const SelectCurrenciesStyledWrapper = styled.div<{
  isMobile?: boolean;
  isSorts?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-right: 20px;
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
`;

export const CurrencyStyleButton = styled.p<{
  isSelected: boolean;
  isMobile?: boolean;
}>`
  margin-right: 5px;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 5px;

  font-size: ${({ isMobile }) => (isMobile ? "20px" : "16px")};
  width: ${({ isMobile }) => (isMobile ? "50px" : "auto")};
  height: ${({ isMobile }) => (isMobile ? "50px" : "auto")};
  display: ${({ isMobile }) => (isMobile ? "flex" : "block")};

  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ isMobile }) => (isMobile ? colors.black : colors.primary)};
  }

  color: ${({ isSelected }) =>
    isSelected ? `${colors.white} !important` : colors.black};
  background: ${({ isSelected }) =>
    isSelected ? colors.primary : colors.darkWhite};
`;

export const MobileFiltersStyledWrapper = styled.div``;
