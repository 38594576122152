import styled, { css } from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const TabsStyledWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid ${colors.lightGrey};
`;

const SelectedTabCSS = css`
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-bottom: 2px solid ${colors.primary};
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`;

export const TabStyledWrapper = styled.div<{
  $isSelected: boolean;
  $tabWidth: number | string;
}>`
  width: "100%";
  padding: 10px;
  font-weight: 600;
  width: ${({ $tabWidth }) => $tabWidth};
  text-align: center;
  cursor: pointer;
  position: relative;

  ${({ $isSelected }) => $isSelected && SelectedTabCSS}

  &:hover {
    ${SelectedTabCSS}
  }
`;
