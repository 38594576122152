import { useState } from "react";

import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import { ValidatedPhoneInput } from "../../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import { SmsVerification } from "../../SmsVerification/SmsVerification";
import { AuthUserError } from "../AuthUserError/AuthUserError";

import { userApi } from "../../../../../api/requests/user.api";
import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../../store/UserStore";
import { UserErrorMessagesEnum } from "../../../../../constants/error-messages/user-error-messages";
import { localStorageUtils } from "../../../../../utils/browser-utils/LocalStorage";

import "react-phone-input-2/lib/style.css";
import { SignInFormStyledWrapper } from "./SignInForm.styled";
import { usePlan } from "../../../../../hooks/use-plan-hook/usePlan";
import { useConfirmCode } from "../../../../../hooks/use-confirm-code-hook/useConfirmCode";
import { useSignIn } from "../../../../../hooks/use-sign-in-hook/useSignIn";
import { getOpenedPosts } from "../../../../../hooks/use-auth-hook/useAuth.utils";
import { PostsStore } from "../../../../../store/PostsStore";
import { translationMap } from "../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { useSmsLimitation } from "../../../../../hooks/use-sms-limitaion/useSmsLimitaion";
import { SmsLimitationError } from "../../SmsLimitationError/SmsLimitationError";
import { useTradingStore } from "../../../../../hooks/use-trading-store-hook/useTradingStore";
import { tradingApi } from "../../../../../api/requests/trading.api";
import { TradingAccountWidgetStatesEnum } from "../../../../../types/enums/TradingAccountWidgetStates.enum";

export const SignInForm = () => {
  const {
    phone,
    isPhoneValid,
    isSignInStarted,
    isUserNotFound,
    isTelegram,

    handleChangePhone,
    checkUserNotFound,
    handleIsPhoneValid,
    handleChangeIsSignInStarted,
    handleIsTelegram,
  } = useSignIn();

  const {
    code,
    usedCode,
    isCodeInvalid,

    getCodeErrors,
    handleChangeCode,
    handleChangeUsedCode,
    handleChangeIsCodeValid,
  } = useConfirmCode();

  const {
    isSmsLimitExceeded,

    handleIsSmsLimitExceeded,
    checkIsSmsLimitationError,
  } = useSmsLimitation();

  const { setTradingAccount, setTradingAccountState, getTradingAccountPosts } =
    useTradingStore();

  const { setCurrentUser, setSignInModalOpenClose, setSignUpModalOpenClose } =
    useStore(StoresEnum.USER_STORE) as UserStore;
  const { setPersistedPostContacts } = useStore(
    StoresEnum.POSTS_STORE
  ) as PostsStore;

  usePlan();

  const cleanState = (): void => {
    handleChangePhone("");

    handleChangeCode("");
    handleChangeUsedCode("");

    setSignInModalOpenClose();

    handleChangeIsSignInStarted(false);
  };

  const { t } = useTranslation();

  const handleSignIn = async (): Promise<void> => {
    const userAuthInfo = await userApi.signIn(phone, code);
    const { user, isSignedIn, errors } = userAuthInfo;

    if (!isSignedIn) {
      handleChangeUsedCode(code);

      const { isCodeInvalid, isCodeExpired } = getCodeErrors(errors);

      if (isCodeInvalid || isCodeExpired) {
        handleChangeIsCodeValid(true);

        return;
      }

      return;
    }

    setCurrentUser(user);

    localStorageUtils.setAllAuthUserInfo(userAuthInfo);
    localStorageUtils.setDeviceMarkedAsUsed();

    await getOpenedPosts(setPersistedPostContacts);

    const tradingAccount = await tradingApi.getTradingAccount();
    setTradingAccount(tradingAccount);
    if (!tradingAccount?.id) {
      setTradingAccountState(TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT);
    } else {
      await getTradingAccountPosts();

      setTradingAccountState(
        TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT
      );
    }

    cleanState();
  };

  const handleStartSignIn = async (): Promise<void> => {
    const { isStarted, isTelegram, errors } = await userApi.startSignIn(phone);

    checkUserNotFound(errors);

    if (checkIsSmsLimitationError(errors)) {
      handleIsSmsLimitExceeded(true);

      return;
    }

    handleIsTelegram(isTelegram);
    handleChangeIsSignInStarted(isStarted);
  };

  const openSignUpForm = (): void => {
    setSignInModalOpenClose();
    setSignUpModalOpenClose();
  };

  if (isSignInStarted) {
    return (
      <SmsVerification
        phone={phone}
        code={code}
        onSave={handleSignIn}
        onChangeCode={handleChangeCode}
        isInvalid={isCodeInvalid}
        isTelegram={isTelegram}
        resendCode={handleStartSignIn}
        isConfirmButtonDisabled={code === usedCode || !Number(code)}
      />
    );
  }

  const userErrorType = isUserNotFound
    ? UserErrorMessagesEnum.USER_NOT_FOUND
    : null;

  return (
    <SignInFormStyledWrapper>
      <ValidatedPhoneInput
        phone={phone}
        isValid={isPhoneValid}
        onChangePhone={handleChangePhone}
        setIsPhoneValid={handleIsPhoneValid}
      />

      <SmsLimitationError isSmsLimitExceeded={isSmsLimitExceeded} />
      <AuthUserError userErrorType={userErrorType} onClick={openSignUpForm} />

      {isUserNotFound && <div style={{ marginTop: 20 }} />}
      <CustomButton
        type="primary"
        onClick={handleStartSignIn}
        disabled={!isPhoneValid}
        style={{ width: 200, margin: "20px auto" }}
      >
        {t(translationMap.buttons.confirm)}
      </CustomButton>
    </SignInFormStyledWrapper>
  );
};
