import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../../../i18n/translationMap";

export const HiddenPaymentPlug = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div style={{ color: "gray", textAlign: "center" }}>
      {t(translationMap.post.priceHiddenWording)}
    </div>
  );
};
