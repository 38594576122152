import React, { ReactElement } from "react";

import {
  TradingPostContentStyledWrapper,
  TradingPostInfoStyledWrapper,
} from "./TradingPostContent.styled";
import { TradingPostInterface } from "../../../../types/interfaces/TradingPost.interface";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";
import { countriesCodesToFlagIconMap } from "../../../../constants/countries.constants";
import { TradingPostPrices } from "../TradingPostPrices/TradingPostPrices";
import { TradingPostDates } from "../TradingPostDates/TradingPostDates";
import { getTranslatedStates } from "../../../../utils/common-utils/getTranslatedStates";
import { MdEdit } from "react-icons/md";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { TagList } from "../../../shared-components/TagList/TagList";
import { StatesTags } from "../../../shared-components/StatesTags/StatesTags";

interface TradingPostContentProps {
  post: TradingPostInterface;
  language: LanguagesEnum;
  countriesStates: any;
  onEditPost: () => void;
}

export const TradingPostContent = observer(
  (props: TradingPostContentProps): ReactElement => {
    const { post, language, countriesStates, onEditPost } = props;

    // @ts-ignore
    const { cerealCrop, amount, country, states = [] } = post;
    const { translations } = cerealCrop || {};

    return (
      <TradingPostContentStyledWrapper>
        <TradingPostInfoStyledWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>{translations?.[language] || ""}</p>
            <span
              style={{
                marginLeft: 10,
                fontSize: 25,
              }}
            >
              {amount}T
            </span>

            <MdEdit
              size={20}
              style={{ marginLeft: 6, cursor: "pointer" }}
              onClick={onEditPost}
            />
          </div>

          <TradingPostPrices post={post} language={language} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginRight: 10 }}>
              {countriesCodesToFlagIconMap[country?.code || ""]}
            </p>
            <StatesTags
              states={post.states}
              countriesStates={countriesStates}
              country={post.country}
              language={language}
              isFullList
              isFullCountry={post.isFullCountry}
            />
          </div>
        </TradingPostInfoStyledWrapper>

        <TradingPostDates post={post} language={language} />
      </TradingPostContentStyledWrapper>
    );
  }
);
