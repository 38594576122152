import React, { ReactElement } from "react";

import { PlanStyledWrapper } from "./Plan.styled";
import { CurrencyEnum } from "../../../types/enums/Currency.enum";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../i18n/translationMap";
import { colors } from "../../../constants/styles/colors.constants";
import { CustomButton } from "../CustomButton/CustomButton";
import { gtagReportConversion } from "../../../analitics/GA/gtagReportConversion";
import { currencyPrefixesMap } from "../../../constants/currencies.constants";
import { LanguagesEnum } from "../../../types/enums/Languages.enum";
import { TradingPlanInterface } from "../../../types/interfaces/TradingPlan.interface";
import { TradingTopPlanInterface } from "../../../types/interfaces/TradingTopPlan.interface";

interface PlanProps {
  isMobile: boolean;
  plan: TradingPlanInterface | TradingTopPlanInterface | any;
  selectedCurrency: CurrencyEnum;
  handleChoosePlan: (planId: string) => void;
  CustomPlanButtons?: any;
  language: LanguagesEnum;
  isDisabled?: boolean;
  isBalance?: boolean;
  isBasePlan?: boolean;
}

const calculateSalePrice = (
  originalPrice: number,
  discountPercentage: number
) => {
  let discountedPrice = originalPrice * (1 - discountPercentage / 100);
  return parseFloat(discountedPrice.toFixed(0));
};

const BasePrice = ({
  plan,
  selectedCurrency,
  isMobile,
}: {
  plan: TradingPlanInterface | TradingTopPlanInterface | any;
  selectedCurrency: CurrencyEnum;
  isMobile: boolean;
}) => {
  return (
    <p style={{ fontSize: 12, textAlign: "center", marginBottom: 10 }}>
      <span
        style={{
          color: colors.orange,
          position: "relative",
          display: "inline-block",
        }}
      >
        {!plan.isSale && (
          <>
            {(plan.prices[selectedCurrency] / plan.days).toFixed(2)}
            {currencyPrefixesMap[selectedCurrency]}
          </>
        )}

        {plan.isSale && (
          <>
            <span
              style={{
                position: "absolute",
                top: isMobile ? -30 : -38,
                left: -60,
                fontSize: 10,
                backgroundColor: colors.orange,
                width: 130,
                height: 2,
                transform: "rotate(-5deg)",
              }}
            ></span>

            {plan.isSale && (
              <span
                style={{
                  position: "absolute",
                  top: isMobile ? -58 : -80,
                  left: -58,
                  fontSize: 24,
                  width: 130,
                  color: colors.orange,
                  fontWeight: 800,
                }}
              >
                {calculateSalePrice(
                  plan.prices[selectedCurrency],
                  plan.salePercentage
                )}
                {currencyPrefixesMap[selectedCurrency]}
              </span>
            )}
          </>
        )}
      </span>{" "}
      {!plan.isSale && (
        <span
          style={{
            fontWeight: 400,
          }}
        >
          за добу
        </span>
      )}
    </p>
  );
};

export const Plan = (props: PlanProps): ReactElement => {
  const {
    isMobile,
    plan,
    selectedCurrency,
    handleChoosePlan,
    CustomPlanButtons,
    language,
    isDisabled = false,
    isBalance = false,
    isBasePlan = false,
  } = props;
  const { t } = useTranslation();

  return (
    <PlanStyledWrapper isMobile={isMobile}>
      <p style={{ fontSize: 18 }}>{`${
        plan?.translation?.[language] || plan?.name
      }`}</p>
      {isBasePlan && (
        <p
          style={{
            textAlign: "center",
            fontWeight: 400,
            marginTop: 4,
            fontSize: 14,
          }}
        >
          <span
            style={{
              color: colors.primary,
              fontWeight: 800,
            }}
            className="points-number"
          >
            +{plan.tradingPoints}
          </span>{" "}
          оголошення на закупівлю
        </p>
      )}

      <p
        className="plan-price"
        style={{
          marginTop: "auto",
          color: colors.primary,
          fontSize: plan.isSale ? 18 : 22,
          textAlign: "center",
          fontWeight: 800,
          marginBottom: 8,
        }}
      >
        {isBalance
          ? `-${plan?.points} з балансу`
          : `${plan.prices[selectedCurrency]}${currencyPrefixesMap[selectedCurrency]} ${selectedCurrency}`}
      </p>

      {isBasePlan && (
        <BasePrice
          plan={plan}
          selectedCurrency={selectedCurrency}
          isMobile={isMobile}
        />
      )}

      {CustomPlanButtons ? (
        CustomPlanButtons
      ) : (
        <CustomButton
          type="primary"
          onClick={() => {
            gtagReportConversion(
              undefined,
              "AW-11135401568/5hggCLjy3qQYEOD84r0p"
            );
            handleChoosePlan(plan.id);
          }}
          style={{ width: "100%", fontWeight: 600 }}
          disabled={isDisabled}
        >
          {t(translationMap.payment.choosePlan)}
        </CustomButton>
      )}
    </PlanStyledWrapper>
  );
};
