import React, { ReactElement } from "react";
import { Select } from "antd";
import { observer } from "mobx-react-lite";

import { LocationInformationStyledWrapper } from "./LocationInformation.styled";
import { useAddPostForm } from "../../useAddPostForm";
import { ValidationSelect } from "../../../../../../shared-components/ValisdationSelect/VaslidationSelect";
import { translationMap } from "../../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { CountriesList } from "../../../../../../shared-components/CountriesList/CountriesList";
import { StatesList } from "../../../../../../shared-components/StatesList/StatesList";
import { SellStatesList } from "../../../../SellStatesList/SellStatesList";

export const LocationInformation = observer((): ReactElement => {
  const { state, handlers, errorFields } = useAddPostForm();

  const { t } = useTranslation();

  const { country, state: selectedState } = state;
  const { handleChangeCountry, handleChangeState } = handlers;

  return (
    <LocationInformationStyledWrapper>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        {t(translationMap.post.selectLocation)}
      </p>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <CountriesList
          value={country || undefined}
          isValid={!errorFields.includes("country")}
          onChange={handleChangeCountry}
          isRequired
          style={{ width: "49%", marginBottom: 10 }}
          selectStyle={{ width: "100%" }}
          showSearch={false}
        />

        <SellStatesList
          value={selectedState || undefined}
          isValid={!errorFields.includes("country")}
          onChange={handleChangeState}
          isRequired
          style={{ width: "49%", marginBottom: 10 }}
          selectStyle={{ width: "100%" }}
          disabled={!Boolean(country)}
          showSearch={false}
          country={country}
        />
      </div>
    </LocationInformationStyledWrapper>
  );
});
