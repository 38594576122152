import { httpClient } from "../httpClient/HttpClient";

export class LocationApi {
  private LOCATION_ENDPOINT = "location";

  getCountries = async (): Promise<any> => {
    const data = await httpClient.get(`${this.LOCATION_ENDPOINT}/countries`);

    return data;
  };
}

export const locationApi = new LocationApi();
