import styled from "styled-components";

import { colors } from "../../../../../constants/styles/colors.constants";

export const MobileMenuActionsBarFixedStyledWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${colors.white};
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  border-top: 2px solid ${colors.orange};
`;

export const MobileMenuActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 12px;
    margin-top: 2px;
  }
`;

export const MobileMenuActionsBarStyledWrapper = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
`;

export const MobileMenuOverlayStyledWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  background: rgba(0, 0, 0, 0.7);
  transition: 0.3s;

  z-index: 100;
`;
