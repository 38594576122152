import styled from "styled-components";

export const ResendCodeStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const CheckSMSWording = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
`;
export const PhoneWording = styled.p`
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 14px;
`;
