import React, { ReactElement } from "react";

import { NoTradingAccountStyledWrapper } from "./NoTradingAccount.styled";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { Card } from "../../../shared-components/Card/Card";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { TradingAccountWidgetStatesEnum } from "../../../../types/enums/TradingAccountWidgetStates.enum";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";

export const NoTradingAccount = (): ReactElement => {
  const { setTradingAccountState, toggleTradingInfoModalOpen } =
    useTradingStore();
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const handleSetCreateTradingAccountState = () => {
    setTradingAccountState(
      TradingAccountWidgetStatesEnum.CREATE_TRADING_ACCOUNT
    );
  };

  return (
    <Card
      style={{
        width: isMobile() ? "95%" : "48%",
        margin: "0 auto",
      }}
    >
      <NoTradingAccountStyledWrapper $isMobile={isMobile()}>
        <span>Iнформація про компанію відсутня</span>

        <CustomButton
          type="primary"
          style={{
            width: isMobile() ? "100%" : "50%",
            margin: isMobile() ? "20px auto" : "0 auto",
          }}
          onClick={handleSetCreateTradingAccountState}
        >
          Додати інформацію
        </CustomButton>

        <CustomButton
          type="default"
          style={{
            width: isMobile() ? "100%" : "50%",
            margin: isMobile() ? "0 auto" : "0 auto",
          }}
          onClick={toggleTradingInfoModalOpen}
        >
          Як користуватия
        </CustomButton>
      </NoTradingAccountStyledWrapper>
    </Card>
  );
};
