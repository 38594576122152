import { CurrencyEnum } from "../types/enums/Currency.enum";

export const currencyPrefixesMap = {
  [CurrencyEnum.USD]: "$",
  [CurrencyEnum.EUR]: "€",
  [CurrencyEnum.PLN]: "zł",
  [CurrencyEnum.UAH]: "₴",
};

export const currencySuffixMap = {
  [CurrencyEnum.USD]: "USD",
  [CurrencyEnum.EUR]: "EUR",
  [CurrencyEnum.PLN]: "PLN",
  [CurrencyEnum.UAH]: "UAH",
};

export const polandCurrenciesPriorityList = [
  CurrencyEnum.PLN,
  CurrencyEnum.USD,
  CurrencyEnum.EUR,
  CurrencyEnum.UAH,
];

export const ukraineCurrenciesPriorityList = [
  CurrencyEnum.UAH,
  CurrencyEnum.USD,
  CurrencyEnum.EUR,
  CurrencyEnum.PLN,
];

export const defaultCurrenciesPriorityList = [
  CurrencyEnum.USD,
  CurrencyEnum.EUR,
  CurrencyEnum.PLN,
  CurrencyEnum.UAH,
];

export const currenciesForPlans = [
  CurrencyEnum.UAH,
  CurrencyEnum.USD,
  CurrencyEnum.EUR,
];

export const availableCurrenciesDefaultList = Object.values(CurrencyEnum).map(
  (currency: CurrencyEnum) => currency
);
