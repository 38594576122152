import React, { ReactElement, useState } from "react";

import { observer } from "mobx-react-lite";
import { BiCalendar, BiSortDown } from "react-icons/bi";
import { colors } from "../../../constants/styles/colors.constants";

import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { SortKeysEnum } from "../../../types/enums/SortsKeysEnum";
import { SortSwitcher } from "../../shared-components/SortSwitcher/Sortswitcher";
import { FilterKeysEnum } from "../../../types/enums/FilterKeys.enum";

import { AppStore } from "../../../store/AppStore";
import { TbWeight } from "react-icons/tb";
import { MdAttachMoney, MdDelete } from "react-icons/md";
import {
  currencyPrefixesMap,
  defaultCurrenciesPriorityList,
} from "../../../constants/currencies.constants";
import { CurrencyEnum } from "../../../types/enums/Currency.enum";
import { FaAngleUp } from "react-icons/fa";
import { translationMap } from "../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

import {
  MobilePostSortsPriceSelectionStyledWrapper,
  MobilePostSortsStyledWrapper,
  MobilePostSortsWithPriceSelectionStyledWrapper,
  MobileSidebarRowClearActionStyledButton,
  MobileSortCurrencyStyledButton,
  PostSortsStyledWrapper,
  SelectSortsStyledWrapper,
  SidebarRowClearActionStyledButton,
} from "./Sorts.styled";

interface SortsProps {
  filters: any;
  sorts: any;
  handleChangeSort: any;
  handleClearSorts: () => void;
  handleChangeFilters: (filters: any) => void;
  checkIsSortsApplied: () => boolean;
}

export const Sorts = observer((props: SortsProps): ReactElement => {
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { t } = useTranslation();

  const {
    sorts,
    filters,
    handleClearSorts,
    handleChangeSort,
    handleChangeFilters,
    checkIsSortsApplied,
  } = props;

  const isPriseSortDisabled = !filters[FilterKeysEnum.CURRENCY];
  const [isShowPricesSelect, setIsShowPricesSelect] = useState(false);

  const handleOpenCurrencySelect = () => {
    setIsShowPricesSelect(true);
  };

  if (isMobile()) {
    const handleCloseCurrencySelect = () => {
      setIsShowPricesSelect(false);
    };

    const handleSelectCurrency = (currency: CurrencyEnum) => {
      return () => {
        setIsShowPricesSelect(false);

        if (filters[FilterKeysEnum.CURRENCY] === currency) {
          return handleChangeFilters({
            ...filters,
            [FilterKeysEnum.CURRENCY]: "",
          });
        }

        handleChangeFilters({
          ...filters,
          [FilterKeysEnum.CURRENCY]: currency,
        });
      };
    };

    return (
      <MobilePostSortsWithPriceSelectionStyledWrapper
        isOpen={isShowPricesSelect}
      >
        <MobilePostSortsStyledWrapper>
          <div style={{ marginRight: 10 }}>
            <BiSortDown size={25} color={colors.orange} />
          </div>

          <SelectSortsStyledWrapper>
            <SortSwitcher
              value={sorts[SortKeysEnum.DATE] as any}
              title=""
              Icon={<BiCalendar size={22} />}
              onChange={handleChangeSort(SortKeysEnum.DATE)}
              isMobile
            />
          </SelectSortsStyledWrapper>

          <SelectSortsStyledWrapper>
            <SortSwitcher
              value={sorts[SortKeysEnum.AMOUNT] as any}
              title=""
              Icon={<TbWeight size={25} />}
              onChange={handleChangeSort(SortKeysEnum.AMOUNT)}
              isMobile
            />
          </SelectSortsStyledWrapper>

          <SelectSortsStyledWrapper onClick={handleOpenCurrencySelect}>
            <SortSwitcher
              value={sorts[SortKeysEnum.PRICE] as any}
              title=""
              Icon={<MdAttachMoney size={25} />}
              onChange={handleChangeSort(SortKeysEnum.PRICE)}
              isDisabled={isPriseSortDisabled}
              disablingReason="Select currency first"
              isMobile
            />
          </SelectSortsStyledWrapper>

          <MobileSidebarRowClearActionStyledButton onClick={handleClearSorts}>
            <MdDelete size={24} />
          </MobileSidebarRowClearActionStyledButton>
        </MobilePostSortsStyledWrapper>

        <MobilePostSortsPriceSelectionStyledWrapper isOpen={isShowPricesSelect}>
          <FaAngleUp size={26} onClick={handleCloseCurrencySelect} />
          <p style={{ marginRight: 5, marginLeft: 10 }}>
            {t(translationMap.filters.currencyFilter)}:{" "}
          </p>

          {defaultCurrenciesPriorityList.map((currency) => (
            <MobileSortCurrencyStyledButton
              key={currency}
              onClick={handleSelectCurrency(currency)}
              isSelected={filters?.currency === currency}
            >
              {currencyPrefixesMap[currency]}
            </MobileSortCurrencyStyledButton>
          ))}
        </MobilePostSortsPriceSelectionStyledWrapper>
      </MobilePostSortsWithPriceSelectionStyledWrapper>
    );
  }

  return (
    <PostSortsStyledWrapper>
      <BiSortDown size={26} color={colors.orange} />
      <div style={{ display: "flex", marginRight: 40, marginLeft: 3 }}>
        <p
          style={{
            fontSize: "18px",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(translationMap.sorts.sorts)}
        </p>
      </div>

      <SelectSortsStyledWrapper>
        <SortSwitcher
          value={sorts[SortKeysEnum.DATE] as any}
          title={t(translationMap.sorts.byDate)}
          onChange={handleChangeSort(SortKeysEnum.DATE)}
        />
      </SelectSortsStyledWrapper>

      <SelectSortsStyledWrapper>
        <SortSwitcher
          value={sorts[SortKeysEnum.AMOUNT] as any}
          title={t(translationMap.sorts.byQuantity)}
          onChange={handleChangeSort(SortKeysEnum.AMOUNT)}
        />
      </SelectSortsStyledWrapper>

      <SelectSortsStyledWrapper>
        <SortSwitcher
          value={sorts[SortKeysEnum.PRICE] as any}
          title={t(translationMap.sorts.byPrice)}
          onChange={handleChangeSort(SortKeysEnum.PRICE)}
          isDisabled={isPriseSortDisabled}
          disablingReason="Select currency first"
        />
      </SelectSortsStyledWrapper>

      <SidebarRowClearActionStyledButton
        onClick={handleClearSorts}
        disabled={!checkIsSortsApplied()}
      >
        {t(translationMap.buttons.clear)}
      </SidebarRowClearActionStyledButton>
    </PostSortsStyledWrapper>
  );
});
