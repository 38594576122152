import { CountriesEnum } from "../../types/enums/Countries.enum";

interface GetTranslatedStatesParamsInterface {
  states: any;
  countriesStates: any;
  country: CountriesEnum;
}

export const getTranslatedStates = (
  params: GetTranslatedStatesParamsInterface
) => {
  const { states, countriesStates, country } = params;
  const satatesByCountry = countriesStates?.[country || ""] || [];

  return states.map((state: any) => {
    return satatesByCountry?.find(({ isoCode }: any) => isoCode === state);
  });
};

interface GetStateNameByCodeParamsInterface {
  isoCode: string;
  countriesStates: any;
  country: CountriesEnum;
  language: any;
}
export const getStateNameByCode = ({
  isoCode,
  country,
  countriesStates,
  language,
}: GetStateNameByCodeParamsInterface) => {
  const satatesByCountry = countriesStates?.[country || ""] || [];

  return satatesByCountry?.find(({ isoCode: code }: any) => code === isoCode)
    ?.translation[language];
};
