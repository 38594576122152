import styled from "styled-components";

export const TradingAccountEditStyledWrapper = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  width: 100%;

  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
`;
