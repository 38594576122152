import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../store/UserStore";
import { PlansList } from "./PlansList";
import { PlansModalWrapper } from "./Plan.styled";
import { AppStore } from "../../../../store/AppStore";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { translationMap } from "../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

export const ChoosePlanModal = observer((): ReactElement => {
  const { isPlanModalOpen, setPlanModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const { t } = useTranslation();

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isPlanModalOpen}
        title={t(translationMap.payment.yourPlan)}
        onClose={setPlanModalOpenClose}
        Content={
          <div>
            <PlansList isMobile />
          </div>
        }
      />
    );
  }

  return (
    <PlansModalWrapper>
      <Modal
        title={t(translationMap.payment.yourPlan)}
        centered
        open={isPlanModalOpen}
        onCancel={setPlanModalOpenClose}
        footer={null}
        destroyOnClose
        width={700}
      >
        <PlansList />
      </Modal>
    </PlansModalWrapper>
  );
});
