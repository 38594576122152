import { useState } from "react";
import { checkIsErrorExist } from "../../utils/error-utils/checkIsErrorExist";
import { ConfirmationCodeErrorMessagesEnum } from "../../constants/error-messages/confirmation-error-messages";

export const useConfirmCode = () => {
  const [usedCode, setUsedCode] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);

  const handleChangeCode = (code: string) => {
    setCode(code);
  };

  const handleChangeUsedCode = (code: string) => {
    setUsedCode(code);
  };

  const handleChangeIsCodeValid = (isValid: boolean) => {
    setIsCodeInvalid(isValid);
  };

  const getCodeErrors = (errors: any) => {
    const isCodeInvalid = checkIsErrorExist(
      errors,
      ConfirmationCodeErrorMessagesEnum.CODE_IS_NOT_VALID
    );

    const isCodeExpired = checkIsErrorExist(
      errors,
      ConfirmationCodeErrorMessagesEnum.CODE_EXPIRED
    );

    return {
      isCodeInvalid,
      isCodeExpired,
    };
  };

  return {
    code,
    usedCode,
    isCodeInvalid,

    handleChangeCode,
    handleChangeUsedCode,
    handleChangeIsCodeValid,

    getCodeErrors,
  };
};
