import React, { ReactElement } from "react";

import { CountriesListStyledWrapper } from "./CountriesList.styled";
import { ValidationSelect } from "../ValisdationSelect/VaslidationSelect";
import { translationMap } from "../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { getCountriesOptions } from "../../../utils/options-utils/getCountriesOptions";
import { CountriesEnum } from "../../../types/enums/Countries.enum";

interface CountriesListProps {
  value?: string;
  isValid: boolean;
  isRequired?: boolean;
  style?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  showSearch?: boolean;
  onChange: (value: string) => void;
}

export const CountriesList = observer(
  (props: CountriesListProps): ReactElement => {
    const {
      value,
      isValid,
      isRequired = false,
      style = {},
      selectStyle = {},
      showSearch = false,
      onChange,
    } = props;

    const { t } = useTranslation();

    const { countries = [], language } = useStore(
      StoresEnum.APP_STORE
    ) as AppStore;

    const countryOptions = getCountriesOptions([...countries], language).filter(
      (countryOption: any) => {
        return countryOption.value !== CountriesEnum.PL;
      }
    );

    return (
      <ValidationSelect
        showSearch={showSearch}
        placeholder={t(translationMap.post.selectCountry)}
        isValid={isValid}
        value={value || undefined}
        isRequired={isRequired}
        onChange={onChange}
        style={style}
        selectStyle={selectStyle}
        options={countryOptions}
      />
    );
  }
);
