import React, { ReactElement } from "react";

import { TelegramModalStyledWrapper } from "./TelegramModal.styled";
import { Modal } from "antd";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { AgrobridgesToTelegram } from "../../../../pages/BotAuth/BotAuth";
import { colors } from "../../../../constants/styles/colors.constants";
import { observer } from "mobx-react-lite";

interface TelegramModalProps {}

export const TelegramModal = observer(
  (props: TelegramModalProps): ReactElement => {
    const { isTelegramModalUp, setIsTelegramModalUp } = useStore(
      StoresEnum.APP_STORE
    ) as AppStore;

    const toggleModal = () => setIsTelegramModalUp(false);

    return (
      <Modal
        title=""
        open={isTelegramModalUp}
        onOk={toggleModal}
        onCancel={toggleModal}
        centered
        footer={null}
      >
        <TelegramModalStyledWrapper>
          <AgrobridgesToTelegram
            bridgeLogoBorder={`2px solid ${colors.darkGrey}`}
            tgIconColor="#1890ff"
          />
          <div
            style={{
              fontSize: 18,
              fontWeight: 600,
              marginBottom: 30,
              textAlign: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: 400 }}>Agro</span>bridges з Вами у
              Telegram!
            </p>
            <p>Отримуйте оголошення першими у чат-боті 🌽</p>
          </div>

          <div>
            <a
              href={`https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?start=auth`}
              target="_blank"
              rel="noreferrer"
              onClick={toggleModal}
              style={{
                fontSize: 18,
                color: "#fff",
                textDecoration: "none",
                borderRadius: 5,
                padding: "8px 25px",
                border: "2px solid #1890ff",
                backgroundColor: "#1890ff",
                fontWeight: 600,
              }}
            >
              Активувати бота
            </a>
          </div>
        </TelegramModalStyledWrapper>
      </Modal>
    );
  }
);
