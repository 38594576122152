import React, { ReactElement } from "react";
import { Radio } from "antd";
import { BsCashCoin, BsFillCreditCard2BackFill } from "react-icons/bs";
import { BiHide } from "react-icons/bi";

import { PaymentTypeRadioButton } from "./PaymentTypeRadioButton/PaymentTypeRadioButton";

import { PaymentTypesEnum } from "../../../../../../../types/enums/PaymentTypes.enum";

import { PaymentTypeStyledWrapper } from "./PaymentType.styled";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../../../i18n/translationMap";

interface PaymentTypeProps {
  paymentType?: PaymentTypesEnum;
  isValid: boolean;
  onChange: (value: any) => void;
}

export const PaymentType = (props: PaymentTypeProps): ReactElement => {
  const { paymentType, onChange, isValid } = props;

  const { t } = useTranslation();

  return (
    <PaymentTypeStyledWrapper>
      <PaymentTypeRadioButton
        value={PaymentTypesEnum.CASH}
        label={t(translationMap.post.cash)}
        IconSlot={<BsCashCoin />}
        isSelected={paymentType === PaymentTypesEnum.CASH}
        onClick={() => onChange(PaymentTypesEnum.CASH)}
      />
      <PaymentTypeRadioButton
        value={PaymentTypesEnum.CARD}
        label={t(translationMap.post.card)}
        IconSlot={<BsFillCreditCard2BackFill />}
        isSelected={paymentType === PaymentTypesEnum.CARD}
        onClick={() => onChange(PaymentTypesEnum.CARD)}
      />
      <PaymentTypeRadioButton
        value={PaymentTypesEnum.ANY}
        label={t(translationMap.post.any)}
        IconSlot={
          <>
            <BsCashCoin /> <BsFillCreditCard2BackFill />
          </>
        }
        isSelected={paymentType === PaymentTypesEnum.ANY}
        onClick={() => onChange(PaymentTypesEnum.ANY)}
      />
      <PaymentTypeRadioButton
        value={PaymentTypesEnum.HIDDEN}
        label={t(translationMap.post.hidden)}
        IconSlot={<BiHide />}
        isSelected={paymentType === PaymentTypesEnum.HIDDEN}
        onClick={() => onChange(PaymentTypesEnum.HIDDEN)}
      />
    </PaymentTypeStyledWrapper>
  );
};
