import styled from "styled-components";

export const TermsOfUseStyledWrapper = styled.div`
  color: gray;
  text-align: right;
  margin-top: 10px;
  display: flex;
`;

export const ActionsBarStyledWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
