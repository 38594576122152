import axios, { AxiosResponse } from "axios";

import { localStorageUtils } from "../../utils/browser-utils/LocalStorage";
import { BASE_URL } from "./../../config/endpoints";

interface HttpClientInterface {
  get(path: string): Promise<AxiosResponse>;
  post(path: string, params?: Object): Promise<AxiosResponse>;
  put(path: string, params?: Object): Promise<AxiosResponse>;
  patch(path: string, params?: Object): Promise<AxiosResponse>;
  delete(path: string, params?: Object): Promise<AxiosResponse>;
}

export class HttpClient implements HttpClientInterface {
  async get(path: string): Promise<any> {
    try {
      const response = await fetch(`${BASE_URL}/${path}`, {
        headers: new Headers({ ...this.getAuthHeaders() }),
      });
      const parsedResponse = await response.json();

      return parsedResponse;
    } catch (error) {
      //
    }
  }

  async post(path: string, params: any, isFormData = false): Promise<any> {
    const response = await fetch(`${BASE_URL}/${path}`, {
      method: "POST",
      headers: new Headers({ ...this.getAuthHeaders(isFormData) }),
      body: isFormData ? params : JSON.stringify({ ...params }),
    });
    const parsedResponse = await response.json();

    return parsedResponse;
  }

  async put(path: string, params: any, isFormData = false): Promise<any> {
    const response = await fetch(`${BASE_URL}/${path}`, {
      method: "PUT",
      headers: new Headers({ ...this.getAuthHeaders(isFormData) }),
      body: isFormData ? params : JSON.stringify({ ...params }),
    });
    const parsedResponse = await response.json();

    return parsedResponse;
  }

  async patch(path: string, params: Object): Promise<any> {
    const response = await fetch(`${BASE_URL}/${path}`, {
      headers: new Headers({ ...this.getAuthHeaders() }),
      body: JSON.stringify(params),
    });
    const parsedResponse = await response.json();

    return parsedResponse;
  }

  async delete(path: string, params?: Object): Promise<any> {
    const response = await fetch(`${BASE_URL}/${path}`, {
      headers: { ...this.getAuthHeaders() },
    });
    const parsedResponse = await response.json();

    return parsedResponse;
  }

  private getAuthHeaders(isFormData = false): any {
    const headers: any = {
      Authorization: `Basic ${localStorageUtils.getToken()}`,
      Accept: "application.json",
    };

    if (!isFormData) {
      headers["Content-Type"] = "application/json";
    }

    return headers;
  }
}

const httpClient = new HttpClient();

export { httpClient };
