import React, { ReactElement } from "react";

import { ValidationSelect } from "../ValisdationSelect/VaslidationSelect";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../i18n/translationMap";
import { getCerealCropsOptions } from "../../../utils/options-utils/getCeralCropsOptions";
import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../store/AppStore";
import { observer } from "mobx-react-lite";

interface CerealsListProps {
  value?: string;
  isValid: boolean;
  isRequired?: boolean;
  style?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  onChange: (value: string) => void;
}

export const CerealsList = observer((props: CerealsListProps): ReactElement => {
  const {
    value,
    isValid,
    isRequired = false,
    style = {},
    selectStyle = {},
    onChange,
  } = props;

  const { cerealCrops, language } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { t } = useTranslation();

  const cerealCropOptions = getCerealCropsOptions(cerealCrops, language);

  return (
    <ValidationSelect
      value={value || undefined}
      placeholder={t(translationMap.post.selectCrop)}
      onChange={onChange}
      isValid={isValid}
      style={style}
      selectStyle={selectStyle}
      options={cerealCropOptions}
      isRequired={isRequired}
    />
  );
});
