import { observer } from "mobx-react-lite";
import { Page } from "../components/shared-components/Page/Page";
import { StoresEnum, useStore } from "../hooks/use-store-hook/useStore";
import { AppStore } from "../store/AppStore";
import { LanguagesEnum } from "../types/enums/Languages.enum";
import { translationMap } from "../i18n/translationMap";
import { useTranslation } from "react-i18next";

const generalTitle = {
  [LanguagesEnum.UK]: "1. Загальні положення",
  [LanguagesEnum.EN]: "1. General provisions",
  [LanguagesEnum.PL]: "1. Ogólne postanowienia",
};
const general = {
  [LanguagesEnum.UK]: [
    "1.1. Веб — сайт agrobridges.com (також по тексту - “сайт”) рішуче налаштований на захист персональних даних і дотримання права на конфіденційність відвідувачів сайту (далі по тексту — Користувачів чи Користувача).",
    "1.2. У цій Політиці про конфіденційність описано, як сайт збирає та обробляє персональні дані Користувачів, а також надано огляд прав щодо персональних даних.",
    "1.3. Дана Політика стосується інформації, яку Користувачі можуть надати сайту безпосередньо через Обліковий запис або через треті сторони.",
    "1.4. Обробка здійснюється на підставі українського та польського законодавства, законів Європейського Співтовариства. , зокрема Регламент (ЄС) 2016/679 Європейського Парламенту та Ради від 27 квітня 2016 року про захист фізичних осіб щодо обробки персональних даних і про вільний рух таких даних, а також про Директива 95/ 46/EC (Загальний регламент захисту даних, надалі «GDPR»).",
    `1.5. Обробка даних здійснюється на таких правових підставах:
    - згода Користувача (стаття 6(1)(a) GDPR),
    - укладений договір про надання послуг або дії, вжиті Користувачем, спрямовані на укладення такого договору (стаття 6(1)(b) GDPR),
    - Законний інтерес адміністратора щодо запобігання недобросовісним діям користувачів веб-сайту (стаття 6(1)(f) GDPR),
    - Законний інтерес адміністратора щодо обробки з метою прямого маркетингу (стаття 6(1)(f) GDPR)
    `,
  ],
  [LanguagesEnum.PL]: [
    '1.1. Strona internetowa agrobridges.com (dalej nazywana "Stroną") jest zdecydowanie nastawiona na ochronę danych osobowych i przestrzeganie prawa do prywatności odwiedzających Stronę (dalej nazywanych "Użytkownikami" lub "Użytkownikiem").',
    "1.2. W niniejszej Polityce prywatności opisano, jak Strona zbiera i przetwarza dane osobowe Użytkowników oraz przedstawiono przegląd praw dotyczących danych osobowych.",
    "1.3. Niniejsza Polityka dotyczy informacji, które Użytkownicy mogą udostępniać Stronie bezpośrednio za pośrednictwem Konta lub za pośrednictwem stron trzecich.",
    '1.4. Administratorem danych osobowych (dalej nazywanym "Administratorem") użytkowników strony agrobridges jest firma agrobridges.com. Obecny adres e-mail jest dostępny na stronie głównej strony w sekcji "Kontakt".',
    '1.5. Przetwarzanie danych odbywa się na podstawie prawa ukraińskiego i polskiego, prawa Unii Europejskiej, w szczególności Rozporządzenia (UE) 2016/679 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz Dyrektywy 95/46/WE (Ogólne rozporządzenie o ochronie danych, dalej "RODO").',
    `
    1.6. Przetwarzanie danych odbywa się na następujących podstawach prawnych:
    zgoda Użytkownika (art. 6 ust. 1 lit. a RODO),
    zawarta umowa o świadczenie usług lub działania podjęte przez Użytkownika mające na celu zawarcie takiej umowy (art. 6 ust. 1 lit. b RODO),
    Prawidłowe interesy Administratora związane z zapobieganiem nieuczciwym działaniom użytkowników strony internetowej (art. 6 ust. 1 lit. f RODO),
    Prawidłowe interesy Administratora związane z przetwarzaniem w celach marketingu bezpośredniego (art. 6 ust. 1 lit. f RODO).    
    `,
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const usageTitle = {
  [LanguagesEnum.UK]: "2. Застосування цієї Політики",
  [LanguagesEnum.PL]: "2. Zastosowanie niniejszej Polityki",
  [LanguagesEnum.EN]: "2. Application of this Policy",
};
const usage = {
  [LanguagesEnum.UK]: [
    "2.1. Персональні дані, зібрані через веб — сайт, а також через пов’язані служби та інструменти, які використовуються Користувачем для виконання таких дій, як реєстрація, перегляд повних списків оголошень, розміщення реклами, зв’язок із вибраними потенційними діловими партнерами.",
    "2.2. Адміністратор самостійно та під свою відповідальність визначає цілі та засоби обробки персональних даних Користувачів, зокрема для цілей, пов’язаних з укладенням нашої угоди, законні інтереси Адміністратора, пов’язані з виконанням цієї угоди та наданням Послуг в електронному вигляді:",
    "2.3.. Користувач надає наступні персональні дані: дані користувача, необхідні для створення облікового запису користувача та початку надання Послуг, включаючи принаймні ім’я користувача та адресу електронної пошти, номер мобільного телефону.",
    "2.4.. Надання персональних даних є добровільним, однак ненадання унеможливлює початок використання Послуг, що надаються в електронному вигляді. ",
    "2.5. Обробка даних залишається законною, доки Користувач не відкличе свою згоду.",
  ],
  [LanguagesEnum.PL]: [
    "2.1. Dane osobowe gromadzone za pośrednictwem Strony internetowej oraz za pośrednictwem powiązanych usług i narzędzi, które Użytkownik używa do wykonania takich czynności, jak rejestracja, przeglądanie pełnych list ogłoszeń, umieszczanie reklam, kontaktowanie się z wybranymi potencjalnymi partnerami biznesowymi.",
    "2.2. Administrator samodzielnie i na własną odpowiedzialność okreśa cele i środki przetwarzania danych osobowych Użytkowników, w tym w celu realizacji naszej umowy, zgodnie z prawidłowymi interesami Administratora związanymi z realizacją tej umowy i świadczeniem Usług w formie elektronicznej.",
    "2.3. Użytkownik udostępnia następujące dane osobowe: dane użytkownika niezbędne do utworzenia konta użytkownika i rozpoczęcia świadczenia Usług, w tym przynajmniej imię użytkownika, adres e-mail, numer telefonu komórkowego.",
    "2.4. Podanie danych osobowych jest dobrowolne, jednak ich niepodanie uniemożliwia rozpoczęcie korzystania z Usług świadczonych w formie elektronicznej.",
    "2.5. Przetwarzanie danych pozostaje legalne do momentu wycofania przez Użytkownika zgody.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const personalDataTtile = {
  [LanguagesEnum.UK]: "3. Персональні дані, які обробляємо",
  [LanguagesEnum.PL]: "3. Dane osobowe, które przetwarzamy",
  [LanguagesEnum.EN]: "3. Personal data we process",
};
const personalData = {
  [LanguagesEnum.UK]: [
    "3.1. Окрім персональних даних, необхідних для створення Облікового запису Користувача та користування Послугами, Адміністратор, щоб надавати Послуги в електронному вигляді, може обробляти додаткові персональні дані, надані Користувачем для цієї мети, включаючи його прізвище, ідентифікаційні дані Користувача компанія, адреса, номер телефону, ідентифікаційний номер платника податків, дані Національного офіційного реєстру суб'єктів господарювання.",
    "3.2. Крім того, у зв’язку з технічними умовами, пов’язаними з використанням Послуг, що надаються Користувачем в електронному вигляді, Адміністратор може обробляти дані про Користувачів, включаючи персональні дані, що стосуються географічного розташування пристрою Користувача, технічні дані про пристрої, які використовуються для отримати доступ до облікового запису, даних підключення до Інтернету або мережі (включаючи IP-адресу), ідентифікатора мобільного пристрою, типу операційної системи, типу браузера чи іншого програмного забезпечення, пов’язаних з апаратним забезпеченням чи інших технічних даних, наданих браузером, даних вимірювань про те, як і коли Обліковий запис використовується, вибрана історія рекламних оголошень і посилань, налаштування та вибір, наприклад, підписки, бажана мова та валюта.",
    "3.3. Кожна особа, персональні дані якої обробляються Адміністратором, має право на доступ до змісту своїх даних, а також право виправляти, доповнювати, оновлювати, передавати, виправляти та заперечувати проти обробки персональних даних. Для цього ви повинні зв’язатися з Адміністратором, використовуючи контактну інформацію, наведену на сайті.",
  ],
  [LanguagesEnum.PL]: [
    "3.1. Oprócz danych osobowych niezbędnych do utworzenia Konta Użytkownika i korzystania z Usług, Administrator w celu świadczenia Usług w formie elektronicznej może przetwarzać dodatkowe dane osobowe udostępnione przez Użytkownika w tym celu, w tym jego nazwisko, dane identyfikacyjne użytkownika firmowego, adres, numer telefonu, numer identyfikacyjny podatnika, dane z Krajowego Rejestru Sądowego.",
    "3.2. Ponadto, związane z warunkami technicznymi związanymi z korzystaniem z Usług świadczonych przez Użytkownika w formie elektronicznej, Administrator może przetwarzać dane na temat. Użytkowników, w tym dane osobowe dotyczące geolokalizacji urządzenia Użytkownika, dane techniczne dotyczące urządzeń używanych do uzyskania dostępu do konta, dane połączenia z Internetem lub siecią (w tym adres IP), identyfikator urządzenia mobilnego, typ systemu operacyjnego, typ przeglądarki lub innego oprogramowania związane z oprogramowaniem sprzętowym lub innymi danymi technicznymi dostarczonymi przez przeglądarkę, dane dotyczące pomiarów dotyczące tego, jak i kiedy używane jest Konto, historia wybranych reklam i linków, preferencje i wybory, takie jak subskrypcje, preferowany język i waluta.",
    "3.3. Każda osoba, której dane osobowe są przetwarzane przez Administratora, ma prawo dostępu do treści swoich danych, a także prawo do poprawiania, uzupełniania, aktualizacji, przesyłania, poprawiania i sprzeciwu wobec przetwarzania danych osobowych. W tym celu należy skontaktować się z Administratorem, korzystając z danych kontaktowych podanych na stronie.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const cookieFilesTitle = {
  [LanguagesEnum.UK]: "4. Файли cookie",
  [LanguagesEnum.PL]: "4. Pliki cookie",
  [LanguagesEnum.EN]: "4. Cookies",
};
const cookieFiles = {
  [LanguagesEnum.UK]: [
    "4.1 Щоб використовувати сайт, Користувач повинен дати згоду на зберігання файлів cookie на пристрої Користувача або інших еквівалентних технологій в обсязі, необхідному для забезпечення роботи основних функцій . Користувач також може погодитися або заперечити проти використання та зберігання файлів cookie Адміністратором на пристрої Користувача та обробки персональних даних Користувача в більшому обсязі, ніж це необхідно, зокрема для статистичних і маркетингових цілей або для запису уподобань Користувача. Користувач дає свою згоду, вибравши відповідні параметри у вікні налаштувань файлів cookie на сайті.",
  ],
  [LanguagesEnum.PL]: [
    "4.1. Aby korzystać z witryny, Użytkownik musi wyrazić zgodę na przechowywanie plików cookie na urządzeniu Użytkownika lub innych równoważnych technologii w zakresie niezbędnym do zapewnienia działania podstawowych funkcji. Użytkownik może również wyrazić zgodę lub wyrazić sprzeciw wobec wykorzystywania i przechowywania przez Administratora plików cookie na urządzeniu Użytkownika oraz przetwarzania danych osobowych Użytkownika w większym zakresie, niż jest to niezbędne, w szczególności do celów statystycznych i marketingowych lub do zapisywania preferencji Użytkownika. Użytkownik wyraża zgodę, wybierając odpowiednie parametry w oknie ustawień plików cookie na stronie.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const showDataTitle = {
  [LanguagesEnum.UK]: "5. Розкриття  персональних даних",
  [LanguagesEnum.PL]: "5. Ujawnienie danych osobowych",
  [LanguagesEnum.EN]: "5. Disclosure of personal data",
};
const showData = {
  [LanguagesEnum.UK]: [
    "5.1. З метою виконання договору та правильного виконання замовлення Адміністратор може надавати персональні дані одержувачам даних, зокрема поштовому оператору, кур’єрській компанії чи платіжному оператору, суб’єктам, що надають бухгалтерські та юридичні послуги, маркетинговим агентствам (у межах маркетингових послуг). ) та іншим особам, що забезпечують функціонування сайту та сприяють веденню бізнесу Адміністратора сайту. В іншому обсязі персональні дані можуть бути розкриті лише у випадках, передбачених законом, або за отриманням згоди.",
    "5.2. Адміністратор залишає за собою право розкривати інформацію про Користувача відповідним установам або третім особам, які вимагатимуть таку інформацію в межах чинного законодавства, а також для встановлення, розслідування та захисту від пред'явлених претензій.",
  ],
  [LanguagesEnum.PL]: [
    "5.1. W celu wykonania umowy i prawidłowego realizacji zamówienia Administrator może udostępniać dane osobowe odbiorcom danych, w tym operatorowi pocztowemu, firmie kurierskiej lub operatorowi płatności, podmiotom świadczącym usługi księgowe i prawne, agencjom marketingowym (w ramach usług marketingowych) i innym osobom, które zapewniają funkcjonowanie witryny i wspierają działalność Administratora witryny. W innych przypadkach dane osobowe mogą być ujawniane jedynie w przypadkach przewidzianych prawem lub po uzyskaniu zgody.",
    "5.2. Administrator zastrzega sobie prawo do ujawnienia informacji o Użytkowniku odpowiednim organom lub osobom trzecim, które będą żądać takchą informacji zgodnie z obowiązującym prawem oraz w celu ustalenia, ścigania i ochrony przed zgłoszonymi roszczeniami.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const transferDataTitle = {
  [LanguagesEnum.UK]: "6. Передача  персональних даних",
  [LanguagesEnum.PL]: "6. Przekazywanie danych osobowych",
  [LanguagesEnum.EN]: "6. Transfer of personal data",
};
const transferData = {
  [LanguagesEnum.UK]: [
    "6.1. Адміністратор зберігає та обробляє персональні дані на серверах, розташованих за адресою: agrobridges-api.pp.ua",
    "6.2. Адміністратор має право передавати персональні дані Користувача у треті країни, які забезпечують відповідний рівень захисту відповідно до стандартів захисту даних ЄС або де існують відповідні гарантії захисту  персональних даних.",
  ],
  [LanguagesEnum.PL]: [
    "6.1. Administrator przechowuje i przetwarza dane osobowe na serwerach.",
    "6.2. Administrator ma prawo przekazywać dane osobowe Użytkownika do państw trzecich, które zapewniają odpowiedni poziom ochrony zgodnie ze standardami ochrony danych UE lub w przypadku istnienia odpowiednich gwarancji ochrony danych osobowych.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const saveDataTitle = {
  [LanguagesEnum.UK]: "7. Збереження  персональних даних",
  [LanguagesEnum.PL]: "7. Przechowywanie danych osobowych",
  [LanguagesEnum.EN]: "7. Storage of personal data",
};
const saveData = {
  [LanguagesEnum.UK]: [
    "7.1. Щоб визначити відповідний період зберігання персональних даних, Адміністратор враховує обсяг, характер і конфіденційність персональних даних, потенційний ризик заподіяння шкоди від несанкціонованого використання або розкриття  персональних даних, цілі, для яких обробляє персональні дані, і те, чи може досягти цих цілей за допомогою інших засобів і відповідних юридичних вимог.",
    "7.2. Персональні дані Користувача обробляються протягом усього часу існування Облікового запису та видаляються у разі надходження запиту на видалення персональних даних Користувача на протязі місяця з дати отримання такого запиту. Період обробки даних може бути продовжений, якщо обробка даних необхідна для встановлення, розслідування або захисту від претензій у судовому, адміністративному чи іншому несудовому порядку або за запитом компетентних державних установ, а після цього періоду лише в обсязі та в випадках, передбачених правовими нормами. Після закінчення цього терміну дані безповоротно видаляються.",
  ],
  [LanguagesEnum.PL]: [
    "7.1. W celu określenia odpowiedniego okresu przechowywania danych osobowych Administrator uwzględnia zakres, charakter i poufność danych osobowych, potencjalne ryzyko szkody wynikającej z nieautoryzowanego wykorzystania lub ujawnienia danych osobowych, cele, dla których dane osobowe są przetwarzane, oraz możliwość osiągnięcia tych celów za pomocą innych środków i odpowiednich wymogów prawnych.",
    "7.2. Dane osobowe Użytkownika są przetwarzane przez cały okres istnienia Konta i są usuwane w przypadku otrzymania żądania usunięcia danych osobowych Użytkownika w ciągu miesiąca od daty otrzymania takiego żądania. Okres przetwarzania danych może być przedłużony, jeżeli przetwarzanie danych jest niezbędne do ustalenia, dochodzenia lub obrony roszczeń w postępowaniu sądowym, administracyjnym lub innym postępowaniu pozasądowym lub na żądanie odpowiednich organów państwowych, a po tym okresie jedynie w zakresie i w przypadkach przewidzianych przez prawo.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const dataSecurityTitle = {
  [LanguagesEnum.UK]: "8. Безпека персональних даних",
  [LanguagesEnum.PL]: "8. Bezpieczeństwo danych osobowych",
  [LanguagesEnum.EN]: "8. Security of personal data",
};
const dataSecurity = {
  [LanguagesEnum.UK]: [
    "8.1. Персональні дані Користувача обробляються конфіденційно, Адміністратор вживає належних технічних та організаційних заходів безпеки, щоб захистити персональні дані від втрати, неправильного використання, зміни та/або знищення. До них належать, але не обмежуються:",
    "•	Встановлення брандмауера для запобігання несанкціонованому доступу до  систем;",
    "•	Використання захищених серверів;",
    "•	Забезпечення зберігання персональних даних у фізично безпечному приміщенні.",
    "8.2. Заходи безпеки, зазначені вище, застосовуються до систем, які знаходяться під контролем Адміністратора сайту, але не гарантує безпеку та конфіденційність будь-якої інформації під час її передачі, коли вона передається засобами, які не знаходяться під  , зокрема через Інтернет, і що ви усвідомлюючи, що дані, передані за допомогою таких засобів, можуть бути перехоплені третіми особами.",
    "8.3. Якщо Адміністратору стане відомо про будь-яке порушення безпеки, яке призведе до несанкціонованого доступу до ваших персональних даних, докладе зусиль, щоб повідомити вас.",
    "8.4. Веб-сайт може надавати гіперпосилання на веб-сайти інших компаній або осіб. Ця політика не поширюється на використання таких веб-сайтів, і Адміністратор не несе відповідальності за будь-яку обробку персональних даних Користувачів через ці сторонні веб-сайти.",
    "8.5. Крім того, якщо Користувач ділиться вмістом із веб-сайту через соціальні мережі,  особисті дані будуть видимі для тих, хто відвідує особисті сторінки Користувача в цих соціальних мережах. Адміністратор не несе відповідальності за будь-яку обробку персональних даних через такі носії. Ця політика не поширюється на використання таких соціальних мереж.",
  ],
  [LanguagesEnum.PL]: [
    `
    8.1. Dane osobowe Użytkownika są przetwarzane poufnie, Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę danych osobowych przed utratą, nieuprawnionym wykorzystaniem, zmianą lub zniszczeniem. Obejmują one, ale nie są ograniczone do:
    - Ustanowienie zapory ogniowej w celu zapobieżenia nieautoryzowanemu dostępowi do systemów;
    - Używanie zabezpieczonych serwerów;
    - Zapewnienie przechowywania danych osobowych w fizycznie bezpiecznych pomieszczeniach.    
    `,
    "8.2. Środki bezpieczeństwa wymienione powyżej stosuje się do systemów znajdujących się pod kontrolą Administratora witryny, ale nie gwarantują one bezpieczeństwa i poufności jakiejkolwiek informacji podczas jej przesyłania, gdy jest przekazywana za pomocą środków niezależnych od Administratora, w szczególności przez Internet, i należy mieć świadomość, że dane przekazane za pomocą takich środków mogą być przechwycone przez osoby trzecie.",
    "8.3. Jeśli Administrator dowiaduje się o jakimkolwiek naruszeniu bezpieczeństwa, które prowadzi do nieautoryzowanego dostępu do Twoich danych osobowych, podejmie odpowiednie działania w celu powiadomienia Cię.",
    "8.4. Witryna może zawierać hiperłącza do witryn innych firm lub osób. Niniejsza polityka nie obejmuje korzystania z tych witryn, i Administrator nie ponosi odpowiedzialności za przetwarzanie danych osobowych Użytkowników przez te strony trzecie.",
    "8.5. Ponadto, jeśli Użytkownik udostępnia treści za pośrednictwem witryny poprzez sieci społecznościowe, jego dane osobowe będą widoczne dla osób odwiedzających jego osobiste strony na tych platformach społecznościowych. Administrator nie ponosi odpowiedzialności za przetwarzanie danych osobowych przez takie platformy. Niniejsza polityka nie obejmuje korzystania z tych sieci społecznościowych.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const userHaveRightSecurityTitle = {
  [LanguagesEnum.UK]: "9. Права Користувача",
  [LanguagesEnum.PL]: "9. Prawa Użytkownika",
  [LanguagesEnum.EN]: "9. User rights",
};
const userHaveRightSecurity = {
  [LanguagesEnum.UK]: [
    "9.1. Користувач має право:",
    "9.1.1 Право знати, що персональні дані обробляються, як використовуються персональні дані та права щодо персональних даних.",
    "9.1.2 Право з’ясувати, який тип персональних даних зберігає сайт, а також отримати копію цих персональних даних.",
    "9.1.3 Право на оновлення або виправлення будь-яких неточних персональних даних, які зберігаються.",
    "9.1.4 Вимагати видалення персональних даних, які зберігаються.",
    "9.1.5 Право вимагати, щоб припинили використання  персональних даних.",
    "9.1.6 Право заперечити проти такого використання персональних. чий законний інтерес у продовженні обробки.",
    "9.2 Користувач можете скористатися будь-яким із цих прав відповідно надіславши запит на agrobridgess@gmail.com",
  ],
  [LanguagesEnum.PL]: [
    "9.1. Użytkownik ma prawo:",
    "9.1.1 Prawo do informacji - prawo do wiedzy, że dane osobowe są przetwarzane, w jaki sposób są wykorzystywane i jakie prawa przysługują w odniesieniu do danych osobowych.",
    "9.1.2 Prawo dostępu - prawo do sprawdzenia, jakie rodzaje danych osobowych przechowuje witryna oraz otrzymania kopii tych danych osobowych.",
    "9.1.3 Prawo do poprawiania - prawo do aktualizacji lub poprawienia jakichkolwiek nieprawidłowych danych osobowych przechowywanych.",
    "9.1.4 Prawo do usunięcia - prawo do żądania usunięcia przechowywanych danych osobowych Prawo do ograniczenia przetwarzania - prawo do żądania zaprzestania wykorzystywania danych osobowych.",
    "9.1.5 Prawo do sprzeciwu - prawo do sprzeciwienia się przetwarzaniu danych osobowych, gdy istnieje uzasadniony interes prawny związany z dalszym przetwarzaniem.",
    "9.2. Użytkownik może skorzystać z dowolnego z tych praw, przesyłając odpowiednie żądanie na adres agrobridgess@gmail.com.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const changesToThisPolicyTitle = {
  [LanguagesEnum.UK]: "10. Зміни до цієї Політики",
  [LanguagesEnum.PL]: "10. Zmiany w Polityce",
  [LanguagesEnum.EN]: "10. Changes to this Policy",
};
const changesToThisPolicy = {
  [LanguagesEnum.UK]: [
    "10.1.Адміністратор може будь-коли змінити цю Політику конфіденційності. Користувач зобов'язаний самостійно регулярно переглядати цю Політику, щоб бути в курсі будь-яких змін.",
  ],
  [LanguagesEnum.PL]: [
    "10.1. Administrator może w dowolnym momencie zmienić niniejszą Politykę prywatności. Użytkownik jest zobowiązany regularnie sprawdzać tę Politykę, aby być świadomym wszelkich zmian.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

const questionsAnsWishesTitle = {
  [LanguagesEnum.UK]: "11. Запитання та побажання",
  [LanguagesEnum.PL]: "11. Pytania i sugestie",
  [LanguagesEnum.EN]: "11. Questions and wishes",
};
const questionsAnsWishes = {
  [LanguagesEnum.UK]: [
    "Якщо у вас виникнуть запитання чи запити щодо цієї Політики, зв’яжіться з Адміністратором agrobridgess@gmail.com.",
  ],
  [LanguagesEnum.PL]: [
    "Jeśli masz jakiekolwiek pytania lub sugestie dotyczące tej Polityki, skontaktuj się z Administratorem pod adresem agrobridgess@gmail.com.",
  ],
  [LanguagesEnum.EN]: ["Translation in progress..."],
};

export const PrivacyPolicyPage = observer(() => {
  const { language } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { t } = useTranslation();

  return (
    <Page>
      <div
        style={{
          marginTop: 30,
          padding: 20,
          backgroundColor: "white",
          borderRadius: 10,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          marginBottom: 20,
        }}
      >
        <h1 style={{ fontSize: 30, fontWeight: 600 }}>
          {t(translationMap.menu.privacyPolicy)}
        </h1>
        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {generalTitle[language]}
        </h2>
        {general[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}
        <h2 style={{ fontSize: 26, marginTop: 30 }}>{usageTitle[language]}</h2>
        {usage[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}
        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {personalDataTtile[language]}
        </h2>
        {personalData[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {cookieFilesTitle[language]}
        </h2>
        {cookieFiles[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {showDataTitle[language]}
        </h2>
        {showData[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {transferDataTitle[language]}
        </h2>
        {transferData[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {saveDataTitle[language]}
        </h2>
        {saveData[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {dataSecurityTitle[language]}
        </h2>
        {dataSecurity[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {userHaveRightSecurityTitle[language]}
        </h2>

        {userHaveRightSecurity[language].map((item) => (
          <p
            style={{
              lineHeight: 1.5,
              margin: "10px 0",
              fontSize: 16,
              textAlign: "justify",
            }}
          >
            {item}
          </p>
        ))}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {changesToThisPolicyTitle[language]}
        </h2>
        {changesToThisPolicy[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}

        <h2 style={{ fontSize: 26, marginTop: 30 }}>
          {questionsAnsWishesTitle[language]}
        </h2>
        {questionsAnsWishes[language].map((item) => {
          return (
            <p
              style={{
                lineHeight: 1.5,
                margin: "10px 0",
                fontSize: 16,
                textAlign: "justify",
              }}
            >
              {item}
            </p>
          );
        })}
      </div>
    </Page>
  );
});
