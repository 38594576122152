export const PolandFlagIcon = () => {
  return (
    <svg
      width="26"
      height="17"
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ borderRadius: 2 }}
    >
      <g clip-path="url(#clip0_3_2372)">
        <path d="M0 17H26V1.51418e-06H0V17Z" fill="white" />
        <path d="M0 17H26V8.49998H0V17Z" fill="#C70000" />
      </g>
      <defs>
        <clipPath id="clip0_3_2372">
          <rect
            width="26"
            height="17"
            fill="white"
            transform="matrix(1 0 0 -1 0 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
