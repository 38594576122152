import React, { ReactElement } from "react";

import { SuggestedActionStyledWrapper } from "./SuggestedActionButton.styled";

interface SuggestedActionButtonProps {
  title: string;
  onClick: () => void;
}

export const SuggestedActionButton = (
  props: SuggestedActionButtonProps
): ReactElement => {
  const { title, onClick } = props;

  return (
    <SuggestedActionStyledWrapper onClick={onClick}>
      {title}
    </SuggestedActionStyledWrapper>
  );
};
