import React, { ReactElement } from "react";

export const ContactsTitle = ({
  marginTop,
  title,
  Icon,
}: any): ReactElement => {
  return (
    <p
      style={{
        fontSize: 18,
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        marginBottom: 5,
        marginTop: marginTop !== undefined ? marginTop : 10,
      }}
    >
      {Boolean(Icon) && <div style={{ marginRight: 5 }}>{Icon}</div>}
      <p>{title}</p>
    </p>
  );
};
