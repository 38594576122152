import React, { ReactElement, useEffect } from "react";

import { Page } from "../../components/shared-components/Page/Page";

import {
  BotAuthStyledWrapper,
  IconsStyledWrapper,
  TelegramBotAuthStyledWrapper,
} from "./BotAuth.styled";
import { BsTelegram } from "react-icons/bs";
import { LogoShort } from "../../components/composit-components/app-module/Menu/Logo/Logo";
import { TbPlugConnected } from "react-icons/tb";
import { Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userApi } from "../../api/requests/user.api";
import { UserStore } from "../../store/UserStore";
import { StoresEnum, useStore } from "../../hooks/use-store-hook/useStore";
import { localStorageUtils } from "../../utils/browser-utils/LocalStorage";
import { getOpenedPosts } from "../../hooks/use-auth-hook/useAuth.utils";
import { PostsStore } from "../../store/PostsStore";
import { useTradingStore } from "../../hooks/use-trading-store-hook/useTradingStore";
import { TradingAccountWidgetStatesEnum } from "../../types/enums/TradingAccountWidgetStates.enum";
import { tradingApi } from "../../api/requests/trading.api";

export const AgrobridgesToTelegram = ({
  bridgeLogoBorder = "",
  tgIconColor = "#000",
}: any): ReactElement => {
  return (
    <IconsStyledWrapper>
      <LogoShort size="large" bridgeLogoBorder={bridgeLogoBorder} />
      <TbPlugConnected
        size={40}
        style={{
          transform: "rotate(45deg)",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      />
      <BsTelegram size={50} color={tgIconColor} />
    </IconsStyledWrapper>
  );
};

export const BotAuthPage = (): ReactElement => {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const botAuthCode = searchParams.get("bot_auth");
  const botType = searchParams.get("bot_type");
  const phone = searchParams.get("phone");

  const { setCurrentUser } = useStore(StoresEnum.USER_STORE) as UserStore;
  const { setPersistedPostContacts } = useStore(
    StoresEnum.POSTS_STORE
  ) as PostsStore;
  const { setTradingAccount, setTradingAccountState, getTradingAccountPosts } =
    useTradingStore();

  useEffect(() => {
    const signIn = async () => {
      if (!phone || !botAuthCode || !botType) {
        return;
      }

      const userAuthInfo = await userApi.signIn(phone, botAuthCode);
      const { user } = userAuthInfo;

      setCurrentUser(user);

      localStorageUtils.setAllAuthUserInfo(userAuthInfo);
      localStorageUtils.setDeviceMarkedAsUsed();

      await getOpenedPosts(setPersistedPostContacts);

      const tradingAccount = await tradingApi.getTradingAccount();
      setTradingAccount(tradingAccount);
      if (!tradingAccount?.id) {
        setTradingAccountState(
          TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT
        );
      } else {
        await getTradingAccountPosts();

        setTradingAccountState(
          TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT
        );
      }

      navigate("/");
    };

    signIn();
  }, [botAuthCode, botType, phone]);

  return (
    <Page>
      <BotAuthStyledWrapper>
        <AgrobridgesToTelegram />
        <TelegramBotAuthStyledWrapper>
          <p>Виконуємо вхід через Telegram</p>

          <Spin
            size="large"
            style={{
              marginTop: "20px",
            }}
          />
        </TelegramBotAuthStyledWrapper>
      </BotAuthStyledWrapper>
    </Page>
  );
};
