import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";

const SelectedCSS = css`
  color: ${colors.white};
  background: ${colors.primary};
`;

const MobileMenuLinkCSS = css`
  font-size: 16px;
  text-decoration: none;
  color: #000;
  border: 2px solid ${colors.primary};
  border-radius: 5px;
  padding: 5px 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  min-width: 160px;
  justify-content: center;

  svg {
    margin-right: 5px;
    margin-left: -6px;
  }
`;

export const MobileMenuLink = styled(Link)<{
  $isMobile: boolean;
  $isSelected?: boolean;
}>`
  ${MobileMenuLinkCSS}

  ${({ $isSelected }) => $isSelected && SelectedCSS}
`;
