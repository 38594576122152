import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../i18n/translationMap";

interface SmsLimitationErrorProps {
  isSmsLimitExceeded: boolean;
}

export const SmsLimitationError = (
  props: SmsLimitationErrorProps
): ReactElement => {
  const { isSmsLimitExceeded } = props;
  const { t } = useTranslation();

  return (
    <>
      {isSmsLimitExceeded && (
        <div style={{ marginTop: 20, color: "red", textAlign: "center" }}>
          {t(translationMap.contacts.smsLimitation)}
        </div>
      )}
    </>
  );
};
