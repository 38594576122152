import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const PlansStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChoosePlanListStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PlansModalWrapper = styled.div`
  & .ant-modal-content {
    width: 700px !important;
  }
`;
