import { ReactElement } from "react";
import { PaymentTypeStyledButton } from "./PaymentTypeRadioButton.styled";

interface PaymentTypeRadioButtonProps {
  IconSlot: JSX.Element;
  value: string;
  label: string;
  isSelected?: boolean;
  onClick: () => void;
}

export const PaymentTypeRadioButton = (
  props: PaymentTypeRadioButtonProps
): ReactElement => {
  const { label, IconSlot, isSelected = false, onClick } = props;

  return (
    <PaymentTypeStyledButton onClick={onClick} isSelected={isSelected}>
      <div>{IconSlot}</div>

      <p>{label}</p>
    </PaymentTypeStyledButton>
  );
};
