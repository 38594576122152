import React, { ReactElement } from "react";

import { TradingContinuePlansModalStyledWrapper } from "./TradingContinuePlansModal.styled";
import { usePlanContinueTradingPost } from "../../../../hooks/use-plan-continue-trading-post-hook/usePlancCntinueTradingPost";
import { observer } from "mobx-react-lite";
import { Modal } from "antd";
import { tradingApi } from "../../../../api/requests/trading.api";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import { navigateByCheckoutUrl } from "../../../../utils/navigation-utils/navigateByCheckoutUrl";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { Plan } from "../../../shared-components/Plan/Plan";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { SelectPlanCurrency } from "../../../shared-components/SelectPlanCurrency/SelectPlanCurrency";
import { TradingContinuePlanTypeEnum } from "../../../../types/enums/TradingContinuePlanType.enum";

export const TradingContinuePlansModal = observer((): ReactElement => {
  const {
    tradingAccount,
    setTradingAccount,

    isTradingContinuePlanOpen,
    toggleTradingContinuePlanModal,

    tradingPostToContinue,
    setTradingPostToContinue,

    getTradingAccountPosts,
  } = useTradingStore();

  const { continuePlans } = usePlanContinueTradingPost();

  const { isMobile, language } = useStore(StoresEnum.APP_STORE) as AppStore;

  const [selectedCurrency, setSelectedCurrency] = React.useState(
    CurrencyEnum.UAH
  );

  const balanceContinuePlans = continuePlans.filter(
    (plan: any) => plan.type === TradingContinuePlanTypeEnum.BALANCE
  );

  const simpleContinuePlans = continuePlans.filter(
    (plan: any) => plan.type === TradingContinuePlanTypeEnum.BUY
  );

  const handleChooseContinuePlan = async (id: string): Promise<void> => {
    if (!tradingPostToContinue?.id) {
      return;
    }

    const { payment } = await tradingApi.getTradingContinueCheckoutUrl(
      id,
      CurrencyEnum.UAH,
      tradingPostToContinue.id
    );

    navigateByCheckoutUrl(payment.checkoutUrl);

    setTradingPostToContinue(null);
  };

  const handleCancelTopPlan = () => {
    setTradingPostToContinue(null);
    toggleTradingContinuePlanModal();
  };

  const handleChooseContinueFromBalance = async (id: string) => {
    const { balance } = await tradingApi.continuePostFromBalance(
      id,
      tradingPostToContinue.id
    );

    await getTradingAccountPosts();

    // @ts-ignore
    setTradingAccount({
      ...tradingAccount,
      postBalance: balance,
    });

    toggleTradingContinuePlanModal();
  };

  const Content = (
    <TradingContinuePlansModalStyledWrapper>
      {balanceContinuePlans.map((plan: any) => {
        return (
          <Plan
            isMobile={isMobile()}
            plan={plan}
            selectedCurrency={selectedCurrency}
            handleChoosePlan={() => handleChooseContinueFromBalance(plan.id)}
            language={language}
            // @ts-ignore
            isDisabled={!Boolean(tradingAccount?.postBalance >= plan.points)}
            isBalance
          />
        );
      })}
      {simpleContinuePlans.map((plan: any) => {
        return (
          <Plan
            isMobile={isMobile()}
            plan={plan}
            selectedCurrency={selectedCurrency}
            handleChoosePlan={() => handleChooseContinuePlan(plan.id)}
            language={language}
          />
        );
      })}
    </TradingContinuePlansModalStyledWrapper>
  );

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isTradingContinuePlanOpen}
        title="Продовження поста"
        onClose={handleCancelTopPlan}
        Content={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectPlanCurrency
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
            </div>
            {Content}
          </>
        }
      />
    );
  }

  return (
    <Modal
      title="Продовження поста"
      open={isTradingContinuePlanOpen}
      onOk={toggleTradingContinuePlanModal}
      onCancel={handleCancelTopPlan}
      centered
      width={740}
      footer={null}
    >
      {Content}
    </Modal>
  );
});
