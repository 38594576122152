export const UkrainianFlagIcon = () => {
  return (
    <svg
      width="26"
      height="17"
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ borderRadius: 2 }}
    >
      <g clip-path="url(#clip0_3_2370)">
        <path d="M0 0.000488281H26V17.0001H0V0.000488281Z" fill="#FFDA44" />
        <path d="M0 0.000488281H26V8.50031H0V0.000488281Z" fill="#338AF3" />
      </g>
      <defs>
        <clipPath id="clip0_3_2370">
          <rect width="26" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
