import React, { ReactElement } from "react";
import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../i18n/translationMap";

interface GetContactsButtonProps {
  isMobile: boolean;
  onGetContacts: () => void;
}

export const GetContactsButton = (
  props: GetContactsButtonProps
): ReactElement => {
  const { isMobile, onGetContacts } = props;

  const { t } = useTranslation();

  return (
    <CustomButton
      size={isMobile ? "small" : "middle"}
      type="primary"
      onClick={onGetContacts}
      style={{ minWidth: isMobile ? "110px" : "130px" }}
    >
      <p style={{ fontSize: isMobile ? "12px" : "14px" }}>
        {t(translationMap.post.getContacts)}
      </p>
    </CustomButton>
  );
};
