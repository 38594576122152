import React, { ReactElement } from "react";
import { translationMap } from "../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

interface HarvestYearProps {
  harvestYear?: number;
}

export const HarvestYear = (props: HarvestYearProps): ReactElement => {
  const { harvestYear } = props;

  const { t } = useTranslation();

  return (
    <p style={{ fontSize: 16, marginTop: 5 }}>
      {t(translationMap.post.harvestYear)}:{" "}
      <span style={{ fontWeight: 600 }}>{harvestYear || "-"}</span>
    </p>
  );
};
