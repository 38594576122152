import React, { ReactElement } from "react";

import {
  SettingsIconStyledWrapper,
  TradingCompanyAccountInfoStyledWrapper,
  TradingCompanyInfoStyledWrapper,
  TradingCompanyLogoStyled,
  TradingCompanyNameStyledWrapper,
  TradingCompanyRootPhoneStyledWrapper,
} from "./TradingCompanyInfoWidget.styled";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { MdEdit } from "react-icons/md";
import { Card } from "../../../shared-components/Card/Card";
import { observer } from "mobx-react-lite";
import { TradingAccountWidgetStatesEnum } from "../../../../types/enums/TradingAccountWidgetStates.enum";
import { AppStore } from "../../../../store/AppStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { LOGO_MOCK } from "../TradingAccountCreate/TradingAccountCreate";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { colors } from "../../../../constants/styles/colors.constants";
import { BsQuestionCircle } from "react-icons/bs";

export const TradingCompanyInfoWidget = observer((): ReactElement => {
  const { tradingAccount, setTradingAccountState, toggleTradingInfoModalOpen } =
    useTradingStore();
  const { logoUrl, name, contacts } = tradingAccount || {};
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const handleSetEditTradingAccountState = () => {
    setTradingAccountState(TradingAccountWidgetStatesEnum.EDIT_TRADING_ACCOUNT);
  };

  const mobileStyle = {
    width: "95%",
    margin: "0 auto",
  };

  const desktopStyle = {
    width: "48%",
  };

  if (isMobile()) {
    return (
      <Card style={isMobile() ? mobileStyle : desktopStyle}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <TradingCompanyInfoStyledWrapper>
            <TradingCompanyLogoStyled src={logoUrl || LOGO_MOCK} />

            <TradingCompanyAccountInfoStyledWrapper>
              <TradingCompanyNameStyledWrapper>
                {name || "-"}
              </TradingCompanyNameStyledWrapper>

              <TradingCompanyRootPhoneStyledWrapper>
                {contacts?.rootPhone ? contacts.rootPhone : "-"}
              </TradingCompanyRootPhoneStyledWrapper>
            </TradingCompanyAccountInfoStyledWrapper>

            <div
              style={{
                position: "absolute",
                top: -10,
                right: -10,
              }}
              onClick={toggleTradingInfoModalOpen}
            >
              <BsQuestionCircle size={28} style={{ color: colors.orange }} />
            </div>
          </TradingCompanyInfoStyledWrapper>

          <CustomButton
            type="default"
            onClick={handleSetEditTradingAccountState}
            style={{
              margin: "10px auto 0 auto",
              width: "100%",
            }}
          >
            Редагувати
          </CustomButton>
        </div>
      </Card>
    );
  }

  return (
    <Card style={isMobile() ? mobileStyle : desktopStyle}>
      <TradingCompanyInfoStyledWrapper>
        <TradingCompanyLogoStyled src={logoUrl || LOGO_MOCK} />

        <TradingCompanyAccountInfoStyledWrapper>
          <TradingCompanyNameStyledWrapper>
            {name || "-"}
          </TradingCompanyNameStyledWrapper>

          <TradingCompanyRootPhoneStyledWrapper>
            {contacts?.rootPhone ? contacts.rootPhone : "-"}
          </TradingCompanyRootPhoneStyledWrapper>
        </TradingCompanyAccountInfoStyledWrapper>

        <SettingsIconStyledWrapper onClick={handleSetEditTradingAccountState}>
          <MdEdit size={20} />
        </SettingsIconStyledWrapper>
      </TradingCompanyInfoStyledWrapper>
    </Card>
  );
});
