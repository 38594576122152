import { postApi } from "../../api/requests/posts.api";
import { tradingApi } from "../../api/requests/trading.api";
import { userApi } from "../../api/requests/user.api";
import { UserErrorMessagesEnum } from "../../constants/error-messages/user-error-messages";
import { TradingAccountWidgetStatesEnum } from "../../types/enums/TradingAccountWidgetStates.enum";
import { localStorageUtils } from "../../utils/browser-utils/LocalStorage";
import { checkIsErrorExist } from "../../utils/error-utils/checkIsErrorExist";

export const handleUserNotFoundError = (error: any) => {
  const isUserNotFound = checkIsErrorExist(
    error.errors,
    UserErrorMessagesEnum.USER_NOT_FOUND
  );

  if (isUserNotFound) {
    localStorageUtils.clearAuthData();
  }
};

export const getOpenedPosts = async (setPersistedPostContacts: any) => {
  try {
    const openedPost = await postApi.getOpenedPosts();

    const updatedOpenedPosts = openedPost.reduce((acc: any, post: any) => {
      const { contacts, _id } = post;

      return {
        ...acc,
        [_id]: contacts,
      };
    }, {});

    setPersistedPostContacts(updatedOpenedPosts);
  } catch (error) {
    return;
  }
};

export const fetchUserById = async (
  id: string,
  setCurrentUser: any,
  setPersistedPostContacts: any,
  setTradingAccount: any,
  setTradingAccountState: any
) => {
  try {
    const { user, ...authInfo } = await userApi.getUserById(id);

    setCurrentUser(user);
    localStorageUtils.setAllAuthUserInfo(authInfo);

    await getOpenedPosts(setPersistedPostContacts);

    const tradingAccount = await tradingApi.getTradingAccount();

    setTradingAccount(tradingAccount);

    if (!tradingAccount?.id) {
      setTradingAccountState(TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT);
    } else {
      setTradingAccountState(
        TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT
      );
    }
  } catch (error: any) {
    handleUserNotFoundError(error);
  }
};

export const refreshToken = async (
  refreshToken: string,
  setCurrentUser: any,
  setPersistedPostContacts: any,
  setTradingAccount: any,
  setTradingAccountState: any
) => {
  try {
    const { user, ...authInfo } = await userApi.refreshToken(refreshToken);

    setCurrentUser(user);
    localStorageUtils.setAllAuthUserInfo(authInfo);

    await getOpenedPosts(setPersistedPostContacts);

    const tradingAccount = await tradingApi.getTradingAccount();

    setTradingAccount(tradingAccount);

    if (!tradingAccount) {
      setTradingAccountState(TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT);
    } else {
      setTradingAccountState(
        TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT
      );
    }
  } catch (error: any) {
    handleUserNotFoundError(error);
  }
};
