import React from "react";
import { ContactsInformation } from "../../add-post-form-components/ContactsInformation/ContactsInformation";

export const ContactsStep = () => {
  return (
    <div>
      <ContactsInformation />
    </div>
  );
};
