import { LanguagesEnum } from "../../types/enums/Languages.enum";
import { CerealCropInterface } from "../../types/interfaces/CerealCrop.interface";

export const getCerealCropsOptions = (
  cerealCrops: CerealCropInterface[],
  language: LanguagesEnum
) => {
  return cerealCrops.map((cerealCrop: CerealCropInterface) => ({
    value: cerealCrop.id,
    label: cerealCrop.translations[language],
  }));
};
