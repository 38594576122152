import styled from "styled-components";
import { colors } from "../../../../../../../../constants/styles/colors.constants";

export const PaymentTypeRadioButtonStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -1px;

  div {
    margin-right: 10px;
    margin-top: 5px;
  }
`;

export const PaymentTypeStyledButton = styled.div<{ isSelected: boolean }>`
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;

  div {
    width: 60px;
    height: 60px;
    background: ${({ isSelected }) =>
      isSelected ? colors.primary : colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    transition: 0.3s;
    font-size: 24px;

    color: ${({ isSelected }) => (isSelected ? colors.white : colors.black)};
  }

  p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
  }

  &:hover div {
    background: ${colors.primary};

    & svg {
      color: ${colors.white};
      transition: 0.3s;
    }
  }
`;
