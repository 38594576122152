import { ReactElement, useState } from "react";
import {
  NoteStyled,
  PostDescriptionStyledWrapper,
  PostDescriptionControllsStyledWrapper,
  DescriptionStyledWrapper,
} from "./Post.styled";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import { translationMap } from "../../../../i18n/translationMap";
import { PostInterface } from "../../../../types/interfaces/Post.interface";
import { PostContacts } from "../PostContacts/PostContacts";
import { CurrenciesList } from "./PostSharedComponents/CurrenciesList/CurrenciesList";
import { HarvestYear } from "./PostSharedComponents/HarvestYear/HarvestYear";

interface PostDescriptionProps {
  post: PostInterface;
  language: string;
  filters: Record<string, any>;
}

export const PostDescription = (props: PostDescriptionProps): ReactElement => {
  const { post, language, filters } = props;
  const { id, descriptions, contacts, prices, harvestYear } = post;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <PostDescriptionStyledWrapper>
      <PostDescriptionControllsStyledWrapper>
        <NoteStyled onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
          <FaAngleDown />
          {t(translationMap.post.description)}
        </NoteStyled>

        <PostContacts postId={id} contacts={contacts} />
      </PostDescriptionControllsStyledWrapper>

      <div
        style={{
          display: isDetailsOpen ? "block" : "none",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <CurrenciesList prices={prices} filters={filters} />

          <HarvestYear harvestYear={harvestYear} />
        </div>

        {descriptions?.[language] ? (
          <DescriptionStyledWrapper>
            {descriptions?.[language] || ""}
          </DescriptionStyledWrapper>
        ) : (
          <div style={{ marginTop: 10 }}>
            {t(translationMap.post.description)}: -
          </div>
        )}
      </div>
    </PostDescriptionStyledWrapper>
  );
};
