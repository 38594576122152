import { PaymentTypesEnum } from "../../types/enums/PaymentTypes.enum";
import { phone as validatePhone } from "phone";

const isValidYear = (year: number): boolean => {
  return year >= 2000 && year <= 2030;
};

interface ValidationInterface {
  isValid: boolean;
  errorFields: string[];
}

export const checkIsCerealCropStepValid = (
  postModalData: any
): ValidationInterface => {
  const { cerealCrop, amount, harvestYear } = postModalData;
  const errorFields = [];

  const isHarvestYearValid = !harvestYear ? true : isValidYear(harvestYear);
  const isCerealCropValid = Boolean(cerealCrop);
  const isAmountValid = Boolean(amount);

  if (!isHarvestYearValid) {
    errorFields.push("harvestYear");
  }

  if (!isCerealCropValid) {
    errorFields.push("cerealCrop");
  }

  if (!isAmountValid) {
    errorFields.push("amount");
  }

  return {
    isValid: Boolean(cerealCrop && amount && isHarvestYearValid),
    errorFields,
  };
};

export const checkIsLocationStepValid = (
  postModalData: any
): ValidationInterface => {
  const { country, state } = postModalData;
  const errorFields = [];

  const isCountryValid = Boolean(country);
  const isStateValid = Boolean(state);

  if (!isCountryValid) {
    errorFields.push("country");
  }

  if (!isStateValid) {
    errorFields.push("state");
  }

  return {
    isValid: Boolean(country && state),
    errorFields,
  };
};

export const isPriceStepValid = (postModalData: any): boolean => {
  const { prices, paymentType } = postModalData;

  if (paymentType === PaymentTypesEnum.HIDDEN) {
    return true;
  }

  return (
    Object.values(prices).some((price: any) => price > 0) &&
    Boolean(paymentType)
  );
};

export const isDescriptionStepValid = (): boolean => {
  return true;
};

export const checkIsPhoneValid = (phone: string): boolean => {
  const { isValid } = validatePhone(`+${phone}`);

  return isValid;
};

export const checkIsContactsStepValid = (
  postModalData: any,
  isFacebookUrlValid: boolean
): ValidationInterface => {
  const { contacts } = postModalData || {};
  const { rootPhone, facebookUrl, messengers } = contacts || {};
  const errorFields = [];

  const isPhoneValid = !rootPhone ? true : checkIsPhoneValid(rootPhone);
  const isFacebookUrlValidated = isFacebookUrlValid || !facebookUrl;
  const isViberValid = messengers?.viber?.phone
    ? checkIsPhoneValid(messengers?.viber?.phone)
    : true;
  const isWhatsappValid = messengers?.whatsapp?.phone
    ? checkIsPhoneValid(messengers?.whatsapp?.phone)
    : true;
  const isTelegramValid = messengers?.telegram?.phone
    ? checkIsPhoneValid(messengers?.telegram?.phone)
    : true;

  if (!isPhoneValid) {
    errorFields.push("rootPhone");
  }

  if (!isFacebookUrlValidated) {
    errorFields.push("facebookUrl");
  }

  if (!isViberValid) {
    errorFields.push("viber");
  }

  if (!isWhatsappValid) {
    errorFields.push("whatsapp");
  }

  if (!isTelegramValid) {
    errorFields.push("telegram");
  }

  const isAtLeastOneContactProvided = Boolean(
    rootPhone ||
      facebookUrl ||
      messengers?.viber?.phone ||
      messengers?.whatsapp?.phone ||
      messengers?.telegram?.phone
  );

  return {
    isValid: Boolean(
      isPhoneValid &&
        isFacebookUrlValidated &&
        isViberValid &&
        isWhatsappValid &&
        isTelegramValid &&
        isAtLeastOneContactProvided
    ),
    errorFields,
  };
};

export const checkIsConfirmationPhoneFormValid = (
  phone: string
): ValidationInterface => {
  const errorFields = [];

  const isPhoneValid = checkIsPhoneValid(phone);

  if (!isPhoneValid) {
    errorFields.push("phone");
  }

  return {
    isValid: isPhoneValid,
    errorFields,
  };
};
export const checkIsConfirmationCodeFormValid = (
  code: any
): ValidationInterface => {
  const errorFields = [];

  const isCodeValid = Boolean(code) && code.length === 4;

  if (!isCodeValid) {
    errorFields.push("code");
  }

  return {
    isValid: isCodeValid,
    errorFields,
  };
};
