import { format } from "date-fns";
import { LanguagesEnum } from "../../types/enums/Languages.enum";
import { uk, pl, enUS } from "date-fns/locale";

const localesMap: any = {
  [LanguagesEnum.UK]: uk,
  [LanguagesEnum.PL]: pl,
  [LanguagesEnum.EN]: enUS,
};

export const getStandardLocaleDate = (
  date: string | Date,
  language: LanguagesEnum,
  monthFormat = "LLLL"
): string => {
  let dateToFormat = "";

  try {
    dateToFormat = format(new Date(date), `dd/${monthFormat}/yy`, {
      locale: localesMap[language],
    });

    return dateToFormat;
  } catch (error: any) {
    return dateToFormat;
  }
};
