import { useEffect } from "react";
import { tradingApi } from "../../api/requests/trading.api";
import { useTradingStore } from "../use-trading-store-hook/useTradingStore";

export const usePlanContinueTradingPost = () => {
  const { continuePlans, setContinuePlans } = useTradingStore();

  const fetchPlanContinueTradingPost = async () => {
    if (continuePlans.length > 0) {
      return;
    }

    const plans = await tradingApi.getTradingContinueAllPlans();

    setContinuePlans(plans);
  };

  useEffect(() => {
    fetchPlanContinueTradingPost();
  }, []);

  return {
    continuePlans,
  };
};
