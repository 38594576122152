import styled from "styled-components";

export const AcceptPoliciesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
`;
