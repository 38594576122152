import styled from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";

export const AddPostFormStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;
`;

export const AddPostModalTitleStyledWrapper = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
`;

export const StepsStyledWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;

  .ant-steps-item-active {
    svg {
      fill: ${colors.orange};
    }
  }
  .ant-steps-item-tail {
    margin-left: 16px !important;
  }
  .ant-steps-item-icon {
    margin-left: 0 !important;
  }
  .ant-steps-item-container {
    width: 30px;
  }

  .ant-steps-item-tail {
    &::after {
      background-color: ${colors.primary} !important;
    }
  }

  .ant-steps-item-content {
    margin-top: -15px !important;

    .ant-steps-item-title {
      margin-top: 0;
      font-size: 14px;
    }
  }

  .ant-steps-icon {
    svg {
      font-size: 35px;
    }
  }
  .ant-steps-item-title {
    color: #f00;
  }
`;
