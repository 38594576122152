import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { PostsStore } from "../../../../store/PostsStore";
import { UserStore } from "../../../../store/UserStore";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { translationMap } from "../../../../i18n/translationMap";
import { colors } from "../../../../constants/styles/colors.constants";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { AppStore } from "../../../../store/AppStore";
import { getStandardLocaleDate } from "../../../../utils/date-utils/getStandardLocaleDate";
import { BsFillPersonFill } from "react-icons/bs";
import { useAddPostStore } from "../../../../hooks/use-add-post-store/useAddPostStore";
import { Link } from "react-router-dom";

const AccountPersonalInfo = ({ user, isMobile }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <p
        style={{ fontSize: isMobile ? 26 : 16, fontWeight: 600, marginTop: 30 }}
      >
        {t(translationMap.menu.personalInfo)}
      </p>
      {user?.name && (
        <p style={{ fontSize: isMobile ? 24 : 20, marginTop: 10 }}>
          {user?.name}
        </p>
      )}
      <p style={{ fontSize: isMobile ? 26 : 20, marginTop: 5 }}>
        +{user?.phone}
      </p>
    </div>
  );
};

const AccountSubscriptionInfo = ({
  user,
  language,
  isMobile,
  isSubscriptionActive,
  onOpenPlansModal,
}: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <p
        style={{
          fontSize: isMobile ? 26 : 16,
          fontWeight: 600,
          marginTop: 20,
          paddingTop: 15,
          borderTop: `1px solid ${colors.lightGrey}`,
        }}
      >
        {t(translationMap.menu.yourPlan)}
      </p>
      <p style={{ fontSize: isMobile ? 26 : 18, marginTop: 10 }}>
        {isSubscriptionActive && user?.subscription?.activeTill ? (
          <>
            <p>{`${t(translationMap.menu.activeTill)}`}</p>
            <p>
              {`${getStandardLocaleDate(
                user?.subscription?.activeTill,
                language
              )}`}
            </p>
          </>
        ) : (
          <div>
            <p>{t(translationMap.payment.subscriptionExpirationMessage)}</p>
            <CustomButton
              type="primary"
              style={{ width: "100%", marginTop: "30px" }}
              size="large"
              onClick={onOpenPlansModal}
            >
              {t(translationMap.payment.choosePlan)}
            </CustomButton>
          </div>
        )}
      </p>
    </div>
  );
};

export const PersonalAccount = observer((): ReactElement => {
  const { setPhone } = useAddPostStore();
  const { isMobile, language } = useStore(StoresEnum.APP_STORE) as AppStore;

  const {
    user,
    logout,
    isSubscriptionActive,
    setPlanModalOpenClose,
  } = useStore(StoresEnum.USER_STORE) as UserStore;
  const { clearPostContacts } = useStore(StoresEnum.POSTS_STORE) as PostsStore;

  const { t } = useTranslation();

  const handleLogout = () => {
    setPhone("");
    clearPostContacts();
    logout();
  };

  const handleOpenPlansModal = () => {
    setPlanModalOpenClose();
  };

  const handleSetAccountModalOpenClose = (): void => {
  };

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={false}
        title={t(translationMap.menu.account)}
        onClose={handleSetAccountModalOpenClose}
        Content={
          <div style={{ height: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <AccountPersonalInfo user={user} isMobile />

              <AccountSubscriptionInfo
                user={user}
                language={language}
                isMobile
                isSubscriptionActive={isSubscriptionActive}
                onOpenPlansModal={handleOpenPlansModal}
              />
            </div>

            <CustomButton
              type="default"
              style={{ width: "100%", marginTop: "30px" }}
              onClick={handleLogout}
            >
              {t(translationMap.menu.logout)}
            </CustomButton>
          </div>
        }
      />
    );
  }

  return (
    <Link
      to="/account"
      style={{
        textDecoration: "none",
        color: "black",
      }}
    >
      <BsFillPersonFill
        size={30}
        style={{ cursor: "pointer" }}
        onClick={handleSetAccountModalOpenClose}
      />
    </Link>
  );
});
