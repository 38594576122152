import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const TradingPostContentStyledWrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TradingPostInfoStyledWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  p {
    font-size: 26px;
    font-weight: 600;
  }
`;

export const TradingPostStateStyledWrapper = styled.div`
  border: 2px solid ${colors.primary};
  background: ${colors.darkWhite};
  padding: 4px 6px;
  border-radius: 5px;
  font-weight: 600;
  margin: 0 2px;
  font-size: 14px;
`;
