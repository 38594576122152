import { SyntheticEvent, useState } from "react";
import { checkIsErrorExist } from "../../utils/error-utils/checkIsErrorExist";
import { UserErrorMessagesEnum } from "../../constants/error-messages/user-error-messages";

export const useSignUp = () => {
  const [phone, setPhone] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isSignUpStarted, setIsSignUpStarted] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const handleChangePhone = (value: string): void => {
    setPhone(value);
  };

  const handleChangeIsPhoneValid = (isValid: boolean): void => {
    setIsPhoneValid(isValid);
  };

  const handleChangeName = (event: SyntheticEvent) => {
    // @ts-ignore
    setName(event.target.value);
  };

  const startSignUp = (isStarted: boolean) => {
    setIsSignUpStarted(isStarted);
  };

  const checkIsUserExists = (errors: any) => {
    const isUserExists = checkIsErrorExist(
      errors,
      UserErrorMessagesEnum.USER_ALREADY_EXISTS
    );

    if (isUserExists) {
      setIsUserExists(isUserExists);
    }
  };

  return {
    name,
    phone,
    isUserExists,
    isPhoneValid,
    isSignUpStarted,

    handleChangeName,
    handleChangePhone,
    handleChangeIsPhoneValid,

    startSignUp,
    checkIsUserExists,
  };
};
