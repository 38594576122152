import { useEffect, useState } from "react";
import { FilterKeysEnum } from "../../types/enums/FilterKeys.enum";
import { defaultFilters } from "../../store/PostsStore";

interface UseSharedFiltersParams {
  filters: any;

  handleChangeFilters: (filters: any) => void;
  clearPostFilters: () => void;
  checkIsFiltersApplied: () => boolean;
  setIsFiltersOpen: (isOpen: boolean) => void;
}

export const useSharedFilters = ({
  filters,

  handleChangeFilters,
  clearPostFilters,
  checkIsFiltersApplied,
  setIsFiltersOpen,
}: UseSharedFiltersParams): any => {
  const [localFilters, setLocalFilters] = useState(defaultFilters);

  useEffect(() => {
    if (filters?.currency) {
      setLocalFilters({
        ...localFilters,
        currency: filters?.currency,
      });
    }
  }, [filters?.currency]);

  const {
    country: selectedCountry,
    states: selectedStated,
    cerealCrops: selectedCerealCrops,
    paymentTypes: selectedPaymentTypes,
  } = localFilters;

  const onApplyFilters = async () => {
    const appliedFilters = {
      // @ts-ignore
      cerealCrops: selectedCerealCrops,
      country: selectedCountry || "",
      // @ts-ignore
      states: selectedStated,
      // @ts-ignore
      paymentTypes: selectedPaymentTypes,
      currency: localFilters?.currency || "",
    };

    handleChangeFilters(appliedFilters);
    setIsFiltersOpen(false);
  };

  const selectFilter = (key: string) => {
    return (value: string | string[] | number[]) => {
      setLocalFilters({ ...localFilters, [key]: value });
    };
  };

  const onClearPostFilters = () => {
    if (!checkIsFiltersApplied()) {
      return;
    }

    clearPostFilters();
    setLocalFilters(defaultFilters);
    setIsFiltersOpen(false);
  };

  useEffect(() => {
    selectFilter(FilterKeysEnum.STATES)([]);
  }, [localFilters[FilterKeysEnum.COUNTRY]]);

  return {
    localFilters,

    onApplyFilters,
    selectFilter,
    onClearPostFilters,
    setLocalFilters,
  };
};
