import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Logo } from "../Logo/Logo";
import { MenuLinks } from "../MenuLinks/MenuLinks";
import { MenuActions } from "../MenuActions/MenuActions";
import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import { LanguageSelect } from "../../LanguageSelect/LanguageSelect";
import { AddPostModal } from "../../../sell-module/AddPost/AddPostModal/AddPostModal";

import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { translationMap } from "../../../../../i18n/translationMap";
import { UserStore } from "../../../../../store/UserStore";

import {
  MenuContentStyledWrapper,
  MenuLinksAndAction,
  MenuLinksStyledWrapper,
  MenuStyledWrapper,
} from "./DesktopMenu.styled";
import { observer } from "mobx-react-lite";
import { PersonalAccount } from "../../../auth-module/PersonalAccount/PersonalAccount";

interface DesktopMenuProps {
  isPosts: boolean;
  isTrading: boolean;
  handleOpenCloseAddPostModal: () => void;
  handleCheckout: () => void;
}

export const DesktopMenu = observer((props: DesktopMenuProps): ReactElement => {
  const { isPosts, isTrading, handleOpenCloseAddPostModal, handleCheckout } =
    props;

  const { t } = useTranslation();

  const { user, setSignInModalOpenClose, setSignUpModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const { phone } = user || {};

  const isExpandedMenu = isPosts || isTrading;

  if (!isExpandedMenu) {
    return (
      <MenuStyledWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            width: "100%",
          }}
        >
          <Logo />

          <div style={{ marginLeft: 30 }}>
            <LanguageSelect />
          </div>
        </div>
      </MenuStyledWrapper>
    );
  }

  return (
    <MenuStyledWrapper>
      <MenuContentStyledWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MenuLinksAndAction>
            <Logo />

            <MenuActions
              handleOpenCloseAddPostModal={handleOpenCloseAddPostModal}
              handleCheckout={handleCheckout}
            />
          </MenuLinksAndAction>

          <div style={{ display: "flex", alignItems: "center" }}>
            {phone && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: 18, marginRight: 10 }}>+{phone}</p>{" "}
                <PersonalAccount />
              </div>
            )}

            {!phone && (
              <div style={{ marginRight: 10 }}>
                <CustomButton
                  type="default"
                  onClick={setSignInModalOpenClose}
                  margin="0 10px 0 0"
                >
                  {t(translationMap.auth.signIn)}
                </CustomButton>

                <CustomButton type="primary" onClick={setSignUpModalOpenClose}>
                  {t(translationMap.auth.signUp)}
                </CustomButton>
              </div>
            )}

            <LanguageSelect />
          </div>
        </div>

        <MenuLinksStyledWrapper>
          <MenuLinks />
        </MenuLinksStyledWrapper>
      </MenuContentStyledWrapper>

      <AddPostModal />
    </MenuStyledWrapper>
  );
});
