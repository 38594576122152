import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { Spin } from "antd";

import { PostsStore } from "../../../../store/PostsStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { renderPostElements } from "./Post.utils";
import { AppStore } from "../../../../store/AppStore";

import { PostsListStyledWrapper } from "./Posts.styled";

export const PostsList = observer((): ReactElement => {
  const { posts, isPostsLoading } = useStore(
    StoresEnum.POSTS_STORE
  ) as PostsStore;

  const { device, isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  if (isPostsLoading) {
    return (
      <div
        style={{
          minHeight: "calc(60vh)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!Array.isArray(posts) || !posts.length) {
    return (
      <div
        style={{
          minHeight: isMobile() ? 0 : "calc(100vh)",
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        <p style={{ fontSize: 30, fontWeight: 600 }}>No posts</p>
      </div>
    );
  }

  return (
    <div>
      <PostsListStyledWrapper device={device}>
        {renderPostElements(posts)}
      </PostsListStyledWrapper>
    </div>
  );
});
