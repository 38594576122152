import { useState } from "react";

interface UseOpenModalInterface {
  isOpen: boolean;
  toggleModal: () => void;
}

export const useOpenModal = (): UseOpenModalInterface => {
  const [isOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isOpen);
  };

  return {
    isOpen,
    toggleModal,
  };
};
