import { httpClient } from "../httpClient/HttpClient";

export class PlanApi {
  private PLAN_ENDPOINT = "plan";

  getAllPlans = async (): Promise<any> => {
    const data = await httpClient.get(`${this.PLAN_ENDPOINT}/all`);

    return data;
  };
}

export const planApi = new PlanApi();
