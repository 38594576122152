import React, { useState } from "react";
import { Input } from "antd";

import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import { SmsVerification } from "../../SmsVerification/SmsVerification";
import { ValidatedPhoneInput } from "../../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import { AuthUserError } from "../AuthUserError/AuthUserError";

import { userApi } from "../../../../../api/requests/user.api";
import { localStorageUtils } from "../../../../../utils/browser-utils/LocalStorage";
import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../../store/UserStore";
import { UserErrorMessagesEnum } from "../../../../../constants/error-messages/user-error-messages";

import { useConfirmCode } from "../../../../../hooks/use-confirm-code-hook/useConfirmCode";

import "react-phone-input-2/lib/style.css";
import {
  SignUpFormStyledWrapper,
  SignUpGetCodeButtonStyledWrapper,
  SignUpWithTelegramButtonStyledWrapper,
  SignUpDividerStyledWrapper,
} from "./SignUpForm.styled";
import { useSignUp } from "../../../../../hooks/use-sign-up-hook/useSignUp";
import { AppStore } from "../../../../../store/AppStore";
import { AcceptPolicies } from "../../../app-module/AccepPolicies/AcceptPolicies";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../i18n/translationMap";
import { SmsLimitationError } from "../../SmsLimitationError/SmsLimitationError";
import { useSmsLimitation } from "../../../../../hooks/use-sms-limitaion/useSmsLimitaion";
import { useTradingStore } from "../../../../../hooks/use-trading-store-hook/useTradingStore";
import { TradingAccountWidgetStatesEnum } from "../../../../../types/enums/TradingAccountWidgetStates.enum";
import { FaTelegram } from "react-icons/fa";

export const SignUpForm = () => {
  const {
    name,
    phone,
    isUserExists,
    isPhoneValid,
    isSignUpStarted,

    checkIsUserExists,
    startSignUp,

    handleChangeName,
    handleChangePhone,
    handleChangeIsPhoneValid,
  } = useSignUp();

  const {
    code,
    usedCode,
    isCodeInvalid,

    getCodeErrors,
    handleChangeCode,
    handleChangeUsedCode,
    handleChangeIsCodeValid,
  } = useConfirmCode();

  const {
    isSmsLimitExceeded,

    handleIsSmsLimitExceeded,
    checkIsSmsLimitationError,
  } = useSmsLimitation();

  const { setTradingAccount, setTradingAccountState } = useTradingStore();

  const {
    isSignUpBonusOpen,
    setCurrentUser,
    setSignInModalOpenClose,
    setSignUpModalOpenClose,
    toggleSignUpBonusModal,
  } = useStore(StoresEnum.USER_STORE) as UserStore;
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const [isAccepted, setIsAccepted] = useState(false);

  const { t } = useTranslation();

  const handleAccept = (isAccepted: boolean) => {
    setIsAccepted(isAccepted);
  };

  const openSignInModal = (): void => {
    setSignUpModalOpenClose();
    setSignInModalOpenClose();
  };

  const handleStartSignUp = async (): Promise<void> => {
    if (!isAccepted) {
      return;
    }

    const { isStarted, errors } = await userApi.startSignUp(phone, isAccepted);

    if (checkIsSmsLimitationError(errors)) {
      handleIsSmsLimitExceeded(true);

      return;
    }

    checkIsUserExists(errors);

    startSignUp(isStarted);
  };

  const handleCreateUser = async (): Promise<void> => {
    if (!isAccepted) {
      return;
    }

    const isDeviceMarkedAsUsed = localStorageUtils.getDeviceMarkedAsUsed();

    const {
      user,
      expiresIn,
      refreshToken,
      refreshTokenExpiresIn,
      token,
      isSignedUp,
      tradingAccount,
      errors,
    } = await userApi.signUp(
      phone,
      code,
      name,
      isAccepted,
      isDeviceMarkedAsUsed
    );

    if (!isSignedUp) {
      const { isCodeInvalid, isCodeExpired } = getCodeErrors(errors);

      if (isCodeInvalid || isCodeExpired) {
        handleChangeIsCodeValid(true);
      }

      handleChangeUsedCode(code);

      return;
    }

    localStorageUtils.setAllAuthUserInfo({
      token,
      refreshToken,
      expiresIn,
      refreshTokenExpiresIn,
    });
    localStorageUtils.setDeviceMarkedAsUsed();

    setCurrentUser(user);

    if (tradingAccount) {
      setTradingAccount(tradingAccount);
      setTradingAccountState(
        TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT
      );
    }

    startSignUp(false);
    toggleSignUpBonusModal();
  };

  const handleCloseSignUpBonusModal = () => {
    if (isMobile()) {
      setSignInModalOpenClose();
    } else {
      setSignUpModalOpenClose();
    }

    toggleSignUpBonusModal();
  };

  if (isSignUpBonusOpen) {
    const isUsedDevice = localStorageUtils.getDeviceMarkedAsUsed();

    if (isUsedDevice) {
      handleCloseSignUpBonusModal();

      return <></>;
    }

    return (
      <div style={{ textAlign: "center", marginTop: 40 }}>
        <p style={{ fontSize: 22 }}>
          {t(translationMap.post.welcomeTo)} Agrobridges!
        </p>
        <p style={{ fontSize: 25, fontWeight: 600, marginTop: 20 }}>
          {t(translationMap.post.youCreditedWith)}
        </p>

        <CustomButton
          type="primary"
          style={{ width: "100%", marginTop: 30 }}
          size="large"
          onClick={handleCloseSignUpBonusModal}
        >
          {t(translationMap.buttons.confirm)}
        </CustomButton>
      </div>
    );
  }

  if (isSignUpStarted) {
    return (
      <SmsVerification
        phone={phone}
        code={code}
        isInvalid={isCodeInvalid}
        onSave={handleCreateUser}
        resendCode={handleStartSignUp}
        onChangeCode={handleChangeCode}
        isConfirmButtonDisabled={code === usedCode || !Number(code)}
      />
    );
  }

  const userErrorType = isUserExists
    ? UserErrorMessagesEnum.USER_ALREADY_EXISTS
    : null;

  return (
    <SignUpFormStyledWrapper>
      <SignUpWithTelegramButtonStyledWrapper
        href={`https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?start=auth`}
        target="_blank"
      >
        <span>Війти через Telegram</span>
        <FaTelegram style={{ marginLeft: 8 }} color="#fff" size={24} />
      </SignUpWithTelegramButtonStyledWrapper>

      <SignUpDividerStyledWrapper>
        <div />
        <span>Або</span>
        <div />
      </SignUpDividerStyledWrapper>

      <Input
        value={name}
        placeholder={t(translationMap.auth.enterYourName) as string}
        onChange={handleChangeName}
        style={{ marginBottom: 15 }}
      />
      <ValidatedPhoneInput
        phone={phone}
        isValid={isPhoneValid}
        onChangePhone={handleChangePhone}
        setIsPhoneValid={handleChangeIsPhoneValid}
      />
      <SmsLimitationError isSmsLimitExceeded={isSmsLimitExceeded} />
      <AuthUserError userErrorType={userErrorType} onClick={openSignInModal} />
      <AcceptPolicies isAccepted={isAccepted} onChange={handleAccept} />
      <SignUpGetCodeButtonStyledWrapper>
        <CustomButton
          type="primary"
          onClick={handleStartSignUp}
          style={{ width: 200 }}
          disabled={!Boolean(isPhoneValid && isAccepted)}
        >
          {t(translationMap.auth.signUp)}
        </CustomButton>
      </SignUpGetCodeButtonStyledWrapper>
    </SignUpFormStyledWrapper>
  );
};
