import styled from "styled-components";

import { colors } from "../../../../constants/styles/colors.constants";

export const TradingPostPricesStyledWrapper = styled.div`
  display: flex;
`;

export const TradingPostPriceStyledWrapper = styled.div`
  color: ${colors.primary};
  margin: 0 5px;
  font-weight: 600;
  font-size: 20px;
`;
