import { locationApi } from "../../api/requests/location.api";
import { LanguagesEnum } from "../../types/enums/Languages.enum";

const states = [
  [
    {
      name: "Greater Poland Voivodeship",
      isoCode: "WP",
      countryCode: "PL",
      latitude: "52.27998600",
      longitude: "17.35229390",
      translation: {
        [LanguagesEnum.EN]: "Greater Poland Voivodeship",
        [LanguagesEnum.PL]: "Woj. Wielkopolskie",
        [LanguagesEnum.UK]: "Великопольське воєводство",
      },
    },
    {
      name: "Kuyavian-Pomeranian Voivodeship",
      isoCode: "KP",
      countryCode: "PL",
      latitude: "53.16483630",
      longitude: "18.48342240",
      translation: {
        [LanguagesEnum.EN]: "Kuyavian-Pomeranian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Kujawsko-Pomorskie",
        [LanguagesEnum.UK]: "Куявсько-Поморське воєводство",
      },
    },
    {
      name: "Lesser Poland Voivodeship",
      isoCode: "MA",
      countryCode: "PL",
      latitude: "49.72253060",
      longitude: "20.25033580",
      translation: {
        [LanguagesEnum.EN]: "Lesser Poland Voivodeship",
        [LanguagesEnum.PL]: "Woj. Małopolskie",
        [LanguagesEnum.UK]: "Малопольське воєводство",
      },
    },
    {
      name: "Lower Silesian Voivodeship",
      isoCode: "DS",
      countryCode: "PL",
      latitude: "51.13398610",
      longitude: "16.88419610",
      translation: {
        [LanguagesEnum.EN]: "Lower Silesian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Dolnośląskie",
        [LanguagesEnum.UK]: "Долносілезьке воєводство",
      },
    },
    {
      name: "Lublin Voivodeship",
      isoCode: "LU",
      countryCode: "PL",
      latitude: "51.24935190",
      longitude: "23.10113920",
      translation: {
        [LanguagesEnum.EN]: "Lublin Voivodeship",
        [LanguagesEnum.PL]: "Woj. Lubelskie",
        [LanguagesEnum.UK]: "Люблінське воєводство",
      },
    },
    {
      name: "Lubusz Voivodeship",
      isoCode: "LB",
      countryCode: "PL",
      latitude: "52.22746120",
      longitude: "15.25591030",
      translation: {
        [LanguagesEnum.EN]: "Lubusz Voivodeship",
        [LanguagesEnum.PL]: "Woj. Lubuskie",
        [LanguagesEnum.UK]: "Любушське воєводство",
      },
    },
    {
      name: "Masovian Voivodeship",
      isoCode: "MZ",
      countryCode: "PL",
      latitude: "51.89271820",
      longitude: "21.00216790",
      translation: {
        [LanguagesEnum.EN]: "Masovian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Mazowieckie",
        [LanguagesEnum.UK]: "Мазовецьке воєводство",
      },
    },
    {
      name: "Opole Voivodeship",
      isoCode: "OP",
      countryCode: "PL",
      latitude: "50.80037610",
      longitude: "17.93798900",
      translation: {
        [LanguagesEnum.EN]: "Opole Voivodeship",
        [LanguagesEnum.PL]: "Woj. Opolskie",
        [LanguagesEnum.UK]: "Опольське воєводство",
      },
    },
    {
      name: "Podkarpackie Voivodeship",
      isoCode: "PK",
      countryCode: "PL",
      latitude: "50.05747490",
      longitude: "22.08956910",
      translation: {
        [LanguagesEnum.EN]: "Podkarpackie Voivodeship",
        [LanguagesEnum.PL]: "Woj. Podkarpackie",
        [LanguagesEnum.UK]: "Підкарпатське воєводство",
      },
    },
    {
      name: "Podlaskie Voivodeship",
      isoCode: "PD",
      countryCode: "PL",
      latitude: "53.06971590",
      longitude: "22.96746390",
      translation: {
        [LanguagesEnum.EN]: "Podlaskie Voivodeship",
        [LanguagesEnum.PL]: "Woj. Podlaskie",
        [LanguagesEnum.UK]: "Подляське воєводство",
      },
    },
    {
      name: "Pomeranian Voivodeship",
      isoCode: "PM",
      countryCode: "PL",
      latitude: "54.29442520",
      longitude: "18.15311640",
      translation: {
        [LanguagesEnum.EN]: "Pomeranian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Pomorskie",
        [LanguagesEnum.UK]: "Поморське воєводство",
      },
    },
    {
      name: "Silesian Voivodeship",
      isoCode: "SL",
      countryCode: "PL",
      latitude: "50.57165950",
      longitude: "19.32197680",
      translation: {
        [LanguagesEnum.EN]: "Silesian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Śląskie",
        [LanguagesEnum.UK]: "Сілезьке воєводство",
      },
    },
    {
      name: "Warmian-Masurian Voivodeship",
      isoCode: "WN",
      countryCode: "PL",
      latitude: "53.86711170",
      longitude: "20.70278610",
      translation: {
        [LanguagesEnum.EN]: "Warmian-Masurian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Warmińsko-Mazurskie",
        [LanguagesEnum.UK]: "Вармінсько-Мазурське воєводство",
      },
    },
    {
      name: "West Pomeranian Voivodeship",
      isoCode: "ZP",
      countryCode: "PL",
      latitude: "53.46578910",
      longitude: "15.18225810",
      translation: {
        [LanguagesEnum.EN]: "West Pomeranian Voivodeship",
        [LanguagesEnum.PL]: "Woj. Zachodniopomorskie",
        [LanguagesEnum.UK]: "Західнопоморське воєводство",
      },
    },
    {
      name: "Łódź Voivodeship",
      isoCode: "LD",
      countryCode: "PL",
      latitude: "51.46347710",
      longitude: "19.17269740",
      translation: {
        [LanguagesEnum.EN]: "Łódź Voivodeship",
        [LanguagesEnum.PL]: "Woj. Łódzkie",
        [LanguagesEnum.UK]: "Лодзінське воєводство",
      },
    },
    {
      name: "Świętokrzyskie Voivodeship",
      isoCode: "SK",
      countryCode: "PL",
      latitude: "50.62610410",
      longitude: "20.94062790",
      translation: {
        [LanguagesEnum.EN]: "Świętokrzyskie Voivodeship",
        [LanguagesEnum.PL]: "Woj. Świętokrzyskie",
        [LanguagesEnum.UK]: "Свентокшиське воєводство",
      },
    },
  ],
  [
    // {
    //   name: "Autonomous Republic of Crimea",
    //   isoCode: "43",
    //   countryCode: "UA",
    //   latitude: "44.95211700",
    //   longitude: "34.10241700",
    //   translation: {
    //     [LanguagesEnum.EN]: "Autonomous Republic of Crimea",
    //     [LanguagesEnum.PL]: "Republika Autonomiczna Krymu",
    //     [LanguagesEnum.UK]: "Автономна Республіка Крим",
    //   },
    // },
    {
      name: "Cherkaska oblast",
      isoCode: "71",
      countryCode: "UA",
      latitude: "49.44443300",
      longitude: "32.05976700",
      translation: {
        [LanguagesEnum.EN]: "Cherkasy Oblast",
        [LanguagesEnum.PL]: "Obwód Czerkaski",
        [LanguagesEnum.UK]: "Черкаська область",
      },
    },
    {
      name: "Chernihivska oblast",
      isoCode: "74",
      countryCode: "UA",
      latitude: "51.49820000",
      longitude: "31.28934990",
      translation: {
        [LanguagesEnum.EN]: "Chernihiv Oblast",
        [LanguagesEnum.PL]: "Obwód Czernihowski",
        [LanguagesEnum.UK]: "Чернігівська область",
      },
    },
    {
      name: "Chernivetska oblast",
      isoCode: "77",
      countryCode: "UA",
      latitude: "48.29168300",
      longitude: "25.93521700",
      translation: {
        [LanguagesEnum.EN]: "Chernivtsi Oblast",
        [LanguagesEnum.PL]: "Obwód Czerniowcki",
        [LanguagesEnum.UK]: "Чернівецька область",
      },
    },
    {
      name: "Dnipropetrovska oblast",
      isoCode: "12",
      countryCode: "UA",
      latitude: "48.46471700",
      longitude: "35.04618300",
      translation: {
        [LanguagesEnum.EN]: "Dnipropetrovsk Oblast",
        [LanguagesEnum.PL]: "Obwód Dniepropietrowski",
        [LanguagesEnum.UK]: "Дніпропетровська область",
      },
    },
    {
      name: "Donetska oblast",
      isoCode: "14",
      countryCode: "UA",
      latitude: "48.01588300",
      longitude: "37.80285000",
      translation: {
        [LanguagesEnum.EN]: "Donetsk Oblast",
        [LanguagesEnum.PL]: "Obwód Doniecki",
        [LanguagesEnum.UK]: "Донецька область",
      },
    },
    {
      name: "Ivano-Frankivska oblast",
      isoCode: "26",
      countryCode: "UA",
      latitude: "48.92263300",
      longitude: "24.71111700",
      translation: {
        [LanguagesEnum.EN]: "Ivano-Frankivsk Oblast",
        [LanguagesEnum.PL]: "Obwód Iwano-Frankiwski",
        [LanguagesEnum.UK]: "Івано-Франківська область",
      },
    },
    {
      name: "Kharkivska oblast",
      isoCode: "63",
      countryCode: "UA",
      latitude: "49.99350000",
      longitude: "36.23038300",
      translation: {
        [LanguagesEnum.EN]: "Kharkiv Oblast",
        [LanguagesEnum.PL]: "Obwód Charkowski",
        [LanguagesEnum.UK]: "Харківська область",
      },
    },
    {
      name: "Khersonska oblast",
      isoCode: "65",
      countryCode: "UA",
      latitude: "46.63541700",
      longitude: "32.61686700",
      translation: {
        [LanguagesEnum.EN]: "Kherson Oblast",
        [LanguagesEnum.PL]: "Obwód Chersoński",
        [LanguagesEnum.UK]: "Херсонська область",
      },
    },
    {
      name: "Khmelnytska oblast",
      isoCode: "68",
      countryCode: "UA",
      latitude: "49.42298300",
      longitude: "26.98713310",
      translation: {
        [LanguagesEnum.EN]: "Khmelnytskyi Oblast",
        [LanguagesEnum.PL]: "Obwód Chmielnicki",
        [LanguagesEnum.UK]: "Хмельницька область",
      },
    },
    {
      name: "Kirovohradska oblast",
      isoCode: "35",
      countryCode: "UA",
      latitude: "48.50793300",
      longitude: "32.26231700",
      translation: {
        [LanguagesEnum.EN]: "Kirovohrad Oblast",
        [LanguagesEnum.PL]: "Obwód Kirowogradzki",
        [LanguagesEnum.UK]: "Кіровоградська область",
      },
    },
    {
      name: "Kyivska oblast",
      isoCode: "32",
      countryCode: "UA",
      latitude: "50.05295060",
      longitude: "30.76671340",
      translation: {
        [LanguagesEnum.EN]: "Kyiv Oblast",
        [LanguagesEnum.PL]: "Obwód Kijowski",
        [LanguagesEnum.UK]: "Київська область",
      },
    },
    // {
    //   name: "Luhanska oblast",
    //   isoCode: "09",
    //   countryCode: "UA",
    //   latitude: "48.57404100",
    //   longitude: "39.30781500",
    //   translation: {
    //     [LanguagesEnum.EN]: "Luhansk Oblast",
    //     [LanguagesEnum.PL]: "Obwód Ługański",
    //     [LanguagesEnum.UK]: "Луганська область",
    //   },
    // },
    {
      name: "Lvivska oblast",
      isoCode: "46",
      countryCode: "UA",
      latitude: "49.83968300",
      longitude: "24.02971700",
      translation: {
        [LanguagesEnum.EN]: "Lviv Oblast",
        [LanguagesEnum.PL]: "Obwód Lwowski",
        [LanguagesEnum.UK]: "Львівська область",
      },
    },
    {
      name: "Mykolaivska oblast",
      isoCode: "48",
      countryCode: "UA",
      latitude: "46.97503300",
      longitude: "31.99458290",
      translation: {
        [LanguagesEnum.EN]: "Mykolaiv Oblast",
        [LanguagesEnum.PL]: "Obwód Mikołajowski",
        [LanguagesEnum.UK]: "Миколаївська область",
      },
    },
    {
      name: "Odeska oblast",
      isoCode: "51",
      countryCode: "UA",
      latitude: "46.48458300",
      longitude: "30.73260000",
      translation: {
        [LanguagesEnum.EN]: "Odessa Oblast",
        [LanguagesEnum.PL]: "Obwód Odeski",
        [LanguagesEnum.UK]: "Одеська область",
      },
    },
    {
      name: "Poltavska oblast",
      isoCode: "53",
      countryCode: "UA",
      latitude: "49.64291960",
      longitude: "32.66753390",
      translation: {
        [LanguagesEnum.EN]: "Poltava Oblast",
        [LanguagesEnum.PL]: "Obwód Połtawski",
        [LanguagesEnum.UK]: "Полтавська область",
      },
    },
    {
      name: "Rivnenska oblast",
      isoCode: "56",
      countryCode: "UA",
      latitude: "50.61990000",
      longitude: "26.25161700",
      translation: {
        [LanguagesEnum.EN]: "Rivne Oblast",
        [LanguagesEnum.PL]: "Obwód Rówieński",
        [LanguagesEnum.UK]: "Рівненська область",
      },
    },
    {
      name: "Sumska oblast",
      isoCode: "59",
      countryCode: "UA",
      latitude: "50.90770000",
      longitude: "34.79810000",
      translation: {
        [LanguagesEnum.EN]: "Sumy Oblast",
        [LanguagesEnum.PL]: "Obwód Sumski",
        [LanguagesEnum.UK]: "Сумська область",
      },
    },
    {
      name: "Ternopilska oblast",
      isoCode: "61",
      countryCode: "UA",
      latitude: "49.55351700",
      longitude: "25.59476700",
      translation: {
        [LanguagesEnum.EN]: "Ternopil Oblast",
        [LanguagesEnum.PL]: "Obwód Tarnopolski",
        [LanguagesEnum.UK]: "Тернопільська область",
      },
    },
    {
      name: "Vinnytska oblast",
      isoCode: "05",
      countryCode: "UA",
      latitude: "49.23308300",
      longitude: "28.46821690",
      translation: {
        [LanguagesEnum.EN]: "Vinnytsia Oblast",
        [LanguagesEnum.PL]: "Obwód Winnicki",
        [LanguagesEnum.UK]: "Вінницька область",
      },
    },
    {
      name: "Volynska oblast",
      isoCode: "07",
      countryCode: "UA",
      latitude: "50.74723200",
      longitude: "24.19416690",
      translation: {
        [LanguagesEnum.EN]: "Volyn Oblast",
        [LanguagesEnum.PL]: "Obwód Wołyński",
        [LanguagesEnum.UK]: "Волинська область",
      },
    },
    {
      name: "Zakarpatska oblast",
      isoCode: "21",
      countryCode: "UA",
      latitude: "48.62080000",
      longitude: "22.28788300",
      translation: {
        [LanguagesEnum.EN]: "Zakarpattia Oblast",
        [LanguagesEnum.PL]: "Obwód Zakarpacki",
        [LanguagesEnum.UK]: "Закарпатська область",
      },
    },
    {
      name: "Zaporizka oblast",
      isoCode: "23",
      countryCode: "UA",
      latitude: "47.83780000",
      longitude: "35.19030000",
      translation: {
        [LanguagesEnum.EN]: "Zaporizhzhia Oblast",
        [LanguagesEnum.PL]: "Obwód Zaporoże",
        [LanguagesEnum.UK]: "Запорізька область",
      },
    },
    {
      name: "Zhytomyrska oblast",
      isoCode: "18",
      countryCode: "UA",
      latitude: "50.25279130",
      longitude: "28.66365930",
      translation: {
        [LanguagesEnum.EN]: "Zhytomyr Oblast",
        [LanguagesEnum.PL]: "Obwód Żytomierski",
        [LanguagesEnum.UK]: "Житомирська область",
      },
    },
    {
      name: "Ishmael",
      isoCode: "city-ishmael",
      countryCode: "UA",
      latitude: "0",
      longitude: "0",
      type: "city",
      translation: {
        [LanguagesEnum.EN]: "Ishmael",
        [LanguagesEnum.PL]: "Ismael",
        [LanguagesEnum.UK]: "Ізмаїл",
      },
    },
    {
      name: "Renee",
      isoCode: "city-renee",
      countryCode: "UA",
      latitude: "0",
      longitude: "0",
      type: "city",
      translation: {
        [LanguagesEnum.EN]: "Renee",
        [LanguagesEnum.PL]: "Renee",
        [LanguagesEnum.UK]: "Рені",
      },
    },
  ],
];

export const fetchLocations = async (
  setCountries: any,
  setCountriesStates: any,
  setIsLocationsLoading: any
) => {
  setIsLocationsLoading(true);

  const { countries = [] } = await locationApi.getCountries();

  const updatedStates = states.reduce((acc: any, states: any) => {
    const { countryCode } = states[0];

    return { ...acc, [countryCode]: states };
  }, {});

  setCountries(countries);
  setCountriesStates(updatedStates);
  setIsLocationsLoading(false);
};
