import React, { ReactElement, useEffect } from "react";
import {
  MobileBottomSlideModalHeaderStyledWrapper,
  MobileBottomSlideModalStyledWrapper,
  MobileOverlayStyledWrapper,
} from "./MobileBottomSlideModal.styled";
import { ImCross } from "react-icons/im";

interface MobileBottomSlideModalProps {
  isOpen: boolean;
  title: string;
  Content: ReactElement;
  LeftSlot?: ReactElement;

  onClose: () => void;
}

export const MobileBottomSlideModal = (
  props: MobileBottomSlideModalProps
): ReactElement => {
  const { Content, LeftSlot, isOpen, title, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const isLeftSlotProvided = Boolean(LeftSlot);

  return (
    <>
      <MobileBottomSlideModalStyledWrapper isOpen={isOpen}>
        <MobileBottomSlideModalHeaderStyledWrapper>
          {isLeftSlotProvided && LeftSlot}

          <p style={{ marginLeft: isLeftSlotProvided ? -36 : 0 }}>{title}</p>

          <div onClick={onClose} style={{ marginBottom: -8, maxWidth: 50 }}>
            <ImCross size={22} />
          </div>
        </MobileBottomSlideModalHeaderStyledWrapper>

        <div
          style={{ overflowY: "auto", padding: "15px 30px 0", height: "90%" }}
        >
          {Content}
        </div>
      </MobileBottomSlideModalStyledWrapper>

      <MobileOverlayStyledWrapper isOpen={isOpen} />
    </>
  );
};
