import { Input } from "antd";

import { useTranslation } from "react-i18next";
import { CurrencyEnum } from "../../../types/enums/Currency.enum";
import { translationMap } from "../../../i18n/translationMap";
import { currencyPrefixesMap } from "../../../constants/currencies.constants";

interface CurrencyInputProps {
  value?: number;
  currency: CurrencyEnum;
  disabled?: boolean;
  width?: string;
  onChange: (e: any) => void;
}

export const CurrencyInput = (props: CurrencyInputProps) => {
  const { value, currency, disabled, width, onChange } = props;

  const { t } = useTranslation();

  return (
    <Input
      value={value}
      style={{ width: width || "100%" }}
      placeholder={t(translationMap.post.price) as string}
      type="number"
      prefix={currencyPrefixesMap[currency]}
      onChange={onChange}
      required
      min={0}
      disabled={disabled}
    />
  );
};
