import React, { ReactElement } from "react";

import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import { Plan } from "../../../shared-components/Plan/Plan";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { TradingPlanInterface } from "../../../../types/interfaces/TradingPlan.interface";
import { tradingApi } from "../../../../api/requests/trading.api";
import { navigateByCheckoutUrl } from "../../../../utils/navigation-utils/navigateByCheckoutUrl";

interface TradingCreationPostPlanProps {
  plan: TradingPlanInterface;
  currency: CurrencyEnum;
}

const CustomPlanButtons = ({ onClick }: any): ReactElement => {
  return (
    <>
      <CustomButton type="primary" style={{ width: "100%" }} onClick={onClick}>
        Поповнити
      </CustomButton>
    </>
  );
};

export const TradingCreationPostPlan = observer(
  (props: TradingCreationPostPlanProps): ReactElement => {
    const { plan, currency } = props;

    const { isMobile, language } = useStore(StoresEnum.APP_STORE) as AppStore;

    const increaseAccountBalance = async (): Promise<void> => {
      const { payment } = await tradingApi.getBalanceCheckoutUrl(
        plan.id,
        currency
      );

      navigateByCheckoutUrl(payment.checkoutUrl);
    };

    return (
      <Plan
        isMobile={isMobile()}
        plan={plan}
        selectedCurrency={currency}
        handleChoosePlan={() => {}}
        CustomPlanButtons={
          <CustomPlanButtons onClick={increaseAccountBalance} />
        }
        language={language}
      />
    );
  }
);
