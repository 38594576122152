import styled from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";

export const MobilePostStyledWrapper = styled.div`
  display: flex;
  margin: 5px auto;
  width: 92%;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  box-shadow: -1px 10px 11px -6px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const MobilePostHeaderStyledWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const MobilePostMainInfoStyledWrapper = styled.div`
  width: 100%;
  margin-left: 6px;
  padding-bottom: 6px;
`;

export const MobilePostTitleAndDateStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const MobilePostTitleStyledWrapper = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MobilePostDateStyledWrapper = styled.p`
  font-size: 14px;
  color: ${colors.darkGrey};
`;

export const MobilePostLocationAndPriceStyledWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
`;

export const MobilePostLocationStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  p {
    font-size: 14px;
  }
`;

export const MobilePostPriceStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 6px;

  & .price {
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 800;
  }
`;

export const MobileCerealCropImageStyledWrapper = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 6px;
`;

export const MobilePostDetailsAndContactsStyledWrapper = styled.div<{
  isDetailsEmpty: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .details {
    font-weight: 600;
    color: ${({ isDetailsEmpty }: any) =>
      isDetailsEmpty ? colors.lightGrey : colors.primary};
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  & .quantity {
    font-size: 16px;
    letter-spacing: -1;
    display: flex;
    align-items: center;
    text-align: left;
    width: 130px;
  }
`;

export const MobileDetailsStyledWrapper = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }: any) => (isOpen ? "auto" : 0)};
  display: ${({ isOpen }: any) => (isOpen ? "block" : "none")};
  max-width: 100%;

  transition: 0.3s;
`;

export const MobilePostDescriptionStyledWrapper = styled.p`
  font-size: 16px;
  margin-top: 10px;
  border-radius: 6px;
  padding: 5px 8px;
  word-break: break-word;
  text-align: justify;
  background: ${colors.darkWhite};
`;

export const MobilePostLocationAvailabilityStyledWrapper = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
