import { ReactElement, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { usePostStore } from "../../../../hooks/use-posts-store/usePostStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { CerealCropInterface } from "../../../../types/interfaces/CerealCrop.interface";
import { colors } from "../../../../constants/styles/colors.constants";

const quickFilters: any = ["WHEAT", "SOYA", "CORN", "RIPAK"];

export const QuickFilters = observer((): ReactElement => {
  const { handleChangeFilters, unstable_filters } = usePostStore();

  const { cerealCrops, language } = useStore(StoresEnum.APP_STORE) as AppStore;

  const [localQuickFilters, setLocalQuickFilters] = useState<any>([]);

  const quickCrops = cerealCrops.filter(({ name }: CerealCropInterface) => {
    return quickFilters.includes(name);
  });

  const handleCheckQuickFilter = (value: CerealCropInterface) => {
    const names = localQuickFilters.map(
      (item: CerealCropInterface) => item.name
    );
    const isSelected = names.includes(value.name);

    if (isSelected) {
      const filtered = localQuickFilters.filter((item: any) => {
        return item.name !== value.name;
      });

      setLocalQuickFilters(filtered);

      return;
    }

    setLocalQuickFilters([...localQuickFilters, value]);
  };

  useEffect(() => {
    const ids = localQuickFilters.map((item: CerealCropInterface) => {
      return item.id;
    });

    handleChangeFilters({
      ...unstable_filters,
      cerealCrops: ids,
    });
  }, [localQuickFilters]);

  return (
    <div style={{ display: "flex", width: "96%", margin: "4px auto" }}>
      {quickCrops.map((item: CerealCropInterface) => {
        const isSelected = localQuickFilters.find((filter: any) => {
          return filter.name === item.name;
        });

        return (
          <div
            style={{
              margin: "0 4px",
              width: "25%",
              background: isSelected ? colors.primary : colors.white,
              color: isSelected ? colors.white : colors.primary,
              padding: 4,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
              fontWeight: 600,
              borderRadius: 4,
              fontSize: 16,
            }}
            onClick={() => handleCheckQuickFilter(item)}
          >
            {item.translations[language]}
          </div>
        );
      })}
    </div>
  );
});
