import { get } from "lodash";
import { LanguagesEnum } from "../../types/enums/Languages.enum";
import { ContactsInterface } from "../../types/interfaces/Contacts.interface";
import { RefreshTokenResponseInterface } from "../../types/interfaces/RefreshToken.interface";

interface LocalStorageInterface {
  setToken(token: string): void;
  setRefreshToken(refreshToken: string): void;
  setTokenExpiresIn(refreshToken: string): void;
  setRefreshTokenExpiresIn(refreshTokenExpiresIn: string): void;
  setAllAuthUserInfo(userAuthInfo: RefreshTokenResponseInterface): void;

  getToken(): string;
  getRefreshToken(): string;
  getTokenExpiresIn(): string;
  getRefreshTokenExpiresIn(): string;
}

export enum LocalStorageFields {
  TOKEN = "token",
  REFRESH_TOKEN = "refreshToken",
  TOKEN_EXPIRES_IN = "tokenExpirationTime",
  REFRESH_TOKEN_EXPIRES_IN = "refreshTokenExpirationTime",
}

class LocalStorageUtils implements LocalStorageInterface {
  setToken(token: string): void {
    localStorage.setItem(LocalStorageFields.TOKEN, token);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(LocalStorageFields.REFRESH_TOKEN, refreshToken);
  }

  setTokenExpiresIn(tokenExpirationTime: string): void {
    localStorage.setItem(
      LocalStorageFields.TOKEN_EXPIRES_IN,
      tokenExpirationTime
    );
  }

  setRefreshTokenExpiresIn(refreshTokenExpiresIn: string): void {
    localStorage.setItem(
      LocalStorageFields.REFRESH_TOKEN_EXPIRES_IN,
      refreshTokenExpiresIn
    );
  }

  setLanguage(language: LanguagesEnum): void {
    localStorage.setItem("language", language);
  }

  setPostContacts(contacts: Record<string, ContactsInterface>): void {
    const postContacts = JSON.stringify(contacts);

    localStorage.setItem("postContacts", postContacts);
  }

  setDeviceMarkedAsUsed(): void {
    localStorage.setItem("plan", "true");
  }

  setTour(): void {
    const tour = this.getTour();

    if (tour === 2) {
      return;
    }

    if (!tour) {
      localStorage.setItem("tour", "1");

      return;
    }

    localStorage.setItem("tour", String(tour + 1));
  }

  getToken(): string {
    return localStorage.getItem(LocalStorageFields.TOKEN) || "";
  }

  getRefreshToken(): string {
    return localStorage.getItem(LocalStorageFields.REFRESH_TOKEN) || "";
  }

  getTokenExpiresIn(): string {
    return localStorage.getItem(LocalStorageFields.TOKEN_EXPIRES_IN) || "";
  }

  getRefreshTokenExpiresIn(): string {
    return (
      localStorage.getItem(LocalStorageFields.REFRESH_TOKEN_EXPIRES_IN) || ""
    );
  }

  getLanguage(): LanguagesEnum | null {
    return localStorage.getItem("language") as LanguagesEnum | null;
  }

  getPostContacts(): Record<string, ContactsInterface> | null {
    const postContacts = localStorage.getItem("postContacts");

    return postContacts ? JSON.parse(postContacts) : null;
  }

  getDeviceMarkedAsUsed(): boolean {
    return Boolean(localStorage.getItem("plan"));
  }

  getTour(): number {
    return Number(localStorage.getItem("tour"));
  }

  getIsTourFinished(): boolean {
    return Boolean(localStorage.getItem("tour") === "2");
  }

  getIsTradingTourDone(): string | null {
    return localStorage.getItem("tradingTour");
  }

  setIsTradingTourDone(): void {
    localStorage.setItem("tradingTour", "true");
  }

  setAllAuthUserInfo(
    userAuthInfo: Partial<RefreshTokenResponseInterface>
  ): void {
    const {
      token = "",
      refreshToken = "",
      expiresIn,
      refreshTokenExpiresIn,
    } = userAuthInfo || {};

    this.setToken(token);
    this.setRefreshToken(refreshToken);
    this.setTokenExpiresIn(Date.now() + String(expiresIn));
    this.setRefreshTokenExpiresIn(String(refreshTokenExpiresIn));
  }

  clearAuthData(): void {
    localStorage.removeItem(LocalStorageFields.TOKEN);
    localStorage.removeItem(LocalStorageFields.REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageFields.TOKEN_EXPIRES_IN);
    localStorage.removeItem(LocalStorageFields.REFRESH_TOKEN_EXPIRES_IN);
  }

  getPriceUpModal(): string {
    return localStorage.getItem("priceUpViewed") || "";
  }

  setPriceUpModal(): void {
    localStorage.setItem("priceUpViewed", "true");
  }

  getTelegramUpModal(): string {
    return localStorage.getItem("telegramUpViewed") || "";
  }

  setTelegramUpModal(): void {
    localStorage.setItem("telegramUpViewed", "true");
  }
}

const localStorageUtils = new LocalStorageUtils();

export { localStorageUtils };
