import React, { ReactElement } from "react";

import {
  AdditionalCurrencyStyledWrapper,
  CurrenciesListStyledWrapper,
} from "./PriceForm.styled";
import { availableCurrenciesDefaultList } from "../../../constants/currencies.constants";
import { CurrencyEnum } from "../../../types/enums/Currency.enum";
import { CurrencyInput } from "../CurrencyInput/CurrencyInput";

interface PriceFormProps {
  prices: Record<CurrencyEnum, number>;
  onChange: any;
}

export const PriceForm = (props: PriceFormProps): ReactElement => {
  const { prices, onChange } = props;

  return (
    <CurrenciesListStyledWrapper>
      {availableCurrenciesDefaultList.map((currency: CurrencyEnum) => (
        <AdditionalCurrencyStyledWrapper>
          <CurrencyInput
            value={prices?.[currency] || undefined}
            currency={currency}
            onChange={onChange(currency)}
          />
        </AdditionalCurrencyStyledWrapper>
      ))}
    </CurrenciesListStyledWrapper>
  );
};
