import { LanguagesEnum } from "../../../../../../../types/enums/Languages.enum";

export const updateDescriptionInformation = (
  currentLanguage: LanguagesEnum,
  selectedLanguage: LanguagesEnum[],
  descriptions: Record<string, string>
) => {
  return [currentLanguage, ...selectedLanguage].reduce(
    (acc: any, language: LanguagesEnum) => {
      if (!language) {
        return { ...acc };
      }

      if (descriptions?.[language]) {
        return { ...acc, [language]: descriptions[language] };
      }

      return {
        ...acc,
        [language]: "",
      };
    },
    {}
  );
};
