import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { AuthModal } from "./AuthModal";
import { UserStore } from "../../../../store/UserStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { SignUpForm } from "../AuthForms/SignUpForm/SignUpForm";
import { AppStore } from "../../../../store/AppStore";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../i18n/translationMap";

export const SignUpModal = observer((): ReactElement => {
  const { isSignUpModalOpen, setSignUpModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { t } = useTranslation();

  if (isMobile()) {
    return <></>;
  }

  return (
    <AuthModal
      title={t(translationMap.auth.signUp)}
      isOpen={isSignUpModalOpen}
      content={<SignUpForm />}
      handleClose={setSignUpModalOpenClose}
    />
  );
});
