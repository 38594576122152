import React, { ReactElement } from "react";
import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";
import { AddOneMorePostPlugStyled } from "./AddOneMorePostPlug.styled";
import { MdAdminPanelSettings, MdOutlineCloudDone } from "react-icons/md";
import { BsPostcard } from "react-icons/bs";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { colors } from "../../../../../constants/styles/colors.constants";
import { observer } from "mobx-react-lite";
import { useAddPostStore } from "../../../../../hooks/use-add-post-store/useAddPostStore";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../i18n/translationMap";

export const AddOneMorePostPlug = observer(
  ({ isMobile = false }: any): ReactElement => {
    const { onClearPostModal } = useAddPostStore();
    const { t } = useTranslation();

    const handleAddOneMorePost = () => {
      onClearPostModal();
    };

    return (
      <AddOneMorePostPlugStyled>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            marginTop: isMobile ? "30px" : 0,
          }}
        >
          <BsPostcard style={{ fontSize: "50px", marginBottom: -10 }} />

          <HiOutlineArrowNarrowRight
            style={{
              fontSize: isMobile ? "40px" : "60px",
              margin: "0 10px",
              marginBottom: -15,
              color: colors.lightGrey,
            }}
          />

          <MdAdminPanelSettings
            style={{
              fontSize: "70px",
              margin: "0 2px",
              marginBottom: -12,
              color: colors.primary,
            }}
          />

          <HiOutlineArrowNarrowRight
            style={{
              fontSize: isMobile ? "40px" : "60px",
              margin: "0 10px",
              marginBottom: -15,
              color: colors.lightGrey,
            }}
          />

          <MdOutlineCloudDone style={{ fontSize: "50px", marginBottom: -8 }} />
        </div>

        <p
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginTop: 10,
            textAlign: isMobile ? "center" : "center",
          }}
        >
          {t(translationMap.post.postSuccessfullyCreated)}
        </p>
        <p
          style={{
            fontSize: 18,
            marginBottom: 20,
            marginTop: isMobile ? "20px" : 5,
          }}
        >
          {t(translationMap.post.youCanAddOneMorePost)}
        </p>

        <CustomButton
          type="primary"
          onClick={handleAddOneMorePost}
          size="large"
        >
          {t(translationMap.post.addOneMorePost)}
        </CustomButton>
      </AddOneMorePostPlugStyled>
    );
  }
);
