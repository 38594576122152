import React, { ReactElement } from "react";

import {
  CurrencyStyledWrapper,
  SelectCurrencyStyledWording,
  SelectCurrencyStyledWrapper,
  SelectPlanCurrencyStyledWrapper,
} from "./SelectPlanCurrency.styled";
import { CurrencyEnum } from "../../../types/enums/Currency.enum";
import { translationMap } from "../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import {
  currenciesForPlans,
  currencyPrefixesMap,
} from "../../../constants/currencies.constants";

interface SelectPlanCurrencyProps {
  selectedCurrency: string;
  setSelectedCurrency: (currency: CurrencyEnum) => void;
}

export const SelectPlanCurrency = (
  props: SelectPlanCurrencyProps
): ReactElement => {
  const { selectedCurrency, setSelectedCurrency } = props;

  const { t } = useTranslation();

  return (
    <SelectPlanCurrencyStyledWrapper>
      <SelectCurrencyStyledWording>
        {t(translationMap.payment.selectCurrency)}
      </SelectCurrencyStyledWording>

      <SelectCurrencyStyledWrapper>
        {currenciesForPlans.map((currency) => {
          const isCurrencySelected = selectedCurrency === currency;

          return (
            <CurrencyStyledWrapper
              $isSelected={isCurrencySelected}
              onClick={() => setSelectedCurrency(currency)}
            >
              {`${currency} `} {currencyPrefixesMap[currency]}
            </CurrencyStyledWrapper>
          );
        })}
      </SelectCurrencyStyledWrapper>
    </SelectPlanCurrencyStyledWrapper>
  );
};
