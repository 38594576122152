import React, { ReactElement, useState } from "react";
import { observer } from "mobx-react-lite";

import { ChoosePlanListStyledWrapper, PlansStyledWrapper } from "./Plan.styled";
import { UserStore } from "../../../../store/UserStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { paymentApi } from "../../../../api/requests/payment.api";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../i18n/translationMap";
import { Plan } from "../../../shared-components/Plan/Plan";
import { navigateByCheckoutUrl } from "../../../../utils/navigation-utils/navigateByCheckoutUrl";
import { SelectPlanCurrency } from "../../../shared-components/SelectPlanCurrency/SelectPlanCurrency";

const ChoosePlanList = ({
  isMobile,
  isPaymentStarted,
  plans,
  selectedCurrency,
  handleChoosePlan,
  language,
  isDisabled,
}: any) => {
  if (isPaymentStarted) {
    return <Spin style={{ minHeight: isMobile ? 200 : 270 }} />;
  }

  return (
    <ChoosePlanListStyledWrapper>
      {plans.map((plan: any) => {
        return (
          <Plan
            language={language}
            isMobile={isMobile}
            isBasePlan
            plan={plan}
            selectedCurrency={selectedCurrency}
            handleChoosePlan={handleChoosePlan}
            isDisabled={isDisabled}
          />
        );
      })}
    </ChoosePlanListStyledWrapper>
  );
};

export const PlansList = observer(({ isMobile }: any): ReactElement => {
  const { plans, language } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { user, isSubscriptionActive } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const [isPaymentStarted, setIsPaymentStarted] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(CurrencyEnum.UAH);

  const { t } = useTranslation();

  const handleChoosePlan = async (selectedPlanId: string) => {
    if (!selectedPlanId || !user?.id) {
      return;
    }

    const checkoutUrl = await paymentApi.getCheckoutUrl(
      selectedPlanId,
      selectedCurrency,
      user?.id
    );

    if (!checkoutUrl) {
      // TODO: handle error
      alert("Something went wrong with checkoutUrl");
      return;
    }

    setIsPaymentStarted(true);

    setTimeout(() => {
      setIsPaymentStarted(false);
    }, 3500);

    navigateByCheckoutUrl(checkoutUrl);
  };

  const isUserLoggedIn = !!user?.id;

  return (
    <PlansStyledWrapper>
      {!isSubscriptionActive && isUserLoggedIn && (
        <>
          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              margin: "5px 0 10px 0",
              fontWeight: 600,
            }}
          >
            {t(translationMap.payment.subscriptionExpirationMessage)}
          </p>
        </>
      )}

      {!isUserLoggedIn && (
        <p
          style={{
            fontSize: 16,
            textAlign: "center",
            margin: "5px 0 10px 0",
            fontWeight: 600,
          }}
        >
          Зареєструйтесь, щоб отримати доступ до тарифів
        </p>
      )}

      <SelectPlanCurrency
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
      />

      <ChoosePlanList
        language={language}
        isMobile={isMobile}
        isPaymentStarted={isPaymentStarted}
        plans={plans}
        selectedCurrency={selectedCurrency}
        handleChoosePlan={handleChoosePlan}
        isDisabled={!isUserLoggedIn}
      />
    </PlansStyledWrapper>
  );
});
