import styled from "styled-components";
import { colors } from "../../constants/styles/colors.constants";

export const BotAuthStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 95%;
  margin: 0 auto;
`;

export const TelegramBotAuthStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.link};
  padding: 50px;
  border-radius: 5px;
  color: ${colors.white};
  font-weight: 600;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

  p {
    font-size: 22px;
    text-align: center;
  }

  .ant-spin-dot-item {
    background-color: ${colors.white};
  }
`;

export const IconsStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
