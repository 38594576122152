import styled from "styled-components";

export const TradingAccountAvatarEditStyledWrapper = styled.div``;

export const CurrentAvatarStyledImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
`;

export const CurrentAvatarStyledWrapper = styled.div`
  position: relative;
`;
