import React, { ReactElement } from "react";
import { PageStyledWrapper } from "./Page.styled";
import { observer } from "mobx-react-lite";
import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../store/AppStore";

interface PageProps {
  children: ReactElement | ReactElement[];
}

export const Page = observer(({ children }: PageProps): ReactElement => {
  const { device } = useStore(StoresEnum.APP_STORE) as AppStore;

  return <PageStyledWrapper device={device}>{children}</PageStyledWrapper>;
});
