import { ReactElement } from "react";
import { AcceptPoliciesWrapper } from "./AcceptPolicies.styled";
import { Checkbox } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../i18n/translationMap";

interface AcceptPoliciesProps {
  isAccepted: boolean;
  onChange: (value: boolean) => void;
}

export const AcceptPolicies = (props: AcceptPoliciesProps): ReactElement => {
  const { isAccepted, onChange } = props;

  const { t } = useTranslation();

  return (
    <AcceptPoliciesWrapper>
      <Checkbox
        checked={isAccepted}
        onChange={(e) => onChange(e.target.checked)}
      >
        {t(translationMap.auth.iAccept)}{" "}
        <Link to="/terms-of-use">{t(translationMap.menu.termOfUse)}</Link>{" "}
        {t(translationMap.auth.and)}{" "}
        <Link to="/privacy-policy">{t(translationMap.menu.privacyPolicy)}</Link>
      </Checkbox>
    </AcceptPoliciesWrapper>
  );
};
