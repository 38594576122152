import styled from "styled-components";
import { colors } from "../../../../../../../constants/styles/colors.constants";

export const DeliveryInformationStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AvailableForStyledWrapper = styled.div<{ isSelected: boolean }>`
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 70px;
    height: 70px;
    background: ${({ isSelected }) =>
      isSelected ? colors.primary : colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    transition: 0.3s;

    color: ${({ isSelected }) => (isSelected ? colors.white : colors.black)};
  }

  p {
    font-size: 16;
    font-weight: 600;
    margin-top: 10px;
  }
`;
