import React, { ReactElement, useEffect } from "react";

import { ValidationSelect } from "../ValisdationSelect/VaslidationSelect";
import { observer } from "mobx-react-lite";
import { translationMap } from "../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { getCountryStatesOptions } from "../../../utils/options-utils/getCountriesOptions";
import { AppStore } from "../../../store/AppStore";
import {
  getStateNameByCode,
  getTranslatedStates,
} from "../../../utils/common-utils/getTranslatedStates";
import { CustomButton } from "../CustomButton/CustomButton";
import { CountriesEnum } from "../../../types/enums/Countries.enum";
import { Checkbox } from "antd";
import { colors } from "../../../constants/styles/colors.constants";
import { is } from "date-fns/locale";

interface StatesListProps {
  value?: string | string[];
  isValid: boolean;
  onChange: (value: string) => void;
  isRequired: boolean;
  style?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  disabled?: boolean;
  showSearch?: boolean;
  country: CountriesEnum;
  mode?: "tags" | "multiple" | undefined;
  isShortForm?: boolean;
  onChangeIsFullCountry?: (value: boolean) => void;
  isFullCountry?: boolean;
}

export const StatesList = observer((props: StatesListProps): ReactElement => {
  const {
    value,
    isValid,
    onChange: handleChangeState,
    isRequired,
    style = {},
    selectStyle = {},
    disabled,
    showSearch,
    country,
    mode = undefined,
    isShortForm = false,
    onChangeIsFullCountry,
    isFullCountry = false,
  } = props;

  const { countriesStates, language } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  const stateOptions = getCountryStatesOptions(
    countriesStates?.[country] || [],
    language,
    isShortForm
  );

  const states = countriesStates?.[country] || [];
  const cities =
    states?.filter(({ type }: any) => {
      return type === "city";
    }) || [];

  const { t } = useTranslation();

  const toggleIsFullCountry = () => {
    onChangeIsFullCountry?.(!isFullCountry);
  };

  return (
    <div>
      <ValidationSelect
        showSearch={false}
        placeholder={
          isFullCountry ? "Вся Україна" : t(translationMap.post.selectState)
        }
        isValid={isValid}
        value={isFullCountry ? undefined : value || undefined}
        isRequired={isRequired}
        onChange={handleChangeState}
        style={style}
        selectStyle={selectStyle}
        options={stateOptions}
        disabled={disabled || isFullCountry}
        mode={mode}
        listHeight={155}
        getPopupContainer={(trigger) => trigger.parentNode}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px 0 20px 0",
        }}
      >
        <div>
          <Checkbox
            type="checkbox"
            onClick={toggleIsFullCountry}
            checked={isFullCountry}
          >
            Вся Україна
          </Checkbox>
        </div>
        <div>
          {cities.map(({ isoCode }: any) => {
            const isIncluded = value?.includes(isoCode);

            return (
              <CustomButton
                type={isIncluded ? "primary" : "default"}
                key={isoCode}
                disabled={isFullCountry}
                onClick={() => {
                  if (isIncluded) {
                    handleChangeState(
                      // @ts-ignore
                      value?.filter((code: string) => code !== isoCode)
                    );
                    return;
                  }

                  handleChangeState(
                    // @ts-ignore
                    value?.length > 0 ? [...value, isoCode] : [isoCode]
                  );
                }}
                style={{
                  width: 70,
                  marginLeft: 10,
                  border: isIncluded ? "none" : `1px solid ${colors.primary}`,
                }}
              >
                {getStateNameByCode({
                  isoCode: isoCode,
                  language,
                  country,
                  countriesStates,
                })}
              </CustomButton>
            );
          })}
        </div>
      </div>
    </div>
  );
});
