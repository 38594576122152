import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { SignInForm } from "../SignInForm/SignInForm";
import { SignUpForm } from "../SignUpForm/SignUpForm";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../../../i18n/translationMap";
import React from "react";
import { CustomButton } from "../../../../shared-components/CustomButton/CustomButton";

enum AuthTabsEnum {
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
}

export const MobileAuthForm = observer(() => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState(AuthTabsEnum.SIGN_UP);

  const handleSelectSignInTab = () => {
    setActiveTab(AuthTabsEnum.SIGN_IN);
  };

  const handleSelectSignUpTab = () => {
    setActiveTab(AuthTabsEnum.SIGN_UP);
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomButton
            style={{ width: "49%", padding: 0 }}
            size="large"
            type={activeTab === AuthTabsEnum.SIGN_UP ? "primary" : "default"}
            onClick={handleSelectSignUpTab}
          >
            {t(translationMap.auth.signUp)}
          </CustomButton>
          <CustomButton
            style={{ width: "48%" }}
            size="large"
            type={activeTab === AuthTabsEnum.SIGN_IN ? "primary" : "default"}
            onClick={handleSelectSignInTab}
          >
            {t(translationMap.auth.signIn)}
          </CustomButton>
        </div>

        <div style={{ marginTop: 20, width: "90%" }}>
          {activeTab === AuthTabsEnum.SIGN_UP && <SignUpForm />}

          {activeTab === AuthTabsEnum.SIGN_IN && <SignInForm />}
        </div>
      </div>
    </div>
  );
});
