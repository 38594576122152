import {
  AddTradingPostStore,
  TradingPostDataInterface,
} from "./../../store/AddTradingPostStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { SyntheticEvent } from "react";
import { CurrencyEnum } from "../../types/enums/Currency.enum";
import { PaymentTypesEnum } from "../../types/enums/PaymentTypes.enum";

interface UseCreateTradingFormInterface {
  tradingPostData: TradingPostDataInterface | null;

  handleChangeCrop: (crop: string) => void;
  handleChangeAmount: (event: SyntheticEvent) => void;
  handleChangeCountry: (country: string) => void;
  handleChangeState: (state: string) => void;
  handleChangePrice: (price: number, currency: CurrencyEnum) => void;
  handleChangeComment: (comment: any) => void;
  handleChangePhone: (phone: any) => void;
  handleChangePaymentType: (paymentType: PaymentTypesEnum) => void;
  handleChangeIsFullCountry: (isFullCountry: boolean) => void;
  onClearTradingCreateForm: () => void;
}

export const useCreateTradingForm = (): UseCreateTradingFormInterface => {
  const { tradingPostData, setTradingPost } = useStore(
    StoresEnum.ADD_TRADING_POST_STORE
  ) as AddTradingPostStore;

  const handleChangeCrop = (crop: string) => {
    setTradingPost({
      ...tradingPostData,
      crop,
    });
  };

  const handleChangeAmount = (event: SyntheticEvent) => {
    // @ts-ignore
    const amount = Number(event.target.value);

    setTradingPost({
      ...tradingPostData,
      amount,
    });
  };

  const handleChangeCountry = (country: string) => {
    setTradingPost({
      ...tradingPostData,
      country,
    });
  };

  const handleChangeState = (states: string) => {
    setTradingPost({
      ...tradingPostData,
      states,
    });
  };

  const handleChangePrice = (price: number, currency: CurrencyEnum) => {
    const prices = {
      ...tradingPostData?.prices,
      [currency]: Number(price),
    };

    setTradingPost({
      ...tradingPostData,
      prices,
    });
  };

  const handleChangeComment = (event: any) => {
    setTradingPost({
      ...tradingPostData,
      comment: event.target.value,
    });
  };

  const handleChangePhone = (phone: any) => {
    setTradingPost({
      ...tradingPostData,
      phone,
    });
  };

  const handleChangePaymentType = (paymentType: PaymentTypesEnum) => {
    setTradingPost({
      ...tradingPostData,
      paymentType,
    });
  };

  const handleChangeIsFullCountry = (isFullCountry: boolean) => {
    setTradingPost({
      ...tradingPostData,
      isFullCountry,
    });
  };

  const onClearTradingCreateForm = () => {
    setTradingPost(null);
  };

  return {
    tradingPostData,

    handleChangeCrop,
    handleChangeAmount,
    handleChangeCountry,
    handleChangeState,
    handleChangePrice,
    handleChangeComment,
    handleChangePhone,
    handleChangePaymentType,
    handleChangeIsFullCountry,
    onClearTradingCreateForm,
  };
};
