import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

// MOBILE

export const MobilePostContactsStyled = styled.div`
  border: 1px solid ${colors.primary};
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`;

export const MobilePostContactsRootPhoneStyled = styled.a`
  color: ${colors.link};
  text-decoration: none;
  margin-bottom: 4px;
  display: inline-block;
`;

// DESKTOP

export const PostContactsStyled = styled.div`
  display: flex;
`;
