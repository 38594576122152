import React, { ReactElement } from "react";

import { AddYourPhone } from "./AddYourPhone/AddYourPhone";
import { SmsVerification } from "../../../auth-module/SmsVerification/SmsVerification";

import { ConfirmFormStyledWrapper } from "./ConfirmForm.styled";
import { observer } from "mobx-react-lite";
import { useAddPostStore } from "../../../../../hooks/use-add-post-store/useAddPostStore";
import { useConfirmCode } from "../../../../../hooks/use-confirm-code-hook/useConfirmCode";
import { SmsLimitationError } from "../../../auth-module/SmsLimitationError/SmsLimitationError";
import { useSmsLimitation } from "../../../../../hooks/use-sms-limitaion/useSmsLimitaion";

export const ConfirmForm = observer(
  ({
    phone,
    onSave,
    onValidatePhone,
    onResendCode,
    onValidateCode,
  }: any): ReactElement => {
    const { isPhoneAdded, setCurrentStep } = useAddPostStore();
    const {
      code,
      usedCode,
      isCodeInvalid,

      getCodeErrors,
      handleChangeCode,
      handleChangeUsedCode,
      handleChangeIsCodeValid,
    } = useConfirmCode();

    const {
      isSmsLimitExceeded,
      handleIsSmsLimitExceeded,
      checkIsSmsLimitationError,
    } = useSmsLimitation();

    const handleSave = async () => {
      const data = await onSave(code);
      const { isPostCreated, errors } = data;

      if (!isPostCreated) {
        handleChangeUsedCode(code);

        const { isCodeInvalid, isCodeExpired } = getCodeErrors(errors);

        if (checkIsSmsLimitationError(errors)) {
          handleIsSmsLimitExceeded(true);

          return;
        }

        if (isCodeInvalid || isCodeExpired) {
          handleChangeIsCodeValid(true);

          return;
        }

        return;
      }

      setCurrentStep(5);
    };

    const handleValidateChangeCode = (code: string) => {
      onValidateCode(code);
      handleChangeCode(code);
    };

    if (!isPhoneAdded) {
      return (
        <ConfirmFormStyledWrapper>
          <AddYourPhone onValidatePhone={onValidatePhone} />

          <SmsLimitationError isSmsLimitExceeded={isSmsLimitExceeded} />
        </ConfirmFormStyledWrapper>
      );
    }

    return (
      <ConfirmFormStyledWrapper>
        <SmsVerification
          phone={phone}
          code={code}
          onSave={handleSave}
          onChangeCode={handleValidateChangeCode}
          isInvalid={isCodeInvalid}
          resendCode={onResendCode}
          isConfirmButtonDisabled={code === usedCode || !Number(code)}
        />
      </ConfirmFormStyledWrapper>
    );
  }
);
