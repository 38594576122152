import { useState } from "react";
import { checkIsErrorExist } from "../../utils/error-utils/checkIsErrorExist";
import { UserErrorMessagesEnum } from "../../constants/error-messages/user-error-messages";

export const useSignIn = () => {
  const [phone, setPhone] = useState<string>("");
  const [isSignInStarted, setIsSignInStarted] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);

  const handleChangePhone = (value: string) => {
    setPhone(value);
  };

  const handleChangeIsSignInStarted = (isStarted: boolean) => {
    setIsSignInStarted(isStarted);
  };

  const handleIsPhoneValid = (isValid: boolean) => {
    setIsPhoneValid(isValid);
  };

  const handleIsTelegram = (isTelegram: boolean) => {
    setIsTelegram(isTelegram);
  };

  const checkUserNotFound = (errors: any) => {
    const isUserNotFond = checkIsErrorExist(
      errors,
      UserErrorMessagesEnum.USER_NOT_FOUND
    );

    if (isUserNotFond) {
      return setIsUserNotFound(isUserNotFond);
    }
  };

  return {
    phone,
    isPhoneValid,
    isSignInStarted,
    isUserNotFound,
    isTelegram,

    handleChangePhone,
    checkUserNotFound,
    handleIsPhoneValid,
    handleChangeIsSignInStarted,
    handleIsTelegram,
  };
};
