import styled, { css } from "styled-components";
import { DevicesEnum } from "../../../hooks/use-adaptation-hook/useAdapatation";

const DesKtopPageCSS = css`
  width: 1100px;
  padding-top: 65px;
  margin: 0 auto;
`;

const MobilePageCSS = css`
  width: 100vw;
`;

export const PageStyledWrapper = styled.div<{ device: DevicesEnum | null }>`
  ${({ device }) => device === DevicesEnum.DESKTOP && DesKtopPageCSS}
  ${({ device }) => device === DevicesEnum.MOBILE && MobilePageCSS}
  ${({ device }) => device === DevicesEnum.TABLET && MobilePageCSS}
`;
