import React, { ReactElement, useState } from "react";

import {
  CurrentAvatarStyledImg,
  CurrentAvatarStyledWrapper,
  TradingAccountCreateStyledWrapper,
} from "./TradingAccountCreate.styled";
import { Card } from "../../../shared-components/Card/Card";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { ImageCrop } from "../../../shared-components/ImageCrop/ImageCrop";
import { useOpenModal } from "../../../../hooks/use-open-modal-hook/useOpenModal";
import { MdEdit } from "react-icons/md";
import { useUploadFile } from "../../../../hooks/use-upload-file-hook/useUploadFile";
import { Input } from "antd";
import { tradingApi } from "../../../../api/requests/trading.api";
import { TradingAccountWidgetStatesEnum } from "../../../../types/enums/TradingAccountWidgetStates.enum";
import { ConfirmActionsBar } from "../../../shared-components/ConfirmActionsBar/ConfirmActionsBar";
import { AppStore } from "../../../../store/AppStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../store/UserStore";
import { ValidatedPhoneInput } from "../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";

export const LOGO_MOCK =
  "https://images.squarespace-cdn.com/content/v1/568981602399a3a3e507fff4/1548253334574-W4OFLUFUXJKJBXNW2UDK/Asset+2%40300x.png";

export const TradingAccountCreate = observer(
  ({ isCard = true }: any): ReactElement => {
    const { setTradingAccountState, setTradingAccount, toggleCreatePostModal } =
      useTradingStore();
    const { user } = useStore(StoresEnum.USER_STORE) as UserStore;

    const { isOpen: isCropModalOpen, toggleModal: toggleCropModal } =
      useOpenModal();

    const { file, onSetFile } = useUploadFile();

    const [accountName, setAccountName] = useState("");
    const [rootPhone, setRootPhone] = useState(user?.phone);
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
    const handleChangeCompanyName = (e: any) => {
      setAccountName(e.target.value);
    };

    const handleChangeRootPhone = (value: string) => {
      setRootPhone(value);
    };

    const createTradingAccount = async () => {
      const { tradingAccount } = await tradingApi.createTradingAccount(
        {
          name: accountName,
          contacts: {
            rootPhone: rootPhone,
          },
        },
        file
      );

      setTradingAccount(tradingAccount);
      setTradingAccountState(
        TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT
      );
    };

    const handleCancelEdit = () => {
      setTradingAccountState(TradingAccountWidgetStatesEnum.NO_TRADING_ACCOUNT);
    };

    const Content = (
      <TradingAccountCreateStyledWrapper $isMobile={isMobile()}>
        <CurrentAvatarStyledWrapper>
          <CurrentAvatarStyledImg
            src={file || LOGO_MOCK}
            alt="avatar"
            onClick={toggleCropModal}
          />

          {!isMobile() && (
            <MdEdit
              size={20}
              style={{
                position: "absolute",
                top: 10,
                right: 25,
              }}
            />
          )}

          <ImageCrop
            fileUrl={file}
            isOpen={isCropModalOpen}
            toggleModal={toggleCropModal}
            onFileChange={onSetFile}
          />
        </CurrentAvatarStyledWrapper>

        <div
          style={{
            width: "100%",
          }}
        >
          <Input
            placeholder="Назва компанії"
            value={accountName}
            onChange={handleChangeCompanyName}
            style={{ margin: "8px 0" }}
          />

          <ValidatedPhoneInput
            phone={rootPhone || ""}
            isValid={isPhoneValid}
            onChangePhone={handleChangeRootPhone}
            setIsPhoneValid={setIsPhoneValid}
          />

          <ConfirmActionsBar
            confirmText="Створити"
            cancelText="Скасувати"
            handleCancel={isCard ? handleCancelEdit : toggleCreatePostModal}
            handleConfirm={createTradingAccount}
          />
        </div>
      </TradingAccountCreateStyledWrapper>
    );

    if (!isCard) {
      return (
        <div
          style={{
            width: isMobile() ? "95%" : "48%",
            margin: "0 auto",
          }}
        >
          {Content}
        </div>
      );
    }

    return (
      <Card
        style={{
          width: isMobile() ? "95%" : "48%",
          margin: "0 auto",
        }}
      >
        {Content}
      </Card>
    );
  }
);
