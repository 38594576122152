import { httpClient } from "../httpClient/HttpClient";

export class CerealCropsApi {
  private CEREAL_CROPS_ENDPOINT = "cereal-crops";

  getAllCerealCrops = async (): Promise<any> => {
    const data = await httpClient.get(`${this.CEREAL_CROPS_ENDPOINT}/all`);

    return data;
  };
}

export const cerealCropsApi = new CerealCropsApi();
