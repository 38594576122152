import { useState } from "react";
import { ConfirmationCodeErrorMessagesEnum } from "../../constants/error-messages/confirmation-error-messages";
import { checkIsErrorExist } from "../../utils/error-utils/checkIsErrorExist";

export const useSmsLimitation = () => {
  const [isSmsLimitExceeded, setIsSmsLimitExceeded] = useState(false);

  const handleIsSmsLimitExceeded = (isExceeded: boolean) => {
    setIsSmsLimitExceeded(isExceeded);
  };

  const checkIsSmsLimitationError = (errors: any) => {
    const isLimitation = checkIsErrorExist(
      errors,
      ConfirmationCodeErrorMessagesEnum.SMS_LIMIT_EXCEEDED
    );

    return isLimitation;
  };

  return {
    isSmsLimitExceeded,

    handleIsSmsLimitExceeded,
    checkIsSmsLimitationError,
  };
};
