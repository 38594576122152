import React, { ReactElement, useState } from "react";

import {
  MobileTradingPostContactsRootPhoneStyled,
  Mobile_PostDateStyledWrapper,
  Mobile_PublicTradingPostCropStyledImage,
  Mobile_PublicTradingPostLogoStyledImage,
  PublicTradingPostAmountStyledWrapper,
  PublicTradingPostCropNameStyledWrapper,
  PublicTradingPostCropStyledImage,
  PublicTradingPostInfoStyledWrapper,
  PublicTradingPostLogoStyledImage,
  PublicTradingPostPriceStyledWrapper,
  PublicTradingPostStyledWrapper,
} from "./PublicTradingPost.styled";
import { Card } from "../../../shared-components/Card/Card";
import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { getPrioritizedPrice } from "../../sell-module/Post/Post.utils";
import { PostDate } from "../../../shared-components/PostDate/PostDate";
import { countriesCodesToFlagIconMap } from "../../../../constants/countries.constants";
import { colors } from "../../../../constants/styles/colors.constants";
import { getStandardLocaleDate } from "../../../../utils/date-utils/getStandardLocaleDate";
import { PostLineLabelStyled } from "../TradingPost/TradingPost.styled";
import { FaAngleDown } from "react-icons/fa";
import { translationMap } from "../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { currencyPrefixesMap } from "../../../../constants/currencies.constants";
import { CurrenciesList } from "../../sell-module/Post/PostSharedComponents/CurrenciesList/CurrenciesList";
import { PaymentTypesEnum } from "../../../../types/enums/PaymentTypes.enum";
import {
  StatesTags,
  VISIBLE_STATES_AMOUNT,
} from "../../../shared-components/StatesTags/StatesTags";
import { TbWeight } from "react-icons/tb";
import { paymentTypeIcon } from "../../sell-module/Post/payment-type-icon";
import { TradingPostInterface } from "../../../../types/interfaces/TradingPost.interface";
import { tradingApi } from "../../../../api/requests/trading.api";

interface PublicTradingPostProps {
  post: TradingPostInterface;
  filters: Record<string, any>;
}

const DEFAULT_LOGO =
  "https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/bridge-logo.png";

export const PublicTradingPost = observer(
  (props: PublicTradingPostProps): ReactElement => {
    const { post, filters } = props;

    const { language, countriesStates, isMobile } = useStore(
      StoresEnum.APP_STORE
    ) as AppStore;

    const { t } = useTranslation();

    const { states } = post;

    const hiddenStates = states.slice(VISIBLE_STATES_AMOUNT);

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const toggleDetails = () => setIsDetailsOpen(!isDetailsOpen);

    const { prioritizedPrice, prioritizedCurrency } = getPrioritizedPrice(
      post.prices,
      language || "",
      (filters?.currency as any) || ""
    );

    const handleIncreaseContactsViewsClick = async (e: any) => {
      e.stopPropagation();

      await tradingApi.increasePostClick(post.id);
    };

    if (isMobile()) {
      return (
        <div
          style={{
            marginBottom: 12,
            width: "95%",
            margin: "5px auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Card
            key={post.id}
            style={{
              width: "100%",
              padding: "6px 10px",
              border: post.isTop ? `2px solid ${colors.link}` : `none`,
              borderBottom: post.isTop
                ? `2px solid ${colors.link}`
                : `2px solid ${colors.lightGrey}`,
              boxShadow: "none",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Mobile_PublicTradingPostLogoStyledImage
                    src={post?.tradingAccount?.logoUrl || DEFAULT_LOGO}
                    alt=""
                  />

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      marginLeft: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Mobile_PublicTradingPostCropStyledImage
                          src={post?.cerealCrop?.imgUrl || ""}
                          alt=""
                        />
                        <p style={{ fontSize: 16, marginLeft: 4 }}>
                          {post?.cerealCrop?.translations?.[language] || ""}
                        </p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            color: colors.primary,
                            fontWeight: 800,
                            marginRight: 10,
                          }}
                        >
                          {post.paymentType === PaymentTypesEnum.HIDDEN ? (
                            <span
                              style={{
                                fontWeight: 600,
                                color: colors.black,
                              }}
                            >
                              Договірна
                            </span>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {paymentTypeIcon[post?.paymentType || ""]}
                              {prioritizedPrice}
                              {currencyPrefixesMap[prioritizedCurrency]}
                            </div>
                          )}
                        </p>

                        <Mobile_PostDateStyledWrapper>
                          {getStandardLocaleDate(
                            post.updatedAtByUser,
                            language,
                            "LL"
                          )}
                        </Mobile_PostDateStyledWrapper>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {
                            countriesCodesToFlagIconMap[
                              post?.country?.code || ""
                            ]
                          }
                          <StatesTags
                            states={post.states}
                            countriesStates={countriesStates}
                            country={post.country}
                            language={language}
                            isShowMarker={false}
                            isFullCountry={post.isFullCountry}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 5,
                        color: colors.primary,
                        cursor: "pointer",
                        fontWeight: 600,
                      }}
                      onClick={toggleDetails}
                    >
                      <FaAngleDown style={{ marginBottom: -2 }} />
                      <span>{t(translationMap.post.description)}</span>
                    </div>

                    {Boolean(hiddenStates?.length) && (
                      <div
                        style={{
                          fontSize: 12,
                          marginTop: 5,
                          color: colors.white,
                          background: colors.primary,
                          borderRadius: 4,
                          cursor: "pointer",
                          fontWeight: 600,
                          padding: "2px 4px",
                          marginLeft: 4,
                        }}
                        onClick={toggleDetails}
                      >
                        <span>
                          +{hiddenStates?.length}{" "}
                          {hiddenStates?.length === 1 && "область"}
                          {[2, 3, 4].includes(hiddenStates?.length) &&
                            "області"}
                          {hiddenStates?.length > 4 && "областей"}
                        </span>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 6,
                      }}
                    >
                      {Boolean(post?.amount) && post?.amount === 0 ? (
                        <p
                          style={{
                            fontSize: 14,
                            marginTop: 5,
                            marginRight: 10,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <TbWeight />
                          <p>{Math.abs(post?.amount)}Т</p>
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <MobileTradingPostContactsRootPhoneStyled
                    href={`tel:+${post?.contacts?.rootPhone}`}
                    onClick={handleIncreaseContactsViewsClick}
                  >
                    {post?.contacts?.rootPhone}
                  </MobileTradingPostContactsRootPhoneStyled>
                </div>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginTop: 4,
                  display: isDetailsOpen ? "flex" : "none",
                }}
              >
                {post.paymentType === PaymentTypesEnum.HIDDEN ? (
                  <></>
                ) : (
                  <CurrenciesList prices={post.prices} filters={filters} />
                )}

                {Boolean(post.states.length > 2) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginTop: 5,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Області:
                    </p>
                    <StatesTags
                      states={post.states}
                      countriesStates={countriesStates}
                      country={post.country}
                      language={language}
                      isShowMarker={false}
                      isFullList
                    />
                  </div>
                )}
                <p
                  style={{
                    width: "100%",
                    background: "rgb(239, 239, 240)",
                    padding: 6,
                    fontSize: 14,
                    textAlign: "justify",
                    marginTop: 6,
                    borderRadius: 4,
                  }}
                >
                  {post?.descriptions?.[language]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: 10, marginTop: 10 }}>
                  {Boolean(post?.tradingAccount?.name)
                    ? `${post?.tradingAccount?.name} `
                    : "Покупець "}
                  на сайті з{" "}
                  {getStandardLocaleDate(
                    post?.tradingAccount?.owner?.createdAt,
                    language,
                    "LL"
                  )}
                </p>
              </div>
            </div>
          </Card>

          {post.isTop && (
            <PostLineLabelStyled $isTop={post.isTop} $bg={colors.link} $isSmall>
              Топ
            </PostLineLabelStyled>
          )}
        </div>
      );
    }

    return (
      <div
        style={{ marginBottom: 12, position: "relative", overflow: "hidden" }}
      >
        <Card
          key={post.id}
          style={{
            border: post.isTop ? `2px solid ${colors.link}` : "none",
          }}
        >
          <PublicTradingPostStyledWrapper>
            <PublicTradingPostLogoStyledImage
              src={post?.tradingAccount?.logoUrl || ""}
              alt=""
            />
            <PublicTradingPostCropStyledImage
              src={post?.cerealCrop?.imgUrl || ""}
              alt=""
            />

            <PublicTradingPostInfoStyledWrapper>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <PublicTradingPostCropNameStyledWrapper>
                    {post?.cerealCrop?.translations?.[language] || ""}
                  </PublicTradingPostCropNameStyledWrapper>
                  <PublicTradingPostAmountStyledWrapper>
                    {post.amount}
                  </PublicTradingPostAmountStyledWrapper>
                  <PublicTradingPostPriceStyledWrapper>
                    {prioritizedPrice}
                    {prioritizedCurrency}
                  </PublicTradingPostPriceStyledWrapper>
                </div>

                <PostDate date={post.createdAt} language={language} />
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 6,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: 6,
                  }}
                >
                  <div>
                    {countriesCodesToFlagIconMap[post?.country?.code || ""]}
                  </div>

                  <StatesTags
                    states={post.states}
                    countriesStates={countriesStates}
                    country={post.country}
                    language={language}
                    isShowMarker={false}
                    isFullCountry={post.isFullCountry}
                  />
                </div>

                <div>
                  <div>{post?.contacts?.rootPhone}</div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 6,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background: "rgb(239, 239, 240)",
                    padding: 10,
                    fontSize: 14,
                    textAlign: "justify",
                    marginTop: 6,
                    borderRadius: 4,
                  }}
                >
                  {post?.descriptions?.[language]}
                </div>
              </div>
            </PublicTradingPostInfoStyledWrapper>
          </PublicTradingPostStyledWrapper>
        </Card>

        {post.isTop && (
          <PostLineLabelStyled $isTop={post.isTop} $bg={colors.link}>
            Топ
          </PostLineLabelStyled>
        )}
      </div>
    );
  }
);
