import { planApi } from "../../api/requests/plan.api";

export const fetchPlans = async (setPlans: any, setIsPlansLoading: any) => {
  setIsPlansLoading(true);

  try {
    const { plans } = await planApi.getAllPlans();

    setPlans(plans);
  } catch {
    setIsPlansLoading(false);
    // error
  }

  setIsPlansLoading(false);
};
