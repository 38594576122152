import React, { ReactElement, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { phone as validatePhone } from "phone";
import {
  PhoneErrorStyledWrapper,
  PhoneInputStyledWrapper,
} from "./ValidatedPhoneInput.styled";
import { useTranslation } from "react-i18next";
import { translationMap } from "../../../i18n/translationMap";
import { observer } from "mobx-react-lite";
import { StoresEnum, useStore } from "../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../store/AppStore";
import { LanguagesEnum } from "../../../types/enums/Languages.enum";

interface ValidatedPhoneInputProps {
  phone: string;
  isValid: boolean;
  country?: string;
  onChangePhone: (value: string) => void;
  setIsPhoneValid: (value: boolean) => void;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  marginBottom?: number;
}

const languageToCountryMap = {
  [LanguagesEnum.PL]: "pl",
  [LanguagesEnum.EN]: "pl",
  [LanguagesEnum.UK]: "ua",
};

export const ValidatedPhoneInput = observer(
  (props: ValidatedPhoneInputProps): ReactElement => {
    const {
      phone,
      isValid,
      country,
      onChangePhone,
      setIsPhoneValid,
      inputStyle = {},
      marginBottom,
      disabled,
    } = props;

    const { language } = useStore(StoresEnum.APP_STORE) as AppStore;

    const [isUnfocused, setIsUnfocused] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
      const phoneInfo = validatePhone(`+${phone}`);
      const { isValid, countryIso2 } = phoneInfo;

      setIsPhoneValid(isValid && ["PL", "UA"].includes(countryIso2));
    }, [phone]);

    const isInvalid = isUnfocused && !isValid;

    return (
      <PhoneInputStyledWrapper marginBottom={marginBottom}>
        {isInvalid && (
          <PhoneErrorStyledWrapper>
            {t(translationMap.errors.phoneFormatInvalid)}
          </PhoneErrorStyledWrapper>
        )}

        <PhoneInput
          onlyCountries={["pl", "ua"]}
          country={languageToCountryMap[language] || "ua"}
          value={phone}
          onChange={onChangePhone}
          buttonStyle={{ ...(isInvalid ? { border: "1px solid red" } : {}) }}
          inputStyle={{
            ...inputStyle,
            ...(isInvalid ? { border: "1px solid red" } : {}),
          }}
          onBlur={() => setIsUnfocused(true)}
          disabled={disabled}
        />
      </PhoneInputStyledWrapper>
    );
  }
);
