import React, { ReactElement } from "react";
import { IoLocationSharp } from "react-icons/io5";

import { colors } from "../../../../constants/styles/colors.constants";
import { paymentTypesToNames } from "../../../../constants/payment.constants";
import { countriesCodesToFlagIconMap } from "../../../../constants/countries.constants";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";
import { PostInterface } from "../../../../types/interfaces/Post.interface";
import { getPrioritizedPrice } from "./Post.utils";

import { observer } from "mobx-react-lite";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { PostsStore } from "../../../../store/PostsStore";
import { PaymentTypesEnum } from "../../../../types/enums/PaymentTypes.enum";
import { LocationAvailableFor } from "./PostSharedComponents/LocationAvailableFor/LocationAvailableFor";

import { FlexStyledWrapper } from "../../../common-styled/common.styled";
import {
  CerealCropImageStyledWrapper,
  CerealCropNameStyled,
  DateStyled,
  LocationStyled,
  PostDateStyledWrapper,
  PostInfoContainerStyledWrapper,
  PostInfoStyledWrapper,
  PostRightSideStyledWrapper,
  PriceInfoStyled,
  SeparatorStyledWrapper,
} from "./Post.styled";
import { currencyPrefixesMap } from "../../../../constants/currencies.constants";
import { getStandardLocaleDate } from "../../../../utils/date-utils/getStandardLocaleDate";
import { capitalizeString } from "../../../../utils/common-utils/capitalizeString";
import { AppStore } from "../../../../store/AppStore";
import { paymentTypeIcon } from "./payment-type-icon";
import { PostDate } from "../../../shared-components/PostDate/PostDate";

interface PostInfoProps {
  post: PostInterface;
  language: LanguagesEnum;
}

export const PostInfo = observer((props: PostInfoProps): ReactElement => {
  const { post, language } = props;
  const {
    state,
    prices,
    amount,
    amountFrom,
    amountTo,
    country,
    createdAt,
    cerealCrop,
    paymentType,
    locationAvailableFor,
  } = post;
  const { name: cerealCropName, translations, imgUrl } = cerealCrop || {};

  const { unstable_filters } = useStore(StoresEnum.POSTS_STORE) as PostsStore;
  const { countriesStates } = useStore(StoresEnum.APP_STORE) as AppStore;

  const translatedState = countriesStates?.[country?.code || ""]?.find(
    ({ name }: any) => name === state
  )?.translation[language];

  const { prioritizedPrice, prioritizedCurrency } = getPrioritizedPrice(
    prices,
    language || "",
    (unstable_filters?.currency as any) || ""
  );

  return (
    <PostInfoContainerStyledWrapper>
      <FlexStyledWrapper>
        <CerealCropImageStyledWrapper src={imgUrl || ""} alt={cerealCropName} />

        <PostInfoStyledWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CerealCropNameStyled>
              {capitalizeString(
                String(translations?.[language] || cerealCropName || "")
              )}
            </CerealCropNameStyled>

            <PriceInfoStyled>{`${
              amount ? amount : `${amountFrom} - ${amountTo}`
            }T`}</PriceInfoStyled>

            {paymentType !== PaymentTypesEnum.HIDDEN && (
              <>
                <SeparatorStyledWrapper />

                <PriceInfoStyled color={colors.greenGradientStart}>
                  <span>{prioritizedPrice}</span>
                  <span>{currencyPrefixesMap[prioritizedCurrency]}/T</span>
                </PriceInfoStyled>
              </>
            )}

            <SeparatorStyledWrapper />

            <PriceInfoStyled>{paymentTypeIcon[paymentType]}</PriceInfoStyled>
          </div>

          <LocationStyled>
            <div>
              <IoLocationSharp
                color={colors.orange}
                style={{
                  fontSize: 16,
                  padding: 0,
                  marginRight: 5,
                }}
              />
              {countriesCodesToFlagIconMap[country?.code || ""]}
              <span style={{ marginLeft: 8 }}>{translatedState}</span>
            </div>
          </LocationStyled>
        </PostInfoStyledWrapper>
      </FlexStyledWrapper>

      <PostRightSideStyledWrapper>
        <PostDateStyledWrapper>
          <PostDate date={createdAt} language={language} />
        </PostDateStyledWrapper>

        <LocationAvailableFor locationAvailableFor={locationAvailableFor} />
      </PostRightSideStyledWrapper>
    </PostInfoContainerStyledWrapper>
  );
});
