import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const PlanStyledWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding: 15px 10px;
  border: 1px solid ${colors.primary};
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: -1px 10px 11px -6px rgba(0, 0, 0, 0.05);
  min-width: 120px;
  width: ${({ isMobile }) => (isMobile ? "48%" : "150px")};
  height: ${({ isMobile }) => (isMobile ? "190px" : "250px")};
  margin: 0 3px;
  margin-bottom: ${({ isMobile }) => (isMobile ? "15px" : "10px")};

  box-shadow: rgba(50, 50, 93, 0.2) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.25) 0px 3px 7px -3px;

  &:hover {
    background: ${({ isMobile }) => (isMobile ? colors.white : colors.primary)};
    color: ${({ isMobile }) => (isMobile ? colors.black : colors.white)};

    & .plan-price {
      color: ${({ isMobile }) => (isMobile ? colors.black : "#fff !important")};
    }

    & .ant-btn {
      background: ${({ isMobile }) =>
        isMobile ? colors.primary : "#fff !important"};

      color: ${({ isMobile }) =>
        isMobile ? colors.white : `${colors.primary} !important`};
    }

    & .points-number {
      color: ${({ isMobile }) => (isMobile ? colors.black : "#fff !important")};
    }
  }
`;
