import React, { ReactElement } from "react";

import { ConfirmActionsBarStyledWrapper } from "./ConfirmActionsBar.styled";
import { CustomButton } from "../CustomButton/CustomButton";

interface ConfirmActionsBarProps {
  confirmText: string;
  cancelText: string;
  disabled?: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
}

export const ConfirmActionsBar = (
  props: ConfirmActionsBarProps
): ReactElement => {
  const {
    confirmText = "Підтвердити",
    cancelText = "Скасувати",
    disabled = false,
    handleCancel,
    handleConfirm,
  } = props;

  return (
    <ConfirmActionsBarStyledWrapper>
      <CustomButton
        type="default"
        style={{ width: "45%" }}
        onClick={handleCancel}
      >
        {cancelText}
      </CustomButton>

      <CustomButton
        type="primary"
        style={{ width: "45%" }}
        onClick={handleConfirm}
        disabled={disabled}
      >
        {confirmText}
      </CustomButton>
    </ConfirmActionsBarStyledWrapper>
  );
};
