import React, { ReactElement } from "react";
import { BsTruck } from "react-icons/bs";
import { FaShip, FaTrain } from "react-icons/fa";

import { MobilePostLocationAvailabilityStyledWrapper } from "../../MobilePost/MobilePost.styled";
import { translationMap } from "../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

const getLocationAvailabilityElement = (
  locationAvailableFor: Record<string, boolean>
): ReactElement => {
  const iconsToRender: ReactElement[] = [];

  if (locationAvailableFor.truck) {
    iconsToRender.push(<BsTruck key="truck" size={18} />);
  }

  if (locationAvailableFor.train) {
    iconsToRender.push(<FaTrain key="train" size={16} />);
  }

  if (locationAvailableFor.ship) {
    iconsToRender.push(
      <FaShip key="ship" size={18} style={{ margin: "0 5px" }} />
    );
  }

  if (!iconsToRender?.length) {
    return <div>-</div>;
  }

  return <>{iconsToRender.map((Icon: ReactElement) => Icon)}</>;
};

interface LocationAvailableForProps {
  locationAvailableFor: Record<string, boolean>;
}

export const LocationAvailableFor = (props: LocationAvailableForProps) => {
  const { locationAvailableFor } = props;

  const { t } = useTranslation();

  return (
    <MobilePostLocationAvailabilityStyledWrapper>
      <p style={{ marginRight: 5, fontSize: 16 }}>
        {t(translationMap.post.locationNear)}:
      </p>

      {getLocationAvailabilityElement(locationAvailableFor)}
    </MobilePostLocationAvailabilityStyledWrapper>
  );
};
