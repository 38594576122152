import React, { ReactElement } from "react";
import {
  AvailableForStyledWrapper,
  DeliveryInformationStyledWrapper,
} from "./DeliveryInformation.styled";

import { FaShip, FaTruckMoving } from "react-icons/fa";
import { IoMdTrain } from "react-icons/io";
import { observer } from "mobx-react-lite";
import { useAddPostForm } from "../../useAddPostForm";
import {
  StoresEnum,
  useStore,
} from "../../../../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../../../../store/AppStore";
import { toJS } from "mobx";
import { translationMap } from "../../../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";

export enum LocationAvailableForEnum {
  Truck = "truck",
  Train = "train",
  Ship = "ship",
}

export const DeliveryInformation = observer((): ReactElement => {
  const { state, handlers } = useAddPostForm();
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { t } = useTranslation();

  const { locationAvailableFor } = state;
  const { handleAddLocationAvailableFor } = handlers;

  return (
    <DeliveryInformationStyledWrapper>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: 15,
          marginTop: isMobile() ? "20px" : 0,
        }}
      >
        {t(translationMap.post.locationAvailableFor)}
      </p>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <AvailableForStyledWrapper
          isSelected={locationAvailableFor?.[LocationAvailableForEnum.Truck]}
          onClick={handleAddLocationAvailableFor(
            LocationAvailableForEnum.Truck
          )}
        >
          <div>
            <FaTruckMoving size={50} />
          </div>

          <p>{t(translationMap.post.truck)}</p>
        </AvailableForStyledWrapper>

        <AvailableForStyledWrapper
          isSelected={locationAvailableFor?.[LocationAvailableForEnum.Train]}
          onClick={handleAddLocationAvailableFor(
            LocationAvailableForEnum.Train
          )}
        >
          <div>
            <IoMdTrain size={50} />
          </div>

          <p>{t(translationMap.post.train)}</p>
        </AvailableForStyledWrapper>

        <AvailableForStyledWrapper
          isSelected={locationAvailableFor?.[LocationAvailableForEnum.Ship]}
          onClick={handleAddLocationAvailableFor(LocationAvailableForEnum.Ship)}
        >
          <div>
            <FaShip size={50} />
          </div>

          <p>{t(translationMap.post.ship)}</p>
        </AvailableForStyledWrapper>
      </div>
    </DeliveryInformationStyledWrapper>
  );
});
