import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { FacebookUrl } from "./FacebookUrl/FacebookUrl";
import { RootPhone } from "./RootPhone/RootPhone";
import { Messengers } from "./Messengers/Messengers";

import { ContactsInformationStyledWrapper } from "./ContactsInformation.styled";
import { AppStore } from "../../../../../../../store/AppStore";
import {
  StoresEnum,
  useStore,
} from "../../../../../../../hooks/use-store-hook/useStore";
import { translationMap } from "../../../../../../../i18n/translationMap";

export const ContactsInformation = observer((): ReactElement => {
  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { t } = useTranslation();

  return (
    <ContactsInformationStyledWrapper>
      <p
        style={{
          fontSize: 18,
          fontWeight: 600,
          display: "flex",
          justifyContent: "center",
          marginBottom: isMobile() ? 15 : 0,
        }}
      >
        {t(translationMap.post.howToContactYou)}
      </p>

      <RootPhone />
      <Messengers />
      <FacebookUrl />
    </ContactsInformationStyledWrapper>
  );
});
