import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const TradingCompanyInfoWidgetStyledWrapper = styled.div``;

export const TradingCompanyLogoStyled = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-right: 20px;

  &:hover {
  }
`;

export const TradingCompanyInfoStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const NoTradingCompanyStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.white};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 40%;
  text-align: center;
`;

export const TradingCompanyAccountInfoStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TradingCompanyNameStyledWrapper = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  text-align: left;
`;

export const TradingCompanyRootPhoneStyledWrapper = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

export const TradingCompanyMessengerPhoneStyledWrapper = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 4px 0;

  span {
    padding-left: 5px;
  }
`;

export const CreateTradingAccountStyledWording = styled.p`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
`;

export const SettingsIconStyledWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
`;
