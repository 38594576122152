import styled from "styled-components";
import { colors } from "../../../../../constants/styles/colors.constants";

export const SignUpFormStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SignUpGetCodeButtonStyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const SignUpWithTelegramButtonStyledWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: ${colors.white};
  background: ${colors.link};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  font-weight: 600;
  text-decoration: none;
  width: 100%;
`;

export const SignUpDividerStyledWrapper = styled.div<{ $margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  ${({ $margin }) => $margin && `margin: ${$margin};`}

  span {
    margin: 0 10px;
  }

  div {
    width: 150px;
    height: 1px;
    background: ${colors.darkWhite};
  }
`;
