import React, { ReactElement, useEffect, useState } from "react";

import { CreateTradingPostStyledWrapper } from "./CreateTradingPost.styled";
import Modal from "antd/es/modal/Modal";
import { tradingApi } from "../../../../api/requests/trading.api";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { CerealsList } from "../../../shared-components/CerealsList/CerealsList";
import { CountriesList } from "../../../shared-components/CountriesList/CountriesList";
import { StatesList } from "../../../shared-components/StatesList/StatesList";
import { ValidationInput } from "../../../shared-components/ValidationInput/ValidationInput";
import TextArea from "antd/es/input/TextArea";
import { PriceForm } from "../../../shared-components/PriceForm/PriceForm";
import { ValidatedPhoneInput } from "../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import { useCreateTradingForm } from "../../../../hooks/use-create-trading-form-hook/useCreateTradingForm";
import { CountriesEnum } from "../../../../types/enums/Countries.enum";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { PaymentType } from "../../sell-module/AddPost/AddPostForm/add-post-form-components/PaymentType/PaymentType";
import { PaymentTypesEnum } from "../../../../types/enums/PaymentTypes.enum";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { checkIsPhoneValid } from "../../../../hooks/use-add-post-store/useAddPostStore.utils";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { TradingAccountCreate } from "../TradingAccountCreate/TradingAccountCreate";
import { UserStore } from "../../../../store/UserStore";

export const checkIsAmountValid = (amount: number) => {
  // @ts-ignore
  if (amount < 0) {
    return false;
  }

  if (!amount) {
    return true;
  }

  return true;
};

export const CreateTradingPost = observer((): ReactElement => {
  const {
    isCreatePostModalOpen,
    toggleCreatePostModal,
    setTradingAccount,
    getTradingAccountPosts,
    tradingAccount,
  } = useTradingStore();
  const {
    tradingPostData,

    handleChangeCrop,
    handleChangeAmount,
    handleChangeCountry,
    handleChangeState,
    handleChangePrice,
    handleChangeComment,
    handleChangePhone,
    handleChangePaymentType,
    handleChangeIsFullCountry,
    onClearTradingCreateForm,
  } = useCreateTradingForm();

  const { countries, isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { user } = useStore(StoresEnum.USER_STORE) as UserStore;

  const {
    amount,
    crop,
    country = CountriesEnum.UA,
    states,
    prices = {},
    comment,
    phone = tradingAccount?.contacts.rootPhone || "",
    paymentType,
    isFullCountry,
  } = tradingPostData || {};

  const [errors, setErrors] = useState<any>({});
  const isAccountExist = Boolean(tradingAccount?.id);
  const isUserExist = Boolean(user?.id);

  useEffect(() => {
    const isPhoneValid = checkIsPhoneValid(phone);
    const isAmountValid = checkIsAmountValid(amount || 0);
    const isCropValid = Boolean(crop);
    const isCountryValid = Boolean(country);
    const isStateValid = isFullCountry || Boolean(states?.length);
    const isPaymentTypeValid = Boolean(paymentType);
    const isPriceValid =
      paymentType === PaymentTypesEnum.HIDDEN
        ? true
        : Boolean(Object.values(prices || {}).some((price) => Boolean(price)));

    setErrors({
      phone: isPhoneValid,
      amount: isAmountValid,
      crop: isCropValid,
      country: isCountryValid,
      states: isStateValid,
      paymentType: isPaymentTypeValid,
      price: isPriceValid,
    });
  }, [tradingPostData]);

  const createTradingPost = async (): Promise<void> => {
    const countryToFind = tradingPostData?.country || CountriesEnum.UA;
    const country = countries.find(
      (country: any) => country.code === countryToFind
    );

    const { balance } = await tradingApi.createTradingPost({
      ...tradingPostData,
      country: country?.id,
      phone: tradingPostData?.phone || tradingAccount?.contacts.rootPhone || "",
    });

    await getTradingAccountPosts();

    const updatedAccount: any = {
      ...tradingAccount,
      postBalance: balance,
    };
    setTradingAccount(updatedAccount);
  };

  const handleCreatePost = () => {
    createTradingPost();
    toggleCreatePostModal();
    onClearTradingCreateForm();
    setErrors({});
  };

  const isHiddenPrice = paymentType === PaymentTypesEnum.HIDDEN;

  const Content = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CerealsList
          value={crop}
          isValid={errors.crop}
          onChange={handleChangeCrop}
          isRequired
          style={{ width: "49%", marginBottom: 10 }}
          selectStyle={{ width: "100%" }}
        />

        <ValidationInput
          value={amount}
          isValid={errors.amount}
          placeholder="Об'єм"
          style={{ width: "49%", marginBottom: 10 }}
          onChange={handleChangeAmount}
          type="number"
        />
      </div>

      <CountriesList
        value={country}
        isValid={errors.country}
        onChange={handleChangeCountry}
        isRequired
        style={{ marginBottom: 10 }}
        selectStyle={{ width: "100%" }}
      />

      <StatesList
        value={states}
        isValid={errors.states}
        onChange={handleChangeState}
        onChangeIsFullCountry={handleChangeIsFullCountry}
        isFullCountry={isFullCountry}
        isRequired
        style={{ marginBottom: 10 }}
        selectStyle={{ width: "100%" }}
        country={CountriesEnum.UA}
        mode="multiple"
        disabled={!Boolean(country)}
        isShortForm
        showSearch={false}
      />

      <PaymentType
        paymentType={paymentType}
        onChange={handleChangePaymentType}
        isValid={errors.paymentType}
      />

      {isHiddenPrice ? (
        <div
          style={{
            margin: 10,
            fontSize: 16,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Договірна
        </div>
      ) : (
        <PriceForm
          prices={prices as any}
          onChange={(currency: CurrencyEnum) => {
            return (event: any) => {
              handleChangePrice(event.target.value, currency);
            };
          }}
        />
      )}

      <TextArea
        value={comment}
        placeholder="Опис"
        onChange={handleChangeComment}
        style={{
          marginBottom: 10,
        }}
        maxLength={500}
        showCount
        autoSize={{ minRows: 3, maxRows: 3 }}
      />

      <ValidatedPhoneInput
        phone={phone}
        isValid={errors.phone}
        onChangePhone={handleChangePhone}
        setIsPhoneValid={() => {}}
      />
    </>
  );

  const Footer = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <CustomButton onClick={toggleCreatePostModal}>Скасувати</CustomButton>
      <CustomButton
        onClick={handleCreatePost}
        type="primary"
        disabled={
          !Boolean(Object.values(errors).every((error) => Boolean(error)))
        }
      >
        Створити
      </CustomButton>
    </div>
  );

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isCreatePostModalOpen}
        title="Купити"
        onClose={toggleCreatePostModal}
        Content={
          <>
            {isAccountExist && isUserExist ? (
              <div>
                <div
                  style={{
                    marginBottom: 10,
                  }}
                >
                  {Footer}
                </div>
                {Content}
              </div>
            ) : (
              <>
                {isUserExist && (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 22,
                        fontWeight: 600,
                        marginBottom: 20,
                      }}
                    >
                      Для того, щоб створити оголошення на купівлю, вам
                      необхідно створити торговий акаунт
                    </p>

                    <TradingAccountCreate isCard={false} />
                  </div>
                )}
              </>
            )}
          </>
        }
      />
    );
  }

  return (
    <CreateTradingPostStyledWrapper>
      <Modal
        title="Оголошення на купівлю"
        centered
        open={isCreatePostModalOpen}
        onOk={handleCreatePost}
        onCancel={toggleCreatePostModal}
        footer={Footer}
      >
        {Content}
      </Modal>
    </CreateTradingPostStyledWrapper>
  );
});
