import React, { ReactElement, useState } from "react";

import { TradingInfoModalStyledWrapper } from "./TradingInfoModal.styled";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { Modal } from "antd";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { colors } from "../../../../constants/styles/colors.constants";
import {
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from "../../sell-module/AddPost/AddPostForm/add-post-form-components/ContactsInformation/ContsctIcons";
import { localStorageUtils } from "../../../../utils/browser-utils/LocalStorage";
import { set } from "lodash";

interface TutorSlideProps {
  imgUrl?: string;
  imgStyles?: React.CSSProperties;
  text?: string;
  TextNode?: React.ReactNode;
  ImgNode?: React.ReactNode;
}
const TutorSlide = (props: TutorSlideProps) => {
  const { imgUrl, text, imgStyles = {}, ImgNode, TextNode } = props;

  return (
    <div
      style={
        {
          // display: "flex",
          // flexDirection: "column",
        }
      }
    >
      {ImgNode ? ImgNode : <img src={imgUrl} alt="1" style={imgStyles} />}
      {TextNode ? TextNode : <p className="default-p">{text}</p>}
    </div>
  );
};
const imgStyles = {
  width: "230px",
  margin: "0 auto",
  display: "block",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.35)",
};

export const TradingInfoModal = observer((): ReactElement => {
  const { isTradingInfoModalOpen, toggleTradingInfoModalOpen } =
    useTradingStore();

  const [step, setStep] = useState(0);

  const navigateToViber = () => {
    window.open(`viber://chat?number=+380937782382`, "_blank");
  };

  const navigateToWhatsapp = () => {
    window.open(`https://wa.me/+380937782382`, "_blank");
  };

  const navigateToTelegram = () => {
    window.open(`https://t.me/+380937782382`, "_blank");
  };

  const steps = [
    // STEP 0
    <TutorSlide
      ImgNode={
        <div
          style={{
            position: "relative",
            height: 320,
          }}
        >
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/my-account-falf2.png"
            style={{
              position: "absolute",
              zIndex: 1,
              right: 0,
              bottom: 0,
              ...imgStyles,
            }}
          />
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/my-account-falf1.png"
            style={{
              position: "absolute",
              left: 0,
              ...imgStyles,
              width: "200px",
            }}
          />
        </div>
      }
      text="Це особистий кабінет, у якому Ви можете редагувати свій профіль, створювати та редагувати оголошення на закупівлю."
    />,
    // STEP 1
    <TutorSlide
      ImgNode={
        <div
          style={{
            position: "relative",
            height: 300,
          }}
        >
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/create-account-empty-state.png"
            style={{
              position: "absolute",
              left: 0,
              ...imgStyles,
              width: "250px",
            }}
          />
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/create-account-filled-form.png"
            style={{
              position: "absolute",
              zIndex: 1,
              right: 0,
              bottom: 0,
              ...imgStyles,
              width: "250px",
            }}
          />
        </div>
      }
      text="Форма створення торгового акаунту. Потрібно натиснути на кнопку «Додати інформацію» та заповнити назву компанії, контактні дані та завантажити логотип (не обов'язково)."
    />,
    // STEP 2
    <TutorSlide
      imgUrl="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/balance.png"
      imgStyles={{
        width: "230px",
        margin: "0 auto",
        display: "block",
        borderRadius: "8px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.35)",
      }}
      TextNode={
        <>
          <p
            style={{
              paddingTop: 5,
              marginTop: 15,
              borderTop: `1px solid #e5e5e5`,
            }}
          >
            При створенні акаунту Ви отримаєте 3 безкоштовні оголошення на
            закупівлю які будуть активні 3 дні.
          </p>

          <p>
            Після закінчення безкоштовних оголошень Ви можете поповнити баланс,
            придбані оголошення будуть активні 14 днів.
          </p>

          <p
            style={{
              paddingTop: 5,
              borderTop: `1px solid #e5e5e5`,
            }}
          >
            Щоб додати оголошення, натисніть на кнопку «Додати оголошення».
          </p>
        </>
      }
    />,
    // STEP 3,
    <TutorSlide
      imgUrl="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/create-post-small.png"
      imgStyles={{
        width: "230px",
        margin: "0 auto",
        display: "block",
        borderRadius: "12px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.35)",
      }}
      text="Форма створення оголошення на закупівлю. Потрібно обрати культуру, бажаний обʼєм та ціни (одну або декілька), обрати області, заповнити коментар та додати номер телефону."
    />,

    // STEP 4
    <TutorSlide
      ImgNode={
        <div
          style={{
            position: "relative",
            height: 300,
          }}
        >
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/continue-plans.png"
            style={{
              position: "absolute",
              left: 0,
              ...imgStyles,
              width: "250px",
            }}
          />
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/expired-post.png"
            style={{
              position: "absolute",
              zIndex: 1,
              right: 0,
              bottom: 0,
              ...imgStyles,
              width: "250px",
            }}
          />
        </div>
      }
      TextNode={
        <>
          <p
            style={{
              paddingTop: 5,
              marginTop: 15,
              borderTop: `1px solid #e5e5e5`,
            }}
          >
            Після того коли оголошення закінчується, воно переходить в статус
            «Не активно».
          </p>

          <p>
            Ви можете продовжити термін дії оголошення, натиснувши на кнопку
            «Продовжити». Після цього Вам буде доступно 4 плани:
          </p>
          <p>1. Зписати з балансу 1 (14 днів) або 2 (31 день)</p>
          <p>2. Продовжити за 1$ (14 днів) або 2$ (31 день)</p>
        </>
      }
    />,

    // STEP 5
    <TutorSlide
      ImgNode={
        <div
          style={{
            position: "relative",
            height: 300,
          }}
        >
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/edit-form.png"
            style={{
              position: "absolute",
              left: 0,
              ...imgStyles,
              width: "250px",
            }}
          />
          <img
            src="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/post-in-account.png"
            style={{
              position: "absolute",
              zIndex: 1,
              right: 0,
              bottom: 0,
              ...imgStyles,
              width: "250px",
            }}
          />
        </div>
      }
      TextNode={
        <>
          <p
            style={{
              paddingTop: 5,
              marginTop: 15,
              borderTop: `1px solid #e5e5e5`,
            }}
          >
            Також ви можете редагувати оголошення, натиснувши на кнопку
            «Редагувати» або додати оголошення в ТОП.
          </p>

          <p>
            Ви можете додати в ТОП на період менший ніж період дії оголошення.
          </p>

          <p>
            Наприклад, якщо оголошення закінчується через 7 днів, то ви можете
            додати в ТОП тільки на 3 дні. Безкоштовні оголошення неможна додати
            в ТОП.
          </p>
        </>
      }
    />,

    // STEP 6
    <TutorSlide
      imgUrl="https://prod-agrobridges-cdn.fra1.cdn.digitaloceanspaces.com/trading-tutr/hamster"
      imgStyles={{
        width: "150px",
        margin: "0 auto",
        display: "block",
      }}
      TextNode={
        <>
          <p>Дякую, за увагу!</p>
          <p>
            Якщо у Вас виникли питання, звертайтесь до нашої служби підтримки.
          </p>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-around",
              margin: "15px auto",
            }}
          >
            <div onClick={navigateToTelegram}>
              <TelegramIcon fontSize={35} />
            </div>

            <div onClick={navigateToViber}>
              <ViberIcon fontSize={35} />
            </div>

            <div onClick={navigateToWhatsapp}>
              <WhatsappIcon fontSize={35} />
            </div>
          </div>
        </>
      }
    />,
  ];

  const handleNextStep = () => {
    if (step === steps.length - 1) {
      toggleTradingInfoModalOpen();
      return;
    }

    setStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setStep((prev) => prev - 1);
  };

  const handleFinish = () => {
    toggleTradingInfoModalOpen();
    localStorageUtils.setIsTradingTourDone();
    setStep(0);
  };

  return (
    <Modal
      title="Як користуватися?"
      open={isTradingInfoModalOpen}
      onOk={toggleTradingInfoModalOpen}
      onCancel={() => {
        toggleTradingInfoModalOpen();
        setStep(0);
      }}
      centered
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {step === 0 && (
            <CustomButton
              style={{
                width: "100%",
                border: `1px solid ${colors.primary}`,
              }}
              onClick={handleNextStep}
            >
              Далі
            </CustomButton>
          )}
          {[1, 2, 3, 4, 5].includes(step) && (
            <>
              <CustomButton
                style={{
                  width: "45%",
                }}
                onClick={handlePrevStep}
              >
                Назад
              </CustomButton>
              <CustomButton
                style={{
                  width: "45%",
                  border: `1px solid ${colors.primary}`,
                }}
                onClick={handleNextStep}
              >
                Далі
              </CustomButton>
            </>
          )}
          {step === 6 && (
            <CustomButton
              type="primary"
              style={{
                width: "100%",
                border: `1px solid ${colors.primary}`,
              }}
              onClick={handleFinish}
            >
              Зрозуміло
            </CustomButton>
          )}
        </div>
      }
    >
      <TradingInfoModalStyledWrapper>
        {steps[step]}
      </TradingInfoModalStyledWrapper>
    </Modal>
  );
});
