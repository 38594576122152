import React, { ReactElement } from "react";

import {
  CurrentAvatarStyledImg,
  CurrentAvatarStyledWrapper,
  TradingAccountAvatarEditStyledWrapper,
} from "./TradingAccountAvatarEdit.styled";
import { MdEdit } from "react-icons/md";
import { ImageCrop } from "../../../shared-components/ImageCrop/ImageCrop";
import { useOpenModal } from "../../../../hooks/use-open-modal-hook/useOpenModal";
import { LOGO_MOCK } from "../TradingAccountCreate/TradingAccountCreate";

interface TradingAccountAvatarEditProps {
  logoUrl?: string;
  onSetAvatarFile: (file: File) => void;
}

export const TradingAccountAvatarEdit = (
  props: TradingAccountAvatarEditProps
): ReactElement => {
  const { logoUrl, onSetAvatarFile } = props;

  const { isOpen: isCropModalOpen, toggleModal: toggleCropModal } =
    useOpenModal();

  return (
    <TradingAccountAvatarEditStyledWrapper>
      <CurrentAvatarStyledWrapper onClick={toggleCropModal}>
        <CurrentAvatarStyledImg
          src={logoUrl || LOGO_MOCK}
          alt="avatar"
          style={{
            width: 100,
            height: 100,
          }}
        />
      </CurrentAvatarStyledWrapper>

      <ImageCrop
        isOpen={isCropModalOpen}
        toggleModal={toggleCropModal}
        onFileChange={onSetAvatarFile}
        fileUrl={logoUrl}
      />
    </TradingAccountAvatarEditStyledWrapper>
  );
};
