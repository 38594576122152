import { ReactElement, useState } from "react";
import { observer } from "mobx-react-lite";
import { isPast } from "date-fns";

import { ContactsInterface } from "../../../../types/interfaces/Contacts.interface";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { PostsStore } from "../../../../store/PostsStore";
import { postApi } from "../../../../api/requests/posts.api";
import { UserStore } from "../../../../store/UserStore";
import { GetContactsButton } from "./GetContactsButton/GetContactsButton";
import { ContactsLoading } from "./ContactsLoading/ContactsLoading";
import { ContactIcons } from "./ContactsIcons/ContactIcons";

import {
  MobilePostContactsRootPhoneStyled,
  MobilePostContactsStyled,
  PostContactsStyled,
} from "./PostContacts.styled";

interface PostContactsProps {
  postId: string;
  contacts?: ContactsInterface;
  isMobile?: boolean;
}

const { POSTS_STORE, USER_STORE } = StoresEnum;

export const PostContacts = observer(
  (props: PostContactsProps): ReactElement => {
    const { postId, isMobile = false } = props;

    const [isContactsLoading, setIsContactsLoading] = useState(false);

    const { postContacts, setPostContacts } = useStore(
      POSTS_STORE
    ) as PostsStore;

    const { user, setSignInModalOpenClose, setPlanModalOpenClose } = useStore(
      USER_STORE
    ) as UserStore;

    const handleGetContacts = async () => {
      const isUserLoggedIn = Boolean(user?.id);

      if (!isUserLoggedIn) {
        return setSignInModalOpenClose();
      }

      const subscriptionActiveTill = user?.subscription?.activeTill;
      const isSubscriptionActive = !isPast(
        new Date(subscriptionActiveTill || "")
      );

      if (!isSubscriptionActive || !subscriptionActiveTill) {
        return setPlanModalOpenClose();
      }

      setIsContactsLoading(true);

      try {
        const contacts = await postApi.getPostContacts(postId);

        setPostContacts(postId, contacts);
        setIsContactsLoading(false);
      } catch {
        setIsContactsLoading(false);
      }
    };

    if (isContactsLoading) {
      return <ContactsLoading />;
    }

    if (!postContacts[postId]) {
      return (
        <GetContactsButton
          isMobile={isMobile}
          onGetContacts={handleGetContacts}
        />
      );
    }

    if (isMobile) {
      const rootPhoneLink = `tel:+${postContacts[postId]?.rootPhone}`;

      return (
        <MobilePostContactsStyled>
          <MobilePostContactsRootPhoneStyled href={rootPhoneLink}>
            {postContacts[postId]?.rootPhone}
          </MobilePostContactsRootPhoneStyled>

          <ContactIcons postId={postId} postContacts={postContacts} />
        </MobilePostContactsStyled>
      );
    }

    const isRootPhoneProvided = Boolean(postContacts?.[postId]?.rootPhone);

    return (
      <PostContactsStyled>
        {isRootPhoneProvided && <p>{postContacts[postId]?.rootPhone}</p>}

        <ContactIcons postId={postId} postContacts={postContacts} />
      </PostContactsStyled>
    );
  }
);
