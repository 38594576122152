import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const CardStyledWrapper = styled.div`
  background: ${colors.white};
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
`;
