export const checkIsFiltersApplied = (filters: any) => {
  return Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === "string") {
      return value.length > 0;
    }

    if (typeof value === "number") {
      return value >= 0;
    }

    return !!value;
  });
};
