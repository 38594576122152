import React, { ReactElement, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { TradingPostsEmptyState } from "../TradingPostsEmptyState/TradingPostsEmptyState";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { TradingPost } from "../TradingPost/TradingPost";

import {
  PublishedTradingPostList,
  TradingControlPanelStyledWrapper,
} from "./TradingMyOffers.styled";
import { ListTitle } from "../../../shared-components/ListTitle/ListTitle";
import { Pagination } from "antd";

export const TradingMyOffers = observer((): ReactElement => {
  const {
    tradingAccountPosts = [],
    getTradingAccountPosts,
    currentAccountPage,
    currentAccountPerPage,
    currentAccountPostsAmount,
    setCurrentAccountPage,
  } = useTradingStore();

  useEffect(() => {
    const fetchTradingPosts = async () => {
      await getTradingAccountPosts();
    };

    fetchTradingPosts();
  }, [currentAccountPage]);

  if (!tradingAccountPosts.length) {
    return (
      <TradingControlPanelStyledWrapper>
        <TradingPostsEmptyState />
      </TradingControlPanelStyledWrapper>
    );
  }

  return (
    <TradingControlPanelStyledWrapper>
      <ListTitle title="Ваші заявки на купівлю" />
      <PublishedTradingPostList>
        {tradingAccountPosts.map((post: any) => (
          <TradingPost key={post.id} post={post} />
        ))}
      </PublishedTradingPostList>

      <div
        style={{
          margin: "30px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          total={currentAccountPostsAmount}
          pageSize={currentAccountPerPage}
          current={currentAccountPage || 1}
          responsive
          showSizeChanger={false}
          onChange={(page: number) => setCurrentAccountPage(page)}
          hideOnSinglePage
        />
      </div>
    </TradingControlPanelStyledWrapper>
  );
});
