import React, { ReactElement, useState } from "react";
import { ImCross } from "react-icons/im";
import { observer } from "mobx-react-lite";

import { Logo } from "../Logo/Logo";
import { MenuLinks } from "../MenuLinks/MenuLinks";
import { LanguageSelect } from "../../LanguageSelect/LanguageSelect";
import { AddPostModal } from "../../../sell-module/AddPost/AddPostModal/AddPostModal";

import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../../store/UserStore";

import {
  MenuMobileLinksListStyledWrapper,
  MenuMobileStyledWrapper,
  MobileLanguageSelectStyledWrapper,
  MobileMenuHeaderStyledWrapper,
  MobileMenuStaticHeaderStyledWrapper,
} from "./Menu.mobile.styled";
import { MobileBottomSlideModal } from "../../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";

import { MobileAuthForm } from "../../../auth-module/AuthForms/MobileAuthForm/MobileAuthForm";
import { MobileMenuActionBar } from "../MobileMenuActionBar/MobileMenuActionBar";
import { translationMap } from "../../../../../i18n/translationMap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";

interface MobileMenuSidebarProps {
  isMenuOpen: boolean;
  onOpenCloseMenu: () => void;
}
const MobileMenuSidebar = (props: MobileMenuSidebarProps): ReactElement => {
  const { isMenuOpen, onOpenCloseMenu } = props;

  return (
    <MenuMobileStyledWrapper isOpen={isMenuOpen}>
      <MobileMenuHeaderStyledWrapper>
        <Logo size="large" />

        <ImCross
          size={20}
          onClick={onOpenCloseMenu}
          style={{ marginBottom: -4 }}
        />
      </MobileMenuHeaderStyledWrapper>

      <MenuMobileLinksListStyledWrapper>
        <MenuLinks />
      </MenuMobileLinksListStyledWrapper>
    </MenuMobileStyledWrapper>
  );
};

const MobileMenuStaticHeader = (): ReactElement => {
  return (
    <MobileMenuStaticHeaderStyledWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Logo size="large" />

        <a
          href={`https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?start=auth`}
          target="_blank"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            fontWeight: 500,
            color: "#fff",
            cursor: "pointer",
            background: "#1890ff",
            padding: "10px 8px",
            borderRadius: 5,
            marginLeft: 10,
            textDecoration: "none",
          }}
          rel="noreferrer"
        >
          <FaTelegramPlane color="#fff" style={{ marginRight: 4 }} />
          Бот
        </a>

        <MobileLanguageSelectStyledWrapper>
          <LanguageSelect />
        </MobileLanguageSelectStyledWrapper>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          margin: "10px 0",
        }}
      >
        <MenuLinks styles={{ fontSize: 18, margin: "8px 0" }} isMobile />
      </div>
    </MobileMenuStaticHeaderStyledWrapper>
  );
};

interface MenuMobileProps {
  isPosts: boolean;
  isTrading: boolean;
  handleCheckout: () => void;
  handleOpenCloseAddPostModal: () => void;
}
export const MenuMobile = observer((props: MenuMobileProps): ReactElement => {
  const { isPosts, handleCheckout, handleOpenCloseAddPostModal } = props;
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isSignInModalOpen, setSignInModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const handleOpenCloseMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSetSignInModalOpenClose = (): void => {
    setSignInModalOpenClose();
  };

  const handleNavigateToAccount = (): void => {
    navigate("/account");
  };

  if (!isPosts) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            width: "100%",
          }}
        >
          <Logo />

          <div style={{ marginLeft: 30 }}>
            <LanguageSelect />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <MobileMenuStaticHeader />

      <MobileMenuSidebar
        isMenuOpen={isMenuOpen}
        onOpenCloseMenu={handleOpenCloseMenu}
      />

      <MobileMenuActionBar
        isMenuOpen={isMenuOpen}
        onCheckout={handleCheckout}
        onOpenCloseMenu={handleOpenCloseAddPostModal}
        onOpenCloseSignIn={handleSetSignInModalOpenClose}
        onToggleAccountModal={handleNavigateToAccount}
      />

      <AddPostModal />

      <MobileBottomSlideModal
        isOpen={isSignInModalOpen}
        title={t(translationMap.menu.account)}
        onClose={handleSetSignInModalOpenClose}
        Content={<MobileAuthForm />}
      />
    </div>
  );
});
