export const UkFlagIcon = () => {
  return (
    <svg
      width="26"
      height="17"
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ borderRadius: 2 }}
    >
      <g clip-path="url(#clip0_3_2338)">
        <path d="M0 0H26V17.0002H0V0Z" fill="white" />
        <path
          d="M14.625 -6.10352e-05H11.375V6.90616H0V10.0937H11.375V16.9999H14.625V10.0937H26V6.90616H14.625V-6.10352e-05Z"
          fill="#D80027"
        />
        <path
          d="M19.997 11.4564L26.0001 14.7273V11.4564H19.997ZM15.8262 11.4564L26.0001 16.9998V15.4323L18.7032 11.4564H15.8262ZM23.2901 16.9998L15.8262 12.9326V16.9998H23.2901Z"
          fill="#0052B4"
        />
        <path
          d="M15.8262 11.4564L26.0001 16.9998V15.4323L18.7032 11.4564H15.8262Z"
          fill="white"
        />
        <path
          d="M15.8262 11.4564L26.0001 16.9998V15.4323L18.7032 11.4564H15.8262Z"
          fill="#D80027"
        />
        <path
          d="M4.58763 11.4562L0 13.9559V11.4562H4.58763ZM10.1739 12.1612V16.9998H1.29446L10.1739 12.1612Z"
          fill="#0052B4"
        />
        <path
          d="M7.29691 11.4564L0 15.4323V16.9998L10.1739 11.4564H7.29691Z"
          fill="#D80027"
        />
        <path
          d="M6.00311 5.54341L0 2.27243V5.54341H6.00311ZM10.1739 5.54341L0 -6.10352e-05V1.56749L7.29691 5.54341H10.1739ZM2.70999 -6.10352e-05L10.1739 4.06719V-6.10352e-05H2.70999Z"
          fill="#0052B4"
        />
        <path
          d="M10.1739 5.54341L0 -6.10352e-05V1.56749L7.29691 5.54341H10.1739Z"
          fill="white"
        />
        <path
          d="M10.1739 5.54341L0 -6.10352e-05V1.56749L7.29691 5.54341H10.1739Z"
          fill="#D80027"
        />
        <path
          d="M21.4125 5.54339L26.0001 3.04369V5.54339H21.4125ZM15.8262 4.83846V-0.00012207H24.7056L15.8262 4.83846Z"
          fill="#0052B4"
        />
        <path
          d="M18.7032 5.54334L26.0001 1.56743V-0.00012207L15.8262 5.54334H18.7032Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_2338">
          <rect width="26" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
