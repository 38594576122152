import { ReactElement } from "react";
import { Spin } from "antd";

export const ContactsLoading = (): ReactElement => {
  return (
    <div style={{ minWidth: 80 }}>
      <Spin size="small" />
    </div>
  );
};
