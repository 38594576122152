import { Modal } from "antd";

interface AuthModalProps {
  title: "Sign up" | "Sign in";
  content: JSX.Element;
  isOpen: boolean;
  handleClose: () => void;
}

export const AuthModal = (props: AuthModalProps) => {
  const { title, content, isOpen, handleClose } = props;

  return (
    <Modal
      title={title}
      centered
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      destroyOnClose
    >
      {content}
    </Modal>
  );
};
