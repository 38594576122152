import React, { ReactElement } from "react";
import { Input, Select } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useAddPostForm } from "../../useAddPostForm";
import { ValidationInput } from "../../../../../../shared-components/ValidationInput/ValidationInput";
import { ValidationSelect } from "../../../../../../shared-components/ValisdationSelect/VaslidationSelect";
import { translationMap } from "../../../../../../../i18n/translationMap";
import { CerealsList } from "../../../../../../shared-components/CerealsList/CerealsList";

export const CerealCropInformation = observer((): ReactElement => {
  const { state, handlers, errorFields } = useAddPostForm();
  const { t } = useTranslation();

  const { amount, cerealCrop, harvestYear } = state;
  const {
    handleChangeCerealCrop,
    handleChangeHarvestYear,
    handleChangeAmount,
  } = handlers;

  return (
    <>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: 5,
        }}
      >
        {t(translationMap.post.cropInformation)}
      </p>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <CerealsList
          value={cerealCrop || undefined}
          isValid={!errorFields.includes("cerealCrop")}
          onChange={handleChangeCerealCrop}
          isRequired
          style={{ width: "49%", marginBottom: 10 }}
          selectStyle={{ width: "100%" }}
        />

        <ValidationInput
          value={harvestYear}
          placeholder={t(translationMap.post.harvestYear)}
          isValid={!errorFields.includes("harvestYear")}
          onChange={handleChangeHarvestYear}
          style={{
            width: "49%",
            marginBottom: 10,
          }}
          type="number"
        />
      </div>

      <ValidationInput
        value={amount || undefined}
        placeholder={t(translationMap.post.quantity)}
        style={{ width: "100%", marginBottom: 10 }}
        type="number"
        suffix="T"
        isValid={!errorFields.includes("amount")}
        isRequired
        onChange={handleChangeAmount}
      />
    </>
  );
});
