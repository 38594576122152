import { LanguagesEnum } from "../../types/enums/Languages.enum";
import { localStorageUtils } from "./LocalStorage";

export const getLanguageFromBrowser = (): LanguagesEnum => {
  const preselectedLanguage = localStorageUtils.getLanguage();

  const language =
    preselectedLanguage || navigator.language.split(/[-_]/)[0].toUpperCase();

  if (language === LanguagesEnum.PL) {
    return LanguagesEnum.PL;
  }

  if (language === LanguagesEnum.EN) {
    return LanguagesEnum.EN;
  }

  if (language === LanguagesEnum.UK || language === "RU") {
    return LanguagesEnum.UK;
  }

  return LanguagesEnum.PL;
};
