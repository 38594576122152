import { LanguagesEnum } from "../../types/enums/Languages.enum";

export const checkIsDetailsExist = (
  details: any,
  language: LanguagesEnum
): boolean => {
  const { prices, descriptions, locationAvailableFor, harvestYear } = details;

  const isLocationNearProvided = Object.values(locationAvailableFor).some(
    (near) => near
  );

  return (
    isLocationNearProvided ||
    locationAvailableFor[language] ||
    Object.values(prices || {})?.length > 1 ||
    descriptions?.[language] ||
    harvestYear
  );
};
