import { useQuery } from "react-query";
import { TradingStore, defaultSorts } from "../../store/TradingStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { checkIsFiltersApplied } from "../../utils/common-utils/checkIsFiltersApplied";
import { useEffect } from "react";

export const usePublicTradingPosts = (isFetch: boolean = true): any => {
  const {
    publicTradingPosts,
    isTradingPostsLoading,

    getAllTradingPosts,

    filters,
    setFilters,

    sorts,
    setSorts,

    isFiltersOpen,
    setIsFiltersOpen,

    page,
    perPage,
    setPage,

    postsAmount,
    setPostsAmount,

    onClearTradingFilters,
  } = useStore(StoresEnum.TRADING_STORE) as TradingStore;

  const isFiltersApplied = () => {
    return checkIsFiltersApplied(filters);
  };

  useQuery({
    queryKey: ["trading-posts", filters, sorts, page],
    queryFn: getAllTradingPosts,
    refetchOnWindowFocus: false,
    enabled: isFetch,
  });

  useEffect(() => {
    setPage(1);
  }, [filters, sorts]);

  const handleChangeSorts = (sorts: any) => {
    setPage(1);
    setSorts(sorts);
  };

  const clearPostSorts = () => {
    setPage(1);
    setSorts(defaultSorts);
  };

  const checkIsSortsApplied = () => {
    return Object.values(sorts).some((value) => {
      if (typeof value === "string") {
        return value.length > 0;
      }

      return !!value;
    });
  };

  return {
    publicTradingPosts,
    isTradingPostsLoading,

    filters,
    setFilters,

    sorts,
    setSorts,

    isFiltersOpen,
    setIsFiltersOpen,

    checkIsFiltersApplied: isFiltersApplied,

    page,
    perPage,
    setPage,

    postsAmount,
    setPostsAmount,

    handleChangeSorts,
    clearPostSorts,
    checkIsSortsApplied,

    onClearTradingFilters,
  };
};
