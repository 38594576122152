import { useState } from "react";

interface UseUploadFileInterface {
  file: any;
  onSetFile: (event: any) => void;
}

export const useUploadFile = (): UseUploadFileInterface => {
  const [file, setFile] = useState<any>(null);

  const onSetFile = (file: any) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      // @ts-ignore
      setFile(event.target.result);
    };

    if (file?.target?.files?.length) {
      reader.readAsDataURL(file.target.files[0]);
    } else {
      reader.readAsDataURL(file);
    }
  };

  return {
    file,
    onSetFile,
  };
};
