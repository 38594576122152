import styled from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const PublicTradingPostStyledWrapper = styled.div`
  display: flex;
  font-size: 18px;
  width: 100%;
`;

export const PublicTradingPostInfoStyledWrapper = styled.div`
  width: 100%;
`;

export const PublicTradingPostLogoStyledImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 10px;
`;

export const PublicTradingPostCropStyledImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin: 0 10px;
`;

export const PublicTradingPostCropNameStyledWrapper = styled.div`
  margin-right: 20px;
`;

export const PublicTradingPostAmountStyledWrapper = styled.div`
  margin-right: 20px;
`;

export const PublicTradingPostPriceStyledWrapper = styled.div`
  margin-right: 20px;
`;

// MOBILE

export const Mobile_PublicTradingPostLogoStyledImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

export const Mobile_PublicTradingPostCropStyledImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 5px;
`;

export const Mobile_PostDateStyledWrapper = styled.p`
  font-size: 12px;
  color: ${colors.darkGrey};
`;

export const MobileTradingPostContactsRootPhoneStyled = styled.a`
  color: ${colors.link};
  text-decoration: none;
  margin-bottom: 4px;
  display: inline-block;
  font-size: 14px;
`;
