import { CiWheat } from "react-icons/ci";
import { LogoShortStyled, LogoStyled } from "./Logo.styled";
import { colors } from "../../../../../constants/styles/colors.constants";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

interface LogoProps {
  size?: "small" | "medium" | "large";
  bridgeLogoBorder?: string;
}

export const LogoShort = (props: LogoProps): ReactElement => {
  const { size, bridgeLogoBorder } = props;

  return (
    <LogoShortStyled bridgeLogoBorder={bridgeLogoBorder}>
      <div>
        <CiWheat style={{ fill: colors.orange }} />
      </div>
    </LogoShortStyled>
  );
};

export const Logo = (props: LogoProps): ReactElement => {
  const { size } = props;

  return (
    <Link
      to="/"
      style={{
        textDecoration: "none",
        color: colors.black,
      }}
    >
      <LogoStyled size={size || "large"}>
        <div>
          <CiWheat style={{ fill: colors.orange }} />
        </div>
        Agro<span>bridges</span>
      </LogoStyled>
    </Link>
  );
};
