import styled, { keyframes } from "styled-components";
import { colors } from "../../../../constants/styles/colors.constants";

export const AdvertisementBannerStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  height: 90px;
  padding-top: 10px;
`;

export const MobileAdvertisementBannerStyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  height: 80px;
`;

export const AdvertisementItemOverlayStyledWrapper = styled.div<{
  color: string;
}>`
  position: absolute;
  height: 90px;
  width: 350px;
  background-color: ${({ color }) => color};
  opacity: 0.7;
  border-radius: 5px;
  transition: 0.3s;
  bottom: 0;
  z-index: 2;
`;

export const AdvertisementItemBackgroundStyledWrapper = styled.div<{
  url: string;
}>`
  border-radius: 5px;
  height: 90px;
  width: 350px;
  background-size: cover;
  background-image: ${({ url }) => `url(${url})`};
`;

export const YourAddStyledWording = styled.p`
  color: ${colors.white};
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.8);
  z-index: 3;
`;

export const MobileYourAddStyledWording = styled.p<{ top: number }>`
  color: ${colors.white};
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: ${({ top }) => `${top}%`}};
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.8);
  z-index: 3;
`;

export const ContactUsStyledWording = styled.p`
  color: ${colors.white};
  font-size: 22px;
  font-weight: 600;
  position: absolute;
  bottom: -40px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  text-shadow: 1px 3px 10px rgba(0, 0, 0, 1);
  z-index: 1;
`;

export const AdvertIsementItemStyledWrapper = styled.div`
  position: relative;
  /* cursor: pointer; */
  overflow: hidden;
  transition: 0.3s;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

  /* &:hover ${YourAddStyledWording} {
    top: -30px;
  }

  &:hover ${AdvertisementItemOverlayStyledWrapper} {
    bottom: -100px;
  }

  &:hover ${ContactUsStyledWording} {
    bottom: 20%;
  } */
`;

const pulsate = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ZSUDonationStyledWrapper = styled.div`
  margin: 20px auto;
  padding: 10px;
  border: 2px solid ${colors.primary};
  border-radius: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  position: relative;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

  a {
    text-decoration: none;
    color: ${colors.white};
  }

  p {
    text-decoration: none;
    color: ${colors.black};
    font-size: 20px;
    text-align: center;
    font-weight: 600;

    span {
      color: ${colors.primary};
    }
  }

  .drone-right {
    width: 80px;
    position: absolute;
    right: 0;
    top: -24px;
    rotate: 20deg;
    z-index: 3;
  }
  .pointer-left {
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: -15px;
    rotate: 20deg;
    animation: ${pulsate} 1.5s infinite;
    z-index: 2;
  }
  .ukr-flag {
    position: absolute;
    right: 0px;
    bottom: 0px;
    rotate: 20deg;
    z-index: 4;
  }
  .mono {
    position: absolute;
    right: 0px;
    bottom: -10px;
    rotate: 10deg;
    border-radius: 50%;

    width: 40px;
    z-index: 1;
  }
`;
