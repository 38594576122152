import React, { ReactElement, useEffect, useState } from "react";

import { TradingAccountEditStyledWrapper } from "./TradingAccountEdit.styled";
import { tradingApi } from "../../../../api/requests/trading.api";
import { Card } from "../../../shared-components/Card/Card";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { Input } from "antd";
import { TradingAccountAvatarEdit } from "../TradingAccountAvatarEdit/TradingAccountAvatarEdit";
import { TradingAccountWidgetStatesEnum } from "../../../../types/enums/TradingAccountWidgetStates.enum";
import { useUploadFile } from "../../../../hooks/use-upload-file-hook/useUploadFile";
import { ConfirmActionsBar } from "../../../shared-components/ConfirmActionsBar/ConfirmActionsBar";
import { AppStore } from "../../../../store/AppStore";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { ValidatedPhoneInput } from "../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";

const convertToCanvasElementFromUrl = (url?: string) => {
  if (!url) {
    return "";
  }

  const canvas = document.createElement("canvas");
  let ctx: any = canvas.getContext("2d");

  // Create new image object
  let img = new Image();

  // Set up the onload event
  img.onload = function () {
    // Draw the image on the canvas
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  // Set the image source
  img.src = url; // load the image
};

export const TradingAccountEdit = observer((): ReactElement => {
  const { tradingAccount, setTradingAccountState, setTradingAccount } =
    useTradingStore();

  const { isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const { name, contacts, logoUrl } = tradingAccount || {};
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const { file, onSetFile } = useUploadFile();
  const [logoFile, setLogoFile] = useState<any>(file);
  useEffect(() => {
    if (!logoUrl) {
      return;
    }

    fetch(logoUrl)
      .then((response) => response.blob()) // convert to Blob instead of JSON
      .then((blob) => {
        const file = new File([blob], "logo.jpg", { type: "image/jpeg" });
        setLogoFile(URL.createObjectURL(file));
      })
      .catch(console.error);
  }, [logoUrl]);

  const [tradingAccountName, setTradingAccountName] = useState<any>(name);
  const [tradingAccountRootPhone, setTradingAccountRootPhone] = useState<any>(
    contacts?.rootPhone
  );

  const updateTradingAccount = async () => {
    const { tradingAccount } = await tradingApi.updateTradingAccount(
      {
        name: tradingAccountName,
        contacts: {
          rootPhone: tradingAccountRootPhone,
        },
      },
      file
    );

    setTradingAccount(tradingAccount);
    setTradingAccountState(TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT);
  };

  const handleCancelEdit = () => {
    setTradingAccountState(TradingAccountWidgetStatesEnum.VIEW_TRADING_ACCOUNT);
  };

  return (
    <Card
      style={{
        width: isMobile() ? "95%" : "48%",
        margin: "0 auto",
      }}
    >
      <TradingAccountEditStyledWrapper $isMobile={isMobile()}>
        <TradingAccountAvatarEdit
          logoUrl={file || logoFile}
          onSetAvatarFile={onSetFile}
        />

        <div
          style={{
            width: "100%",
          }}
        >
          <Input
            placeholder="Назва компанії"
            value={tradingAccountName}
            onChange={(e) => setTradingAccountName(e.target.value)}
            style={{ margin: "8px 0" }}
          />

          <ValidatedPhoneInput
            phone={tradingAccountRootPhone || ""}
            isValid={isPhoneValid}
            onChangePhone={(value) => setTradingAccountRootPhone(value)}
            setIsPhoneValid={setIsPhoneValid}
          />

          <ConfirmActionsBar
            confirmText="Зберегти"
            cancelText="Скасувати"
            handleCancel={handleCancelEdit}
            handleConfirm={updateTradingAccount}
          />
        </div>
      </TradingAccountEditStyledWrapper>
    </Card>
  );
});
