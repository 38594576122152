import { observer } from "mobx-react-lite";
import React, { ReactElement, useState } from "react";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";
import { useTranslation } from "react-i18next";
import {
  languagesCodesToFlagIconMap,
  languagesFullTranslatedNamesMap,
} from "../../../../constants/languages.constants";
import { GrLanguage } from "react-icons/gr";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import {
  LanguageDropdownStyledWrapper,
  LanguageSelectStyledWrapper,
  LanguageValueStyledWrapper,
} from "./LanguageSelect.styled";
import { localStorageUtils } from "../../../../utils/browser-utils/LocalStorage";
import { useClickOutside } from "../../../../hooks/use-click-outside/useClickOutside";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { colors } from "../../../../constants/styles/colors.constants";

const languageOptions = [
  {
    label: languagesFullTranslatedNamesMap[LanguagesEnum.EN],
    value: LanguagesEnum.EN,
  },
  {
    label: languagesFullTranslatedNamesMap[LanguagesEnum.PL],
    value: LanguagesEnum.PL,
  },
  {
    label: languagesFullTranslatedNamesMap[LanguagesEnum.UK],
    value: LanguagesEnum.UK,
  },
];

export const LanguageSelect = observer((): ReactElement => {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useStore(StoresEnum.APP_STORE) as AppStore;
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  const handleOutsideClick = () => {
    if (isLanguageDropdownOpen) {
      setIsLanguageDropdownOpen(false);
    }
  };

  const ref = useClickOutside(handleOutsideClick);

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const changeLanguage = (language: LanguagesEnum) => {
    i18n.changeLanguage(language);
    setLanguage(language);
    localStorageUtils.setLanguage(language);
    toggleLanguageDropdown();
  };

  return (
    <LanguageSelectStyledWrapper>
      <div
        onClick={toggleLanguageDropdown}
        style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
      >
        <div>
          {!isLanguageDropdownOpen ? (
            <FaAngleDown size={25} color={colors.primary} />
          ) : (
            <FaAngleUp size={25} color={colors.primary} />
          )}
        </div>
        <div style={{ marginRight: 5 }}>
          {languagesCodesToFlagIconMap?.[language]}
        </div>
      </div>

      {isLanguageDropdownOpen && (
        <LanguageDropdownStyledWrapper ref={ref}>
          {languageOptions.map((option) => {
            return (
              <LanguageValueStyledWrapper
                onClick={() => changeLanguage(option.value)}
                isSelected={language === option.value}
              >
                <div style={{ marginRight: 4 }}>
                  {languagesCodesToFlagIconMap?.[option.value]}
                </div>
                {option.label}
              </LanguageValueStyledWrapper>
            );
          })}
        </LanguageDropdownStyledWrapper>
      )}
    </LanguageSelectStyledWrapper>
  );
});
