import React, { ReactElement } from "react";

import {
  SidebarRowActionStyledWording,
  SidebarRowClearActionStyledButton,
  SidebarTitleRowStyledWrapper,
} from "./SideBarTitleRow.styled";

interface SideBarTitleRowProps {
  Icon?: ReactElement;
  title?: string;
  clearWording?: string;
  disabled: boolean;
  onClear?: () => void;
}

export const SideBarTitleRow = (props: SideBarTitleRowProps): ReactElement => {
  const { Icon, title = "", clearWording = "Clear", disabled, onClear } = props;

  return (
    <SidebarTitleRowStyledWrapper>
      <SidebarRowActionStyledWording>
        <div style={{ marginRight: 5, marginBottom: -4 }}>{Icon || <></>}</div>
        <span>{title}</span>
      </SidebarRowActionStyledWording>

      <SidebarRowClearActionStyledButton disabled={disabled} onClick={onClear}>
        {clearWording}
      </SidebarRowClearActionStyledButton>
    </SidebarTitleRowStyledWrapper>
  );
};
