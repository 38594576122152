import React, { ReactElement } from "react";
import { PricesInformation } from "./add-post-form-components/PricesInformation/PricesInformation";
import { DescriptionInformation } from "./add-post-form-components/DescriptionInformation/DescriptionInformation";
import { ActionsBar } from "./add-post-form-components/ActionsBar/ActionsBar";
import {
  AddPostFormStyledWrapper,
  StepsStyledWrapper,
} from "./AddPostForm.styled";
import { ConfirmForm } from "../ConfirmForm/ConfirmForm";
import { AddOneMorePostPlug } from "../AddOneMorePostPlug/AddOneMorePostPlug";
import { Steps } from "antd";

import {
  StoresEnum,
  useStore,
} from "../../../../../hooks/use-store-hook/useStore";
import { useAddPostStore } from "../../../../../hooks/use-add-post-store/useAddPostStore";
import { GeneralInformationStep } from "./add-post-form-steps/GeneralInformationStep/GeneralInformationStep";
import { observer } from "mobx-react-lite";
import { PaymentTypesEnum } from "../../../../../types/enums/PaymentTypes.enum";
import { LocationStep } from "./add-post-form-steps/DeliveryInformationStep/LocationStep";
import { ContactsStep } from "./add-post-form-steps/ContsctsStep/ContactsStep";
import { postApi } from "../../../../../api/requests/posts.api";
import { AppStore } from "../../../../../store/AppStore";
import { localStorageUtils } from "../../../../../utils/browser-utils/LocalStorage";
import { UserStore } from "../../../../../store/UserStore";
import { getFormSteps } from "./add-post-form.constants";
import { useAddPostForm } from "./useAddPostForm";

interface AddPostFormProps {
  onCancel: () => void;
}

export const AddPostForm = observer((props: AddPostFormProps): ReactElement => {
  const { onCancel } = props;

  const {
    phone,
    code,
    currentStep,
    postModalData,

    setCurrentStep,
    setIsPhoneAdded,
    setIsPhoneConfirmationLoading,
  } = useAddPostStore();
  const { paymentType, prices } = postModalData;

  const { handlers } = useAddPostForm();
  const { handleChangeConfirmationPhone, handleChangeConfirmationCode } =
    handlers;

  const { countries, isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { user } = useStore(StoresEnum.USER_STORE) as UserStore;
  const isAuthorized = Boolean(
    localStorageUtils.getRefreshToken() && user?.phone
  );

  const handleConfirmPhone = async (value: string) => {
    setIsPhoneConfirmationLoading(true);

    try {
      await postApi.startCreatePost(phone);
    } catch (error) {
      setIsPhoneConfirmationLoading(false);

      return;
    }

    setIsPhoneAdded(true);
    setIsPhoneConfirmationLoading(false);
  };

  const savePost = async () => {
    const country = countries.find(
      (country: any) => country.code === postModalData.country
    );

    const data = await postApi.createPost(
      {
        ...postModalData,
        country: country?.id,
        source: "AGBS-C",
        prices:
          paymentType === PaymentTypesEnum.HIDDEN
            ? {}
            : Object.entries(prices).reduce((acc: any, item: any) => {
                if (item[1] > 0) {
                  return { ...acc, [item[0]]: item[1] };
                }

                return acc;
              }, {}),
      },
      {
        phone,
        code,
      }
    );

    return data;
  };

  const handleSavePost = async () => {
    await savePost();

    setCurrentStep(5);
  };

  return (
    <div>
      <div>
        {!isMobile() && (
          <StepsStyledWrapper>
            <Steps
              responsive
              current={currentStep}
              items={getFormSteps(isAuthorized)}
              labelPlacement="vertical"
            />
          </StepsStyledWrapper>
        )}

        {isMobile() && (
          <div style={{ marginBottom: 20 }}>
            <ActionsBar
              onConfirmPhone={handleConfirmPhone}
              onSavePost={handleSavePost}
              isAuthorized={isAuthorized}
              onCancel={onCancel}
            />
          </div>
        )}

        <AddPostFormStyledWrapper>
          {currentStep === 0 && (
            <>
              <GeneralInformationStep />
              <LocationStep />
            </>
          )}

          {currentStep === 1 && <PricesInformation />}

          {currentStep === 2 && <DescriptionInformation />}

          {currentStep === 3 && <ContactsStep />}

          {currentStep === 4 && (
            <ConfirmForm
              phone={phone}
              onResendCode={handleConfirmPhone}
              onSave={savePost}
              onValidatePhone={handleChangeConfirmationPhone}
              onValidateCode={handleChangeConfirmationCode}
            />
          )}

          {currentStep === 5 && <AddOneMorePostPlug isMobile={isMobile()} />}

          {!isMobile() && (
            <ActionsBar
              onConfirmPhone={handleConfirmPhone}
              onSavePost={handleSavePost}
              isAuthorized={isAuthorized}
              onCancel={onCancel}
            />
          )}
        </AddPostFormStyledWrapper>
      </div>
    </div>
  );
});
