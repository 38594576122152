import { makeAutoObservable } from "mobx";
import { PaymentTypesEnum } from "../types/enums/PaymentTypes.enum";

export interface TradingPostDataInterface {
  crop: string;
  amount?: number;
  country: string;
  states: string[];
  paymentType: PaymentTypesEnum;
  prices: Record<any, number>;
  comment: string;
  phone: string;
  isFullCountry: boolean;
}

export class AddTradingPostStore {
  public tradingPostData: TradingPostDataInterface | null = null;

  constructor() {
    makeAutoObservable(this);

    this.setTradingPost = this.setTradingPost.bind(this);
  }

  setTradingPost(tradingPost: any) {
    this.tradingPostData = tradingPost;
  }
}
