import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { LanguagesEnum } from "../types/enums/Languages.enum";
import { getLanguageFromBrowser } from "../utils/browser-utils/getLanguageFromBrowser";

import translationEN from "./en/translation.json";
import translationPL from "./pl/translation.json";
import translationUK from "./uk/translation.json";

export const resources = {
  [LanguagesEnum.EN]: {
    translation: translationEN,
  },
  [LanguagesEnum.PL]: {
    translation: translationPL,
  },
  [LanguagesEnum.UK]: {
    translation: translationUK,
  },
};

i18next.use(initReactI18next).init({
  resources,
  debug: false,
  lng: getLanguageFromBrowser(),
});
