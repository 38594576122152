import { useEffect } from "react";
import { AppStore } from "../../store/AppStore";
import { StoresEnum, useStore } from "../use-store-hook/useStore";
import { fetchPlans } from "./usePlan.utils";
import { UserStore } from "../../store/UserStore";

export const usePlan = () => {
  const { setPlans, setIsPlansLoading } = useStore(
    StoresEnum.APP_STORE
  ) as AppStore;

  const { user } = useStore(StoresEnum.APP_STORE) as UserStore;

  useEffect(() => {
    fetchPlans(setPlans, setIsPlansLoading);
  }, [user]);
};
