import React, { ReactElement } from "react";

import { TabStyledWrapper, TabsStyledWrapper } from "./Tabs.styled";
import { LanguagesEnum } from "../../../types/enums/Languages.enum";
import { ProfileTabEnum } from "../../../types/enums/ProfileTab.enum";

export interface TabOptionInterface {
  tabValue: string;
  translations: {
    [LanguagesEnum.UK]: string;
    [LanguagesEnum.PL]: string;
    [LanguagesEnum.EN]: string;
  };
}

interface TabsProps {
  language?: LanguagesEnum;
  selectedTab?: string;
  tabOptions: TabOptionInterface[];
  onChange?: (tab: any) => void;
  tabWidth?: number | string;
}

export const Tabs = (props: TabsProps): ReactElement => {
  const {
    language = LanguagesEnum.UK,
    selectedTab = "",
    onChange = () => {},
    tabOptions,
    tabWidth = 160,
  } = props;

  return (
    <TabsStyledWrapper>
      {tabOptions.map((tab: TabOptionInterface) => {
        return (
          <TabStyledWrapper
            onClick={() => onChange(tab.tabValue)}
            $isSelected={selectedTab === tab.tabValue}
            $tabWidth={tabWidth}
          >
            {tab.translations[language]}
          </TabStyledWrapper>
        );
      })}
    </TabsStyledWrapper>
  );
};
