import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { PostsStore } from "../../../../store/PostsStore";

import { usePostStore } from "../../../../hooks/use-posts-store/usePostStore";
import { Filters } from "../../../shared-components/Filters/Filters";
import { useSharedFilters } from "../../../../hooks/use-shared-filters-hook/useSharedFilters";
import { getSellCountryStatesOptions } from "../../../../utils/options-utils/getCountriesOptions";

export const PostFilters = observer((): ReactElement => {
  const {
    handleChangeFilters,
    clearPostFilters,
    checkIsFiltersApplied,
    unstable_filters,
  } = usePostStore();

  const {
    isFiltersOpen,

    setIsFiltersOpen,
  } = useStore(StoresEnum.POSTS_STORE) as PostsStore;

  const {
    localFilters,

    onApplyFilters,
    selectFilter,
    onClearPostFilters,
    setLocalFilters,
  } = useSharedFilters({
    filters: unstable_filters,

    handleChangeFilters,
    clearPostFilters,
    checkIsFiltersApplied,
    setIsFiltersOpen,
  });

  const { cerealCrops: selectedCerealCrops } = localFilters;

  return (
    <Filters
      onClear={onClearPostFilters}
      disabled={!checkIsFiltersApplied()}
      localFilters={localFilters}
      setLocalFilters={setLocalFilters}
      selectedCerealCrops={selectedCerealCrops}
      selectFilter={selectFilter}
      handleApplyFilters={onApplyFilters}
      // mobile
      setIsFiltersOpen={setIsFiltersOpen}
      isFiltersOpen={isFiltersOpen}
      isSell
    />
  );
});
