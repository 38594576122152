import styled from "styled-components";
import { colors } from "../../../constants/styles/colors.constants";

export const SelectPlanCurrencyStyledWrapper = styled.div``;

export const SelectCurrencyStyledWording = styled.p`
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
`;

export const SelectCurrencyStyledWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const CurrencyStyledWrapper = styled.div<{ $isSelected: boolean }>`
  margin-right: 10px;
  margin-bottom: 10px;
  background: ${({ $isSelected }) =>
    $isSelected ? colors.primary : colors.white};
  color: ${({ $isSelected }) => ($isSelected ? colors.white : colors.black)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
`;
