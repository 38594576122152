import React, { ReactElement, useEffect, useState } from "react";

import { TradingPostEditModalStyledWrapper } from "./TradingPostEditModal.styled";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useTradingStore } from "../../../../hooks/use-trading-store-hook/useTradingStore";
import { StatesList } from "../../../shared-components/StatesList/StatesList";
import { PriceForm } from "../../../shared-components/PriceForm/PriceForm";
import { CurrencyEnum } from "../../../../types/enums/Currency.enum";
import { tradingApi } from "../../../../api/requests/trading.api";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { AppStore } from "../../../../store/AppStore";
import { LanguagesEnum } from "../../../../types/enums/Languages.enum";
import { PaymentTypesEnum } from "../../../../types/enums/PaymentTypes.enum";
import { PaymentType } from "../../sell-module/AddPost/AddPostForm/add-post-form-components/PaymentType/PaymentType";
import TextArea from "antd/es/input/TextArea";
import { ValidationInput } from "../../../shared-components/ValidationInput/ValidationInput";
import { ConfirmActionsBar } from "../../../shared-components/ConfirmActionsBar/ConfirmActionsBar";
import { MobileBottomSlideModal } from "../../../shared-components/MobileBottomSlideModal/MobileBottomSlideModal";
import { ValidatedPhoneInput } from "../../../shared-components/ValidatedPhoneInput/ValidatedPhoneInput";
import { toJS } from "mobx";
import { checkIsPhoneValid } from "../../../../hooks/use-add-post-store/useAddPostStore.utils";
import { checkIsAmountValid } from "../CreateTradingPost/CreateTradingPost";

export const TradingPostEditModal = observer((): ReactElement => {
  const {
    isEditPostModalOpen,
    toggleEditPostModal,
    postToEdit,
    setPostToEdit,
    tradingAccountPosts,
    setTradingAccountPosts,
  } = useTradingStore();

  const { language, isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    const isPhoneValid = checkIsPhoneValid(postToEdit?.contacts?.rootPhone);
    const isAmountValid = checkIsAmountValid(parseInt(postToEdit?.amount));
    const isStateValid =
      postToEdit?.isFullCountry || Boolean(postToEdit?.states?.length);
    const isPaymentTypeValid = Boolean(postToEdit?.paymentType);
    const isPriceValid =
      postToEdit?.paymentType === PaymentTypesEnum.HIDDEN
        ? true
        : Boolean(
            Object.values(postToEdit?.prices || {}).some(
              (price: any) => parseInt(price) > 0
            )
          );

    setErrors({
      states: isStateValid,
      paymentType: isPaymentTypeValid,
      price: isPriceValid,
      amount: isAmountValid,
      phone: isPhoneValid,
    });
  }, [postToEdit]);

  const handleEditPost = async () => {
    const { tradingPost } = await tradingApi.updateTradingPost(postToEdit);

    const updatedPosts = tradingAccountPosts.map((post: any) => {
      if (post.id === tradingPost.id) {
        return {
          ...post,
          ...tradingPost,
        };
      }

      return post;
    });

    setTradingAccountPosts(updatedPosts);

    setPostToEdit(null);
    toggleEditPostModal();
  };

  const handleCancelEdit = () => {
    setPostToEdit(null);
    toggleEditPostModal();
  };

  const handleAmountChange = (e: any) => {
    setPostToEdit({ ...postToEdit, amount: Number(e.target.value) });
  };

  const handleStatesChange = (states: any) => {
    setPostToEdit({ ...postToEdit, states });
  };

  const handlePricesChange = (currency: CurrencyEnum) => {
    return (e: any) => {
      setPostToEdit({
        ...postToEdit,
        prices: { ...postToEdit?.prices, [currency]: Number(e.target.value) },
      });
    };
  };

  const handleChangePaymentType = (paymentType: PaymentTypesEnum) => {
    setPostToEdit({ ...postToEdit, paymentType });
  };

  const handleChangeComment = (e: any) => {
    setPostToEdit({
      ...postToEdit,
      descriptions: {
        [LanguagesEnum.UK]: e.target.value,
        [LanguagesEnum.PL]: "",
        [LanguagesEnum.EN]: "",
      },
    });
  };

  const handleChangeIsFullCountry = (isFullCountry: boolean) => {
    setPostToEdit({ ...postToEdit, isFullCountry });
  };

  const handleChangePhone = (phone: string) => {
    setPostToEdit({
      ...postToEdit,
      contacts: { ...postToEdit?.contacts, rootPhone: phone },
    });
  };

  const isHiddenPrice = postToEdit?.paymentType === PaymentTypesEnum.HIDDEN;

  const Content = (
    <TradingPostEditModalStyledWrapper>
      <ValidationInput
        value={postToEdit?.amount}
        isValid={errors.amount}
        placeholder="Об'єм"
        style={{ width: "100%", marginBottom: 10 }}
        onChange={handleAmountChange}
        type="number"
      />
      <StatesList
        value={postToEdit?.states || undefined}
        isValid={errors.states}
        onChange={handleStatesChange}
        isRequired
        isFullCountry={postToEdit?.isFullCountry}
        onChangeIsFullCountry={handleChangeIsFullCountry}
        style={{ width: "100%", margin: "10px 0" }}
        selectStyle={{ width: "100%" }}
        disabled={false}
        showSearch={false}
        mode="multiple"
        country={postToEdit?.country?.code}
        isShortForm={language === LanguagesEnum.UK}
      />

      <PaymentType
        paymentType={postToEdit?.paymentType}
        onChange={handleChangePaymentType}
        isValid={errors.paymentType}
      />

      {isHiddenPrice ? (
        <div
          style={{
            margin: 10,
            fontSize: 16,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Договірна
        </div>
      ) : (
        <PriceForm prices={postToEdit?.prices} onChange={handlePricesChange} />
      )}

      <TextArea
        value={postToEdit?.descriptions?.[LanguagesEnum.UK] || ""}
        placeholder="Опис"
        onChange={handleChangeComment}
        style={{
          marginBottom: 20,
        }}
        maxLength={500}
        showCount
        autoSize={{ minRows: 3, maxRows: 3 }}
      />

      <ValidatedPhoneInput
        phone={postToEdit?.contacts?.rootPhone || ""}
        isValid={errors.phone}
        onChangePhone={handleChangePhone}
        setIsPhoneValid={() => {}}
      />
    </TradingPostEditModalStyledWrapper>
  );

  const Footer = (
    <ConfirmActionsBar
      confirmText="Зберегти"
      cancelText="Скасувати"
      disabled={!Object.values(errors).every((error) => error)}
      handleCancel={handleCancelEdit}
      handleConfirm={handleEditPost}
    />
  );

  if (isMobile()) {
    return (
      <MobileBottomSlideModal
        isOpen={isEditPostModalOpen}
        title="Редагувати"
        onClose={handleCancelEdit}
        Content={
          <div>
            <div
              style={{
                marginBottom: 30,
              }}
            >
              {Footer}
            </div>
            {Content}
          </div>
        }
      />
    );
  }

  return (
    <Modal
      title="Редагувати оголошення"
      open={isEditPostModalOpen}
      onOk={handleEditPost}
      onCancel={handleCancelEdit}
      centered
      footer={Footer}
    >
      {Content}
    </Modal>
  );
});
