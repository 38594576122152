import styled from "styled-components";

export const TradingControlPanelStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
`;

export const PublishedTradingPostList = styled.div`
  width: 100%;
`;
