import styled from "styled-components";
import { DevicesEnum } from "../../../../hooks/use-adaptation-hook/useAdapatation";

export const PostsListStyledWrapper = styled.div<{
  device: DevicesEnum | null;
}>`
  width: ${({ device }) => (device === DevicesEnum.DESKTOP ? "100%" : "100%")};
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
`;
