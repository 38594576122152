import React, { ReactElement } from "react";

import { AccountStyledWrapper } from "./Account.styled";
import { Card } from "../../../shared-components/Card/Card";
import { ListTitle } from "../../../shared-components/ListTitle/ListTitle";
import { PersonalAccount } from "../PersonalAccount/PersonalAccount";
import { CustomButton } from "../../../shared-components/CustomButton/CustomButton";
import { getStandardLocaleDate } from "../../../../utils/date-utils/getStandardLocaleDate";
import { translationMap } from "../../../../i18n/translationMap";
import { colors } from "../../../../constants/styles/colors.constants";
import { useTranslation } from "react-i18next";
import {
  StoresEnum,
  useStore,
} from "../../../../hooks/use-store-hook/useStore";
import { UserStore } from "../../../../store/UserStore";
import { useAddPostStore } from "../../../../hooks/use-add-post-store/useAddPostStore";
import { AppStore } from "../../../../store/AppStore";

interface AccountProps {}

const AccountPersonalInfo = ({ user, isMobile }: any) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        width: isMobile ? "95%" : "48%",
        margin: isMobile ? "0 auto" : "0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start ",
        }}
      >
        <p
          style={{
            fontSize: isMobile ? 26 : 16,
            fontWeight: 600,
          }}
        >
          {t(translationMap.menu.personalInfo)}
        </p>
        {user?.name && (
          <p style={{ fontSize: 20, marginTop: 20 }}>{user?.name}</p>
        )}
        <p style={{ fontSize: 20, marginTop: 10 }}>+{user?.phone}</p>
      </div>
    </Card>
  );
};

const AccountSubscriptionInfo = ({
  user,
  language,
  isMobile,
  isSubscriptionActive,
  onOpenPlansModal,
}: any) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        width: isMobile ? "95%" : "48%",
        margin: isMobile ? "10px auto" : "0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start ",
        }}
      >
        <p
          style={{
            fontSize: isMobile ? 26 : 16,
            fontWeight: 600,
          }}
        >
          {t(translationMap.menu.yourPlan)}
        </p>
        <p style={{ fontSize: 20, marginTop: 20 }}>
          {isSubscriptionActive && user?.subscription?.activeTill ? (
            <p>
              {`${t(translationMap.menu.activeTill)} ${getStandardLocaleDate(
                user?.subscription?.activeTill,
                language
              )}`}
            </p>
          ) : (
            <div>
              <p>{t(translationMap.payment.subscriptionExpirationMessage)}</p>
              <CustomButton
                type="primary"
                style={{ width: "100%", marginTop: "30px" }}
                size="large"
                onClick={onOpenPlansModal}
              >
                {t(translationMap.payment.choosePlan)}
              </CustomButton>
            </div>
          )}
        </p>
      </div>
    </Card>
  );
};

export const Account = (props: AccountProps): ReactElement => {
  const {} = props;

  const { language, isMobile } = useStore(StoresEnum.APP_STORE) as AppStore;
  const { user, isSubscriptionActive, setPlanModalOpenClose } = useStore(
    StoresEnum.USER_STORE
  ) as UserStore;

  const handleOpenPlansModal = () => {
    setPlanModalOpenClose();
  };

  return (
    <div>
      <ListTitle title="Особистий кабінет" />

      <AccountStyledWrapper $isMobile={isMobile()}>
        <AccountPersonalInfo user={user} isMobile={isMobile()} />

        <AccountSubscriptionInfo
          user={user}
          language={language}
          isSubscriptionActive={isSubscriptionActive}
          onOpenPlansModal={handleOpenPlansModal}
          isMobile={isMobile()}
        />
      </AccountStyledWrapper>
    </div>
  );
};
