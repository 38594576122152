import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import { HiddenPaymentPlug } from "../HiddentPaymentPlug";

import { PaymentTypesEnum } from "../../../../../../../../types/enums/PaymentTypes.enum";

import { useAddPostForm } from "../../../useAddPostForm";

import { PriceForm } from "../../../../../../../shared-components/PriceForm/PriceForm";

export const CurrenciesLists = observer((): ReactElement => {
  const { state, handlers } = useAddPostForm();

  const { paymentType, prices } = state;
  const { handleChangePrice } = handlers;

  const isPaymentHidden = paymentType === PaymentTypesEnum.HIDDEN;

  if (isPaymentHidden) {
    return <HiddenPaymentPlug />;
  }

  return <PriceForm prices={prices} onChange={handleChangePrice} />;
});
