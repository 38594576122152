import { addUtmMarkToUrl } from "../../utils/url-utils/addUtmMarkToUrl";
import { RefreshTokenResponseInterface } from "../../types/interfaces/RefreshToken.interface";
import { httpClient } from "../httpClient/HttpClient";

export class UserApi {
  private USER_ENDPOINT = "user";
  private AUTH_ENDPOINT = "auth";

  startSignUp = async (phone: string, isAccepted: boolean): Promise<any> => {
    try {
      const data = await httpClient.post(`${this.AUTH_ENDPOINT}/start-signup`, {
        phone,
        isAccepted,
      });

      if (data?.errors?.length) {
        return { isStarted: false, errors: data.errors };
      }

      return { isStarted: data, errors: null };
    } catch (error: any) {
      return { isStarted: false, errors: error.errors };
    }
  };

  signUp = async (
    phone: string,
    code: string,
    name = "",
    isAccepted: boolean,
    planMarked: boolean
  ): Promise<any> => {
    const data = (await httpClient.post(
      addUtmMarkToUrl(`${this.AUTH_ENDPOINT}/signup`),
      {
        phone,
        code,
        name,
        isAccepted,
        planMarked,
      }
    )) as any;

    if (data.errors?.length) {
      return { isSignedUp: false, errors: data.errors };
    }

    return { ...data, isSignedUp: true, errors: null };
  };

  startSignIn = async (phone: string): Promise<any> => {
    try {
      const data = await httpClient.post(`${this.AUTH_ENDPOINT}/start-signin`, {
        phone,
      });

      if (data?.errors?.length) {
        return { isStarted: false, errors: data.errors };
      }

      return {
        isStarted: data.isStarted,
        isTelegram: data.isTelegram,
        errors: null,
      };
    } catch (error: any) {
      return { isStarted: false, errors: error.errors };
    }
  };

  signIn = async (phone: string, code: string): Promise<any> => {
    const data = await httpClient.post(`${this.AUTH_ENDPOINT}/signin`, {
      phone,
      code,
    });

    if (data.errors?.length) {
      return { isSignedIn: false, errors: data.errors };
    }

    return { ...data, isSignedIn: true, errors: null };
  };

  refreshToken = async (refreshToken: string): Promise<any> => {
    const data = (await httpClient.post(`${this.AUTH_ENDPOINT}/refresh-token`, {
      refreshToken,
    })) as { data: RefreshTokenResponseInterface };

    return data;
  };

  async getUserById(id: string): Promise<any> {
    const GET_USER_ENDPOINT = `${this.USER_ENDPOINT}/${id}`;

    const data = await httpClient.get(GET_USER_ENDPOINT);

    return data;
  }
}

export const userApi = new UserApi();
